.tabela-legenda-div {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    #legenda-mensagem {
        /* color: #243B53; */
        width: 420px;
        height: 22px;
        
        font-weight: 400;
        font-size: 16px;
        
        display: flex;
        align-items: center;
    }
    
    #legenda-botao {
        background: none;
        border: none;

        font-weight: 700;
        font-size: 13px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
    
        color: #33CC99;
    }
}
