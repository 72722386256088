.modal-cadastro-operadora{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 58.125em!important;
}

.grupo-cadastro-operadora-1{
    width: 100%!important;
    display: flex!important;
}

.coluna-cadastro-operadora-1{
    width: 31%!important;
    margin-right: 3%!important;
}

.coluna-cadastro-operadora-2{
    width: 31%!important;
    margin-right: 3%!important;
}

.coluna-cadastro-operadora-3{
    width: 12%!important;
    margin-right: 3%!important;
}

.coluna-cadastro-operadora-4{
    width: 17%!important;
}

.divisoria-cadastro-operadoras-faturas{
    width: 100%!important;
    height: 0.0625em!important;
    margin-top: 0.5em!important;
    margin-bottom: 1.5em!important;
}