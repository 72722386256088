.modal-cadastro-sistema{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 40em!important;
}

.grupo-cadastro-sistema-1{
    width: 100%!important;
    display: flex!important;
}

.coluna-cadastro-sistema-1{
    width: 48.5%!important;
    margin-right: 3%!important;
}

.coluna-cadastro-sistema-2{
    width: 48.5%!important;
}