/******************** PROCESSAR PEDIDO ********************/
.modal-acao-processar-pedido{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 45.25em!important;
}

/******************** CANCELAR PEDIDO ********************/
.modal-acao-cancelar-pedido{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 45.25em!important;
}

/******************** CONCLUIR PEDIDO ********************/
.modal-acao-concluir-pedido{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 45.25em!important;
}

.container-valor-pedido {
    display: inline-flex !important;
    width: 100% !important;
    height: 20px !important;
}

.valor-rs-pedido {
    width: 20% !important;
    height: 100% !important;
}

.valor-pedido {
    text-align: right !important;
    width: 45% !important;
    font-size: 1em!important;
    margin-left: 5px!important;
}

.coluna-novo-pedido-sms-3{
    margin-bottom: 10px !important;
    width: 49% !important;
}

.campo-texto-select-tamanho{
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 100% !important;
    text-align: left !important;
}

.campo-texto-select-tamanho-2{
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 100% !important;
    text-align: left !important;
    margin-top: 6px;
}