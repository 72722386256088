/*********** CLASSES GENERICAS DO SISTEMA ***********/
/* manter classes nomeadas de modo genérico.        */
/* Sempre verifique se a classe que você procura já */
/* existe. As classes listadas aqui são reutiliza-  */
/* das por todo o Sistema. Caso queira alterar      */
/* detalhes particulares de cada elemento em telas  */
/* específicas, crie um arquivo CSS exclusivo para  */
/* a tela em questão e utilize classes que referen- */
/* ciem somente a própria, evitando conflitos.      */
/***** MATEUS FOLLETTO - 15/05/2020 - VIRTUEYES *****/
/******************** BASE DO SISTEMA ********************/
* {
  /* BASE DO SISTEMA - NAO MEXER */
  box-sizing: border-box !important;
  /*font-size: 16px!important;*/
}

html,
body {
  /* BASE DO SISTEMA - NAO MEXER */
  height: 100% !important;
  width: 100% !important;
  /*font-size: 16px!important;*/
  display: block !important;
  background-color: black !important;
  overflow: hidden !important;
}

body {
  zoom: 80% !important;
}

#root {
  /* BASE DO SISTEMA - NAO MEXER */
  display: inline-flex !important;
  height: 100% !important;
  width: 100% !important;
  flex-wrap: nowrap !important;
  -ms-flex-wrap: wrap !important;
}

/******************** FONTES ********************/
@font-face {
  src: url('../fonts/Roboto-Light.ttf');
  font-family: RobotoLigth;
}

@font-face {
  src: url('../fonts/Roboto-Medium.ttf');
  font-family: RobotoMedium;
}

@font-face {
  src: url('../fonts/Roboto-Bold.ttf');
  font-family: RobotoBold;
}

@font-face {
  src: url('../fonts/Roboto-Regular.ttf');
  font-family: RobotoRegular;
}

/******************** ESTRUTURA DO SISTEMA ********************/
#container-sistema {
  /* CONTAINER DO SISTEMA - ENGLOBA TUDO*/
  height: 100% !important;
  width: 100% !important;
}

#container-tela {
  /* CONTAINER DA TELA DO SISTEMA - ENGLOBA CABECALHO E CORPO */
  height: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column !important;
}

#container-corpo {
  /* CONTAINER DO ESPACO ONDE AS TELAS RENDERIZAM */
  position: relative;
  height: 100% !important;
  margin-top: 2.125em !important;
  margin-left: 1.875em !important;
  margin-right: 1.875em !important;
  margin-bottom: 2.1875em !important;
  overflow: auto !important;
}

/******************** LABELS ********************/
.label-janela {
  /* TEXTO DOS TITULOS DAS JANELAS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1.375em !important;
  line-height: 1.8125em !important;
  font-weight: 700 !important;
}

.label-integracao {
  /* TEXTO DOS TITULOS DAS JANELAS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1.375em !important;
  line-height: 1.8125em !important;
  font-weight: 700 !important;
}

.label-integracao-alteracao-ciclo {
  /* TEXTO DOS TITULOS DAS JANELAS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1.375em !important;
  line-height: 1.8125em !important;
  font-weight: 700 !important;
}

.label-campo {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
}

.label-campo-simcard {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  margin-left: 19px;
}

.label-campo-extra {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  margin-left: -30px;
}

.label-campo-filtro {
  /*TEXTO DOS TITULOS DOS CAMPOS DE FILTRO DO SISTEMA*/
  font-family: RobotoRegular !important;
  font-size: 1.3em !important;
  line-height: 1em !important;
  margin-left: 0px;
}

.label-campo-operadora {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
}

.label-campo-status {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 0px;
}

.label-campo-sms {
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  margin-left: -33px;
}

.label-campo-sms-b {
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  margin-left: 81px;
}

.label-campo-tecnologia {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 2px;
}

.label-campo-status-contrato {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 57px;
}

.label-campo-status-cliente-final {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 28px;
  padding-left: 107px;
}

.label-campo-status-grupo {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 114px;
}

.label-campo-status-contrato-marca {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 97px;
}

.label-campo-status-contrato-2 {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 71px;
}

.label-campo-trafego {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 11px;
}
.label-campo-conexoes {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 63px;
}

.label-campo-pacotes {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 47px;
}

.label-campo-consumo {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 118px;
  font-weight: 200;
}

.label-campo-valor {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 4px;
}

.label-campo-valor-invalido {
  /* TEXTO DE AVISO */
  font-family: RobotoRegular !important;
  /* font-size: 1em!important; */
  line-height: 1em !important;
  margin-left: -3.3em !important;
  font-size: 0.875em !important;
  color: red;
}

.label-campo-dias-invalido {
  /* TEXTO DE AVISO */
  font-family: RobotoRegular !important;
  /* font-size: 1em!important; */
  line-height: 1em !important;
  margin-left: -2.3em !important;
  font-size: 0.875em !important;
  color: red;
}

.label-campo-conexao-invalido {
  /* TEXTO DE AVISO */
  font-family: RobotoRegular !important;
  /* font-size: 1em!important; */
  line-height: 1em !important;
  margin-left: 3.4em !important;
  font-size: 0.799em !important;
  color: red;
  margin-top: 6px;
}

.label-campo-consumo-invalido {
  /* TEXTO DE AVISO */
  font-family: RobotoRegular !important;
  /* font-size: 1em!important; */
  line-height: 1em !important;
  margin-left: -63px;
  font-size: 0.799em !important;
  color: red;
  margin-top: 6px;
}

.label-campo-saldo-invalido {
  /* TEXTO DE AVISO */
  font-family: RobotoRegular !important;
  /* font-size: 1em!important; */
  line-height: 1em !important;
  margin-left: -18.8em !important;
  font-size: 0.83em !important;
  color: red;
}

.label-campo-plano {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  margin-left: -27px;
}

.label-campo-p {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
}

.label-campo-evento {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 28px;
}

.label-campo-ativacao {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 56px;
}

.label-campo-aquisicao {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 40px;
}

.label-campo-valor-2 {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 39px;
}

.label-campo-dias {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  padding-left: 22px;
}

.label-normal {
  /* TEXTO DOS TEXTOS E CONTEUDOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
}

.label-alerta {
  /* TEXTO DOS ALERTAS DE CAMPOS ORBIGATORIOS */
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1.5em !important;
}

.label-titulo-mensagem {
  /* TEXTO DOS TITULOS DAS MENSAGENS */
  font-family: RobotoBold !important;
  font-weight: 700 !important;
  font-size: 1.875em !important;
  line-height: 1.5em !important;
  word-break: break-all !important;
}

.label-corpo-mensagem {
  /* TEXTO DO CORPO DAS MENSAGENS */
  font-family: RobotoRegular !important;
  font-size: 1.125em !important;
  line-height: 1.5em !important;
  word-break: break-all !important;
}

.label-tabela-nao-permitidos {
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1.5em !important;
  text-align: center !important;
}

.label-tabela-1 {
  /* TEXTO DO CORPO DAS TABELAS */
  font-family: RobotoRegular !important;
  font-size: 16px !important;
  line-height: 1.5em !important;
  display: inline;
  padding-left: 0px;
}

.label-tabela-2 {
  /* TEXTO DO CORPO DAS TABELAS */
  cursor: pointer !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1.5em !important;
  margin-top: -0.625em !important;
}

.label-tabela-2 {
  /* TEXTO DO CONTEUDOS ESPECIFICOS QUE PRECISAM DE TRATAMENTO DIFERENCIADO */
  font-family: RobotoRegular !important;
  font-size: 1.4em !important;
  line-height: 1.4em !important;
  height: 0.25em !important;
}

.label-tabela-3 {
  /* TEXTO DO CORPO DAS TABELAS */
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1.5em !important;
  display: inline;
}

.label-tabela-4 {
  /* TEXTO DO CORPO DAS TABELAS */
  font-family: RobotoRegular !important;
  font-size: 0.975em !important;
  line-height: 1.5em !important;
  display: inline;
}

.label-tabela-tooltip {
  cursor: default !important;
}

.label-tabela-itens-exibidos {
  /* TEXTO DA QUANTIDADE DE ITENS EXIBIDOS NAS TABELAS */
  /* color: #36D896!important; */
  font-weight: 700 !important;
  margin-right: 0.3125em !important;
  margin-left: 0.3125em !important;
  margin-top: -1px !important;
  font-size: 1em !important;
}

.label-elipses {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.centralizar {
  /* CLASSE PADRAO PARA ALINHAR TEXTOS DE MODO CENTRALIZADO */
  text-align: center !important;
}

.negrito {
  font-weight: 700 !important;
}

.nao-selecionavel {
  /* NAO DEIXA O TEXTO SER SELECIONAVEL COM O MOUSE */
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.texto-input-customizado-calendario-filtro {
  margin-top: -1.652em;
  position: relative;
  margin-left: 38px;
  width: 0px;
  margin-top: -30px;
}

.texto-input-customizado-calendario-filtro-b {
  margin-top: -1.652em;
  position: relative;
  margin-left: 11px;
  width: 0px;
  margin-top: -30px;
}

.texto-input-customizado-calendario-filtro-c {
  margin-top: -1.652em;
  position: relative;
  margin-left: 1px;
  width: 0px;
  margin-top: -30px;
}

.texto-input-customizado-calendario {
  margin-top: -1.652em;
  position: relative;
  margin-left: 7px;
  width: 0px;
  margin-top: -27px;
}

.texto-input-customizado-calendario-solicitacao {
  margin-top: -1.652em;
  position: relative;
  margin-left: 14px;
  width: 0px;
  margin-top: -27px;
}

.texto-input-customizado-calendario-b {
  margin-top: -1.652em;
  position: relative;
  margin-left: 8px;
  width: 0px;
  margin-top: -27px;
}

.fonte-cor-1 {
  margin: 0;
}

.fonte-cor-3 {
  margin: 0;
}

.fonte-cor-2 {
  margin: 0;
}

.fonte-cor-6 {
  margin: 0;
}

.fonte-cor-7 {
  margin: 0;
}

.fonte-cor-12 {
  margin: 0;
}

.fonte-barra-filtro {
  margin: 0;
}

.contador-carateres-restantes {
  float: right !important;
  margin-bottom: 1.4375em !important;
}

/******************** CAMPOS DE INPUT ********************/
.campo-texto {
  /* CLASSE PADRAO PARA CAMPOS DE INPUT */
  height: 2em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: -webkit-inline-box !important;
}

.campo-texto-material {
  /* CLASSE PADRAO PARA CAMPOS DE INPUT */
  height: 3.2em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: -webkit-inline-box !important;
}

.campo-texto-calendario-filtro {
  height: 35px;
  width: 93% !important;
  min-width: 146px;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: -webkit-inline-box !important;
  outline: none !important;
  padding-left: 3.2em !important;
  margin-top: 3px;
  margin-left: 28px;
}

.campo-texto-calendario-filtro-b {
  height: 35px;
  width: 162px !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: -webkit-inline-box !important;
  outline: none !important;
  padding-left: 3.2em !important;
  margin-top: 3px;
  margin-left: -2px;
}

.campo-texto-calendario-filtro-c {
  height: 35px;
  width: 85% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: -webkit-inline-box !important;
  outline: none !important;
  padding-left: 3.2em !important;
  margin-top: 3px;
  margin-left: -9px;
}

.campo-texto-calendario {
  height: 2em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: -webkit-inline-box !important;
  outline: none !important;
  padding-left: 3.2em !important;
}

.campo-texto-filtro {
  /* CLASSE PARA CAMPOS DE INPUT DOS FILTROS */
  height: 2em !important;
  width: calc(100% - 2.5em) !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: -webkit-inline-box !important;
  margin-top: 0.4125em !important;
  margin-bottom: 0.4125em !important;
  margin-left: 1.3em !important;
}

.campo-texto-filtro-b {
  /* CLASSE PARA CAMPOS DE INPUT DOS FILTROS */
  height: 2em !important;
  width: calc(100% - 2.5em) !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: -webkit-inline-box !important;
  margin-top: 0.4125em !important;
  margin-bottom: 0.4125em !important;
  margin-left: 1.3em !important;
}

.campo-texto-area {
  /* CLASSE PADRAO PARA CAMPOS DE TEXT AREA */
  height: 6em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 0.3125em !important;
  display: -webkit-inline-box !important;
  resize: none;
}

/******************** CAMPOS SELECT ********************/
.campo-select {
  /* CLASSE PADRAO PARA CAMPOS SELECT */
  height: 2em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
}

.campo-select-material {
  /* CLASSE PADRAO PARA CAMPOS SELECT ESTILO MATERIAL */
  height: 3.2em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
}

.campo-select-grupo-simcard {
  /* CLASSE PADRAO PARA CAMPOS SELECT */
  height: 2em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
}

.campo-select-icone-desativado {
  /* CLASSE PARA ROTACIONAR O ICONE DE DROPDOWN QUANDO DESATIVADO */
  transform: rotate(-90deg) !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.campo-select-icone-desativado-drop-filtros {
  /* CLASSE PARA ROTACIONAR O ICONE DE DROPDOWN QUANDO DESATIVADO */
  transform: rotate(-90deg) !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.campo-select-icone-ativado {
  /* CLASSE PARA POSICIONAR O ICONE DE DROPDOWN CORRETAMENTE QUANDO ATIVADO */
  margin-top: -0.02em !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.campo-select-triangulo {
  /* CLASSE PADRAO PARA POSICIONAR O TRIANGULO QUE AJUDA A DAR FORMA AO CORPO DO SELECT */
  width: 0 !important;
  height: 0 !important;
  border-top: 0.9375em solid transparent !important;
  border-left: 0.9375em solid transparent !important;
  border-right: 0.9375em solid transparent !important;
  margin-left: 3em !important;
  margin-top: -0.9em !important;
}

.campo-select-triangulo-grupo-simcard {
  /* CLASSE PADRAO PARA POSICIONAR O TRIANGULO QUE AJUDA A DAR FORMA AO CORPO DO SELECT */
  width: 0 !important;
  height: 0 !important;
  border-top: 0.9375em solid transparent !important;
  border-left: 0.9375em solid transparent !important;
  border-right: 0.9375em solid transparent !important;
  margin-left: 9em !important;
  margin-top: -0.9em !important;
}

.campo-select-triangulo-2 {
  /* CLASSE PADRAO PARA POSICIONAR O TRIANGULO QUE AJUDA A DAR FORMA AO CORPO DO SELECT */
  width: 0 !important;
  height: 0 !important;
  border-top: 0.9375em solid transparent !important;
  border-left: 0.9375em solid transparent !important;
  border-right: 0.9375em solid transparent !important;
  margin-left: 3em !important;
  margin-top: -0.9em !important;
}

.campo-select-triangulo-menor-cor-2 {
  /* TRIANGULO DOS FILTROS E DAS AÇÕES MENOR  */
  width: 0 !important;
  height: 0 !important;
  border-top: 0.9375em solid transparent !important;
  border-left: 0.7em solid transparent !important;
  border-right: 0.7em solid transparent !important;
  margin-left: 1em !important;
  margin-top: -0.9em !important;
}

.campo-select-triangulo-menor-cor-2-novo {
  /* TRIANGULO DOS FILTROS E DAS AÇÕES MENOR  */
  position: relative !important;
  right: -630px;
  width: 0 !important;
  height: 0 !important;
  border-top: 0.9375em solid transparent !important;
  border-left: 0.7em solid transparent !important;
  border-right: 0.7em solid transparent !important;
  margin-left: 1em !important;
  margin-top: -0.9em !important;
  border-bottom: 0.6em solid #252626 !important;
}

.campo-select-triangulo-menor-cor-2-simcard {
  /* TRIANGULO DOS FILTROS E DAS AÇÕES MENOR  */
  width: 0 !important;
  height: 0 !important;
  border-top: 0.9375em solid transparent !important;
  border-left: 0.7em solid transparent !important;
  border-right: 0.7em solid transparent !important;
  margin-left: 41em !important;
  margin-top: -0.9em !important;
}

.campo-select-triangulo-menor-cor-3 {
  /* TRIANGULO DOS FILTROS E DAS AÇÕES MENOR  */
  width: 0 !important;
  height: 0 !important;
  border-top: 0.9375em solid transparent !important;
  border-left: 0.7em solid transparent !important;
  border-right: 0.7em solid transparent !important;
  margin-left: 3em !important;
  margin-top: -0.9em !important;
}

.campo-select-corpo {
  /* CLASSE PADRAO PARA O CORPO DO SELECT */
  max-height: 10em !important;
  width: 100% !important;
  border-radius: 0.3125em !important;
  overflow-x: hidden !important;
  z-index: 500 !important;
  position: relative !important;
}

.campo-select-comeco {
  /* CLASSE PADRAO PARA O ITEM NAO CLICAVEL QUE INICIA O SELECT */
  margin-left: -2.5em !important;
  width: 150% !important;
  height: 1.875em !important;
  line-height: 1.75em !important;
}

.campo-select-opcao {
  /* CLASSE PADRAO PARA OS ITEMS DO SELECT */
  margin-left: -2.5em !important;
  width: 150% !important;
  height: 1.875em !important;
  line-height: 1.75em !important;
  cursor: pointer !important;
}

.campo-select-opcao-grupo-simcard {
  /* CLASSE PADRAO PARA OS ITEMS DO SELECT */
  margin-left: -2.5em !important;
  width: 121% !important;
  height: 1.875em !important;
  line-height: 1.75em !important;
  cursor: pointer !important;
}

.campo-select-generico-opcao {
  margin-left: -2.5em !important;
  width: 112% !important;
  height: 1.875em !important;
  line-height: 1.75em !important;
  cursor: pointer !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: flex;
}

.campo-select-generico-opcao-b {
  margin-left: -2.5em !important;
  width: 112% !important;
  height: 1.875em !important;
  line-height: 1.75em !important;
  cursor: pointer !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: flex;
}

.campo-select-opcao-label {
  /* CLASSE PADRAO PARA ALINHAR AS LABELS DOS ITEMS DO SELECT */
  width: 80% !important;
  margin-left: 1.5em !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.campo-select-divisor {
  /* CLASSE PADRAO PARA AS LINHAS DIVISORIAS ENTRE OS ITEMS DO SELECT */
  height: 0.0625em !important;
  width: 100% !important;
  margin-left: -1.2em !important;
}

.campo-select-lista {
  /* CLASSE PADRAO PARA LIMPAR BULLETS DA LISTA DE ITEMS DOS SELECTS */
  list-style-type: none !important;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
}

.campo-select-lista-status-contrato {
  /* CLASSE PADRAO PARA LIMPAR BULLETS DA LISTA DE ITEMS DOS SELECTS */
  list-style-type: none !important;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
  margin-left: -48px;
}

.campo-select-lista-filtro {
  /* CLASSE PADRAO PARA LIMPAR BULLETS DA LISTA DE ITEMS DOS SELECTS */
  list-style-type: none !important;
}

/******************** BOTOES ********************/
.botao-fechar-janela {
  /* CLASSE PADRAO PARA POSICIONAMENTO DO BOTAO DE FECHAR JANELA */
  background-color: transparent !important;
  border-color: transparent !important;
  float: right !important;
  padding: 0 !important;
  margin-top: 0.05em !important;
  box-shadow: none !important;
}

.botao-fechar-janela-alteracao-ciclo {
  /* CLASSE PADRAO PARA POSICIONAMENTO DO BOTAO DE FECHAR JANELA */
  background-color: transparent !important;
  border-color: transparent !important;
  float: right !important;
  padding: 0 !important;
  margin-top: -2.5em !important;
  box-shadow: none !important;
  position: absolute;
  margin-left: 887px;
}

.botao-fechar-janela-icone {
  /* CLASSE PADRAO PARA DIMENSIONAR O ICONE DO BOTAO FECHAR */
  height: 1.5em !important;
  width: 2.1875em !important;
  margin-top: 4px;
}

.botao-fechar-janela-icone-barra-filtragem {
  /* FERRAR BARRA DE FILTRAGEM */
  height: 1.5em !important;
  width: 2.1875em !important;
  margin-top: 10px;
}

.botao-fechar-janela-icone-barra-dados-cliente {
  /* FERRAR BARRA DE FILTRAGEM */
  height: 1.5em !important;
  width: 2.1875em !important;
  margin-top: 11px;
}

.botao-confirmar {
  /* CLASSE PADRAO PARA BOTOES DE CONFIRMACAO */
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2.375em !important;
  min-width: 6.875em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  /* border: 0.09375em solid #36D896!important; */
  /* background-color: #36D896!important; */
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 0.625em !important;
  float: right !important;
}

.botao-cancelar {
  /* CLASSE PADRAO PARA BOTOES DE CANCELAMENTO */
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2.375em !important;
  min-width: 6.875em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  border: 0.09375em solid #36d896 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 0.625em !important;
  float: right !important;
}

.botao-expandir-row {
  height: 1.875em !important;
  width: 1.875em !important;
  border-radius: 3.125em !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.botao-expandir-row:active,
.botao-expandir-row:focus {
  box-shadow: none !important;
}

.botoes-tabela-acoes {
  box-shadow: none !important;
  background-color: transparent !important;
  border-color: transparent !important;
  width: 1.7em !important;
  height: 1.7em !important;
}

/******************** OVERLAY DAS MODAIS ********************/
.componente-modal-overlay {
  /* CLASSE PADRÃO PARA OVERLAY DE MODAIS */
  position: absolute !important;
  z-index: 2000 !important;
  top: 0% !important;
  left: 0% !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.componente-modal-overlay-cabecalho {
  position: absolute !important;
  z-index: 9000 !important;
  top: 0% !important;
  left: 0% !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.componente-modal-overlay-tutorial,
.componente-modal-overlay-novidades {
  position: absolute !important;
  z-index: 2000 !important;
  top: 0% !important;
  left: 0% !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.componente-modal-overlay-filtro-arquivo {
  position: absolute !important;
  z-index: 1000 !important;
  /* top: 9.8px!important; */
  left: 0% !important;
  /* height: 98.5%!important; */
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: 4px !important;
}

.componente-modal-overlay-filtro-arquivo-virtueyes-clientes {
  position: absolute !important;
  z-index: 1000 !important;
  top: 10px !important;
  left: 0% !important;
  height: 94% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: 4px !important;
}

.componente-modal-overlay-filtro-arquivo-simcard {
  position: absolute !important;
  z-index: 1000 !important;
  top: -7.2px !important;
  left: -3% !important;
  height: 1270.5% !important;
  width: 157% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: 4px !important;
}

.componente-modal-overlay-botao-filtro-virtueyes-clientes {
  position: absolute !important;
  z-index: 1000 !important;
  top: -41px !important;
  left: 0% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  height: 1.9em !important;
  width: 7.8em !important;
  border-color: transparent !important;
  border-radius: 3.125em !important;
}

.componente-modal-overlay-botao-filtro {
  position: absolute !important;
  z-index: 1000 !important;

  top: -41.8px !important;
  left: 0% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  height: 2em !important;
  width: 7.8em !important;
  border-color: transparent !important;
  border-radius: 3.125em !important;
}

.componente-modal-overlay-botao-filtro-triangulo {
  position: absolute !important;
  z-index: 1000 !important;
  width: 0 !important;
  height: 0 !important;
  border-top: 0.9375em solid transparent !important;
  border-left: 0.7em solid transparent !important;
  border-bottom: 0.6em solid rgba(0, 0, 0, 0.5) !important;
  border-right: 0.7em solid transparent !important;
  top: 17px !important;
  left: 48px !important;
}

.componente-modal-overlay-botao-filtro-triangulo-sombra-filtro-arquivo {
  position: absolute !important;
  z-index: 1000 !important;
  width: 0 !important;
  height: 0 !important;
  border-top: 0.9em solid transparent !important;
  border-left: 0.7em solid transparent !important;
  border-bottom: 0.6em solid rgba(0, 0, 0, 0.5) !important;
  border-right: 0.7em solid transparent !important;
  top: 18px !important;
  left: 16px !important;
}

.componente-modal-mensagem {
  /* MODAL DE MENSAGENS QUE APARECE EM QUALQUER TELA*/
  /* CSS ANTIGO  */
  position: absolute !important;
  left: 50% !important;
  transform: translate(-50%, -0%) !important;
  margin-top: 4.8125em !important;

  /* CSS NOVO */
  /* position: absolute!important;
      left: 31%!important;
      margin-top: 4.8125em!important;
      transform: translate(-50%, -0%)!important; */
}

.componente-modal-container {
  /* CLASSE PADRÃO PARA CENTRALIZAR MODAIS HORIZONTALMENTE*/
  position: absolute !important;
  left: 50% !important;
  transform: translate(-50%, -0%) !important;
}

.componente-spinner-overlay {
  /* CLASSE PADRÃO PARA OVERLAY DE MODAIS */
  position: absolute !important;
  z-index: 1000 !important;
  top: 0% !important;
  left: 0% !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.componente-spinner-overlay-filtro-simcard {
  /* CLASSE PADRÃO PARA OVERLAY DE MODAIS */
  position: absolute !important;
  z-index: 1000 !important;
  top: 0% !important;
  left: 0% !important;
  height: 100% !important;
  width: 152% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.componente-spinner-container {
  /* CLASSE PADRÃO PARA CENTRALIZAR MODAIS HORIZONTALMENTE*/
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

/******************** ICONES ********************/
.icone-label-janela {
  /* DIMENSIONA E POSICIONA CORRETAMENTE O ICONE DE FECHAR JANELA */
  height: 0.375em !important;
  width: 0.375em !important;
  margin-right: 0.625em !important;
  margin-bottom: 0.5em !important;
  border-radius: 6.25em !important;
  background-color: #36d896 !important;
}

.icone-alerta {
  /* DA COR E DIMENSIONA CORRETAMENTE O ICONE DE ALERTA */
  color: #cd2d37 !important;
  width: 1em !important;
  margin-top: -0.1125em !important;
}

.mensagem-icone {
  /* CLASSE PADRAO PARA  DAS MENSAGENS*/
  height: 3.125em !important;
  width: 3.125em !important;
  margin-top: 1.5625em !important;
  margin-bottom: 1.25em !important;
  left: 50% !important;
}

.icone-botao-expandir-row {
  /* POSICIONA CORRETAMENTE O ICONE DE EXPANDIR ROW */
  margin-top: -0.4375em !important;
  margin-left: -0.25em !important;
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.icone-botao-expandir-row-ativado {
  /* POSICIONA CORRETAMENTE O ICONE DE EXPANDIR ROW */
  margin-top: -0.4375em !important;
  margin-left: -0.25em !important;
  transform: rotate(-180deg);
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.campo-calendario img {
  /* POSICIONA CORRETAMENTE O ICONE DO CALENDARIO */
  float: right !important;
}

.icone-calendario {
  /* DIMENSIONA CORRETAMENTE O ICONE DO CALENDARIO */
  height: 1em !important;
  width: 1em !important;
}

.posicao-icone-calendario-filtro {
  position: relative !important;
  margin-top: -1.625em !important;
  margin-left: 89% !important;
}

.posicao-icone-calendario-filtro-b {
  position: relative !important;
  margin-top: -1.625em !important;
  margin-left: 79% !important;
}

.posicao-icone-calendario-filtro-c {
  position: relative !important;
  margin-top: -1.625em !important;
  margin-left: 68% !important;
}

.posicao-icone-calendario {
  position: relative !important;
  margin-top: -1.625em !important;
  margin-left: 90% !important;
}

/******************** MENSAGENS ********************/
.mensagem-overlay {
  /* CLASSE PADRAO PARA OVERLAY DAS MENSAGENS*/
  position: absolute !important;
  z-index: 1000 !important;
  top: 0% !important;
  left: 0% !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.mensagem-container {
  /* CLASSE PADRAO PARA CONTAINER DAS MENSAGENS*/
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.mensagem-corpo {
  /* CLASSE PADRAO PARA CORPO DAS MENSAGENS*/
  white-space: normal !important;
  text-align: center !important;
  width: 26.5625em !important;
  border-radius: 0.3125em !important;
  padding: 0em 1.5625em 1.5625em 1.5625em !important;
}

.mensagem-container-icone {
  /* CLASSE PADRAO PARA  DAS MENSAGENS*/
  width: 100% !important;
}

.mensagem-container-titulo {
  /* CLASSE PADRAO PARA  DAS MENSAGENS*/
  margin-bottom: 1.25em !important;
}

.mensagem-container-botao {
  /* CLASSE PADRAO PARA  DAS MENSAGENS*/
  margin-top: 1.25em !important;
}

.mensagem-botao-confirmar {
  /* CLASSE PADRAO PARA BOTOES DE CONFIRMACAO DAS MENSAGENS*/
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2.375em !important;
  min-width: 6.875em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  /* border: 0.09375em solid #36D896!important; */
  /* background-color: #36D896!important; */
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 0.625em !important;
}

.mensagem-botao-cancelar {
  /* CLASSE PADRAO PARA BOTOES DE CANCELAMENTO DAS MENSAGENS */
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2.375em !important;
  min-width: 6.875em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  border: 0.09375em solid #36d896 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 0.625em !important;
}

.mensagem-container-lista {
  /* POSICIONA CORRETAMENTE A LISTA DA MENSAGEM*/
  position: relative !important;
}

.mensagem-campo-select-opcao {
  /* CLASSE PADRAO PARA OS ITEMS DO SELECT DA MENSAGEM*/
  margin-left: -32% !important;
  width: 150% !important;
  height: 1.875em !important;
  line-height: 1.75em !important;
  cursor: pointer !important;
  text-align: center !important;
}
/******************** TABELAS ********************/
table {
  /* REMOVE ESPACAMENTO FORCADO ENTRE ROWS DAS TABELAS */
  border: 0 !important;
  border-collapse: separate !important;
  border-spacing: 0 0 !important;
}

td {
  /* REMOVE A BORDA BRANCA DO CORPO DAS TABELAS */
  border: 0 !important;
  min-height: 1.5625em !important;
  max-height: 1.5625em !important;
  padding: 0em !important;
}

th {
  /* REMOVE A BORDA BRANCA DO CABECALHO DAS TABELAS */
  border: 0 !important;
  height: 1.9em !important;
}

.react-bootstrap-table thead tr th:first-child {
  /* CLASSE PADRAO PARA ARREDONDAR CANTO SUPERIOR ESQUERDO DAS TABELAS */
  border-top-left-radius: 0.4375em !important;
}

.react-bootstrap-table thead tr th:last-child {
  /* CLASSE PADRAO PARA ARREDONDAR CANTO SUPERIOR DIREITO DAS TABELAS */
  border-top-right-radius: 0.4375em !important;
}

.react-bootstrap-table tr:last-child td:first-child {
  /* CLASSE PADRAO PARA ARREDONDAR CANTO INFERIOR ESQUERDO DAS TABELAS */
  border-bottom-left-radius: 0.4375em !important;
  border-style: solid !important;
}

.react-bootstrap-table tr:last-child td:last-child {
  /* CLASSE PADRAO PARA ARREDONDAR CANTO INFERIOR DIREITO DAS TABELAS */
  border-bottom-right-radius: 0.4375em !important;
  border-style: solid !important;
}

.tabela-coluna-primeira-header {
  /* CLASSE PADRAO PARA A PRIMEIRA COLUNA DAS TABELAS */
  margin-left: 0.625em !important;
}

.tabela-coluna-ultima-header .btn-toolbar {
  /* CLASSE PADRAO PARA A ULTIMA COLUNA DAS TABELAS */
  display: flex !important;
  justify-content: center !important;
}

.table {
  /* CLASSE PADRAO PARA FORMATAR AS DIMENSOES DAS TABELAS E ESPACAMENTO ENTRE ROWS */
  border-collapse: collapse !important;
  width: 99% !important;
  margin-bottom: 0em !important;
}

.react-bootstrap-table tr {
  /* REMOVE O ESPACO EM BRANCO QUE CERCA AS TABELAS */
  white-space: nowrap !important;
  height: 1.9em !important;
}

.react-bootstrap-table td {
  /* CLASS PADRAO PARA FORMATAR O ESPACO INTERNO DAS ROWS DAS TABELAS */
  padding-top: 0.125em !important;
  padding-left: 0.625em !important;
  padding-right: 0.625em !important;
  vertical-align: middle !important;
  text-align: initial !important;
}

.react-bootstrap-table td.expand-cell {
  /* CLASSE PADRAO PARA ALINHAR A COLUNA DE EXPANSAO DE ROWS */
  padding-top: 0.125em !important;
  padding-left: 0em !important;
  padding-right: 0em !important;
}

.react-bootstrap-table th {
  /* CLASSE PADRAO PARA FORMATAR O ESPACO INTERNO DOS CABECALHOS DAS TABELAS */
  vertical-align: inherit !important;
  padding-top: 0.125em !important;
  padding-bottom: 0.125em !important;
  padding-right: 0.9375em !important;
  padding-left: 0.625em !important;
  margin-left: 0.625em !important;
  white-space: nowrap !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 300 !important;
}
.menu-texto-selecionado {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-bootstrap-table-pagination {
  /* CLASSE PADRAO PARA FORMATAR O PADDING DOS BOTOES DE PAGINACAO DAS TABELAS */
  padding-top: 0.3125em !important;
}

.react-bootstrap-table-pagination-list {
  /* CLASSE PADRAO PARA ORGANIZAR OS BOTOES DE PAGINACAO DAS TABELAS */
  float: right !important;
}
.dropdown-ativado-tela {
  max-height: 50em !important;
  margin-left: -40px;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.barra-progresso-container {
  /* CLASSE PADRAO DO CONTAINER QUE DA FORMA AS BARRAS DE PROGRESSO DAS TABELAS */
  position: relative !important;
  text-align: center !important;
  height: 1.125em !important;
  width: 6.25em !important;
  border-radius: 3.125em 3.125em 3.125em 3.125em !important;
  font-size: 16px !important;
}

.barra-progresso {
  /* CLASSE DE COR PADRAO DAS BARRAS DE PROGRESSO DAS TABELAS - TALVEZ SEJA REMOVIDO */
  background-color: #36d896 !important;
}

.texto-barra-progresso {
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1.425em !important;
  position: absolute !important;
  text-align: center !important;
  width: 100% !important;
  background-color: transparent !important;
  z-index: 1 !important;
}

.container-tabela-modal {
  /* CLASSE PADRAO PARA LIMITAR O TAMANHO DAS TABELAS QUE APARECEM DENTRO DE MODAIS */
  overflow: auto !important;
  max-height: 10.75em !important;
}

.expand-cell-header {
  /* CLASSE PADRAO PARA DESABILITAR A EXPANSAO DE TODAS AS ROWS CLICANDO NO CABECALHO DA COLUNA DE EXPANSAO */
  pointer-events: none !important;
}

.grupo-botao-paginacao-tabela button {
  /* GRUPO DE BOTOES DA PAGINACAO DAS TABELAS */
  font-weight: normal !important;
}

.botao-paginacao-primeira-ultima-tabela {
  /* BOTOES DE PRIMEIRA E ULTIMA PAGINA DAS TABELAS */
  line-height: 1em !important;
  outline: none !important;
  border: 0 !important;
  border-radius: 1.25em !important;
  font-weight: 700 !important;
  font-size: 0.875em !important;
  height: 1.7075em !important;
  width: 5.625em !important;
  letter-spacing: 0.0625em !important;
  margin-top: -3px;
}

.botao-paginacao-tabela {
  /* BOTOES NUMERADOS DE PAGINACAO DAS TABELAS */
  background-color: transparent !important;
  border: none !important;
  margin-left: 0.5em !important;
  box-shadow: none !important;
}

.botao-paginacao-tabela:focus {
  /* BOTOES NUMERADOS DE PAGINACAO DAS TABELAS */
  outline: none !important;
}

.botao-paginacao-seta-tabela {
  /* BOTOES DE SETA DE PAGINACAO DAS TABELAS */
  border-radius: 1em !important;
  background-color: transparent !important;
  border: 0 !important;
  width: 1.5625em !important;
  height: 1.5em !important;
  outline: none !important;
}
.fonte-paginacao-verde {
  /* BOTOES DE SETA DE PAGINACAO DAS TABELAS */
  border-radius: 1em !important;
  background-color: transparent !important;
  border: 0 !important;
  width: 1.5625em !important;
  height: 1.5em !important;
  outline: none !important;
  /* color: #36D896 !important; */
}
.botao-setas-paginacao-seta-tabela {
  /* BOTOES DE PROXIMA PAGINA E ANTERIOR DAS TABELAS */
  /* margin-left: 1.125em!important; */
  /* margin-right: 1.125em!important; */
  margin-left: 10px;
  margin-top: -5px;
}

.grupo-botao-paginacao-tabela {
  position: fixed !important;
  bottom: 0.5% !important;
  display: flex !important;
  justify-content: center !important;
  font-weight: normal !important;
  font-family: RobotoRegular !important;
  right: 1.2% !important;
}

.grupo-botao-paginacao-tabela-2 {
  position: absolute !important;
  bottom: 0.5% !important;
  left: 0% !important;
  display: flex !important;
  justify-content: center !important;
  font-weight: normal !important;
  font-family: RobotoRegular !important;
  float: left !important;
  /* right: 1.2% !important; */
}

.grupo-botao-paginacao-tabela-3,
.grupo-botao-paginacao-tabela-3-tipo2 {
  position: absolute !important;
  bottom: -2em !important;
  left: 0% !important;
  display: flex !important;
  justify-content: center !important;
  font-weight: normal !important;
  font-family: RobotoRegular !important;
  float: left !important;
  z-index: 1;
  /* right: 1.2% !important; */
}

.grupo-botao-paginacao-tabela-3-tipo2 {
  bottom: -2.7em !important;
}

.grupo-botao-paginacao-tabela-4 {
  display: flex !important;
  justify-content: center !important;
  font-weight: normal !important;
  font-family: RobotoRegular !important;
  float: left !important;
  z-index: 1;
  margin-top: 0.3em;
}

.itens-exibidos-tabela {
  position: fixed !important;
  bottom: 0.5% !important;
  display: flex !important;
  font-family: RobotoRegular !important;
  justify-content: center !important;
  font-size: 0.875em !important;
}

.acoes {
  margin-bottom: 0em !important;
}

.botoes-barra-acoes {
  display: flex;
  justify-content: center;
  margin-top: 0.09375em !important;
  margin-bottom: 0.09375em !important;
}

.bt-grid-criar {
  height: 2em !important;
  width: 2em !important;
  color: black !important;
  font-weight: bold !important;
  background-color: #36d896 !important;
  border: none !important;
  box-shadow: none !important;
}

.icone-botao-acao-tabela {
  margin-top: -0.9375em !important;
}

.botao-expandir-row {
  margin-left: 0.3125em;
  padding: 0em !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-color: transparent !important;
  border-style: outset !important;
  outline: none !important;
}

.botao-expandir-row:hover {
  background-color: transparent !important;
  box-shadow: none !important;
  border-color: transparent !important;
  border-style: outset !important;
  outline: none !important;
}

/******************** SWITCHES ********************/
.flipswitch {
  /* CLASSE PADRAO DO SWITCH */
  margin-right: 0.625em !important;
  margin-top: 0.3125em !important;
  position: relative !important;
  width: 3.5em !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  float: left !important;
}

.flipswitch input[type='checkbox'] {
  /* CLASSE PADRAO DE FUNCIONAMENTO DO SWITCH */
  display: none !important;
}

.flipswitch-label {
  /* CLASSE PADRAO DA FORMA DO SWITCH */
  display: block !important;
  overflow: hidden !important;
  cursor: pointer !important;
  border-radius: 3.125em !important;
}

.flipswitch-inner {
  /* CLASSE PADRAO DA ANIMACAO DO TRILHO DO SWITCH */
  width: 8em !important;
  margin-left: -0em !important;
  -webkit-transition: margin 0.3s ease-in 0s !important;
  -moz-transition: margin 0.3s ease-in 0s !important;
  -ms-transition: margin 0.3s ease-in 0s !important;
  -o-transition: margin 0.3s ease-in 0s !important;
  transition: margin 0.3s ease-in 0s !important;
}

.flipswitch-inner:before,
.flipswitch-inner:after {
  /* CLASSE PADRAO PARA POSICIONAMENTO DO BOTAO DO SWITCH */
  float: left !important;
  width: 50% !important;
  height: 1.58em !important;
  padding: 0 !important;
  line-height: 1.9375em !important;
  font-size: 1.125em !important;
  color: white !important;
  font-family: Trebuchet, Arial, sans-serif !important;
  font-weight: bold !important;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.flipswitch-inner:before {
  /* CLASSE PADRAO PARA ESCONDER O CONTEUDO DE FUNCIONAMENTO DO SWITCH QUANDO DESATIVADO */
  content: '' !important;
}

.flipswitch-inner:after {
  /* CLASSE PADRAO PARA ESCONDER O CONTEUDO DE FUNCIONAMENTO DO SWITCH QUANDO ATIVADO */
  content: '' !important;
}

.flipswitch-switch {
  /* CLASSE PADRAO DA ESFERDA DO SWITCH */
  width: 1.35em !important;
  height: 1.35em !important;
  margin-top: 0.21875em !important;
  border-radius: 3.125em !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 1.9em !important;
  -webkit-transition: all 0.3s ease-in 0s !important;
  -moz-transition: all 0.3s ease-in 0s !important;
  -ms-transition: all 0.3s ease-in 0s !important;
  -o-transition: all 0.3s ease-in 0s !important;
  transition: all 0.3s ease-in 0s !important;
}

.flipswitch-cb:checked + .flipswitch-label .flipswitch-inner {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  margin-left: 0 !important;
}

.flipswitch-cmp:checked + .flipswitch-label .flipswitch-inner {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  margin-left: 0 !important;
}

.flipswitch-cmp-habilitado:checked + .flipswitch-label .flipswitch-inner {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  margin-left: 0 !important;
}

.flipswitch-cb:checked + .flipswitch-label .flipswitch-cmp {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  margin-left: 0 !important;
}

.flipswitch-cb:checked + .flipswitch-label .flipswitch-cmp-habilitado {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  margin-left: 0 !important;
}

.flipswitch-cb:checked + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  right: 0.25em !important;
}

.flipswitch-cmp:checked + .flipswitch-label .flipswitch-switch-cmp {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  right: 0.25em !important;
}

.flipswitch-cmp-habilitado:checked + .flipswitch-label .flipswitch-switch-cmp {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  right: 0.25em !important;
}

.flipswitch-cb + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH DESATIVADO*/
  background-color: #cd2d37 !important;
}

.flipswitch-cmp + .flipswitch-label .flipswitch-switch-cmp {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH DESATIVADO*/
  background-color: #fafafa !important;
}

.flipswitch-cmp-habilitado + .flipswitch-label .flipswitch-switch-cmp {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH DESATIVADO*/
  background-color: #fafafa !important;
}

.flipswitch-cb:checked + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH ATIVADO*/
  /* background-color: #36D896!important; */
}

.flipswitch-cmp:checked + .flipswitch-label .flipswitch-switch-cmp {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH ATIVADO*/
  /* background-color: #36D896!important; */
}

.flipswitch-cmp-habilitado:checked + .flipswitch-label .flipswitch-switch-cmp {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH ATIVADO*/
  background-color: #36d896 !important;
}

.flipswitch-cb-desativado:checked + .flipswitch-label .flipswitch-inner {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH ENQUANTO DESATIVADO */
  margin-left: 0 !important;
}

.flipswitch-cb:checked + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH ATIVADO*/
  /* background-color: #36D896!important; */
}

.flipswitch-cb-desativado:checked + .flipswitch-label .flipswitch-cmp {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH ENQUANTO DESATIVADO */
  margin-left: 0 !important;
}

.flipswitch-cb-desativado:checked + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH ENQUANTO DESATIVADO */
  right: 0.25em !important;
}

.flipswitch-cb-desativado + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH DESATIVADO ENQUANTO DESATIVADO*/
  background-color: #909795 !important;
}

.flipswitch-cb-desativado:checked + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH ATIVADO ENQUANTO DESATIVADO*/
  background-color: #909795 !important;
}

.flipswitch-medium {
  /* CLASSE PADRAO DO SWITCH */
  margin-right: 0.625em !important;
  margin-top: 0.3125em !important;
  position: relative !important;
  width: 3em !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  float: left !important;
}

.flipswitch-medium input[type='checkbox'] {
  /* CLASSE PADRAO DE FUNCIONAMENTO DO SWITCH */
  display: none !important;
}

.flipswitch-medium-label {
  /* CLASSE PADRAO DA FORMA DO SWITCH */
  display: block !important;
  overflow: hidden !important;
  cursor: pointer !important;
  border-radius: 3.125em !important;
}

.flipswitch-medium-inner {
  /* CLASSE PADRAO DA ANIMACAO DO TRILHO DO SWITCH */
  width: 200% !important;
  margin-left: -100% !important;
  -webkit-transition: margin 0.3s ease-in 0s !important;
  -moz-transition: margin 0.3s ease-in 0s !important;
  -ms-transition: margin 0.3s ease-in 0s !important;
  -o-transition: margin 0.3s ease-in 0s !important;
  transition: margin 0.3s ease-in 0s !important;
}

.flipswitch-medium-inner:before,
.flipswitch-medium-inner:after {
  /* CLASSE PADRAO PARA POSICIONAMENTO DO BOTAO DO SWITCH */
  float: left !important;
  width: 50% !important;
  height: 1.5em !important;
  padding: 0 !important;
  line-height: 1.9375em !important;
  font-size: 1.125em !important;
  color: white !important;
  font-family: Trebuchet, Arial, sans-serif !important;
  font-weight: bold !important;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.flipswitch-medium-inner:before {
  /* CLASSE PADRAO PARA ESCONDER O CONTEUDO DE FUNCIONAMENTO DO SWITCH QUANDO DESATIVADO */
  content: '' !important;
  padding-left: 0.8125em !important;
  color: #ffffff !important;
}

.flipswitch-medium-inner:after {
  /* CLASSE PADRAO PARA ESCONDER O CONTEUDO DE FUNCIONAMENTO DO SWITCH QUANDO ATIVADO */
  content: '' !important;
  padding-right: 0.8125em !important;
  color: #888888 !important;
  text-align: right !important;
}

.flipswitch-medium-switch {
  /* CLASSE PADRAO DA ESFERDA DO SWITCH */
  width: 1.2em !important;
  height: 1.2em !important;
  margin: 0.21875em !important;
  background: #ffffff !important;
  border-radius: 3.125em !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 1.3125em !important;
  -webkit-transition: all 0.3s ease-in 0s !important;
  -moz-transition: all 0.3s ease-in 0s !important;
  -ms-transition: all 0.3s ease-in 0s !important;
  -o-transition: all 0.3s ease-in 0s !important;
  transition: all 0.3s ease-in 0s !important;
}

.flipswitch-medium-cb:checked
  + .flipswitch-medium-label
  .flipswitch-medium-inner {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  margin-left: 0 !important;
}

.flipswitch-medium-cb:checked
  + .flipswitch-medium-label
  .flipswitch-medium-switch {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  right: 0 !important;
}

.flipswitch-medium-cb + .flipswitch-medium-label .flipswitch-medium-switch {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH DESATIVADO*/
  background-color: #c23434ed !important;
}

.flipswitch-medium-cb:checked
  + .flipswitch-medium-label
  .flipswitch-medium-switch {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH ATIVADO*/
  /* background-color: #36D896!important; */
}

.container-switch-menu-dropdown-tabelas {
  margin-left: 0.3125em !important;
  margin-right: 0.3125em !important;
  margin-top: 0.25em !important;
  position: relative !important;
  width: 1.875em !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  float: left !important;
}

.container-switch-menu-dropdown-tabelas input[type='checkbox'] {
  display: none !important;
}
/******************** BARRAS DE RANGE ********************/
.custom-range {
  /* CLASSE PADRAO DAS BARRAS DE RANGE */
  height: 1.3125em !important;
  border-radius: 3.125em 3.125em 3.125em 3.125em !important;
  width: 100% !important;
  position: relative !important;
  /* background-color: #36D896!important; */
}

input[type='range']::-ms-slider-thumb,
input[type='range']::-ms-slider-thumb:focus,
input[type='range']::-ms-slider-thumb:active,
input[type='range']::-ms-slider-thumb:not(:focus) {
  background-position: center !important;
  margin-top: 0.4375em !important;
  background-repeat: no-repeat !important;
  height: 1.3125em !important;
  width: 2.2125em !important;
  border-radius: 0.55em !important;
  cursor: pointer !important;
  -ms-appearance: none !important;
  box-shadow: 21.875em 0em 0em 21.25em black !important;
  transition: box-shadow !important;
}

input[type='range']::-moz-slider-thumb,
input[type='range']::-moz-slider-thumb:focus,
input[type='range']::-moz-slider-thumb:active,
input[type='range']::-moz-slider-thumb:not(:focus) {
  background-position: center !important;
  margin-top: 0.4375em !important;
  background-repeat: no-repeat !important;
  height: 1.3125em !important;
  width: 2.2125em !important;
  border-radius: 0.55em !important;
  cursor: pointer !important;
  -moz-appearance: none !important;
  box-shadow: 21.875em 0em 0em 21.25em black !important;
  transition: box-shadow !important;
}

input[type='range']::-webkit-slider-thumb,
input[type='range']::-webkit-slider-thumb:focus,
input[type='range']::-webkit-slider-thumb:active,
input[type='range']::-webkit-slider-thumb:not(:focus) {
  background-position: center !important;
  margin-top: 0.4375em !important;
  background-repeat: no-repeat !important;
  height: 1.3125em !important;
  width: 2.2125em !important;
  border-radius: 0.55em !important;
  cursor: pointer !important;
  -webkit-appearance: none !important;
  transition: box-shadow !important;
}

input[type='range'],
input[type='range']:focus,
input[type='range']:active,
input[type='range']::not(:focus) {
  background-position: center !important;
  margin-top: 0.4375em !important;
  background-repeat: no-repeat !important;
  height: 1.3125em !important;
  width: 2.2125em !important;
  border-radius: 0.55em !important;
  cursor: pointer !important;
  -webkit-appearance: none !important;
  transition: box-shadow !important;
}

input[type='range']::-moz-range-track {
  width: 100% !important;
  cursor: pointer !important;
  background: none !important;
}

input[type='range']:focus {
  outline: none !important;
}

input[type='range'] {
  -webkit-appearance: none !important;
  overflow: hidden !important;
}

input[type='range']:focus {
  outline: none !important;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 2.5em !important;
  height: 2.5em !important;
  cursor: pointer !important;
  /* background: #36D896!important; */
  border-radius: 1.475em !important;
  border: 0.125em solid transparent !important;
  border-right: 0 !important;
  border-left: 0 !important;
}

input[type='range']::-moz-slider-runnable-track {
  width: 2.5em !important;
  height: 2.5em !important;
  cursor: pointer !important;
  /* background: #36D896!important; */
  border-radius: 1.475em !important;
  border: 0.125em solid transparent !important;
  border-right: 0 !important;
  border-left: 0 !important;
}

input[type='range']::-ms-slider-runnable-track {
  width: 2.5em !important;
  height: 2.5em !important;
  cursor: pointer !important;
  /* background: #36D896!important; */
  border-radius: 1.475em !important;
  border: 0.125em solid transparent !important;
  border-right: 0 !important;
  border-left: 0 !important;
}

input[type='range']::-moz-range-thumb {
  height: 2.5em !important;
  width: 2.5em !important;
  position: relative !important;
}

input[type='range']::-webkit-slider-thumb {
  height: 2.5em !important;
  width: 2.5em !important;
  position: relative !important;
}

input[type='range']::-ms-thumb {
  height: 2.5em !important;
  width: 2.5em !important;
  position: relative !important;
}

/******************** CHECKBOXES ********************/
input[type='checkbox'].input-checkbox-simcard {
  /* CHECKBOX */
  z-index: 99 !important;
  position: relative !important;
  width: 1.125em !important;
  height: 1.125em !important;
  display: none !important;
}

.label-checkbox-simcard {
  display: flex !important;
  width: 1.125em !important;
  height: 1.125em !important;
  border-radius: 0.3125em !important;
  margin: 0em !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

input[type='checkbox'].input-checkbox-simcard:checked
  + label.label-checkbox-simcard {
  display: flex !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

input[type='checkbox'].input-checkbox-simcard-2 {
  /* CHECKBOX */
  z-index: 99 !important;
  position: relative !important;
  width: 1.125em !important;
  height: 1.125em !important;
  display: none !important;
  visibility: hidden !important;
}

.label-checkbox-simcard-2 {
  display: flex !important;
  width: 1.125em !important;
  height: 1.125em !important;
  border-radius: 0.3125em !important;
  margin: 0em !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  visibility: hidden !important;
}

input[type='checkbox'].input-checkbox-simcard-2:checked
  + label.label-checkbox-simcard-2 {
  display: flex !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  visibility: hidden !important;
}

input[type='checkbox'].input-checkbox-header-simcard {
  z-index: 99 !important;
  position: relative !important;
  width: 1.125em !important;
  height: 1.125em !important;
  display: none !important;
}

.label-checkbox-header-simcard {
  display: flex !important;
  width: 1.125em !important;
  height: 1.125em !important;
  /* border: 0.0625em solid #36D896!important; */
  margin: 0em !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

input[type='checkbox'].input-checkbox-header-simcard:checked
  + label.label-checkbox-header-simcard {
  display: flex !important;
  width: 1.125em !important;
  height: 1.125em !important;
  background-image: url('./../../src/icones/checked_header.svg') !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

input[type='checkbox'].input-checkbox-header-simcard-2 {
  z-index: 99 !important;
  position: relative !important;
  width: 1.125em !important;
  height: 1.125em !important;
  display: none !important;
}

.label-checkbox-header-simcard-2 {
  display: flex !important;
  width: 1.125em !important;
  height: 1.125em !important;
  border: 0.0625em solid #cd2d37 !important;
  margin: 0em !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

input[type='checkbox'].input-checkbox-header-simcard-2:checked
  + label.label-checkbox-header-simcard-2 {
  display: flex !important;
  width: 1.125em !important;
  height: 1.125em !important;
  background-image: url('./../../src/icones/checked_header.svg') !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

/******************** CALENDARIO ********************/
.campo-calendario {
  /* DA FORMA E ORGANIZA O BOTAO DO CALENDARIO ATIVADO*/
  height: 2em !important;
  width: 100% !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  border-radius: 3.125em !important;
  box-shadow: none !important;
  text-align: left !important;
  display: inline-flexbox !important;
  padding: 0.375rem 0.6rem !important;
}

.campo-calendario:disabled {
  /* DA FORMA E ORGANIZA O BOTAO DO CALENDARIO DESATIVADO */
  height: 2em !important;
  width: 100% !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  border-radius: 3.125em !important;
  box-shadow: none !important;
  text-align: left !important;
  display: inline-flexbox !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--today {
  /* ARREDONDA O DIA SELECIONADO */
  border-radius: 50% !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.react-datepicker__header {
  /* REMOVE LINHA DIVISORIA ENTRE DIAS DA SEMANA E DATAS */
  border: 0em !important;
}

.react-datepicker {
  /* REMOVE BORDA PRETA AO REDOR DA MODAL */
  border: 0em !important;
}

.react-datepicker-popper {
  /*FAZ COM QUE O CALENDARIO FIQUE POR CIMA DE TUDO*/
  z-index: 301 !important;
}

.react-datepicker-time__input {
  /* DA FORMA E POSICIONA CORRETAMENTE O CAMPO DE HORARIO */
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  height: 2em !important;
  border: 0em !important;
  border-radius: 6.25em !important;
  box-shadow: none !important;
  text-align: center !important;
}

.react-datepicker__navigation--next {
  /* POSICIONA CORRETAMENTE A SETA DE MES SEGUINTE */
  border-left-color: transparent !important;
  right: 0em !important;
  margin-top: 0.1875em !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.react-datepicker__navigation--previous {
  /* POSICIONA CORRETAMENTE A SETA DE MES ANTERIOR */
  border-right-color: transparent !important;
  left: 0.75em !important;
  margin-top: 0.1875em !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.react-datepicker__navigation {
  /* DIMENSIONA CORRETAMENTE AS SETAS DE MES ANTERIOR E SEGUINTE */
  width: 1.1875em !important;
  height: 1.3125em !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.react-datepicker-wrapper {
  /* DIMENSIONA CORRETAMENTE O CAMPO DO CALENDARIO */
  width: 100% !important;
}

/******************** SCROLL BAR ********************/
::-webkit-scrollbar {
  width: 0.9375em !important;
  height: 0.9375em !important;
  background-color: transparent !important;
  -webkit-border-radius: 1.25em !important;
  -moz-border-radius: 1.25em !important;
  border-radius: 1.25em !important;
}

::-webkit-scrollbar-track {
  width: 0.9375em !important;
  height: 0.9375em !important;
  background-color: transparent !important;
  -webkit-border-radius: 1.25em !important;
  -moz-border-radius: 1.25em !important;
  border-radius: 1.25em !important;
}

::-webkit-scrollbar-thumb {
  width: 0.9375em !important;
  height: 0.9375em !important;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-border-radius: 1.25em !important;
  -moz-border-radius: 1.25em !important;
  border-radius: 1.25em !important;
}

::-webkit-scrollbar-thumb:window-inactive {
  width: 0.9375em !important;
  height: 0.9375em !important;
  -webkit-border-radius: 1.25em !important;
  -moz-border-radius: 1.25em !important;
  border-radius: 1.25em !important;
}

/******************** EXPANSAO DE DROPDOWNS ********************/
.dropdown-desativado {
  max-height: 0 !important;
  overflow: hidden !important;
  margin-left: -2.5em !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.dropdown-ativado {
  max-height: 50em !important;
  margin-left: -2.5em !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

/******************** DESTINO INCERTO ********************/
/* .progress span { PERTENCE A CATEGORIA TABELA, POREM PARECE NAO SURTIR EFEITO ATE DADO MOMENTO.
      font-family: RobotoRegular!important;
      line-height: 1.125em!important;
      position: absolute!important; 
      text-align: center!important;
      width: 100%!important;
  } */

.znv-chat .znv-float-button {
  bottom: 30px !important;
  right: 10px !important;
}

.znv-float-button-default {
  display: none !important;
}

.znv-chat:not(.znv-is-inline).znv-is-active .znv-chat-container {
  bottom: 110px !important;
}

.valor-modal {
  text-align: right !important;
  width: 65% !important;
  font-size: 1em !important;
}

.valor-rs-modal {
  width: 30% !important;
  height: 100% !important;
}

.container-valor-modal {
  display: inline-flex !important;
  width: 100% !important;
  height: 20px !important;
}

.valor-rs-consultarSimcard {
  width: 35% !important;
  height: 100% !important;
}

.valor-consultarSimcard {
  text-align: right !important;
  width: 55% !important;
  font-size: 1em !important;
  margin-left: 5px !important;
}

.container-valor-consultarSimcard {
  display: inline-flex !important;
  width: 100% !important;
  height: 20px !important;
}

.modal-trocar-senha {
  padding: 0.5em 2em 2em 2em !important;
  border-radius: 0.625em 0.625em 0.625em 0.625em !important;
  width: 30em !important;
}

.coluna-nova-novo-cliente-2-1 {
  width: 14% !important;
  display: table-row !important;
}

.campo-texto-novo-cliente {
  border-radius: 0px 5em 5em 0em !important;
}

.grupo-pesquisa-novo-cliente {
  display: flex !important;
}

.botao-novo-cliente-pesquisa {
  float: left !important;
  height: 1.75em !important;
  width: 2.1875em !important;
  border-color: transparent !important;
  border-radius: 3.125em 0em 0em 3.125em !important;
  outline: none !important;
  box-shadow: none !important;
}

file-upload-button {
  visibility: hidden !important;
}

.campo-select-generico {
  height: 2em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 0.375rem 1.8rem 0.375rem 0.75rem !important;
}

.campo-select-generico-material {
  height: 3.2em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 0.375rem 1.8rem 0.375rem 0.75rem !important;
}

.campo-select-generico-b {
  height: 32px;
  width: 64% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 0.375rem 1.8rem 0.375rem 0.75rem !important;
  margin-top: -5px;
  margin-left: 87px;
}

.campo-select-generico-2 {
  height: 3em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 0.375rem 1.8rem 0.375rem 0.75rem !important;
}

.campo-select-generico-icone-ativado {
  margin-top: -1.5em !important;
  float: right;
  margin-right: 0.625em;
  transform: rotate(180deg);
}

.campo-select-generico-icone-ativado-b {
  margin-top: -1.3em !important;
  float: right;
  margin-right: 34px;
}

.campo-select-generico-opcao {
  margin-left: -2.5em !important;
  width: 112% !important;
  height: 1.875em !important;
  line-height: 1.75em !important;
  cursor: pointer !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: flex;
}

/******************** DESTE PONTO EM DIANTE ESTÁ SENDO TRABALHADO ********************/
.flipswitch-tiny {
  margin-top: 11px !important;
  position: relative !important;
  width: 30px !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  float: left !important;
}
.flipswitch-tiny input[type='checkbox'] {
  display: none !important;
}
.flipswitch-tiny-label {
  display: block !important;
  overflow: hidden !important;
  cursor: pointer !important;
  border-radius: 50px !important;
  height: 15px !important;
  width: 30px !important;
}
.flipswitch-tiny-inner {
  -webkit-transition: margin 0.3s ease-in 0s !important;
  -moz-transition: margin 0.3s ease-in 0s !important;
  -ms-transition: margin 0.3s ease-in 0s !important;
  -o-transition: margin 0.3s ease-in 0s !important;
  transition: margin 0.3s ease-in 0s !important;
}
.flipswitch-tiny-inner:before,
.flipswitch-tiny-inner:after {
  float: left !important;
  width: 50% !important;
  height: 33px !important;
  padding: 0 !important;
  line-height: 31px !important;
  font-size: 18px !important;
  color: black !important;
  font-family: Trebuchet, Arial, sans-serif !important;
  font-weight: bold !important;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}
.flipswitch-tiny-inner:before {
  content: '' !important;
  padding-left: 13px !important;
  color: #000 !important;
}
.flipswitch-tiny-inner:after {
  content: '' !important;
  padding-right: 13px !important;
  color: black !important;
  text-align: right !important;
}
.flipswitch-tiny-switch {
  width: 11.5px !important;
  height: 11.5px !important;
  margin: 2px !important;
  background: #000 !important;
  border-radius: 50px !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 16px !important;
  -webkit-transition: all 0.3s ease-in 0s !important;
  -moz-transition: all 0.3s ease-in 0s !important;
  -ms-transition: all 0.3s ease-in 0s !important;
  -o-transition: all 0.3s ease-in 0s !important;
  transition: all 0.3s ease-in 0s !important;
}
.flipswitch-tiny-cb:checked + .flipswitch-tiny-label .flipswitch-tiny-inner {
  margin-left: 0 !important;
}
.flipswitch-tiny-cb:checked + .flipswitch-tiny-label .flipswitch-tiny-switch {
  right: 0px !important;
}

.flipswitch-tiny-cb + .flipswitch-tiny-label .flipswitch-tiny-switch {
  background-color: #c23434ed !important;
}

/* .flipswitch-tiny-cb:checked + .flipswitch-tiny-label .flipswitch-tiny-switch {
      background-color: #36D896!important;
  } */

.bt-barra-adicionar-login {
  float: right;
  margin-bottom: 20px;
}
td.reset-expansion-style {
  padding: 0 !important;
}
.icone-pesquisar-redondo {
  margin-top: -10px !important;
  margin-left: -5px !important;
}
/* SER MESCLADA NO NAVEGACAOSISTEMA.CSS */
.icone-menu-com-filho {
  margin-right: 1.118125em;
  width: 0.875em !important;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type='number'] {
  -moz-appearance: textfield !important;
  appearance: textfield !important;
}

.CardPrincipal-importar-arquivo {
  border-radius: 0.625em 0.625em 0.625em 0.625em !important;
  border: none !important;
  padding-right: 1em !important;
}

.lista-importar-arquivo {
  margin-top: 0.3125em !important;
  display: flex !important;
  align-items: center !important;
  display: flex !important;
  align-items: center !important;
  height: 1.9375em !important;
  border-radius: 3.125em !important;
}

.tituloLista-importar-arquivo {
  margin-top: 1.375em !important;
  font-family: Roboto !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
}

.listaNome-importar-arquivo {
  width: 26em !important;
  margin-top: 0.875em !important;
  margin-left: 1.0625em !important;
  font-family: Roboto !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
}

.div-Template-importar-descricao {
  margin-top: 1.4375em;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.botaoTemplate-importar-arquivo {
  height: 2em !important;
  width: 7.75em !important;
  border-radius: 3.125em !important;
  color: white !important;
  border-radius: 1.25em !important;
  border: none !important;
  cursor: pointer !important;
  background-position: 5.625em !important;
  background-repeat: no-repeat !important;
  font-family: Roboto !important;
  box-shadow: none !important;
  text-decoration: none !important;
}

.bt-dropdown-template-importar-arquivo {
  height: 2em !important;
  width: 7.75em !important;
  border-radius: 3.125em !important;
  color: white !important;
  border-radius: 1.25em !important;
  border: none !important;
  cursor: pointer !important;
  background-image: url('../icones/exportar.svg') !important;
  background-position: 5.625em !important;
  background-repeat: no-repeat !important;
  font-family: Roboto !important;
  box-shadow: none !important;
  text-decoration: none !important;
}

.close-balloon {
  padding: 5px 5px 5px 4px !important;
  height: 30px !important;
  width: 30px !important;
  border: solid 3px #fff !important;
}

.campo-texto-telefone-cancelamento {
  border-radius: 0px 5em 5em 0em !important;
}
