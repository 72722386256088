/* Modal "Detalhes da Versão" ou "Novidades" */
.modal-acao-novidades {
    padding: .5em 2em 2em 2em!important;
}

.tela-painel-novidades{
    width: 100%!important;
    height: 100%!important;
}

/* .tela-painel-novidades .botao-fechar-janela-icone {
    margin-right: -25px;
} */

.div-corpo-novidades {
    /* width: 563px; */
    height: 85%;
    /* height: 606px; */
    overflow-y: auto;
    overflow-x: clip;
    margin-bottom: 25px;
}

@media screen and (max-height: 600px) {
    .div-corpo-novidades {
        margin-top: -30px;
        height: 75%;
    }
}

@media screen and (max-height: 730px) {
    .div-corpo-novidades {
        margin-top: -20px;
        height: 80%;
    }
}

#div-imagem-novidades {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px!important;
    margin-bottom: 24px!important;
}

#div-imagem-novidades img, figure > img { /* "imagem principal" ou "imagens segundárias" (no html) */
    max-width: 552px;
    max-height: 261.62px;
}

#lista-novidades-versao {
    margin-left: 0px;
    padding-right: 10px;

    display: flex;
    flex-direction: column;
}

/* #lista-novidades-versao .form-row {} */

#lista-novidades-versao .se-image-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

#novidades-rodape > div {
    display: flex;
    justify-content: flex-end;
}

#novidades-rodape a {
    color: #0288D1;
}

#novidades-rodape #risco-divisorio {
    width: 0px;
    height: 16px;
    border: 1px solid rgba(79, 98, 117, 0.38);
    margin: 6px 8px 0px 13px;
}

#novidades-rodape button {
    background-color: transparent;
    border: none;
    color: #33CC99;
    font-weight: 700;
    /* font-size: 13px; */
}
