.modal-cadastro-menu{
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 55em!important;
}

.preview-menu {
    background-color: #FFFFFF;
    width: 90%;
    height: 26.5em;
    margin: auto;
    border-radius: 0.3125em;
}

.container-menu-1{
    width: 46%;
}
.container-menu-2{
    width: 54%;
}

.container-principal-menu{
    display: flex;
}

.coluna-cadastro-menu-1{
    width: 100%;
    margin-bottom: 1em;
}

.form-menu{
    margin: 0 !important;
}

.form-menu-botao {
    width: 95%;
    margin: 0 !important;
    margin-top: 1.5em !important;
}

.texto-grupo-menu {
    font-family: RobotoRegular!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 2.5em!important;
    margin-bottom: 1em!important;
    display: flex;
}

.campo-texto-area-2{
    height: 10em!important;
    width: 100%!important;
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
    line-height: 1em!important;
    border: 0em!important;
    box-shadow: none!important;
    border-radius: 0.3125em!important;
    resize: none;
    margin: auto;
}

.form-menu-2{
    width: 90% !important;
    margin: auto !important;
    margin-top: 1em !important;
}

.container-principal-switches-menu{
    display: flex;
}

.container-switches-menu{
    width: 50%;
}

.container-arvore-menus{
    width: 80%;
    height: 100%;
    display: grid;
    margin-left: 10%;
    align-items: center;
    text-align: center;
}

.campo-select-icone-desativado-arvore{
    transform: rotate(0deg)!important;
    transition: 0.5s ease!important;
    margin-left: 42%;
    width: 3em;
}

.campo-texto-overflow{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: flex !important;
}

.campo-texto-arvore{
    width: 20em !important;
    word-break: break-word !important;
}