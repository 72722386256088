.container-tabela-generica-modalGestaoDeContasFaturasConciliacao{
    max-height: 54vh !important;
    overflow: auto;
}

.modal-acao-conciliacao{
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 100vw!important;
    max-height: 100vh!important;
}
.container-tabela-modal {
    overflow: auto!important;
    max-height: 75vh!important;
}
.container-tabela-modal-2 {
    overflow: auto!important;
    max-height: 45vh!important;
}

.botao-atualizar{    
    display: flex!important;
    border: none!important;
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    height: 2em!important;
    min-width: 6.875em!important;
    padding-left: 0.875em!important;
    padding-right: 1.625em!important;
    box-shadow: none!important;
    border-radius: 3.125em!important;
    margin-right: 1.625em!important;
    margin-top: 2.5em;
    float: right!important;
}

.botao-relatorio{
    display: flex!important;
    border: none!important;
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    height: 2em!important;
    min-width: 6.875em!important;
    padding-left: 0.875em!important;
    padding-right: 1.625em!important;
    box-shadow: none!important;
    border-radius: 3.125em!important;
    margin-top: 2.5em;
    float: right!important;
}
.triangulo-dropdown-relatorios-conciliacao{
    width: 0!important;
    height: 0!important;
    border-left: 0.9375em solid transparent !important;
    border-right: 0.9375em solid transparent !important;
    margin-left: 3.3em!important;
}
.drop-relatorio-conciliacao{
    width: 100%;
    height: 1.7em;
    padding-left: 0.625em;
} 
.container-drop-relatorio-conciliacao{
    position: absolute!important;
    width: 8em!important;
    margin-top: 4.5em!important;
}
.drop-relatorio-conciliacao-top{
    border-radius: 0.625em 0.625em 0 0;
}
.drop-relatorio-conciliacao-bottom{
    border-radius: 0 0 0.625em 0.625em;
}
.drop-relatorio-conciliacao-divisor {
    height: 0.0625em!important;
    width: 100%!important;
}
.itens-exibidos-tabela-conciliacao{
    position: absolute !important;
    bottom: -1.76em!important;
    right: 1em!important;
    display: flex!important;
    font-family: RobotoRegular!important;
    justify-content: center!important;
}
.espacador_5{
    margin-top: 0.5em;
}
.espacador1{
    margin-top: 1em;
}
.texto-botao{
    padding-right: 0.625em!important;
}

.container-botao-modal-conciliacao{
    display: flex !important;
    justify-content: flex-end !important;
}