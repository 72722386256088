.modal-bloqueio-financeiro{
    padding: 0.5em 2em 0.5em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 36em!important;
    z-index: 300 !important;
}

.componente-modal-bloqueio-financeiro-container{
    position: absolute!important;
    margin-top: 33vh!important;
    margin-left: 42vw;
}

.bloqueio-texto-1 {
    padding-bottom: 24px;
}

.botao-link-financeiro {/* CLASSE PADRAO PARA BOTOES DE CANCELAMENTO */
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    height: 2.375em!important;
    min-width: 6.875em!important;
    padding-left: 1.875em!important;
    padding-right: 1.875em!important;
    border: 0.09375em solid #0288D180!important;
    background-color: transparent!important;
    box-shadow: none!important;
    border-radius: 3.125em!important;
    margin-left: 0.625em!important;
    float: right!important;

    display: flex;
    align-items: center;
}


.botao-link-financeiro > div, #link-tel-suporte, #link-email-suporte {
    color:#29B6F6!important;
}