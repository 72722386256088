.detalhamento-endereco{
    height: 8.8125em !important;
    width: 100% !important;
    border-radius: 0.3125em !important;
    min-width: 8.8125em !important;
}

.container-informacoes {
    border-left: 0.3125em solid #36D896!important;
    height: 3.5em!important;
    width: 90% !important;
    margin-left: 5% !important;
    margin-top: 1.2% !important;
    display: flex !important;
    justify-content: space-between !important;

}

.campo-endereco-empresas, .campo-bairro-empresas, .campo-cidade-empresas, .campo-cep-empresas,
.campo-estado-empresas, .campo-email-empresas{
    height: 25px !important;
    margin-top: 15px !important;
    font-size: 14px !important;
}

.campo-endereco-empresas{
    margin-left: 20px !important;
}

.informacoes-empresas{
    font-size: 14px !important;
}

.corpo-dados-usuario-razao{
    width: 39% !important;
}

.corpo-dados-usuario-endereco-empresa{
    width: 29.75em;
    margin-right: 1em;
}

.corpo-dados-usuario-email-empresa{
    margin-right: 1em;
    width: 49%;
}

.tooltip-campos-fatura{
    margin-top: 0 !important;
}

.campo-cnpj-empresa{
    width: 49% !important;
    display: flex !important;
}

.corpo-dados-usuario-numero{
    margin-right: 1em;
}

.detalhamento-fat-fornecedores-3{
    height: 13.9375em !important;
    width: calc( 50% - 1em) !important;
    margin-right: 1em;
    border-radius: 0.3125em !important;
}