.modal-novo-pedido-sms{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0.5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 33.875em!important;
}

.valor-pedidosSimcard3 {
    text-align: right !important;
    width: 20% !important;
    font-size: 1em!important;
    margin-left: 5px!important;
}