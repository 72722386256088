.detalhamento-endereco{
    height: 8.8125em !important;
    width: 100% !important;
    border-radius: 0.3125em !important;
    min-width: 8.8125em !important;
}

.container-informacoes {
    border-left: 0.3125em solid #36D896!important;
    height: 3.5em!important;
    width: 90% !important;
    margin-left: 5% !important;
    margin-top: 1.2% !important;
    display: flex !important;
    justify-content: space-between !important;

}

.campo-endereco-empresas, .campo-bairro-empresas, .campo-cidade-empresas, .campo-cep-empresas,
.campo-estado-empresas, .campo-email-empresas{
    height: 25px !important;
    margin-top: 15px !important;
    font-size: 14px !important;
}

.campo-endereco-empresas{
    margin-left: 20px !important;
}

.informacoes-empresas{
    font-size: 14px !important;
}

.corpo-dados-usuario-razao{
    width: 39% !important;
}

.corpo-dados-usuario-endereco-empresa{
    width: 29.75em;
    margin-right: 1em;
}

.corpo-dados-usuario-email-empresa{
    margin-right: 1em;
    width: 49%;
}

.tooltip-campos-fatura{
    margin-top: 0 !important;
 
}

.campo-cnpj-empresa{
    width: 49% !important;
    display: flex !important;
}

.corpo-dados-usuario-numero{
    margin-right: 1em;
}

.valor-fat-febraban{
    text-align: right !important;
    width: 7.2em!important;
    font-size: 1em!important;
    margin-left: 5px !important;
}

.modal-importar-febrabans{
    padding: 0.5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 35em!important;
}

.input-importar-febraban{
    position: absolute;
    z-index: -1;
}

.btn-importar-febraban{
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    height: 2em !important;
    width: 7.75em !important;
    /* border: 0.09375em solid #36D896!important; */
    /* background-color: #36D896!important; */
    box-shadow: none!important;
    border-radius: 3.125em!important;
    float: left!important;
    display: flex!important;
    align-items: center;
    margin: 10px 10px 10px 0 !important;
}

.img-importar-febraban{
    width: 30px;
    height: 30px;
    margin-left: 0.875em;
}

.titulo-lista-pedidos-fatura{
    width: 100% !important;
    font-family: Roboto !important;
    font-size: 1em !important;
    margin: 10px 0 0 0 !important;
}

.qtdArquivosModal{
    max-height: 300px;
    overflow: auto;
}

.lista-pedidos-faturas{
    margin-top: 0.3125em !important;
    display: flex !important;
    align-items: center !important;
    color: #fff !important;
    display: flex !important;
    align-items: center !important;
    height: 1.9375em !important;
    width: 100% !important;
    border-radius: 3.125em !important;
}

.lista-nome-pedidos-pedidos {
    width: 85% !important;
    margin: 0 11px 0 9px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.label-alerta-febraban{
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
    line-height: 1.5em!important;
    margin: 10px 0 0 0;
}

.container-switch-febraban{
    margin-top: 1em!important;
    display: flex !important;
}

.label-termos-febraban{
    line-height: 1.375em!important;
    margin-top: 8px !important;
    margin-right: 15px !important;
}