
.container-itens-expandido{
    display: flex !important;
    margin-bottom: 0.9375em !important;
}

.resumo-fatura{
    height: 15.9375em !important;
    width: 65% !important;
    border-radius: 0.3125em !important;
}

.percentual-gastos{
    height: 15.9375em !important;
    width: 35% !important;
    border-radius: 0.3125em !important;
    margin-left: 0.9375em !important;
    min-width: 27.5em !important;
}

.triangulo-navegacao-financeiro{
    width: 0!important;
    height: 0!important;
    border-left: 0.9em solid transparent!important;
    border-right: 0.9em solid transparent!important;
    margin-left: 0.9375em !important;
    margin-top: 0.3125em !important;
    transform: translate(-50%, 0%)!important;
}

.icone-label-filtro-financeiro{
    height: 0.375em!important;
    width: 0.375em!important;
    margin-left: 1em!important;
    margin-right: 0.8em!important;
    margin-bottom: 0.3125em!important;
    border-radius: 6.25em!important;
    background-color: #36D896!important;
}

.label-janela-financeiro{
    margin-top: 0.625em !important;
    font-family: Roboto !important;
    font-size: 1.125em !important;
    font-weight: 700 !important;
    line-height: 1.3125em !important;
}

.container-conteudo-resumo-faturas{
    height: 10.625em !important;
    width: 93% !important;
    padding-right: 1em!important;
    /* border-left: 0.3125em solid #36D896; */
    margin-top: 0.8125em !important;
    margin-left: 3em !important;
    overflow: auto !important;
}

.tabela-transparente-header-fixo th{
    padding: 0em !important;
    border-bottom: 0.0625em solid black !important;
    margin-top: -12px;
    /* min-width: 14em!important; */
    font-size: 0.875em !important;
    z-index: 10!important;
}
.tabela-transparente-header-fixo td{
    /* padding: 0.4em 1em 0.4em 0em!important; */
    border-bottom: 0.0625em solid black !important;
    padding-left: 0px!important;
}

.tabela-transparente-header-fixo-novo th{
    padding: 10px !important;
    border-bottom: 0.0625em solid #bebebe !important;
    margin-top: -12px;
    /* min-width: 14em!important; */
    font-size: 0.875em !important;
    z-index: 10!important;
}
.tabela-transparente-header-fixo-novo td{
    /* padding: 0.4em 1em 0.4em 0em!important; */
    border-bottom: 0.0625em solid #cbcbcb !important;
    padding-left: 10px!important;
}

{
   margin-left: 0.9375em !important;
}

.resumo-fatura .react-bootstrap-table thead tr th{
    border-radius: 0 !important;
}

.texto-td-tabela-direita{
    margin-left: 1.25em !important;
}
.texto-td-tabela-centralizado{
    margin-left: 1.875em !important;
}

.texto-total-grafico{
    font-size: 1em !important;
    font-family: Roboto !important;
}

.container-texto-soma-total{
    margin-top: 1.25em !important;
}

.texto-soma-total{
    font-size: 1em !important;
    font-family: Roboto !important;
    font-weight: 700 !important;
}

.testanolargura {
    width: 0.625em !important;
    background-color: red !important;
}

.expand-row-tabela-financeiro-1{/* ALTERNA DE ACORDO COM UM ESTADO DE REDUX PARA TORNAR A EXPAND ROW RESPONSIVA */
    max-width: 101%!important;
    margin-left: -0.58em;
    transition: 0.5s ease!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
    margin-bottom: 24px;
}

.expand-row-tabela-financeiro-2{/* ALTERNA DE ACORDO COM UM ESTADO DE REDUX PARA TORNAR A EXPAND ROW RESPONSIVA */
    margin-left: -0.58em;
    max-width: 99vw!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    height: 17em!important;
    transition: 0.5s ease!important;
}

.form-row-sem-margem{
    margin:  0em !important;
}

.generico1{
    background-color: red !important;
}


.generico1{
    background-color: blue !important;
}

.esfera-legenda-percentual-gastos{
    height: 1.125em !important;
    width: 1.125em !important;
    border-radius: 50% !important;
}

.grupo-legenda-percentual-gastos-1{
    width: 100% !important;
    display: flex !important;
    max-height: 10.625em !important;
    overflow-y: auto !important;
    margin-bottom: 0.75em !important;
}

.coluna-legenda-percentual-gastos-1{
    width: 15% !important;
}

.coluna-legenda-percentual-gastos-2{
    width: 55% !important;
}

.coluna-legenda-percentual-gastos-3{
    width: 20% !important;
    text-align: right !important;
}

.nome-legenda-percentual-gastos{
    font-family: RobotoRegular !important;
    font-size: 0.875em !important;
    line-height: 1.5em !important;
    display: flow-root !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 100% !important;
  }

.porcentagem-legenda-percentual-gastos{
    font-family: RobotoRegular !important;
    font-size: 1.125em !important;
    font-weight: 700 !important;
    line-height: 1.3125em !important;
}

.container-percentual-gastos{
    width: 100% !important;
    display: flex !important;
}

.container-conteudo-Percentual-gastos{
    height: 10.625em !important;
    /* border-left: 0.3125em solid #36D896; */
    margin-top: 0.8125em !important;
    margin-left: 2.5em !important;
    width: 42% !important;
}

.container-legenda-percentual-gastos{
    width: 58% !important;
    margin-top: 0.75em !important;
    overflow: auto !important;
    max-height: 11.2em  !important;
}

.valor-financeiro {
    text-align: right !important;
    width: 5.2em!important;
    font-size: 1em!important;
    margin-left: 5px !important;;
}

.valor-financeiro2 {
    text-align: right !important;
    width: 5.2em!important;
    font-size: 1em!important;
    margin-left: 5px !important;;
}

.valor-rs-financeiro {
    width: 10% !important;
    height: 100% !important;
}

.container-valor-financeiro {
    display: inline-flex !important;
    width: 100% !important;
    height: 20px !important;
}

.resumo-fatura th{
    border-radius: 0 !important;
}

.container-tabela-solicitacao-simcard{
    width: 100%;
}