.container-caixas-descricao-solicitacao-cliente{
    min-width: 100.3125em;
    display: flex;
    margin: 0em 0px 0.625em 0px;
}

#tela-descricao-visao-geral-soliciatacao-cliente .react-bootstrap-table td{
    padding-left: 0 !important;
    padding-right: 0 !important;
}


.container-caixas-tela-descricao-solicitacao-cliente{
    max-height: 23.875em;
    min-height: 12.875em;
    width: 100%;
}

.container-caixas-tela-descricao-solicitacao-cliente-2{
    padding: 0em 34em 1em 1em;
    border-radius: 0.5em;
    max-height: 23.875em;
    min-height: 12.875em;
    width: 100%;
}

.react-bootstrap-table .expand-row-solicitacao{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.caixa-descricao-solicitacao-cliente-dados {
    margin-top: 1.5625em;
    margin-left: 0.9375em;
    margin-bottom: 1.375em;
    float: left!important;
    max-height: 9.375em!important;
    min-height: 20.5em!important;
    overflow: visible;
    width: 100%!important;
    border-left: 0.3125em solid #36D896;
    padding: 0px 1.25em 0px ;
    overflow-y: auto;
}
.tabela-transparente th{
    min-width: 11em!important;
}
.tabela-transparente td{
    padding: 0.4375em 0px 0.4375em 0px!important;
}

.triangulo-detalhamento-solicitacao-central-acoes{
    width: 0!important;
    height: 0!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-left: 3em!important;
    transform: translate(-50%, 0%)!important;
    margin-top: -0.9em;
}