.modal-content{
    border-radius: 0.3125em!important;
    border: none!important;
    width: 16.875em!important;
    border-radius: 0 0 0.3125em 0.3125em!important;
}

.corpo{
    padding: 1.5625em!important;
}

.header{
    margin-top: 0.625em!important;
    padding: 0em!important;
    border: none!important;
    height: 1.8125em!important;
    font-family: RobotoRegular!important;
    font-size: 1.375em!important;
    font-weight: 700!important;
    line-height: 1.8125em!important;
}

.close{
    background-position: center!important;
    background-repeat: no-repeat!important;
    margin-top: 0em!important;
    margin-right: 0.625em!important;
    width: 1.875em!important;
    height: 1.875em!important;
}

.btn:focus{
    box-shadow: 0em!important;
}

.coluna-exibir-coluna-1{
    min-height: 0em!important;
    border: none!important;
    max-height: 50vh!important;
    overflow: auto!important;
}

.label-acordeao-exibir-coluna{
    display: flex;
    justify-content: space-between!important;
}

.acordeao-exibir-coluna-icone{
    height: 0.625em!important;
}

.acordeao-exibir-coluna{
    background-color: transparent!important;
    font-family: RobotoRegular!important;
    border: 0em!important;
    box-shadow: 0em!important;
}

button:focus.acordeao-exibir-coluna{
    box-shadow: none!important;
    border: none!important;
}

.divisoria-acordeao-exibir-coluna{
    height: 0.0625em!important;
    width: 100%!important;
}

.container-switches-exibir-coluna{
    display: grid!important;
    min-height: none!important;
    max-height: none!important;
}

.coluna-switch-exibir-coluna2{
    text-align: left!important;
    max-width: 3.75em!important;
    height: 2.8125em!important;
}

.coluna-switch-exibir-coluna3{
    text-align: center!important;
    margin-top: 0.6em!important;
}

.coluna-switch-exibir-coluna1{  
    font-family: RobotoRegular!important;
    font-size: 1em!important;
    text-align: left!important;
    width: 1.875em!important;
    height: 0.625em!important;
}

.form-row>.col, .form-row>[class*=col-]{
    text-align: left!important;
    padding: 0em!important;
    margin: 0em!important;
}

.container-exibir-colunas{
    width: 16.875em!important;
    padding-left: 2.75em!important;
    padding-bottom: 2.75em!important;
    padding-top: 0.625em!important;
}

.label-switch-exibir-coluna{
    margin-top: 0.5125em!important;
    font-size: 1em!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 169px !important;
}

.label-titulo-colunas{
    font-family: RobotoRegular!important;
    font-size: 1.175em!important;
    line-height: 1.8125em!important;
    font-weight: 700!important;
}

.label-titulo-botao-colunas{
    margin-left: 1em!important;
    font-family: RobotoRegular!important;
    font-size: 1em!important;
    line-height: 2.74em!important;
    font-weight: 700!important;
}

.grupo-exibir-colunas{
    width: 100%!important;
    display: flex!important;
    min-height: 1em!important;
    margin-bottom: 1em!important;
}

.coluna-exibir-colunas-1{
    width: 12.125em!important;
    height: 18.9em!important;
    min-height: 1em!important;
    margin-right: 1em!important;
}

.divisoria-switch-exibir-colunas{
    width: 447px !important;
    /* margin-left: -355px !important; */
}

.divisoria-switch-exibir-colunas-2 {
    width: 223px !important;
    margin-left: 0px;
}


.coluna-exibir-colunas-2{
    padding: 1em!important;
    width: 31em!important;
    height: 18.9em!important;
    min-height: 1em!important;
    display: flex!important;
    flex-wrap: wrap!important;
    border-radius: 5px !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
}

.container-switches-exibir-colunas-2{
    cursor: pointer!important;
    width: 100%!important;
    height: 2.6875em!important;
}

.container-switches-exibir-colunas{
    height: 18.9em!important;
    min-height: 1em!important;
    display: flex!important;
    flex-direction: column!important;
    flex-wrap: wrap!important;
    margin-left: 8px !important;
}

.grupo-botoes-exibir-colunas{
    display: flex!important;
}

.grupo-switches-exibir-colunas{
    display: flex!important;
    margin-bottom: 0.37em!important;
}

.grupo-switches-alterar-servico{
    display: flex!important;
}

.coluna-switches-exibir-colunas-1 {
    width: 3.4375em !important;
    margin-bottom: -4px !important;
    margin-top: 4px !important;
}

.coluna-switches-exibir-colunas-2 {
    width: 75%!important;
    margin-top: 4px !important;
}

.triangulo-personalizar {
    width: 0!important;
    height: 0!important;
    margin-left: 12.7em!important;
    margin-top: 0.1em!important;
    transform: rotate(90deg) !important;
    position: absolute !important;
    border-top: 1.9375em solid transparent!important;
    border-left: 0.7em solid transparent!important;
    border-right: 0.7em solid transparent!important;
}
