.card-dashboard-migracao-plano {
  padding: 30px;
  border-radius: 11px !important;
  height: 390px !important;
  box-sizing: content-box !important;
}

#grafico-PlanoReal,
#grafico-Status {
  .container-botoes-grafico {
    margin-top: 36px;
  }
}

#grafico-Excedente {
  .container-botoes-grafico {
    margin-top: 10px;
  }
}

.cardHeader-divisor {
  height: 1px !important;
  width: 100% !important;
}

.segundo-divisor {
  height: 1px !important;
}

.cardHeader-grafico-migracaoPlano {
  margin: 0px !important;
  flex-direction: column;
  gap: 8px;
}

.cardHeader-grafico-migracaoPlano-col {
  display: flex;
  justify-content: space-between;
  line-height: 5px;
  align-items: flex-end;
}

.cardHeader-titulo {
  font-family: Roboto !important;
  font-size: 1.13em !important;
  font-weight: 700 !important;
}

.cardHeader-text-ciclo {
  display: grid;
  grid-template-columns: 150px 3fr 1fr;
  gap: 100px;
}

.cardHeader-text-ciclo-dunets {
  display: grid;
  grid-template-columns: 150px auto;
}
/* -------------- Botões -------------- */
.container-botoes-grafico {
  display: flex;
  justify-content: end;
  gap: 8px;
  z-index: 5;
  position: relative;
}

.botao-legenda-grafico {
  box-shadow: none !important; /* Remove a sombra */
  width: 153px;
  height: 32px;
  border-radius: 16px !important;
  padding: 4px !important;

  text-transform: none !important; /* Evita texto em maiúsculas */
  font-size: 13px !important;
  font-size: 700;
}

.botao-legenda-grafico:hover {
  box-shadow: none; /* Remove a sombra no hover */
}
/* -------------- fIM Botões -------------- */

/******************** GRAFICOS GRANDES FUNDO ********************/
.grupo-dash-grafico-barra-migracaoPlano {
  position: relative !important;
  display: flex !important;
  margin-top: 20px;
  height: 67% !important;
  align-items: center !important;
  gap: 24px;
}

.grupo-dash-grafico-barra-migracaoPlano > .recharts-responsive-container {
  width: 530px !important;
  min-width: 350px !important;
  min-height: 230px !important;
  zoom: 120%;
}

.grafico-economia {
  margin-top: 10px;
  width: 450px !important;
  height: 200px !important;
}

.grupo-dash-grafico-donuts-migracaoPlano {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 24px;
}

.container-grafico-pizza-grande-migracaoPlano {
  position: relative !important;
  height: 240px !important;
  width: 240px !important;
  align-content: center !important;
  z-index: 10 !important;
}

.container-tooltip-grafico-migracaoPlano {
  padding: 0.625em 0.625em 0.625em 0.625em !important;
  border-radius: 0.3125em !important;
}

.tooltip-label-grafico-migracaoPlano {
  display: flex;
  align-items: center;
  gap: 6px;
}

.esfera-tooltip-dashboard-migracaoPlano {
  width: 5px !important;
  height: 4px !important;
  border-radius: 50% !important;
}

.tooltip-label-grafico-migracaoPlano > p {
  font-weight: 700 !important;
  height: 0.4em !important;
  width: 100% !important;
  font-size: 0.75em !important;
}

.container-legendas-grafico-barra-migracaoPlano,
.container-legendas-grafico-donuts-migracaoPlano {
  overflow: hidden !important;
}

.container-legendas-grafico-barra-migracaoPlano {
  display: flex;
  gap: 14px;
  flex-direction: column;
  justify-content: flex-start;
  width: max-content;
}

.legenda-economia-2 {
  width: 100% !important;
}

.container-legendas-grafico-donuts-migracaoPlano {
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: center;
}

.linha-legenda-migracaoPlano {
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  display: grid;
  grid-template-columns: 1fr auto;
}

.coluna-legenda-migracaoPlano-1 {
  display: flex;
  align-items: flex-start;
}

.coluna-legenda-migracaoPlano-1-esfera {
  display: flex;
  align-items: center;
  margin-top: 7px;
}

.coluna-legenda-migracaoPlano-1-texto {
  margin-left: 8px;
  line-height: 1.4rem;
}

.esfera-legenda-dashboard-migracaoPlano {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% !important;
}

.texto-legenda-grafico-migracao {
  font-family: 'Roboto' !important;
  font-size: 18px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.coluna-legenda-migracaoPlano-2-valor {
  align-self: start;
}

.texto-legenda-box-status-migracaoPlano {
  font-size: 18px;
  font-weight: 700;
}

.dropdown-vertical {
  width: 22px;
  height: auto;
}

#grafico-Excedente {
  .grupo-dash-grafico-donuts-migracaoPlano {
    margin-top: -40px;
  }
}

@media only screen and (max-width: 1250px) {
  .card-dashboard-migracao-plano {
    min-width: 367px !important;
  }

  .grupo-dash-grafico-barra-migracaoPlano {
    height: auto !important;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1600px) {
  #grafico-Excedente > .grupo-dash-grafico-donuts-migracaoPlano {
    height: 53% !important;
  }
}

@media only screen and (max-width: 1300px) {
  .cardHeader-text-ciclo {
    gap: 8px;
  }

  .grafico-economia {
    margin-top: 10px;
    width: 270px !important;
  }

  #grafico-Excedente {
    .grupo-dash-grafico-donuts-migracaoPlano {
      margin-top: -10px;
    }
  }

  .grupo-dash-grafico-donuts-migracaoPlano {
    gap: 10px;
  }
}
