.modal-cadastro-fornecedor-faturas{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 50em!important;
}

.grupo-cadastro-fornecedor-faturas-1{
    width: 100%!important;
    display: flex!important;
}

.coluna-cadastro-fornecedor-faturas-1{
    margin-right: 3%;
    width: 35%;
}

.coluna-cadastro-fornecedor-faturas-1-1{
    width: 35%;
}

.coluna-cadastro-fornecedor-faturas-2{
    width: 35%;
}

.coluna-cadastro-fornecedor-faturas-3{
    margin-right: 3%;
    width: 24%;
}

.coluna-cadastro-fornecedor-faturas-4{
    margin-right: 3%;
    width: 46%;
}

.coluna-cadastro-fornecedor-faturas-5{
    margin-right: 3%;
    width: 24%;
}

.coluna-cadastro-fornecedor-faturas-6-1{
    width: 24%;
}

.coluna-cadastro-fornecedor-faturas-6-2{
    width: 24%;
    margin-right: 3%;
}

.coluna-cadastro-fornecedor-faturas-7{
    margin-right: 3%;
    width: 56%;
}

.coluna-cadastro-fornecedor-faturas-8{
    width: 14%;
    margin-right: 3%;
}

.coluna-cadastro-fornecedor-faturas-9{
    margin-right: 3%;
    width: 30%;
}

.coluna-cadastro-fornecedor-faturas-10{
    margin-right: 3%;
    width: 50%;
}

.coluna-cadastro-fornecedor-faturas-11{
    width: 14%;
}

.coluna-cadastro-fornecedor-faturas-12{
    width: 100%;
}

.divisoria-cadastro-operadoras-faturas{
    width: 100%!important;
    height: 0.0625em!important;
    margin-top: 0.5em!important;
    margin-bottom: 1.5em!important;
}

.expand-row-fat-fornecedores-1{/* ALTERNA DE ACORDO COM UM ESTADO DE REDUX PARA TORNAR A EXPAND ROW RESPONSIVA */
    max-width: 101%!important;
    margin-left: -0.58em;
    transition: 0.5s ease!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
    height: 15.5em!important;
}

.expand-row-fat-fornecedores-2{/* ALTERNA DE ACORDO COM UM ESTADO DE REDUX PARA TORNAR A EXPAND ROW RESPONSIVA */
    margin-left: -0.58em;
    max-width: 101%!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    height: 15.5em!important;
    transition: 0.5s ease!important;
}

.triangulo-detalhamento-fat-fornecedores{
    width: 0!important;
    height: 0!important;
    border-left: 0.9em solid transparent!important;
    border-right: 0.9em solid transparent!important;
    margin-left: 1.5375em !important;
    margin-top: 0.3125em !important;
    transform: translate(-50%, 0%)!important;
}

.detalhamento-fat-fornecedores-1{
    height: 13.9375em !important;
    width: calc( 50% - 1em) !important;
    margin-right: 1em;
    border-radius: 0.3125em !important;
}

.detalhamento-fat-fornecedores-2{
    height: 13.9375em !important;
    width: 50% !important;
    border-radius: 0.3125em !important;
}

.linha-detalhamento-fat-fornecedores{
    margin-left: 2em!important;
    margin-top: 1em!important;
    border-left: 0.3125em solid #36D896!important;
    padding-top: 0.5em!important;
    padding-left: 2.25em!important;
    padding-right: 1.25em!important;
    height: 8.9em!important;
    overflow: scroll;
    width: calc( 100% - 4em ) !important;
}

.caixa-detalhamento-fat-fornecedores{
    display: flex!important;
}

.label-dados-fornecedor {
    width: 100% !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    margin-left: 5px!important;
}

.label-dados-fornecedor-2 {
    margin-left: 5px !important;
}

.label-dados-fornecedor-3 {
    margin-left: 5px !important;
}