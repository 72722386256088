/******************** TABELA DE AUTOMACAO ********************/
.tabela-automacao-container-esfera{
    display: flex!important;
}

.tabela-automacao-esfera-verde{
    margin-top: 0.25em!important;
    margin-right: 1em!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 50%!important;
    background-color: #36D896!important;
}

.tabela-automacao-esfera-amarela{
    margin-top: 0.25em!important;
    margin-right: 1em!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 50%!important;
    background-color: #FDCB62!important;
}

.tabela-automacao-esfera-vermelha{
    margin-top: 0.25em!important;
    margin-right: 1em!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 50%!important;
    background-color: #CD2D37!important;
}

.tabela-automacao-esfera-azul{
    margin-top: 0.25em!important;
    margin-right: 1em!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 50%!important;
    background-color: #4A9DF0!important;
}

.tabela-automacao-esfera-cinza{
    margin-top: 0.25em!important;
    margin-right: 1em!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 50%!important;
}

/******************** NOVA/EDITAR REGRA ********************/
.modal-acao-nova-editar-regra-automacao{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 36.25em!important;
}

.container-switch-nova-editar-regra-automacao{/* PARA POSICIONAR O SWITCH CORRETAMENTE */
    margin-top: 1.42em!important;
    margin-bottom: 1.875em!important;
}

.container-switch-nova-editar-regra-automacao-2{/* PARA POSICIONAR O SWITCH CORRETAMENTE */
    margin-top: 3.21em!important;
    margin-bottom: 1.875em!important;
}

.label-termos-nova-editar-regra-automacao{/* PARA POSICIONAR O TEXTO DE TERMOS CORRETAMENTE */
    line-height: 2.4em!important;
    margin-bottom: -1.875em!important;
}

.nova-editar-regra-automacao-divisor{/* PARA POSICIONAR A LINHA DIVISORA CORRETAMENTE */
    height: 0.0625em!important;
    margin-top: 1em!important;
    margin-bottom: 1em!important;
}

.grupo-nova-editar-regra-automacao-1{/* CRIA ROWS PARA OS SELECTS */
    width: 100%!important;
    display: flex!important;
}

.coluna-nova-editar-regra-automacao-1{/* CRIA A PRIMEIRA COLUNA DAS ROWS */
    width: 48%!important;
    margin-right: 4%!important;
}

.coluna-nova-editar-regra-automacao-2{/* CRIA A SEGUNDA COLUNA DAS ROWS */
    width: 48%!important;
}

.item-select-nivel-importancia-container-esfera{/* USADA PARA FORMATAR O ITEM DO SELECT DE IMPORTANCIA */
    display: flex!important;
    margin-left: 1.5em!important;
}

.item-select-nivel-importancia-esfera-verde{/* USADA PARA POSICIONAR AS ESFERAS CORRETAMENTE DENTRO DO ITEM DO SELECT */
    margin-top: 0.5em!important;
    margin-right: 1em!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 50%!important;
    background-color: #36D896!important;
}

.item-select-nivel-importancia-esfera-amarela{/* USADA PARA POSICIONAR AS ESFERAS CORRETAMENTE DENTRO DO ITEM DO SELECT */
    margin-top: 0.5em!important;
    margin-right: 1em!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 50%!important;
    background-color: #FDCB62!important;
}

.item-select-nivel-importancia-esfera-vermelha{/* USADA PARA POSICIONAR AS ESFERAS CORRETAMENTE DENTRO DO ITEM DO SELECT */
    margin-top: 0.5em!important;
    margin-right: 1em!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 50%!important;
    background-color: #CD2D37!important;
}

.select-nivel-importancia-container-esfera{/* USADA PARA FORMATAR CORRETAMENTE O CONTEUDO DO BOTAO SELECT */
    display: flex!important;
}

.select-nivel-importancia-esfera-verde{/* USADA PARA POSICIONAR A ESFERA CORRETAMENTE DENTRO DO BOTAO DO SELECT */
    margin-right: 1em!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 50%!important;
    background-color: #36D896!important;
}

.select-nivel-importancia-esfera-amarela{/* USADA PARA POSICIONAR A ESFERA CORRETAMENTE DENTRO DO BOTAO DO SELECT */
    margin-right: 1em!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 50%!important;
    background-color: #FDCB62!important;
}

.select-nivel-importancia-esfera-vermelha{/* USADA PARA POSICIONAR A ESFERA CORRETAMENTE DENTRO DO BOTAO DO SELECT */
    margin-right: 1em!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 50%!important;
    background-color: #CD2D37!important;
}

.campo-select-opcao-checkbox{/* CLASSE PADRAO PARA OS ITEMS DO SELECT */
    display: flex!important;
    margin-left: -2.5em!important;
    width: 150%!important;
    height: 1.875em!important;
    line-height: 1.75em!important;
    cursor: pointer!important;
}

.container-checkbox-item-select {
    margin-top: 0.3125em!important;
    display: block;
    position: relative;
    padding-left: 2.1875em;
    margin-bottom: 0.75em;
    cursor: pointer;
    font-size: 1.375em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
.container-checkbox-item-select input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.chekmark-checkbox-item-select {
    margin-left: 0.625em!important;
    position: absolute;
    border-radius: 0.3125em!important;
    top: 0;
    left: 0;
    height: 1.25em;
    width: 1.25em;
}

.chekmark-checkbox-item-select:after {
    content: "";
    position: absolute;
    display: none;
}

.container-checkbox-item-select input:checked ~ .chekmark-checkbox-item-select:after {
    display: block;
}

.campo-select-usuarios-checkbox-corpo{/* CLASSE PADRAO PARA O CORPO DO SELECT */
    max-height: 10em!important;
    margin-left: -10.6em!important;
    margin-top: -11em!important;
    width: 25em!important;
    border-radius: 0.3125em!important;
    overflow-x: hidden!important;
    z-index: 100!important;
    position: absolute!important;
}

.triangulo-select-absoluto{
    margin-bottom: 11em!important;
}

/******************** FILTRO ********************/
.filtro-automacao-importancia-container-esfera{
    display: flex!important;
}

.filtro-automacao-importancia-esfera-verde{/* USADA PARA POSICIONAR A ESFERA CORRETAMENTE DENTRO DO FILTRO */
    margin-left: 3.40em!important;
    width: 1em!important;
    height: 1em!important;
    border-radius: 50%!important;
    background-color: #36D896!important;
}

.filtro-automacao-importancia-esfera-amarela{/* USADA PARA POSICIONAR A ESFERA CORRETAMENTE DENTRO DO FILTRO */
    margin-left: 3em!important;
    width: 1em!important;
    height: 1em!important;
    border-radius: 50%!important;
    background-color: #FDCB62!important;
}

.filtro-automacao-importancia-esfera-vermelha{/* USADA PARA POSICIONAR A ESFERA CORRETAMENTE DENTRO DO FILTRO */
    margin-left: 3.2em!important;
    width: 1em!important;
    height: 1em!important;
    border-radius: 50%!important;
    background-color: #CD2D37!important;
}

.campo-select-opcao-automacao{
    width: 121% !important;
}