.container-tabela-generica-modalCancelamentoDispositivos{
    max-height: 18em;
    overflow: auto;
}

.campo-select-icone-desativado{
    margin-top: 0.15em!important;
    margin-left: 1em!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
}
.campo-select-icone-ativado{
    margin-top: 0.15em!important;
    margin-left: 1em!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
}

.select-triangulo-cancelamento{
    position: absolute!important;
    margin-top: -1.8em!important;
}

.container-botao-revelar-detalhamento-valores{
    border-radius: 50px !important;
    width: 19em !important;
    margin-top: 1em !important;
    margin-bottom: .9em !important;
}

.container-detalhamento-valores-dropdown{
    border-radius:10px;
    padding: 8px;
    padding-left: 1em;
    padding-right: 1em;
    z-index: 1;
    width:19em;
    position: absolute; 
}

.campo-select-generico-opcao-cancelamento-1{
    display: flex!important;
    width: 100%!important;
    height: 2em!important;
}

.campo-select-generico-opcao-cancelamento{
    justify-content: space-between;
    width: auto;
    z-index: 2;
}

.label-valor-cancelamento-1 {
    margin-left: 20px;
    font-size: small;
}

.campo-texto-valor-cancelamento{/* PARA POSICIONAR O CAMPO DE VALOR */
    width: 20em;
    margin-top: 2em!important;
    justify-content: space-between;
    z-index: 2
}

.campo-select{
    display: flex;
    justify-content: space-between;
}

.spacer{
    padding-top: 3.1em;
}

.alinhamento{
    display: flex;
    width: 10em;
}

.dropdown-cancelamento{
    width: 1em !important;
    height: 1em !important;
    margin-top: 7px !important;
}

.campo-select-lista-cancelamento-1{
    list-style-type: none!important;
    margin-block-start: 0em!important;
    margin-block-end: 0em!important;
    line-height: 1.8em !important;
}

.campo-select-lista-cancelamento{
    list-style-type: none!important;
    margin-block-start: 0em!important;
    margin-block-end: 0em!important;
    padding-left: 1.5em !important;
}

.input-cancelamento-telefone {
    width: 100% !important;
    font-family: RobotoRegular !important;
    font-size: 0.875em !important;
    line-height: 1em !important;
    border: 0em !important;
    box-shadow: none !important;
    border-radius: 5em !important;
    display: -webkit-inline-box !important;
}

.container-tabela-modal-cancelamento-reposicao{
    overflow: auto!important;
    max-height: 27vh!important;
}

.modal-novo-cancelamento{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 2em 2em 2em 2em !important;
    border-radius: 0.625em 0.625em 0.625em 0.625em !important;
    width: 796px !important;
    max-height: 100vh;
    overflow-y: auto;
}

.icone-label-janela {
    height: 16px !important;
    width: 16px !important;
}

.icone-label-janela-alteracao-ciclo {
    height: 16px !important;
    width: 16px !important;
}

.margin-top-cancelamento{
    margin-bottom: 20px;
}

.label-janela{
    font-weight: 100!important;
    font-size: 24px !important;
}

.label-janela-alteracao-ciclo{
    font-weight: 100!important;
    font-size: 24px !important;
}

.label-integracao{
    font-weight: 100!important;
    font-size: 24px !important;
}

.label-integracao-alteracao-ciclo{
    font-weight: 100!important;
    font-size: 24px !important;
}

.properties{
    font-weight: 100!important;
    font-size: 22px !important;
}
.label-cmp, .label-cmp-alteracao-ciclo{
    font-weight: 100!important;
    font-size: 24px !important;
}
.container-botao-revelar-detalhamento-valores {
    border-radius: 50px !important;
    width: 16.5em!important;
    cursor: pointer;
}

.campo-select-lista-cancelamento-1{
    margin: 0px -143px 0px -105px !important;
    padding: 2px 4px;
}

.botao-paginacao-primeira-ultima-tabela{
    width: 52px !important;
}

.ultimo-item{
    margin-left: 10px;
}

.primeiro-item{
    margin-right: 15px;
}

.container-botao-revelar-detalhamento-valores{
    margin: 30px 0px 0px 8px !important;
    padding: 1px 131px !important;
}
.container-detalhamento-valores-dropdown{
    margin-top: 5px !important;
}

.label-campo-margin{
    margin: 20px 0px 0px 0px !important;
}

.campo-select-cancelamento{
    margin-top: 0px !important;
}

.contador-linhas-acoes-dispositivos{
    margin: 0.4em !important;
}

.campo-area-margin{
    margin-top: 5px;
}
.campo-select-triangulo-margin{
    margin-top: -14px !important;
    margin-bottom: -7px !important;
}

.campo-select-cancelamento{
    padding-top: 10px !important;
    padding-bottom: 24px !important;
    width: 100% !important;
}

.campo-select-motivo-margin{
    padding-top: 10px !important;
    padding-bottom: 24px !important;
}

.atencao-label-cancelamento{
    display: flex !important;
    margin: 10px 0px 0px 6px !important;
}

.label-normal-cancelamento{
    width: 650px !important;
    margin-left: 5px !important;
}

.botoes-cancelamento{
    margin-top: 24px !important;
}