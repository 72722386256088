.img-importar-cliente{
    width: 30px;
    height: 30px;
    margin-left: 0.875em;
}

.btn-img-bloqueio-input-adicionar-cliente{
    background-color: transparent!important;
    margin-left: 7em;
    margin-top: -3.49em;
    z-index: 2;
    border: 0px!important;
    box-shadow: none!important;
    padding: 0px!important;
}

.btn-importar-cliente{
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    height: 2.375em!important;
    min-width: 6.875em!important;
    border: 0.09375em solid #36D896!important;
    background-color: #36D896!important;
    box-shadow: none!important;
    border-radius: 3.125em!important;
    float: right!important;
    display: flex!important;
    align-items: center;
}

.bloqueio-input-adicionar-cliente{
    max-width: 9.375em;
    max-height: 2em;
}

.novo-bloqueio-importar-cliente {
    justify-content: center!important;
    align-items: center!important;
    margin-top: 1.2em!important;
}

.modal-bloquear-cliente {
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 36.3125em!important;
}

.btn-switch-enviar-aviso {
    display: flex;
    text-align: center;
    align-items: center;
}

.container-tabela-modal-bloqueio{
    overflow: auto!important;
    max-height: 13.5625em!important;
    padding-bottom: 0.2em!important;
}

.bloqueio-input-adicionar-cliente-alerta{
    margin-left: 0.3em;
}