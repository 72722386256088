.modal-novo-pedido-faturas{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 1em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 71.2em !important;
}

.novo-pedido-divisor{/* PARA POSICIONAR A LINHA DIVISORA CORRETAMENTE */
    height: 0.0625em!important;
    margin-top: 1.5625em!important;
    margin-bottom: 1.5625em!important;
}

.campo-texto-simcard-novo-pedido{/* PARA POSICIONAR O CAMPO DE MB */
    margin-top: 2em!important;
    width: 100%!important;
    display: flex!important;
    align-content: center!important;
    text-align: center!important;
}

.label-simcard-novo-pedido{/* PARA POSICIONAR O CONTEUDO DO CAMPO DE MB */
    margin-left: 50%!important;
    transform: translate(-50%, 25%)!important;
}

.botao-editar-pedidos-novo-simcard{
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    height: 2em!important;
    width: 2em!important;
    /* border: 0.093em solid #36D896!important; */
    background-color: transparent!important;
    box-shadow: none!important;
    border-radius: 3.125em!important;
    float: right!important;
}

.botao-editar-pedidos-novo-simcard-cinza{
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    height: 2em!important;
    width: 2em!important;
    border: 0.093em solid #505352!important;
    background-color: transparent!important;
    box-shadow: none!important;
    border-radius: 3.125em!important;
    float: right!important;
}

.botao-adicionar-pedidos-novo-simcard{
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    height: 2em!important;
    width: 2em!important;
    border: 0.09375em solid transparent!important;
    background-color: transparent!important;
    box-shadow: none!important;
    border-radius: 3.125em!important;
    float: right!important;
}

.botao-adicionar-pedidos-novo-simcard-espacador{
    margin-bottom: 1em!important;
}

.icone-botao-adicionar-pedidos-novo-simcard{
    left: 50%!important;
    top: 50%!important;
    transform: translate(-30%, -20%)!important;
}

.icone-botao-editar-pedidos-novo-simcard{
    left: 50%!important;
    top: 50%!important;
    transform: translate(-0%, -10%)!important;
}

.margem-esquerda-pedidos-novo-simcard{
    margin-left: 0.625em!important;
}

.grupo-painel-pedidos-faturas-1{
    width: 100%!important;
    display: flex!important;
    justify-content: space-around !important;
}

.grupo-painel-pedidos-faturas-2{
    width: 100%!important;
    display: flex!important;
}

.grupo-painel-pedidos-faturas-3{
    margin-top: 1.25em !important;
    width: 100%!important;
    display: flex !important;
    justify-content: space-around !important;
}

.grupo-painel-pedidos-novo-simcard-2{
    width: 100%!important;
    display: flex!important;
}

.coluna-painel-pedidos-faturas-1{
    width: 44%!important;
    margin-right: 2%!important;
}

.coluna-painel-pedidos-faturas-2{
    width: 44%!important;
    margin-right: 2%!important;
}

.coluna-painel-pedidos-faturas-3{
    width: 44%!important;
    margin-right: 2%!important;
    margin-left: 10px!important;
}

.coluna-painel-pedidos-faturas-4{
    width: 54%!important;
}

.campo-texto-quantidade-novo-pedido{
    height: 2em!important;
    width: 100%!important;
    font-family: RobotoRegular!important;
    font-size: 1em!important;
    text-align: center;
    line-height: 1em!important;
    border: 0em!important;
    box-shadow: none!important;
    border-radius: 5em!important;
    display: -webkit-inline-box!important;
    padding: 0px!important;
}

.campo-texto-quantidade-novo-pedido:focus{
    outline:none;
}


.container-valor-pedidosSimcard {
    display: inline-flex !important;
    width: 100% !important;
    height: 20px !important;
}

.valor-rs-pedidosSimcard {
    width: 20% !important;
    height: 100% !important;
}

.valor-pedidosSimcard {
    text-align: right !important;
    width: 35% !important;
    font-size: 1em!important;
    margin-left: 5px!important;
}

.valor-rs-pedidosSimcard2 {
    width: 12% !important;
    height: 100% !important;
}

.valor-pedidosSimcard2 {
    text-align: right !important;
    width: 19% !important;
    font-size: 1em!important;
    margin-left: 5px!important;
}

.button-dropdown-template-pedidos-faturas{
    height: 2em !important;
    width: 7.75em !important;
    border-radius: 3.125em !important;
    color: white !important;
    border-radius: 1.25em !important;
    border: none !important;
    cursor: pointer !important;
    /* background-image: url(/static/media/exportar.2f0f6c2d.svg) !important;
    background-position: 5.625em !important;
    background-repeat: no-repeat !important; */
    font-family: Roboto !important;
    box-shadow: none !important;
    text-decoration: none !important;
}

.btn-importar-pedido-faturas{
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    height: 2em !important;
    width: 7.75em !important;
    border: 0.09375em solid #36D896!important;
    background-color: #36D896!important;
    box-shadow: none!important;
    border-radius: 3.125em!important;
    float: right!important;
    display: flex!important;
    align-items: center;
}

.label-alerta-pedidos-margem{
    margin-top: 1em !important;
    margin-left: 10px !important;
}

.UploadMessage-pedidos{
    margin-right: 2em !important;
    font-size: 1em !important;
    line-height: 1.1875em !important;
}

.menu-dropdown-template-pedidos{
    margin-top: 0.8125em !important;
    height: 4em !important;
    width: 7.6875em !important;
    border-radius: 0.3125em !important;
    min-width: 0em !important;
}
.menu-dropdown-template-pedidos-2{
    margin-top: 0.8125em !important;
    height: 26px !important;
    width: 7.6875em !important;
    border-radius: 0.3125em !important;
    min-width: 0em !important;
}

.titulo-lista-pedidos-fatura{
    width: 100% !important;
    font-family: Roboto !important;
    font-size: 1em !important;
    margin: 10px 0 0 0 !important;
}

.lista-pedidos-faturas{
    margin-top: 0.3125em !important;
    display: flex !important;
    align-items: center !important;
    color: #fff !important;
    display: flex !important;
    align-items: center !important;
    height: 1.9375em !important;
    width: 100% !important;
    border-radius: 3.125em !important;
}

.lista-nome-pedidos-pedidos {
    width: 85% !important;
    margin: 0 11px 0 9px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.div-importar-pedido-faturas{
    margin-left: 10px !important;
}

.expand-row-tabela-fatPedidos{
    max-width: 101%!important;
    margin-left: -0.58em;
    transition: 0.5s ease!important;
    /* transition: 0.5s ease!important; */
    height: 26em!important;
}

.container-detalhamento-fatPedido{
    border-radius: 0.3125em!important;
    width: 100%!important;
    height: 24.6875em!important;
    padding: 1em 2em 2em 2em!important;
    margin-top: 1em!important;
}

.triangulo-navegacao-detalhamento-linha-fatPedido{
    width: 0!important;
    height: 0!important;
    border-left: 0.9em solid transparent!important;
    border-right: 0.9em solid transparent!important;
    margin-left: 1em !important;
    margin-top: 0.4em !important;
    transform: translate(-50%, 0%)!important;
    margin-right: -3em !important;
}