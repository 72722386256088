.detalhamento-endereco{
    height: 8.8125em !important;
    width: 100% !important;
    border-radius: 0.3125em !important;
    min-width: 8.8125em !important;
}

.container-informacoes {
    border-left: 0.3125em solid #36D896!important;
    height: 3.5em!important;
    width: 90% !important;
    margin-left: 5% !important;
    margin-top: 1.2% !important;
    display: flex !important;
    justify-content: space-between !important;

}

.campo-endereco-empresas, .campo-bairro-empresas, .campo-cidade-empresas, .campo-cep-empresas,
.campo-estado-empresas, .campo-email-empresas{
    height: 25px !important;
    margin-top: 15px !important;
    font-size: 14px !important;
}

.campo-endereco-empresas{
    margin-left: 20px !important;
}

.informacoes-empresas{
    font-size: 14px !important;
}

.corpo-dados-usuario-razao{
    width: 39% !important;
}

.corpo-dados-usuario-endereco-empresa{
    width: 29.75em;
    margin-right: 1em;
}

.corpo-dados-conta {
    margin-right: 0.5em !important;
    margin-left: 0.5em !important;
    width: 44% !important;
}

.corpo-dados-conta-numero-conta{
    width: 44% !important;
    margin-left: 1.3em !important;

}

.botao-confirmar-margin{
    margin-right: 20px !important;
}

.conta-dados-divisoria-corpo-conta{
    margin-top: 1.5em !important;
    margin-left: 17px !important;
    width: 93% !important;
}

.container-botoes-cancelar-confirmar{
    margin-top: 3em!important;
}

.css-1wa3eu0-placeholder{
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.label-alerta-sem-margem{
    margin: 0 !important;
}

.expand-row-tabela-faturas-1{
    width: 71%;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
}

.expand-row-tabela-faturas-2{
    width: 77.6%;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
}

.itens-exibidos-tabela-faturas{
    margin-top: -1.3em;
    display: flex!important;
    font-family: RobotoRegular!important;
    justify-content: flex-end!important;
    margin-right: 1em !important;
}

.tabela-preview{
    overflow: auto!important;
    max-height: 23em!important;
}

.modal-editar-fatura{
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 40em!important;
}

.grupo-cadastro-editar-fatura-1{
    width: 100%!important;
    display: flex!important;
}

.coluna-cadastro-editar-fatura-1{
    width: 48.5%!important;
    margin-right: 3%!important;
}

.coluna-cadastro-editar-fatura-2{
    width: 48.5%!important;
}

.coluna-cadastro-editar-fatura-3{
    width: 100%!important;
}

.detalhamento-fat-faturas-1{
    width: 98% !important;
    margin-right: 1em;
    border-radius: 0.3125em !important;
}

.detalhamento-fat-faturas-2{
    /* height: 19.9375em !important; */
    width: 35% !important;
    border-radius: 0.3125em !important;
}

.linha-detalhamento-fat-faturas{
    margin-left: 2em!important;
    overflow: hidden !important;
    width: 100%!important;
}

.expand-row-fat-faturas-1{/* ALTERNA DE ACORDO COM UM ESTADO DE REDUX PARA TORNAR A EXPAND ROW RESPONSIVA */
    max-width: 108vw!important;
    margin-left: -0.58em;
    transition: 0.5s ease!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
    /* height: 21em!important; */
}

.expand-row-fat-faturas-2{/* ALTERNA DE ACORDO COM UM ESTADO DE REDUX PARA TORNAR A EXPAND ROW RESPONSIVA */
    margin-left: -0.58em;
    max-width: 118vw!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    /* height: 21em!important; */
    transition: 0.5s ease!important;
}

.container-preview-fatura-titulo{
    width: 100%!important;
    display: flex;
    justify-content: space-between;
}

.menu-dropdown-formatos-preview{
    margin-left: -5em !important;
    margin-bottom: -6em;
    margin-top: 0.8125em !important;
    height: 5.5em !important;
    width: 7.6875em !important;
    border-radius: 0.3125em !important;
    min-width: 0em !important;
    position: absolute;
    z-index: 305!important;
}

.coluna-preview-fatura-titulo{
    height: 2em !important;
    width: 3em !important;
}
.drop-formato-preview-opcao{
    padding-left: 1em!important;
    width: 100%!important;
    height: 1.875em!important;
    font-size: 0.875em!important;
    line-height: 1.75em!important;
    cursor: pointer;
}

.bt-dropdown-formato-preview{
    height: 2em !important;
    width: 2em !important;
    color: white !important;
    border-radius: 1.25em !important;
    border: none !important;
    cursor: pointer !important;
    background-image: url('../../../icones/exportar.svg') !important;
    background-position: 0.5em !important;
    background-repeat: no-repeat !important;
    margin-right: 1em !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    box-shadow: none !important;
}

.bt-dropdown-formato-preview-false{
    height: 2em !important;
    width: 2em !important;
    background-color: transparent!important;
    border-radius: 1.25em !important;
    border: none !important;
    cursor: pointer !important;
    background-image: url('../../../icones/exportarFalse.svg') !important;
    background-position: 0.5em !important;
    background-repeat: no-repeat !important;
    /* margin-right: 1em !important; */
    margin-top: -1em !important;
    /* margin-bottom: 0.5em !important; */
    box-shadow: none !important;
}

.container-detalhamento-faturas{
    margin-left: -0.9em;
    width: 101.4%!important;
    display: flex!important;
    height: 27em!important;
}

.container-navegacao-detalhamento-faturas{
    display: inline-block!important;
    height: 27em!important;
    width: 11em!important;
    overflow: hidden!important;
    margin-left: 0.9em!important;
}

.container-itens-expandido-faturas{
    display: flex !important;
    margin-bottom: 0.9375em !important;
    margin-top: 1em !important;
}

.corpo-navegacao-detalhamento-faturas{
    height: 25.75em!important;
    width: 100% !important;
    border-radius: 0.3125em 0.3125em 0.3125em 0.3125em!important;
    padding-top: 1.25em!important;
    padding-bottom: 1.25em!important;
}

.valor-rs-detalhamento-nota-fiscal, .valor-detalhamento-nota-fiscal, .valor-rs-detalhamento-historico, .valor-detalhamento-historico { 
    text-align: right !important;
    width: 15px !important;
    font-size: 1em!important;
    margin-left: 7px!important;
}

.valor-rs-detalhamento-nota-fiscal, .valor-rs-detalhamento-historico { 
    margin-left: 0px!important;
}

.triangulo-dropdown-template-detalhamento{
    position: absolute !important;
    /* z-index: 1!important; */ /* ESTAVA FICANDO POR CIMA DE OUTROS ELEMENTOS */
    width: 0!important;
    height: 0!important;
    border-left: 0.9375em solid transparent !important;
    border-right: 0.9375em solid transparent !important;
    margin-top: -0.9em!important;
    margin-left: 5em!important;
}

.container-principal-campos-conciliar-lote{
    display: flex !important;
    justify-content: space-between !important;
}