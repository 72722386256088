.modal-novo-pedido{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 68.4375em!important;
}

.novo-pedido-divisor{/* PARA POSICIONAR A LINHA DIVISORA CORRETAMENTE */
    height: 0.0625em!important;
    margin-top: 1.5625em!important;
    margin-bottom: 1.5625em!important;
}

.campo-texto-simcard-novo-pedido{/* PARA POSICIONAR O CAMPO DE MB */
    margin-top: 2em!important;
    width: 100%!important;
    display: flex!important;
    align-content: center!important;
    text-align: center!important;
}

.label-simcard-novo-pedido{/* PARA POSICIONAR O CONTEUDO DO CAMPO DE MB */
    margin-left: 50%!important;
    transform: translate(-50%, 25%)!important;
}

.botao-editar-pedidos-novo-simcard{
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    height: 2em!important;
    width: 2em!important;
    /* border: 0.093em solid #36D896!important; */
    background-color: transparent!important;
    box-shadow: none!important;
    border-radius: 3.125em!important;
    float: right!important;
}

.botao-editar-pedidos-novo-simcard-cinza{
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    height: 2em!important;
    width: 2em!important;
    border: 0.093em solid #505352!important;
    background-color: transparent!important;
    box-shadow: none!important;
    border-radius: 3.125em!important;
    float: right!important;
}

.botao-adicionar-pedidos-novo-simcard{
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    height: 2em!important;
    width: 2em!important;
    border: 0.09375em solid transparent!important;
    background-color: transparent!important;
    box-shadow: none!important;
    border-radius: 3.125em!important;
    float: right!important;
}

.botao-adicionar-pedidos-novo-simcard-espacador{
    margin-bottom: 1em!important;
}

.icone-botao-adicionar-pedidos-novo-simcard{
    left: 50%!important;
    top: 50%!important;
    transform: translate(-30%, -20%)!important;
}

.icone-botao-editar-pedidos-novo-simcard{
    left: 50%!important;
    top: 50%!important;
    transform: translate(-0%, -10%)!important;
}

.margem-esquerda-pedidos-novo-simcard{
    margin-left: 0.625em!important;
}

.grupo-painel-pedidos-novo-simcard-1{
    width: 100%!important;
    display: flex!important;
}

.grupo-painel-pedidos-novo-simcard-2{
    width: 100%!important;
    display: flex!important;
}

.coluna-painel-pedidos-novo-simcard-1{
    width: 20%!important;
    margin-right: 2%!important;
}

.coluna-painel-pedidos-novo-simcard-2{
    width: 20%!important;
    margin-right: 2%!important;
}

.coluna-painel-pedidos-novo-simcard-3{
    width: 20%!important;
    margin-right: 2%!important;
}

.coluna-painel-pedidos-novo-simcard-4{
    width: 23%!important;
    margin-right: 1%!important;
}

.coluna-painel-pedidos-novo-simcard-5{
    width: 10%!important;
}

.coluna-painel-pedidos-novo-simcard-6{
    width: 28%!important;
    margin-right: 2%!important;
}

.coluna-painel-pedidos-novo-simcard-7{
    width: 28%!important;
    margin-right: 2%!important;
}

.coluna-painel-pedidos-novo-simcard-8{
    width: 27.5%!important;
    margin-right: 2%!important;
}

.coluna-painel-pedidos-novo-simcard-9{
    width: 8.5%!important;
    padding-top: 2.2em!important;
}

.coluna-painel-pedidos-novo-simcard-10{
    width: 28%!important;
    margin-right: 2%!important;
}

.coluna-painel-pedidos-novo-simcard-11{
    width: 50%!important;
    margin-right: 2%!important;
}

.coluna-painel-pedidos-novo-simcard-12{
    width: 18%!important;
}
.campo-texto-quantidade-novo-pedido{
    height: 2em!important;
    width: 100%!important;
    font-family: RobotoRegular!important;
    font-size: 1em!important;
    text-align: center;
    line-height: 1em!important;
    border: 0em!important;
    box-shadow: none!important;
    border-radius: 5em!important;
    display: -webkit-inline-box!important;
    padding: 0px!important;
}

.campo-texto-quantidade-novo-pedido:focus{
    outline:none;
}


.container-valor-pedidosSimcard {
    display: inline-flex !important;
    width: 100% !important;
    height: 20px !important;
}

.valor-rs-pedidosSimcard {
    width: 20% !important;
    height: 100% !important;
}

.valor-pedidosSimcard {
    text-align: right !important;
    width: 35% !important;
    font-size: 1em!important;
    margin-left: 5px!important;
}

.valor-rs-pedidosSimcard2 {
    width: 12% !important;
    height: 100% !important;
}

.valor-pedidosSimcard2 {
    text-align: right !important;
    width: 19% !important;
    font-size: 1em!important;
    margin-left: 5px!important;
}

.modal-novo-pedido-new{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 68.4375em!important;

    min-height: 75vh !important;
    max-height: 100vh !important;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    overflow-y: auto;
}