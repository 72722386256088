/************ CLASSES DO DETALHAMENTO DE LINHA ************/
/* Sempre verifique se a classe que você procura já       */
/* existe. As classes listadas aqui são utilizadas pelas  */
/* pelas telas do detalhamento de linha e seus elementos. */
/******** MATEUS FOLLETTO - 15/05/2020 - VIRTUEYES ********/
.expand-row-tabela-dispositivos-1 {
  /* ALTERNA DE ACORDO COM UM ESTADO DE REDUX PARA TORNAR A EXPAND ROW RESPONSIVA */
  margin-left: -0.58em;
  max-width: 107.5vw !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.expand-row-tabela-dispositivos-2 {
  /* ALTERNA DE ACORDO COM UM ESTADO DE REDUX PARA TORNAR A EXPAND ROW RESPONSIVA */
  margin-left: -0.58em;
  max-width: 117.5vw !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.container-detalhamento-linha {
  /* CONTAINER DO DETALHAMENTO DE LINHA */
  width: 100% !important;
  display: flex !important;
  height: 24.8em !important;
  display: inline-block !important;
}

/******************** NAVEGACAO DO DETALHAMENTO ********************/
.container-navegacao-detalhamento-linha {
  /* CONTAINER DA NAVEGACAO DO DETALHAMENTO DE LINHA */
  display: inline-block !important;
  height: 24.8em !important;
  width: 11em !important;
  overflow: hidden !important;
  margin-left: 0.3125em !important;
}

.triangulo-navegacao-detalhamento-linha {
  width: 0 !important;
  height: 0 !important;
  border-left: 0.9375em solid transparent !important;
  border-right: 0.9375em solid transparent !important;
  margin-left: 50% !important;
  transform: translate(-50%, 0%) !important;
}

.corpo-navegacao-detalhamento-linha {
  height: 23.75em !important;
  width: 100% !important;
  border-radius: 0.3125em 0em 0em 0.3125em !important;
  padding-top: 1.25em !important;
  padding-bottom: 1.25em !important;
}

/******************** ITEMS DA NAVEGACAO ********************/
.lista-navegacao-detalhamento-linha {
  list-style-type: none !important;
  margin-left: -2.5em !important;
  cursor: pointer !important;
}

.container-item-navegacao-detalhamento-linha-ativado {
  height: 3.6875em !important;
  display: flex !important;
  font-weight: 700 !important;
  line-height: 1.875em !important;
  margin-top: 0.3125em !important;
}

.container-item-navegacao-detalhamento-linha-desativado {
  height: 3.6875em !important;
  display: flex !important;
  font-weight: 700 !important;
  margin-top: 0.3125em !important;
}

.coluna-item-navegacao-detalhamento-linha-1 {
  width: 30% !important;
}

.coluna-item-navegacao-detalhamento-linha-2 {
  width: 70% !important;
}

.icone-item-navegacao-detalhamento-linha {
  margin-top: 50% !important;
  margin-left: 50% !important;
  transform: translate(-50%, -30%) !important;
}

.label-item-navegacao-detalhamento-linha {
  line-height: 3.4375em !important;
}

.container-subitem-navegacao-detalhamento-linha {
  display: flex !important;
  width: 100% !important;
}

.container-subitem-navegacao-detalhamento-linha-ativo {
  display: flex !important;
  width: 100% !important;
}

.container-subitem-navegacao-detalhamento-linha-2 {
  display: flex !important;
  -webkit-box-shadow: inset 0.25em 0px 0px 0px #36d896 !important;
  -moz-box-shadow: inset 0.25em 0px 0px 0px #36d896 !important;
  box-shadow: inset 0.25em 0px 0px 0px #36d896 !important;
}

.coluna-subitem-navegacao-detalhamento-linha-1-2 {
  /* margin-left: -1.6em!important; */
  width: 30% !important;
}

.coluna-subitem-navegacao-detalhamento-linha-1 {
  width: 30% !important;
}

.coluna-subitem-navegacao-detalhamento-linha-2 {
  width: 70% !important;
}

.icone-subitem-navegacao-detalhamento-linha-ativado {
  width: 0.625em !important;
  height: 0.625em !important;
  border-radius: 3.125em !important;
  /* background-color: #36D896!important; */
  margin-top: 0.4375em !important;
  margin-left: 1.3125em !important;
}

.icone-subitem-navegacao-detalhamento-linha-desativado {
  width: 0.625em !important;
  height: 0.625em !important;
  border-radius: 3.125em !important;
  background-color: transparent !important;
  /* border: 0.0625em solid #36D896!important; */
  margin-top: 0.4375em !important;
  margin-left: 1.3125em !important;
}

.label-subitem-navegacao-detalhamento-linha {
  width: 3.125em !important;
  word-wrap: break-word !important;
}

/******************** EXPANSAO E CONTRACAO DE MENU ********************/
.detalhamento-linha-contrair {
  max-height: 0 !important;
  overflow: hidden !important;
  margin-left: -2.5em !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.detalhamento-linha-expandir {
  max-height: 50em !important;
  margin-left: -2.5em !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

/******************** FILTRO DE PERIODO ********************/
.container-filtro-detalhamento-linha {
  height: 24.8em !important;
  width: 15em !important;
}

.corpo-filtro-detalhamento-linha {
  height: 23.75em !important;
  width: 15em !important;
  border-radius: 0em 0.3125em 0.3125em 0em !important;
  padding: 1.25em 1.25em 1.25em 1.25em !important;
  margin-top: 0.9375em;
  position: relative !important;
  z-index: 200 !important;
}

.icone-label-filtro-detalhamento-linha {
  height: 0.375em !important;
  width: 0.375em !important;
  margin-right: 0.3125em !important;
  margin-bottom: 0.3125em !important;
  border-radius: 6.25em !important;
  background-color: #36d896 !important;
}

.label-filtro-detalhamento-linha {
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  font-weight: 700 !important;
}

.botao-fechar-filtro-detalhamento-linha {
  background-color: transparent !important;
  border-color: transparent !important;
  float: right !important;
  padding: 0 !important;
  margin-top: 0.05em !important;
  box-shadow: none !important;
}

.botao-fechar-filtro-detalhamento-linha-icone {
  margin-top: -0.1875em !important;
  height: 1.4875em !important;
  width: 1.4875em !important;
}

.container-calendario-detalhemento-linha {
  padding-top: 0.625em;
}

.espacador-filtro-detalhamento-linha {
  margin-bottom: 0.375em;
}

.container-botao-filtro-detalhamento-linha {
  display: flex !important;
}

.modal-select-filtro-detalhamento-linha {
  width: 100% !important;
  position: absolute !important;
  z-index: 1 !important;
}

.botao-confirmar-filtro-detalhamento-linha {
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2.375em !important;
  min-width: 6.875em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  /* border: 0.09375em solid #36D896 !important; */
  /* background-color: #36D896!important; */
  box-shadow: none !important;
  border-radius: 3.125em !important;
  float: right !important;
  margin-left: 40% !important;
  transform: translate(-35%) !important;
}

/******************** RADIO BUTTONS ********************/
.container-radio-button-extrato input {
  /* ESCONDE O RADIO BUTTON NATIVO */
  opacity: 0 !important;
  cursor: pointer !important;
}

.radio-button-extrato {
  /* CRIA O RADIO BUTTON */
  position: relative !important;
  margin-left: 0em !important;
  margin-top: 0.3125em !important;
  float: left !important;
  height: 0.9375em !important;
  width: 0.9375em !important;
  border-radius: 50% !important;
  cursor: pointer !important;
}

.container-radio-button-extrato .radio-button-extrato:after {
  /* CRIA E DIMENSIONA A ESFERA DO RADIO BUTTON */
  content: "" !important;
  position: absolute !important;
  display: none !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 0.5em !important;
  height: 0.5em !important;
  border-radius: 50% !important;
  /* background: #36D896!important; */
}

.container-radio-button-extrato input:checked ~ .radio-button-extrato:after {
  /* MOSTRA A ESFERAD DO RADIO BUTTON SOMENTE NO RADIO ATIVADO */
  display: block !important;
}

/******************** TELAS DO DETALHAMENTO ********************/
.container-tela-com-filtro-detalhamento-linha {
  z-index: 1 !important;
  height: 23.8em !important;
  width: calc(100% - 26.3125em);
  position: relative !important;
  margin-top: 0.9375em;
}

.container-tela-sem-filtro-detalhamento-linha {
  z-index: 1 !important;
  height: 23.8em !important;
  width: calc(100% - 11.3125em);
  position: relative !important;
}

.container-tela-conteudo-detalhamento-linha {
  margin-left: 0.9375em !important;
  width: calc(100% - 1.875em);
}

.container-tela-corpo-detalhamento {
  height: 92.2% !important;
  width: calc(100% - 1.875em) !important;
  position: absolute !important;
}

.container-tela-corpo-detalhamento-localizacao {
  height: 99.4% !important;
  width: calc(100% - 1.875em) !important;
  position: absolute !important;
  margin-top: 1em !important;
}

.container-tela-corpo-detalhamento-historico {
  height: 100% !important;
  width: calc(100% - 1.875em) !important;
  position: absolute !important;
}

/******************** DESCRICAO ********************/
.container-descricao-linha {
  width: 100% !important;
  height: 21.8em !important;
  margin-top: 1em !important;
}

.container-descricao-linha-telas {
  height: 20.3em !important;
  margin-bottom: 1em !important;
  width: 100% !important;
  display: flex !important;
}

.container-descricao-linha-cliente {
  border-radius: 0.3125em !important;
  width: 60% !important;
  margin-right: 1.5% !important;
  padding: 0em 2em 2em 2em !important;
}

.container-descricao-linha-dispositivos {
  border-radius: 0.3125em !important;
  width: 29% !important;
  margin-right: 1.5% !important;
  padding: 0em 2em 2em 2em !important;
}

.container-descricao-linha-personalizado {
  border-radius: 0.3125em !important;
  width: 40% !important;
  padding: 0em 2em 2em 2em !important;
}

.grupo-descricao-linha-cliente-1 {
  width: 100% !important;
  display: flex !important;
}

.coluna-descricao-linha-cliente-1 {
  width: 48.5% !important;
  margin-right: 3% !important;
}

.coluna-descricao-linha-cliente-2 {
  width: 48.5% !important;
}

.grupo-descricao-linha-cliente-2 {
  width: 100% !important;
  display: flex !important;
}

.coluna-descricao-linha-cliente-3 {
  width: 48.5% !important;
  margin-right: 3% !important;
}

.coluna-descricao-linha-cliente-4 {
  width: 22.75% !important;
  margin-right: 3% !important;
}

.coluna-descricao-linha-cliente-5 {
  width: 22.75% !important;
}

.botao-adicionar-campo-personalizado {
  float: right !important;
  height: 2.1875em !important;
  width: 2.1875em !important;
  border-radius: 50% !important;
  margin-top: 0.2em !important;
  background-color: #36d896 !important;
  box-shadow: none !important;
  border: none !important;
}

.label-botao-adicionar-campo-personalizado {
  font-size: 1.875em !important;
  margin-top: -0.4075em !important;
  margin-left: -0.0225em !important;
}

.grupo-descricao-linha-personalizado-1 {
  width: 100% !important;
  display: flex !important;
}

.coluna-descricao-linha-personalizado-1 {
  width: calc(100% - 74px) !important;
  overflow-x: auto !important;
}

.coluna-descricao-linha-personalizado-2 {
  display: flex !important;
  width: 74px !important;
}

.botao-deletar-descricao-linha {
  background-color: transparent !important;
  height: 1.5em !important;
  width: 1.5em !important;
  float: left !important;
  box-shadow: none !important;
  border: none !important;
}

.icone-botao-deletar-descricao-linha {
  margin-top: -1em !important;
  margin-left: -0.45em !important;
  height: 0.75em !important;
}

.modal-select-descricao-cliente {
  width: 22.75% !important;
  position: absolute !important;
  z-index: 1 !important;
}

.grupo-salvar-cancelar-edicao-personalizado-titulo {
  display: flex !important;
  margin-left: 1000px !important;
}

.campo-texto-titulo-personalizado {
  /* CLASSE PADRAO PARA CAMPOS DE INPUT */
  height: 2em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: -webkit-inline-box !important;
}

.campo-texto-cor-personalizado-transparente {
  font-size: 1em !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0em !important;
  margin: 0em !important;
  font-family: RobotoRegular !important;
  box-shadow: none !important;
}

.botao-editar-titulo-personalizado {
  /* margin-left: 0.75em!important; */
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2em !important;
  width: 2em !important;
  /* border: 0.093em solid #36D896!important; */
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  float: right !important;
}

.icone-botao-editar-titulo-personalizado {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-30%, -20%) !important;
}

.espacador-botoes-titulo-personalizado {
  margin-top: -5px !important;
  margin-bottom: 2px !important;
}

.espacador-botoes-titulo-personalizado-2 {
  margin-top: -5px !important;
  margin-bottom: 2.5px !important;
}

.label-campo-personalizado {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  overflow: hidden !important;
  width: calc(100% - 0.75em) !important;
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
}

/******************** CRIAR CAMPO PERSONALIZADO ********************/
.modal-acao-campo-personalizado {
  /* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
  padding: 0em 2em 2em 2em !important;
  border-radius: 0.625em 0.625em 0.625em 0.625em !important;
  width: 25.625em !important;
}

/******************** GRAFICO ULTIMOS CICLOS ********************/
.container-grafico-ciclo-ultimo {
  height: 19em !important;
  width: 100% !important;
  margin-top: 1em !important;
  zoom: 125% !important;
}

.recharts-surface {
  width: 100% !important;
}

.container-tooltip-grafico-ciclo-ultimo {
  padding: 0.625em 0.625em 0.625em 0.625em !important;
  height: 3.5em !important;
  border-radius: 0.3125em !important;
  background-color: #1f8efa !important;
}

.label-tooltip-grafico-ciclo-ultimo {
  color: #ffffff !important;
  font-weight: 700 !important;
  height: 0.4em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.75em !important;
  line-height: 1.375em !important;
}

.transition-container {
  opacity: 0;
  transform: translateX(-40px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  display: flex;
  gap: 1rem;
  max-height: 0;
  overflow: hidden;
}

.transition-container.visible {
  opacity: 1;
  transform: translateX(0);
  max-height: 100px;
}

/******************** GRAFICO CICLO ATUAL ********************/
.container-grafico-ciclo-atual {
  height: 17em !important;
  width: 100% !important;
  margin-top: 1em !important;
  zoom: 125% !important;
}

.container-legenda-grafico-ciclo-atual {
  display: flex !important;
  justify-content: space-evenly !important;
}

.legenda-grafico-ciclo-atual {
  display: flex !important;
  flex-direction: row !important;
}

.coluna-legenda-grafico-ciclo-atual-1 {
  width: 1em !important;
}

.coluna-legenda-grafico-ciclo-atual-2 {
  margin-left: 0.5em !important;
  width: 10em !important;
}

.elipse-verde-grafico-ciclo-atual {
  margin-top: 50% !important;
  margin-left: 50% !important;
  transform: translate(-50%, -10%) !important;
  width: 0.75em !important;
  height: 0.75em !important;
  border-radius: 6.25em !important;
  background-color: #36d896 !important;
  color: #36d896 !important;
}

.elipse-amarelo-grafico-ciclo-atual {
  margin-top: 50% !important;
  margin-left: 50% !important;
  transform: translate(-50%, -10%) !important;
  width: 0.75em !important;
  height: 0.75em !important;
  border-radius: 6.25em !important;
  background-color: #ffab4f !important;
  color: #ffab4f !important;
}

.elipse-vermelho-grafico-ciclo-atual {
  margin-top: 50% !important;
  margin-left: 50% !important;
  transform: translate(-50%, -10%) !important;
  width: 0.75em !important;
  height: 0.75em !important;
  border-radius: 6.25em !important;
  background-color: #ee423d !important;
  color: #ee423d !important;
}

.elipse-azul-grafico-ciclo-atual {
  margin-top: 50% !important;
  margin-left: 50% !important;
  transform: translate(-50%, -10%) !important;
  width: 0.75em !important;
  height: 0.75em !important;
  border-radius: 6.25em !important;
  background-color: #1f8efa !important;
  color: #1f8efa !important;
}

.container-tooltip-grafico-area-consumo-ultimo {
  margin-left: -3.125em !important;
  border-radius: 0.3125em !important;
  height: 1.25em !important;
  width: 5em !important;
  background-color: #1f8efa !important;
  margin-top: 0.6875em !important;
}

.label-tooltip-grafico-area-consumo-ultimo {
  color: #ffffff !important;
  font-family: RobotoRegular !important;
  font-size: 0.75em !important;
  line-height: 1.7875em !important;
  text-align: center !important;
  font-weight: 700 !important;
  margin-bottom: -0.2em !important;
}

.triangulo-tooltip-grafico-area-consumo-ultimo {
  position: absolute !important;
  z-index: 1 !important;
  width: 0 !important;
  height: 0 !important;
  margin-left: 1.5625em !important;
  border-left: 0.9375em solid transparent !important;
  border-right: 0.9375em solid transparent !important;
  border-bottom: 0.9375em solid transparent !important;
  border-top: 0.9375em solid #1f8efa !important;
}

.barra-tooltip-grafico-area-consumo-ultimo {
  box-sizing: border-box !important;
  margin-left: 2.375em !important;
  height: 14.15em !important;
  width: 0.214375em !important;
  border: 0.125em solid #1f8efa !important;
  background-color: #1f8efa !important;
}

.barra-tooltip-grafico-area-consumo-ultimo-2 {
  box-sizing: border-box !important;
  margin-left: 2.375em !important;
  height: 14.15em !important;
  width: 0.214375em !important;
  border: 0.125em solid #1f8efa !important;
  background-color: #1f8efa !important;
}

.elipse-tooltip-grafico-area-consumo-ultimo {
  margin-left: 2.0625em !important;
  border-radius: 6.25em !important;
  height: 0.875em !important;
  width: 0.875em !important;
  background-color: #1f8efa !important;
}

/******************** TIMELINE ********************/
.container-grafico-timeline {
  height: 15em !important;
  width: 110% !important;
  margin-top: 2em !important;
  margin-left: -2.8em !important;
  zoom: 125% !important;
}

.container-tooltip-timeline-online {
  margin-left: -6.9em !important;
  border-radius: 0.3125em !important;
  height: 12em !important;
  width: 12.7em !important;
  padding: 0px 0.5em 0px 0.5em !important;
  background-color: #1f8efa !important;
  margin-top: 0.6875em !important;
}

.triangulo-tooltip-timeline-online {
  position: absolute !important;
  z-index: 1 !important;
  width: 0 !important;
  height: 0 !important;
  margin-left: 4.84em !important;
  border-left: 0.9375em solid transparent !important;
  border-right: 0.9375em solid transparent !important;
  border-bottom: 0.9375em solid transparent !important;
  border-top: 0.9375em solid #1f8efa !important;
}

.barra-tooltip-timeline-online {
  box-sizing: border-box !important;
  margin-left: 5.7em !important;
  height: 2em !important;
  width: 0.214375em !important;
  border: 0.125em solid #1f8efa !important;
  background-color: #1f8efa !important;
}

.elipse-tooltip-timeline-online {
  margin-left: 5.34em !important;
  border-radius: 6.25em !important;
  height: 0.875em !important;
  width: 0.875em !important;
  background-color: #1f8efa !important;
}

.container-tooltip-timeline-offline {
  margin-left: -6.9em !important;
  border-radius: 0.3125em !important;
  height: 2.6em !important;
  width: 12.7em !important;
  padding: 0px 0.5em 0px 0.5em !important;
  background-color: #1f8efa !important;
  margin-top: 0.6875em !important;
}

.triangulo-tooltip-timeline-offline {
  position: absolute !important;
  z-index: 1 !important;
  width: 0 !important;
  height: 0 !important;
  margin-left: 4.84em !important;
  border-left: 0.9375em solid transparent !important;
  border-right: 0.9375em solid transparent !important;
  border-bottom: 0.9375em solid transparent !important;
  border-top: 0.9375em solid #1f8efa !important;
}

.barra-tooltip-timeline-offline {
  box-sizing: border-box !important;
  margin-left: 5.7em !important;
  height: 11.49em !important;
  width: 0.214375em !important;
  border: 0.125em solid #1f8efa !important;
  background-color: #1f8efa !important;
}

.elipse-tooltip-timeline-offline {
  margin-left: 5.34em !important;
  border-radius: 6.25em !important;
  height: 0.875em !important;
  width: 0.875em !important;
  background-color: #1f8efa !important;
}

.linha-tooltip-timeline {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
}

.titulo-tooltip-timeline {
  font-size: 0.9em !important;
  font-weight: 700 !important;
  margin-bottom: 0.5em !important;
  font-family: RobotoRegular !important;
}

.texto-tooltip-timeline {
  font-size: 0.9em !important;
  font-weight: 500 !important;
  margin-bottom: 0em !important;
  font-family: RobotoRegular !important;
}

.container-timeline {
  height: 22em !important;
  width: 100% !important;
  /* margin-left: 300px; */
  align-items: end !important;
}

.container-legenda-timeline {
  display: flex !important;
  margin-left: 1em;
}

.legenda-timeline {
  display: flex !important;
  flex-direction: row !important;
  /* margin-left: 300px; */
}

.coluna-legenda-timeline-1 {
  width: 1em !important;
}

.coluna-legenda-timeline-2 {
  margin-left: 0.5em !important;
  width: 10em !important;
}

.elipse-verde-timeline {
  margin-top: 50% !important;
  margin-left: 50% !important;
  transform: translate(-50%, -10%) !important;
  width: 0.75em !important;
  height: 0.75em !important;
  border-radius: 6.25em !important;
  background-color: #36d896 !important;
  color: #36d896 !important;
}

.elipse-vermelho-timeline {
  margin-top: 50% !important;
  margin-left: 50% !important;
  transform: translate(-50%, -10%) !important;
  width: 0.75em !important;
  height: 0.75em !important;
  border-radius: 6.25em !important;
  background-color: #ee423d !important;
  color: #ee423d !important;
}

/******************** TIMELINE CONTAINERS ********************/
.container.indiana-scroll-container.indiana-scroll-container--hide-scrollbars {
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
}

.container-btn-lupa-timeline {
  position: absolute;
  z-index: 100 !important;
  margin-left: 68.6em;
  width: 0.1em;
}

.btn-lupa-timeline,
.btn-lupa-timeline:hover,
.btn-lupa-timeline:active {
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
}

.timeline-container {
  height: 100% !important;
  width: 100% !important;
}

.timeline-conteudo {
  display: flex !important;
  flex-direction: row !important;
}

.timeline-periodo {
  display: flex !important;
  flex-direction: row !important;
}

.timeline-mes {
  display: flex !important;
  width: 0.625em !important;
}

/******************** TIMELINE CORPO ********************/
.timeline-barras-espaco-de-tempo {
  display: flex !important;
  min-width: 2.5em !important;
  max-width: 2.5em !important;
  height: 17.6875em !important;
}

.timeline-tipo-espaco-de-tempo {
  display: flex !important;
  min-width: 2.5em !important;
  max-width: 2.5em !important;
  padding-left: 2% !important;
  min-height: 2.5em;
  padding-top: 1.3125em !important;
  padding-bottom: 0.25em !important;
}

.timeline-tipo-espaco-de-tempo-zoom {
  display: flex !important;
  min-width: 2.5em !important;
  max-width: 2.5em !important;
  padding-left: 0.8% !important;
  min-height: 2.5em;
  padding-top: 1.3125em !important;
  padding-bottom: 0.25em !important;
}

.timeline-mes-padrao {
  height: 1.375em !important;
  padding: 0.25em !important;
}

.timeline-mes-quatro-horas {
  height: 1.375em !important;
  min-width: 15em !important;
  max-width: 15em !important;
  padding: 0.25em !important;
}

.timeline-mes-uma-hora {
  height: 1.375em !important;
  min-width: 60em !important;
  max-width: 60em !important;
  padding: 0.25em !important;
}

.timeline-mes-quinze-minutos {
  height: 1.375em !important;
  min-width: 240em !important;
  max-width: 240em !important;
  padding: 0.25em !important;
}

.timeline-label {
  height: 0.8125em !important;
  font-size: 0.75em !important;
  line-height: 0.625em !important;
}

.timeline-label-hora {
  height: 0.8125em !important;
  font-size: 0.75em !important;
  line-height: 0.625em !important;
  margin-left: -0.4em;
}

/******************** TIMELINE TOOLTIP ********************/
.timeline-tooltip-container {
  display: flex !important;
  margin-top: 1.2em !important;
  z-index: 1;
  height: 0px !important;
}

.timeline-tooltip-container-2 {
  display: flex !important;
  margin-top: 1.2em !important;
  z-index: 1;
  height: 0px !important;
  margin-top: 2.5em !important;
}

.timeline-tooltip-corpo {
  position: relative !important;
  height: 13.8em !important;
  width: 12.5em !important;
  border-radius: 0.3125em !important;
  padding-top: 0.8em !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.timeline-tooltip-corpo-2 {
  position: relative !important;
  height: 3.9em !important;
  width: 12.5em !important;
  border-radius: 0.3125em !important;
  padding-top: 0.8em !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.timeline-tooltip-container-label {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 0.125em !important;
}

.timeline-tooltip-linha-titulo {
  font-family: RobotoRegular !important;
  font-size: 0.75em !important;
  font-weight: 700 !important;
}

.timeline-tooltip-linha-conteudo {
  color: #ffffff !important;
  font-family: RobotoRegular !important;
  font-size: 0.75em !important;
  font-weight: 100 !important;
}

.timeline-tooltip-triangulo {
  position: relative !important;
  width: 0em !important;
  height: 0em !important;
  border-left: 1em solid transparent !important;
  border-bottom: 1em solid transparent !important;
  border-top: 1em solid transparent !important;
  margin-top: 1.35em !important;
  margin-left: -0.9em !important;
}

.timeline-tooltip-vareta-container {
  position: relative !important;
}

.timeline-tooltip-vareta-cabeca {
  margin-top: 2em !important;
  margin-left: -0.3em !important;
  border-radius: 100em !important;
  height: 0.76em !important;
  width: 0.76em !important;
}

.timeline-tooltip-vareta-corpo {
  height: 11em !important;
  width: 0.2em !important;
}

/******************** DISPONIBILIDADE ********************/
.texto-legenda-disponibilidade {
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-decoration-line: underline;
  margin-left: 16px;
}

.esfera-legenda-disponibilidade {
  width: 1.7em !important;
  height: 10px;
  border-radius: 34% !important;
  margin-top: 6px;
  margin-left: 7px;
}

.paginacao-tabela-reposicao-grupo-btns {
  margin-left: 0px !important;
}

.paginacao-tabela-reposicao-grupo-btns{
    margin-left: 0px !important;
}

.grupo-botao-paginacao-tabela-3 {
  margin-left: 300px;
}

.container-tela-corpo-detalhamento-extrato {
  /* margin-left: 300px !important; */
  height: 92.2% !important;
  width: calc(100% - 1.875em) !important;
  position: absolute !important;
}

.container-tela-conteudo-detalhamento-linha-extrato {
  /* margin-left: 100px !important; */
  width: calc(100% - 1.875em);
}

.box-total-grafico-disponibilidade {
  color: #4f6275;
  font-style: normal;
}

.label-janela-disponibilidade {
  /* font-family: 'Open Sans' !important; */
  color: #4f6275;
  margin-top: 24px;
  font-size: 16px !important;
  font-weight: 700 !important;
  font-style: normal;
  line-height: 125% !important;
  /* margin-left: 0.625em!important; */
}

.titulo-grafico-disponibilidade {
  width: 80% !important;
}

.horas-totais-grafico-disponibilidade {
  text-align: start !important;
  font-size: 48px !important;
  width: 114px;
  height: 60px;
  font-weight: 650;
  font: "Open Sans" !important;
  /* border: 1px solid red; */
}

.container-tela-corpo-disponibilidade {
  margin-left: 10px;
  /* margin-top: 5px; */
  background-color: #dfe3e2 !important;
  height: 380px !important;
  width: 271px !important;
  border-radius: 5px;
  position: absolute !important;
}

.container-legenda-disponibilidade {
  width: 80% !important;
  flex-direction: row;
  align-self: center !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  align-self: center !important;
  justify-content: center;
  max-height: 50% !important;
}

.coluna-legenda-status-3-disponibilidade {
  text-align: end !important;
  width: 100%;
}

.texto-legenda-box-disponibilidade {
  font-family: Roboto !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 125%;
  margin-left: 6px;
  margin-right: 47px;
  /* identical to box height, or 15px /
    / border: 1px solid green; */
}

.grupo-dash-grafico-pizza-grande-disponibilidade {
  position: absolute !important;
  /* display: flex !important; */
  height: 300px !important;
  width: 300px !important;
  /* border: 1px solid red; */
}

.container-dash-grafico-pizza-grande-disponibilidade {
  position: relative !important;
  height: 175px !important;
  width: 200px !important;
  align-content: center !important;
  /* z-index: 10 !important; */
  /* border: 1px solid blue; */
}

.coluna-legenda-disponibilidade-2 {
  width: 5em !important;
  height: 5px !important;
}

/******************** EXTRATO ********************/

.container-extrato {
  width: 100% !important;
  height: 22em !important;
}

.container-tabela-extrato,
.container-tabela-extrato-conectividade {
  width: 100% !important;
  max-height: 22em !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  overflow-x: auto !important;
}

.container-tabela-extrato-conectividade {
  margin-left: 300px;
}

.container-tabela-extrato .react-bootstrap-table th {
  white-space: normal !important;
}

.barra-botoes-paginacao-tabela-extrato {
  position: absolute !important;
  bottom: -1.7em !important;
  left: 0% !important;
  flex-direction: row !important;
  display: flex !important;
  justify-content: center !important;
  font-weight: normal !important;
  font-family: RobotoRegular !important;
}

.itens-exibidos-tabela-extrato,
.itens-exibidos-tabela-extrato-conectividade {
  position: absolute !important;
  bottom: -2.7em !important;
  right: 3.5em !important;

  display: flex !important;
  font-family: RobotoRegular !important;
  justify-content: center !important;
}

.itens-exibidos-tabela-extrato-conectividade {
  bottom: -1.76em !important;
  right: 2.7em !important;
}

.container-button-table {
  margin-top: -3px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
}

.container-mensagem-geo-nao-encontrado {
  width: 28.3em;
  margin-top: 10em;
  margin-left: 50%;
  transform: translate(-50%, 50%);
}

.mensagem-geo-nao-encontrado {
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  font-weight: 700 !important;
}

.gm-style-iw-t {
  top: -2.2em !important;
}

.gm-style-iw-c {
  /* width: 15em!important; */
  padding-bottom: 10px !important;
  padding-right: 10px !important;
}

.poi-info-window .full-width {
  width: auto !important;
}

.gm-style div div div div div div div div {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.poi-info-window a:link {
  text-decoration: none;
  color: #427fed;
  background-color: transparent !important;
}

.info-window-google-maps-detalhamento-linha {
  display: flex !important;
  width: 13em !important;
  word-wrap: break-word !important;
}

.info-window-google-maps-detalhamento-titulo {
  word-wrap: break-word !important;
  font-family: RobotoRegular !important;
  font-weight: 600 !important;
  font-size: 0.8em !important;
  margin-right: 0.3em !important;
}

.info-window-google-maps-detalhamento-texto-link {
  word-wrap: break-word !important;
  font-family: RobotoRegular !important;
  font-weight: 300 !important;
  font-size: 0.8em !important;
  color: #427fed !important;
  cursor: pointer !important;
}

.info-window-google-maps-detalhamento-texto {
  word-wrap: break-word !important;
  font-family: RobotoRegular !important;
  font-weight: 300 !important;
  font-size: 0.8em !important;
}

.info-window-google-maps-detalhamento-titulo-1 {
  word-wrap: break-word !important;
  font-family: RobotoRegular !important;
  font-weight: 600 !important;
  font-size: 0.8em !important;
  margin-right: 0.3em !important;
  /* width: 55%!important; */
}

.info-window-google-maps-detalhamento-texto-1 {
  word-wrap: break-word !important;
  font-family: RobotoRegular !important;
  font-weight: 300 !important;
  font-size: 0.8em !important;
  /* width: 45%!important; */
}

.info-window-google-maps-detalhamento-coluna-1 {
  display: flex !important;
  width: 50% !important;
}

.info-window-google-maps-detalhamento-coluna-2 {
  display: flex !important;
  width: 50% !important;
}

.container-timeseries-grafico-tooltip {
  width: 100% !important;
}

.container-timeseries-grafico {
  width: 100% !important;
}

.container-timeseries-tooltip {
  width: 100% !important;
  display: flex !important;
}

.container-pedaco-tooltip-timeline {
  width: 32% !important;
  margin-right: 5% !important;
  padding-top: 1em !important;
  margin-top: 1em !important;
  padding-bottom: 1em !important;
  margin-bottom: 4em !important;
}

.container-pedaco-tooltip-timeline-2 {
  width: 32% !important;
  margin-right: 5% !important;
  padding-top: 1em !important;
  margin-top: 1em !important;
  padding-bottom: 1em !important;
  margin-bottom: 4em !important;
}

.coluna-marca-modelo-1 {
  width: 48%;
  margin-right: 4%;
}

.coluna-marca-modelo-2 {
  width: 48%;
}

.grupo-descricao-linha-marca-modelo {
  width: 100% !important;
  display: flex !important;
  margin-bottom: 1.6em;
}

.container-titulo-filtro-detalhamento-linha {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.botao-download-relatorio-detalhamento-linha {
  height: 0em;
  margin-top: -2em;
  margin-right: -1em;
}
