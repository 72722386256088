.tela-painel-tutoriais{
    width: 100%!important;
    height: 100%!important;
}

.carrossel-tutoriais{
    width: 100%!important;
    height: 100%!important;
}

.item-carrossel-tutoriais{
    width: 100%!important;
    height: 100%!important;
}

/******************** BOTOES PAGINACAO ********************/
.wrapper-botoes-alternar-tutoriais{
    position: absolute!important;
}

.container-botoes-alternar-tutoriais{
    position: relative!important;
    width: 4.375em!important;
}

.botao-alternar-tutoriais-mais{
    height: 1.875em!important;
    width: 1.875em!important;
    border: 0em solid transparent!important;
    box-shadow: none!important;
    border-radius: 50%!important;
    margin-left: 0.625em!important;
    float: right!important;
}

.botao-alternar-tutoriais-menos{
    height: 1.875em!important;
    width: 1.875em!important;
    border: 0em solid transparent!important;
    box-shadow: none!important;
    border-radius: 50%!important;
    float: right!important;
}

.icone-botao-alternar-tutoriais-mais{
    margin-left: -0.40625em!important;
    margin-top: -0.75em!important;
    width: 1.2em!important;
    height: 1.2em!important;
    transform: rotate(270deg);
}

.icone-botao-alternar-tutoriais-menos{
    margin-left: -0.40625em!important;
    margin-top: -0.375em!important;
    width: 1.2em!important;
    height: 1.2em!important;
    transform: rotate(90deg);
}

/******************** VIDEOS ********************/
.container-grupo-videos{
    display: flex!important;
    width: 100%!important;
    height: 100%!important;
    margin-bottom: 1%!important;
}

.container-grupo-videos-2{
    display: flex!important;
    width: 100%!important;
    height: 100%!important;
}

.coluna-video{
    width: 33%!important;
    margin-right: 1%!important;
    height: 30%!important;
}

.coluna-video-2{
    width: 33%!important;
    height: 30%!important;
}

.container-titulo-video{
    position: absolute!important;
    margin-top: 0.625em!important;
    margin-right: 0.625em!important;
}

.icone-titulo-video{
    height: 1.875em!important;
    width: 1.875em!important;
    border-radius: 50%!important;
}

.titulo-video{
    margin-right: 0.3125em!important;
    height: 1.1875em!important;
    width: 12.25em!important;
    font-family: Roboto!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
}

.container-video{
    height: 17.1875em!important;
    width: 100%!important;
    background-color: black!important;
}

.container-detalhes-video{
    padding-top: 0.5em!important;
    padding-left: 0.9375em!important;
    padding-right: 0.9375em!important;
    padding-bottom: 0.5em!important;
    height: 100%!important;
    width: 100%!important;
    border-radius: 0em 0em 0.625em 0.625em!important;
}

.container-descricao-video{
    max-height: 7em!important;
    overflow: hidden!important;
    height: 7em!important;
}

.descricao-video{
    font-family: Roboto!important;
    font-size: 0.875em!important;
    line-height: 1.3125em!important;
}

.divisora-descricao-video{
    height: 0.0625em!important;
    width: 100%!important;
    margin-top: 0.625em;
    margin-bottom: 0.625em;
}

.container-detalhes-extra-video{
    display: flex!important;
}

.container-uploader-video{
    display: flex!important;
    width: Calc(100% - 6.25em)!important;
}

.container-foto-uploader-video{
    margin-right: 0.625em!important;
    margin-top: 0.255em;
}

.foto-uploader-video{
    height: 2.5em!important;
    width: 2.5em!important;
    border-radius: 50%!important;
}

.container-nome-cargo-apresentador-video{
    
}

.container-nome-apresentador-video{
    
}

.nome-apresentador-video{
    font-family: Roboto!important;
    font-size: 0.875em!important;
    font-weight: 700!important;
    line-height: 1em!important;
    display: flex;
    margin-top: 0.8em;
}

.container-cargo-apresentador-video{

}

.cargo-apresentador-video{
    font-family: Roboto!important;
    font-size: 0.875em!important;
    line-height: 1em!important;
}

.container-relogio-tempo-video{
    width: 6.25em!important;
    margin-top: 0.5em!important;
    display: flex!important;
}

.container-relogio-video{
    margin-right: 0.9375em!important;
}

.icone-relogio-video{

}

.container-tempo-video{

}

.tempo-video{
    font-family: Roboto!important;
    font-size: 1em!important;
    line-height: 2em!important;
}

/******************** VIDEOS ********************/
.carrossel-tutoriais>.carousel-control-prev{
    opacity: 1!important;
    border-radius: 50%!important;
    width: 2.5em!important;
    height: 2.5em!important;
    /* position: absolute!important; */
    z-index: 999!important;
    /* background-color: red!important; */
    margin-top: 30.9%!important;
    margin-left: -3.4%!important;
}

.carrossel-tutoriais>.carousel-control-next{
    opacity: 1!important;
    border-radius: 50%!important;
    width: 2.5em!important;
    height: 2.5em!important;
    /* position: absolute!important; */
    z-index: 999!important;
    /* background-color: red!important; */
    margin-top: 30.9%!important;
    margin-right: -3.4%!important;
}

.carrossel-tutoriais>.carousel-control-prev:hover{
    opacity: 1!important;
    border-radius: 50%!important;
    width: 2.5em!important;
    height: 2.5em!important;
    /* position: absolute!important; */
    z-index: 999!important;
    /* background-color: red!important; */
    margin-top: 30.9%!important;
    margin-left: -3.4%!important;
}

.carrossel-tutoriais>.carousel-control-next:hover{
    opacity: 1!important;
    border-radius: 50%!important;
    width: 2.5em!important;
    height: 2.5em!important;
    /* position: absolute!important; */
    z-index: 999!important;
    /* background-color: red!important; */
    margin-top: 30.9%!important;
    margin-right: -3.4%!important;
}

.carrossel-tutoriais>.carousel-control-prev:focus{
    opacity: 1!important;
    border-radius: 50%!important;
    width: 2.5em!important;
    height: 2.5em!important;
    /* position: absolute!important; */
    z-index: 999!important;
    /* background-color: red!important; */
    margin-top: 30.9%!important;
    margin-left: -3.4%!important;
}

.carrossel-tutoriais>.carousel-control-next:focus{
    opacity: 1!important;
    border-radius: 50%!important;
    width: 2.5em!important;
    height: 2.5em!important;
    /* position: absolute!important; */
    z-index: 999!important;
    /* background-color: red!important; */
    margin-top: 30.9%!important;
    margin-right: -3.4%!important;
}

.carrossel-tutoriais>.carousel-control-prev:active{
    opacity: 1!important;
    border-radius: 50%!important;
    width: 2.5em!important;
    height: 2.5em!important;
    /* position: absolute!important; */
    z-index: 999!important;
    /* background-color: red!important; */
    margin-top: 30.9%!important;
    margin-left: -3.4%!important;
}

.carrossel-tutoriais>.carousel-control-next:active{
    opacity: 1!important;
    border-radius: 50%!important;
    width: 2.5em!important;
    height: 2.5em!important;
    /* position: absolute!important; */
    z-index: 999!important;
    /* background-color: red!important; */
    margin-top: 30.9%!important;
    margin-right: -3.4%!important;
}

.carrossel-tutoriais .carousel-control-prev-icon{
    background-image: url("../../icones/iconesVersaoVerde/icone-maior.svg")!important;
    transform: rotate(180deg)!important;
}

.carrossel-tutoriais .carousel-control-next-icon{
    background-image: url("../../icones/iconesVersaoVerde/icone-maior.svg")!important;
}