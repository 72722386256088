
.modal-ativacao-simcard{
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 50em!important;
}

.cabecalho-modal-cadastro {
    display: flex;
    width: 100%;
    height: 2.5em;
    justify-content: space-between;
    align-items: center;
    padding: 0em 1em 0em 1em;
    margin-bottom: 1em;
  }

.icone-cabecalho-modal-cadastro {
    width: 1em;
    height: 1em;
    border-radius: 100%;
}

.titulo-modal-cadastro {
    font-family: RobotoRegular !important;
    font-size: 1.375em !important;
    margin-top: 0.35em;
    font-weight: 700 !important;
}

.icone-botao-fechar-modal-cadastro {
    width: 2.5em !important;
    height: 2.5em !important;
    cursor: pointer;
}
  
.grupo-1-cabecalho-modal{
    width: 35% !important;
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
}

.selects-versao-acao {
    width: 100%!important;
    display: flex!important;
}

.selects-versao-acao > div{
    width: 100%!important;
    gap: 170px;
    /* justify-content: space-between; */
    display: flex!important;
}

.container-checkbox-selecao-ativacao{
    display: flex !important;
    align-items: center;
}

.campo-texto-selecao-ativacao{
    margin-left: 5px !important;
}

.grupo-1-selecao-ativacao{
    width: 23em !important;
}

.checkbox-selecao-ativacao {
    display: flex !important;
    margin-bottom: 5px !important;
    /* align-items: center !important; */
}

.label-checkbox-ativacao{
    display: flex!important;
    width: 1.125em!important;
    height: 1.125em!important;
    margin: 0em!important;
    background-repeat: no-repeat!important;
    background-size: contain!important;
    margin-top: 5px !important;
}

input[type=checkbox].input-checkbox-header-simcard:checked + label.label-checkbox-ativacao{
    display: flex!important;
    width: 1.125em!important;
    height: 1.125em!important;
    background-image: url("../../../../icones/checked_header.svg")!important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
}

.campo-texto-titulo-opcoes-ativacao-1{
    margin-bottom: 10px !important;
    margin-top: 15px !important;
}

.campo-texto-titulo-opcoes-ativacao-2{
    font-family: RobotoRegular!important;
    font-size: 1.5em!important;
}

.container-principal-checkbox-selecao-ativacao{
    margin-top: 25px !important;
}


.rodape-modal-cadastro {
    display: flex;
    width: 100%;
    justify-content: right;
    margin-top: 2em;
}
  
.botao-secundario-sistema {
    font-family: RobotoBold !important;
    font-size: 1em !important;
    font-weight: 700 !important;
    line-height: 1.1875em !important;
    height: 2.3em !important;
    min-width: 6.875em !important;
    padding-left: 1.875em !important;
    padding-right: 1.875em !important;
    box-shadow: none !important;
    border-radius: 3.125em !important;
    margin-left: 1em !important;
    float: right !important;
}
  
.botao-primario-sistema {
    font-family: RobotoBold !important;
    font-size: 1em !important;
    font-weight: 700 !important;
    line-height: 1.1875em !important;
    height: 2.3em !important;
    min-width: 6.875em !important;
    padding-left: 1.875em !important;
    padding-right: 1.875em !important;
    box-shadow: none !important;
    border-radius: 3.125em !important;
    margin-left: 1em !important;
    float: right !important;
}
  
.botao-primario-sistema-desativado {
    font-family: RobotoBold !important;
    font-size: 1em !important;
    font-weight: 700 !important;
    line-height: 1.1875em !important;
    height: 2.3em !important;
    min-width: 6.875em !important;
    padding-left: 1.875em !important;
    padding-right: 1.875em !important;
    box-shadow: none !important;
    border-radius: 3.125em !important;
    margin-left: 1em !important;
    float: right !important;
}

.linha-modal-cadastro {
    width: 100% !important;
    margin: 0.5 em 0em 0.5em 0em !important;
}

.label-campo-modal-cadastro {
    font-family: RobotoRegular !important;
    font-size: 1em !important;
    line-height: 1em !important;
}

.campo-modal-cadastro {
    display: flex;
    width: 100%;
    height: 2em;
    font-family: RobotoRegular;
    font-size: 0.875em;
    padding: 0.375rem 1.8rem 0.375rem 0.75rem;
    border-radius: 5em;
    border: 0em;
    box-shadow: none;
    outline: none;
    overflow: hidden;
    text-align: inherit;
    text-overflow: ellipsis;
}
  