/* ---------- MODAL ---------- */
.backoffice-modal-cadastro-usuario{
    padding: 24px;
    border-radius: 0.625em 0.625em 0.625em 0.625em;
    width: 60em;
    /* height: 32em; */
}

/* ---------- ABAS ---------- */
.backoffice-modal-cadastro-usuario, div .tab-content{
    padding-top: 2em;
}
.backoffice-cadastro-usuario-tabs{
    background-color: transparent;
    display: flex;
    text-align: center;
    justify-content: space-evenly;
}
.backoffice-cadastro-usuario-tab-ativado{
    border-bottom: 0.2em solid #36D896;
    padding-left: 0px;
    padding-right: 0px;
    width: 12em;
}
.backoffice-cadastro-usuario-tab-desativado{
    padding-left: 0px;
    padding-right: 0px;
    width: 12em;
}

/* ---------- PRIMEIRA ABA ---------- */
.backoffice-cadastro-usuario-coluna-1{
    margin-right: 3%;
    /* NULL */
}
.backoffice-cadastro-usuario-coluna-2{
    margin-left: 3%;
}
.backoffice-cadastro-usuario-coluna-3{
    width: 100%;
}
.backoffice-cadastro-usuario-coluna-4{
    margin-left: 86%;
}

/* ---------- SEGUNDA ABA ---------- */
.backoffice-modal-cadastro-usuario-container-permissoes{
    display: flex;
}
.backoffice-modal-cadastro-usuario-container-lista-modulos{
    width: 13em;
    height: 22em;    
    margin-bottom: 1em;
    border-radius: 0.625em;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 1em;
}
.backoffice-modal-cadastro-usuario-lista-modulos{
    display: flex;
    height: 4em;
    cursor: pointer;
    justify-content: space-between;
    padding-right: 2em;
    padding-left: 1em;
}
.backoffice-modal-cadastro-usuario-lista-modulos-label{
    display: flex;
    align-items: center;
}
.backoffice-modal-cadastro-usuario-lista-modulos-container-switch{
    white-space: nowrap;
    width: 25%!important;
    padding-top: 0.8em!important;
}
.backoffice-modal-cadastro-usuario-lista-permissoes{
    height: 22em;
    width: 42.4em;
    border-radius: 0.625em;
    overflow-y: auto;
    overflow-x: hidden;
}
.backoffice-cadastro-usuario-permissoes-tabs{
    background-color: transparent!important;
    display: flex;
    text-align: center;
    justify-content: space-evenly;
}
.backoffice-cadastro-usuario-permissoes-ativado{
    border-bottom: 0.2em solid #36D896;
    padding-left: 0px!important;
    padding-right: 0px!important;
    width: 12em;
}
.backoffice-cadastro-usuario-permissoes-desativado{
    padding-left: 0px!important;
    padding-right: 0px!important;
    width: 12em;
}
.backoffice-modal-cadastro-usuario-permissoes-campo-divisor{
    height: 0.0625em!important;
    width: 100%!important;
}
.backoffice-modal-cadastro-usuario-permissoes-container-switch{
    display: flex;
    align-items: center;
    height: 2.7em;
    margin-left: 1em;
}
.backoffice-modal-cadastro-usuario-permissoes-container-switches{
    display: flex!important;
    flex-wrap: wrap!important;
    width: 100%;
}
.backoffice-modal-cadastro-usuario-permissoes-linha-switch{
    display: flex!important;
    width: 50%;
}
.backoffice-modal-cadastro-usuario-permissoes-icone{
    align-self: normal;
    width: 1.375em;
    height: 1.375em;
    margin-top: 1.3em;
}
.backoffice-modal-cadastro-usuario-permissoes-icone-invisivel{
    visibility: hidden!important;
    display: none!important;
}
.backoffice-modal-cadastro-usuario-permissoes-ativo{
    display: flex;
    height: 4em;
    cursor: pointer;
}
.backoffice-modal-cadastro-usuario-permissoes-desativo{
    display: flex;
    height: 4em;
    cursor: pointer;
}
.imagem-conteudo-tab-elementos-permissoes-cadastro-usuario-backoffice{
    width: 1.25em;
    height: 1.25em;
    margin-right: 1em;
}
.container-botoes-cadastro-usuario-backoffice{
    float: left!important;
    padding-top: 1.6em;
}