.modal-cadastro-usuario{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 40em!important;
}

.campo-texto-descricao{
    height: 5em !important;
}

.grupo-cadastro-usuario-1{
    width: 100%!important;
    display: flex!important;
}

.coluna-cadastro-usuario-1{
    width: 100%!important;
    margin-right: 3%!important;
}

.coluna-cadastro-usuario-2{
    width: 48.5%!important;
}

.btn-senha-cadastro-usuario{
    margin-top: -3.75em!important;
    margin-left: 15em!important;
    background-color: transparent!important;
    box-shadow: none!important;
}

.container-switch-ativo-cadastro-usuario{
    margin-top: -5px!important;
}

.coluna-cadastro-usuario-3{
    width: 87.3%!important;
    margin-right: 3%!important;
}

.coluna-cadastro-usuario-4{
    width: 9.7%!important;
}

.coluna-cadastro-usuario-5{
    width: 100%!important;
}

.css-yk16xz-control{
    border: none !important;
    box-shadow: none !important;
    min-height: 1.75em!important;
    height: 1.75em!important;
    border-radius: 5em!important;
}

.css-g1d714-ValueContainer{
    min-height: 1.75em!important;
    height: 1.75em!important;
}

.css-1okebmr-indicatorSeparator{
    visibility: hidden !important;
}

.css-1wa3eu0-placeholder{
    top: 35% !important;
}

.css-1pahdxg-control{
    box-shadow: none !important;
    border: none !important;
    min-height: 1.75em!important;
    height: 1.75em!important;
    border-radius: 5em!important;
}

.css-b8ldur-Input{
    margin-top: -5px !important;
}
.css-1uccc91-singleValue {
    top: 35% !important;
}

 #react-select-2-input{
    top: 50% !important;

 }
.Selecione__input{
    top: 35% !important;
    overflow: hidden !important;
}

.css-4ljt47-MenuList{
    overflow-x: hidden !important;
}

.css-1gtu0rj-indicatorContainer{
    background-image: url("../../../icones/icone-seta-dropdown-verde.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-position-y: 7px !important;
}


.css-tlfecz-indicatorContainer{
    background-image: url("../../../icones/icone-seta-dropdown-verde.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    transform: rotate(270deg) !important;
    background-position-x: 12px !important;
}

.css-26l3qy-menu{
    margin-top: 0.3em !important;
}

.css-6q0nyr-Svg{
    width: 1em !important;
    height: 1em !important;
    visibility: hidden !important;
}

.campo-select-triangulo-cadastro-usuario{
    width: 0!important;
    height: 0!important;
    border-top: 0.9375em solid transparent!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-left: 4em!important;
    margin-top: 1em!important;
    position: absolute !important;
}

/* .Selecione__option:hover{
    background-color: red !important;
} */

/* Selecione__option Selecione__option--is-focused css-1n7v3ny-option */


/* 
.css-1wa3eu0-placeholder{
    color:  ${({ theme }) => theme.cor9}!important;
}

.css-1uccc91-singleValue{
    color:  ${({ theme }) => theme.cor9}!important;
}

.Selecione__value-container{
    color:  ${({ theme }) => theme.cor9}!important;
}

.Selecione__value-container--has-value{
     color:  ${({ theme }) => theme.cor9}!important;
}

.css-g1d714-ValueContainer{
    color:  ${({ theme }) => theme.cor9}!important; 
} */
/* 
.css-2613qy-menu{
    background-color:  blue !important;

} */