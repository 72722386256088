.corpo-dados-solicitacao{
    margin-right: 1em;
    width: 49%;
}

.corpo-textarea-solicitacao{
    width: 100%;
}

.calendario-z-index{
    z-index: 301 !important;
}

.tooltip-campos-solicitacao{
    margin-bottom: 11.5em !important;
}

.corpo-tabela-solicitacao {
    max-height: 14em !important;
    overflow: auto !important;
    width: 100% !important;
}