.select-pav-corpo-ciclo-tipo-solicitacao {
    max-height: 157px!important;
    width: 16em!important;
    border-radius: 5px!important;
    overflow-x: hidden!important;
    margin-top: 10px!important;
    text-align: left;
    font-weight: 700;
    font-size: 0.875em;
    line-height: 1em;
    position: relative !important;
    z-index: 2!important;
}

.select-pav-triangulo-ciclo-tipo-solicitacao {
    position: absolute !important;
    z-index: 0!important;
    width: 0!important;
    height: 0!important;
    border-left: 1em solid transparent!important;
    border-right: 1em solid transparent!important;
    border-bottom: 0.9375em solid #343736!important;
    margin-top: -0.5em!important;
    margin-left: 73%!important;
}

.Select-pav-tipo-solicitacao{
    overflow-x: visible!important;
    /* word-break:break-all!important;
    min-height: 30px!important;
    text-overflow: ellipsis;
    flex-basis: 100%; */
}

.select-pav-menu-tipo-solicitacao{
    margin-left: -25px!important;
    width: 135%!important;
    font-size: 0.875em!important;
    line-height: 2em!important;
    cursor: pointer;
    text-align: initial;
}

.select-sms-template-sms{
    width: 95%!important;
    font-size: 0.875em!important;
    line-height: 2em!important;
    cursor: pointer;
    text-align: initial;
}

.select-pav-menu-tipo-solicitacao:active{
    margin-left: -25px!important;
    width: 135%!important;
    font-size: 0.875em!important;
    line-height: 2em!important;
    text-align: initial;
}
.campo-select-comeco-tipo-solicitaca {
    margin-left: -2.5em!important;
    width: 150%!important;
    height: 1.875em!important;
    line-height: 1.75em!important;
    text-align: initial;
}

.campo-select-opcao-label-tipo-solicitacao{/* CLASSE PADRAO PARA ALINHAR AS LABELS DOS ITEMS DO SELECT */
    font-size: 1em!important;
    margin-left: 15px !important;
}

.modal-select-operadora{
    width: 9em !important;
    position: absolute!important;
}

.label-elipses{
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

.campo-select-corpo-novo-pedido{
    width: 170px!important;
    border-radius: 8.3125em!important;
    z-index: 500!important;
    position: relative!important;
}

.campo-select-opcao-novo-pedido{
    margin-left: -2.5em!important;
    height: 1.875em!important;
    line-height: 1.75em!important;
    cursor: pointer!important;
}

.campo-select-triangulo-novo-pedido{
    width: 0!important;
    height: 0!important;
    border-top: 0.9375em solid transparent!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-left: 8.5em!important;
    margin-top: -0.6em!important;
}

.campo-select-pacote-sms{
    font-family: RobotoBold!important;
}