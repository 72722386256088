/***************** CLASSES DOS MAPAS *****************/
/* Muitas das classes dispostas nesse arquivo        */
/* carregam os nomes padrões de seus componentes e   */
/* elementos, tendo em vista que não foi possível    */
/* inserir classes nomeadas de modo genérico.        */
/* É interessante refatorar elas no futuro e tentar  */
/* novamente a fim de evitar possíveis conflitos.    */
/***** MATEUS FOLLETTO - 15/05/2020 - VIRTUEYES ******/
/******************** OPEN STREETS *******************/
.marker-cluster-custom {/* CUSTOMIZA E DIMENSIONA CORRETAMENTE OS CLUSTERS DOS MAPAS */
    background: #787B7A !important;
    border: 0.09em solid #000!important;
    border-radius: 50%!important;
    color: #ededed!important;
    height: 2.5em!important;
    line-height: 2.5em;
    text-align: center!important;
    width: 2.5em!important;
    }

.open-streets-pop-up-text-titulo{/* DEFINE A FONTE DE TITULO DOS BALOS NO OPEN STREETS */
    font-weight: 700!important;
    font-size: 1em!important;
    margin: 0.4375em!important;
}

.open-streets-pop-up-text-corpo{/* DEFINE A FONTE DO CORPO DOS BALOS NO OPEN STREETS */
    font-size: 1em!important;
    margin: 0.4375em!important;
}

.leaflet-popup-content-wrapper{/* DA COR AOS BALOES NO OPEN STREETS */
    /* background: #36D896!important; */
    width: 21em!important;
}

.leaflet-popup.leaflet-zoom-animated{/* POSICIONA CORRETAMENTE OS BALOES NO OPEN STREET */
    margin-bottom: 3.5em;
    margin-left: -0.5em;
    height: auto !important;
}

.leaflet-popup-tip{/* DA COR AO TRIANGULO DOS BALOES NO OPEN STREETS */
    /* background: #36D896!important; */
}


.barra-botoes-mapa-calor-open-streets{/* POSICIONA A BARRA DE BOTOES DO MAPA DE CALOR DO OPEN STREETS */
    top: 1.5%!important;
    left: 50%!important;
    transform: translate(-50%, -0%)!important;
    background-color: rgb(255, 255, 255)!important;
}

.botoes-mapa-calor-open-streets{/* ESTILIZA BOTOES DO MAPA DE CALOR DO OPEN STREETS NO PADRAO GOOGLE */
    direction: ltr!important;
    overflow: hidden!important;
    text-align: center!important;
    height: 2.75em!important;
    display: table-cell!important;
    vertical-align: middle!important;
    position: relative!important;
    color: rgb(86, 86, 86)!important;
    font-family: Roboto, Arial, sans-serif!important;
    user-select: none!important;
    font-size: 1.125em!important;
    background-color: rgb(255, 255, 255)!important;
    padding: 0em 1.0625em!important;
    border-bottom-right-radius: 0.125em!important;
    border-top-right-radius: 0.125em!important;
    background-clip: padding-box!important;
    border-color: rgb(228, 228, 228)!important;
    box-shadow: rgba(129, 129, 129, 0.322) -0.0625em 0em 0em 0.0625em!important;
    min-width: 3.6875em!important;
    border-left: 0em!important;
    border-radius: 0!important;
}

.container-tooltip-open-street{
    width: 10em!important;
}

.grupo-tooltip-open-street-1{
    width: 100%!important;
    display: flex!important;
}

.grupo-tooltip-open-street-2{
    width: 100%!important;
    display: flex!important;
}


/******************** GOOGLE MAPS ********************/
.google-maps-pop-up-texto-titulo{/* DEFINE A FONTE DE TITULO DOS BALOS NO GOOGLE MAPS */
    font-weight: 700!important;
    font-size: 1em!important;
}

.google-maps-pop-up-texto-corpo{/* DEFINE A FONTE DE CORPO DOS BALOS NO GOOGLE MAPS */
    font-size: 0.875em!important;
}

.barra-botoes-mapa-calor-google-maps{/* POSICIONA A BARRA DE BOTOES DO MAPA DE CALOR DO GOOGLE MAPS */
    position: absolute !important;
    top: 1.5%!important;
    left: 50%!important;
    transform: translate(-50%, -0%)!important;
    background-color: rgb(255, 255, 255)!important;
}

.botoes-mapa-calor-google-maps{/* ESTILIZA BOTOES DO MAPA DE CALOR DO GOOGLE MAPS NO PADRAO GOOGLE */
    direction: ltr!important;
    overflow: hidden!important;
    text-align: center!important;
    height: 2.75em!important;
    display: table-cell!important;
    vertical-align: middle!important;
    position: relative!important;
    color: rgb(86, 86, 86)!important;
    font-family: Roboto, Arial, sans-serif!important;
    user-select: none!important;
    font-size: 1.125em!important;
    background-color: rgb(255, 255, 255)!important;
    padding: 0em 1.0625em!important;
    border-bottom-right-radius: 0.125em!important;
    border-top-right-radius: 0.125em!important;
    background-clip: padding-box!important;
    border-color: rgb(228, 228, 228)!important;
    box-shadow: rgba(129, 129, 129, 0.322) -0.0625em 0em 0em 0.0625em!important;
    min-width: 3.6875em!important;
    border-left: 0em!important;
    border-radius: 0!important;
}

.componente-spinner-container-mapa{/* CLASSE PADRÃO PARA CENTRALIZAR MODAIS HORIZONTALMENTE*/
    position: absolute!important;
    z-index: 999999!important;
    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%, -50%)!important;
}

.mensagem-atencao-mapa{
    margin-top: 20vh;
}