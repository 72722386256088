/******************** BARRA DE DADOS ********************/
.barra-dados-cliente {
  display: flex !important;
  justify-content: space-between !important;
  height: 2.9375em !important;
  width: 99% !important;
  margin-bottom: 0.3em !important;
  border-radius: 0.3125em !important;
  position: relative;
  margin-top: 0em;
}

.barra-dados-caixa-titulo {
  height: 2.9375em !important;
  width: 6.25em !important;
  border-radius: 0.3125em !important;
  color: black !important;
  font-size: 1em !important;
  font-family: RobotoRegular !important;
  font-weight: 700 !important;
  line-height: 2.8125em !important;
  text-align: center !important;
}

.barra-dados-caixa {
  min-width: 5em !important;
  color: black !important;
  font-size: 1em !important;
  font-family: RobotoRegular;
  line-height: 3.3em !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.barra-dados-caixa-fechar {
  padding-right: 0.3125em !important;
  cursor: pointer;
  display: flex;
}

.span-barra-dados {
  margin-left: 1em !important;
  margin-right: 1em !important;
}

.divisora-vertical-barra-dados {
  width: 0.0625em !important;
  height: 2.0625em !important;
  margin-top: 0.4375em !important;
}

.barra-vertical-texto {
  font-size: 20px !important;
}

.overlayTrigger-barra-dados {
  bottom: 5em !important;
}
