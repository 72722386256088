.detalhamento-endereco{
    height: 8.8125em !important;
    width: 100% !important;
    border-radius: 0.3125em !important;
    min-width: 8.8125em !important;
}

.container-informacoes {
    border-left: 0.3125em solid #36D896!important;
    height: 3.5em!important;
    width: 90% !important;
    margin-left: 5% !important;
    margin-top: 1.2% !important;
    display: flex !important;
    justify-content: space-between !important;

}

.campo-endereco-empresas, .campo-bairro-empresas, .campo-cidade-empresas, .campo-cep-empresas,
.campo-estado-empresas, .campo-email-empresas{
    height: 25px !important;
    margin-top: 15px !important;
    font-size: 14px !important;
}

.campo-endereco-empresas{
    margin-left: 20px !important;
}

.informacoes-empresas{
    font-size: 14px !important;
}

.corpo-dados-usuario-razao{
    width: 39% !important;
}

.corpo-dados-usuario-endereco-empresa{
    width: 29.75em;
    margin-right: 1em;
}

.modal-acao-faturas-ciclo-vida{
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 33.375em!important;
    height: 100% !important;
}

.corpo-dados-conta {
    margin-right: 0.5em !important;
    margin-left: 0.5em !important;
    width: 44% !important;
}

.corpo-dados-conta-numero-conta{
    width: 44% !important;
    margin-left: 1.3em !important;

}
.usuario-dados-corpo-ciclo-de-vida{
    margin-top: 1em !important;
    justify-content: center;
}

.botao-confirmar-margin{
    margin-right: 20px !important;
}

.conta-dados-divisoria-corpo-conta{
    margin-top: 1.5em !important;
    margin-left: 17px !important;
    width: 93% !important;
}

.container-botoes-cancelar-confirmar{
    margin-top: 3em!important;
}

.css-1wa3eu0-placeholder{
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.container-principal-switch{
    display: flex !important;
    width: 100% !important;
}

.container-coluna-switches-1 {
    width: 50% !important;
    margin-left: 11px !important;
}

.container-coluna-switches-2 {
    width: 50% !important;
}