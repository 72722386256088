.container-uploader-arquivos {
  width: 100%;
}

.container-botoes-uploader-arquivos {
  display: flex;
  width: 21em;
  margin-left: -1em;
}

.container-botao-upload-uploader-arquivos {
  display: flex;
  width: 10em;
}

.botao-uploado-upload-arquivos {
  padding-left: 3em !important;
  width: 100% !important;
  text-align: left !important;
}

.icone-botao-upload {
  height: 100%;
  margin-left: -3em;
  margin-right: 1.5em;
  cursor: pointer;
  width: 5.5em;
  margin-top: 0.5em;
}

.icone-botao-upload-desativado {
  height: 100%;
  margin-left: -3em;
  margin-right: 1.5em;
  cursor: initial !important;
  width: 5.5em;
  margin-top: 0.5em;
}

.botao-template-container {
  width: 10em !important;
  display: flex;
}

.botao-template-upload-arquivos {
  width: 100% !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  height: 2.3em !important;
  display: flex !important;
  font-family: RobotoRegular !important;
  padding: 0.375rem 1.8rem 0.375rem 0.75rem !important;
  border-radius: 5em !important;
  border: 0em !important;
  box-shadow: none !important;
  outline: none !important;
  overflow: hidden;
  text-align: inherit;
  text-overflow: ellipsis;
  padding-left: 2em !important;
}

.icone-botao-template {
  height: 100%;
  margin-left: -3em;
  margin-right: 1.5em;
  margin-top: 0.55em;
  cursor: pointer;
}

.botao-template-list-container-triangle {
  width: 0em !important;
  height: 0em !important;
  border-top: 0.9375em solid transparent !important;
  border-left: 0.9375em solid transparent !important;
  border-right: 0.9375em solid transparent !important;
  margin-top: -1em !important;
  margin-left: 4em;
}

.botao-template-list-main-container {
  width: 100% !important;
  position: absolute !important;
  margin-top: 2.6em !important;
}

.botao-template-list-container {
  width: 10em !important;
  border-radius: 0.3125em !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  position: absolute !important;
  z-index: 11 !important;
}

.botao-template-list {
  max-height: 10em !important;
  margin-left: -2.5em !important;
  list-style-type: none !important;
}

.botao-template-list-item {
  width: 100% !important;
  height: 1.875em !important;
  line-height: 1.95em !important;
  cursor: pointer !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
  display: flex !important;
}

.botao-template-list-item-span {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  cursor: pointer;
}

.botao-template-list-item-dividing-line {
  height: 0.0625em !important;
  width: 100% !important;
}

.container-botao-template-uploader-arquivos {
  margin-bottom: -2.6em;
  margin-left: 1em;
}

.container-lista-uploader-arquivos {
  max-height: 7em;
  overflow-y: auto;
  margin-top: 1em;
}

.lista-uploader-arquivos {
  list-style-type: none !important;
  margin-left: -2em;
}

.item-lista-uploader-arquivos {
  margin-bottom: 0.2em;
}

.icone-item-lista-uploader-arquivos {
  height: 1.2em;
  width: 1.2em;
  margin-right: 0.5em;
}

.icone-remover-item-lista-uploader-arquivos {
  margin-right: 0.5em;
  height: 1.2em;
  width: 1.2em;
  cursor: pointer;
}

.botao-uploado-upload-desabilitado{
  background-color: #787B7A!important;
  border: 1px solid #787B7A!important;
  padding-left: 3em !important;
  width: 100% !important;
  text-align: left !important;
  cursor: inherit !important;
}


.botao-primario-sistema {
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2.3em !important;
  min-width: 6.875em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 1em !important;
  float: right !important;
  cursor: pointer; 
}