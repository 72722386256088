/* ---------- TABELA ---------- */
.tabela-generica thead th:first-child {
    border-top-left-radius: 0.4375em!important;
}

.tabela-generica thead th:last-child {
    border-top-right-radius: 0.4375em!important;
}

.tabela-generica tr:last-child td:first-child {
    border-bottom-left-radius: 0.4375em!important; 
    border-style: solid!important;
}

.tabela-generica tr:last-child td:last-child {
    border-bottom-right-radius: 0.4375em!important; 
    border-style: solid!important;
}

.tabela-generica td {
    padding:5px 15px!important;
    text-align:left!important;
    white-space: nowrap!important;
    text-overflow: ellipsis!important;
    overflow: hidden!important;
    /* max-width: 5em!important; */
}

.tabela-generica thead {
    position: sticky !important;
    z-index: 2;
}

.tabela-generica table {
    overflow: auto!important;
}

.tabela-generica th {
    display: table-cell;
    max-height: 20px;
    padding:5px 10px!important;
    text-align:left!important;
    white-space: nowrap!important;
    position: sticky!important;
    top: 0!important;
    cursor: pointer!important;
}

.base-linha-expandida-testes{
    position: relative!important;
    height: 25em!important;
    width: 0em!important;
}

.container-linha-expandida-testes{
    width: 129em!important;
    position: absolute!important;
    height: 20em!important;
}

.base-tabela-vazia-testes{
    position: relative!important;
    height: 2em!important;
    width: 0em!important;
}

.container-tabela-vazia-testes{
    width: 107.5vw!important;
    position: absolute!important;
    height: 2em!important;
    display: grid!important;
}

.mensagem-tabela-vazia-teste{
    justify-self: center;
}

.base-tabela-teste{
    height: 100%!important;
    margin-bottom: 1em;
    overflow: auto;
}

.container-tabela-teste{
    height: 100%!important;
    overflow: scroll!important;
}

/* ---------- BOTÃO DE EXPANDIR LINHA ---------- */
.botao-expandir-linha-tabela-generica {
    height: 1.875em;
    width: 1.875em;
    border-radius: 3.125em;
    background-color: transparent!important;
    border-color: transparent!important;
    margin-left: -0.2em;
}

.botao-expandir-linha-tabela-generica:hover {
    background-color: transparent!important;
    box-shadow: none!important;
    border-color: transparent!important;
    border-style: outset!important;
    outline: none!important;
}

.botao-expandir-linha-tabela-generica:active, .botao-expandir-linha-tabela-generica:focus {
    box-shadow: none!important;
}

.icone-botao-expandir-linha-tabela-generica {
    margin-top: -0.4375em;
    margin-left: -0.25em;
    transform: rotate(-90deg);
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
}

.icone-botao-expandir-linha-tabela-generica-ativado {
    margin-top: -0.4375em;
    margin-left: -0.25em;
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
}

/* ---------- CHECKBOXES SELEÇÃO DE PARQUE ---------- */
.container-checkbox-tabela-generica-selecao-parque {
    display: block;
    position: relative;
    margin-top: 0.1em;
    margin-bottom: 1.8em;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container-checkbox-tabela-generica-selecao-parque input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark-checkbox-tabela-generica-selecao-parque {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5em;
    width: 1.5em;
    border: solid 2px #CD2D37;
    border-radius: 5px;
}

.container-checkbox-tabela-generica-selecao-parque:hover input ~ .checkmark-checkbox-tabela-generica-selecao-parque {
    border-radius: 5px;
}

.container-checkbox-tabela-generica-selecao-parque input:checked ~ .checkmark-checkbox-tabela-generica-selecao-parque {
    border-radius: 5px;
}

.checkmark-checkbox-tabela-generica-selecao-parque:after {
    content: "";
    position: absolute;
    display: none;
}

.container-checkbox-tabela-generica-selecao-parque input:checked ~ .checkmark-checkbox-tabela-generica-selecao-parque:after {
    display: block;
}

.container-checkbox-tabela-generica-selecao-parque .checkmark-checkbox-tabela-generica-selecao-parque:after {
    left: 6.5px;
    top: 1px;
    width: 8px;
    height: 16px;
    border: solid #36D896;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* ---------- CHECKBOXES SELEÇÃO DE PAGINA ---------- */
.container-checkbox-tabela-generica-selecao-pagina {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-top: 0.1em;
    margin-bottom: 1.8em;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container-checkbox-tabela-generica-selecao-pagina input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark-checkbox-tabela-generica-selecao-pagina {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5em;
    width: 1.5em;
    border: solid 2px #36D896;
    border-radius: 5px;
}

.container-checkbox-tabela-generica-selecao-pagina:hover input ~ .checkmark-checkbox-tabela-generica-selecao-pagina {
    border-radius: 5px;
}

.container-checkbox-tabela-generica-selecao-pagina input:checked ~ .checkmark-checkbox-tabela-generica-selecao-pagina {
    border-radius: 5px;
}

.checkmark-checkbox-tabela-generica-selecao-pagina:after {
    content: "";
    position: absolute;
    display: none;
}

.container-checkbox-tabela-generica-selecao-pagina input:checked ~ .checkmark-checkbox-tabela-generica-selecao-pagina:after {
    display: block;
}

.container-checkbox-tabela-generica-selecao-pagina .checkmark-checkbox-tabela-generica-selecao-pagina:after {
    left: 6.5px;
    top: 1px;
    width: 8px;
    height: 16px;
    border: solid #36D896;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* ---------- CHECKBOXES SELEÇÃO DE LINHA ---------- */
.container-checkbox-tabela-generica-selecao-linha {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-top: 0.2em;
    margin-bottom: 1.8em;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container-checkbox-tabela-generica-selecao-linha input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark-checkbox-tabela-generica-selecao-linha {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5em;
    width: 1.5em;
    border-radius: 5px;
}

.container-checkbox-tabela-generica-selecao-linha:hover input ~ .checkmark-checkbox-tabela-generica-selecao-linha {
    border-radius: 5px;
}

.container-checkbox-tabela-generica-selecao-linha input:checked ~ .checkmark-checkbox-tabela-generica-selecao-linha {
    border-radius: 5px!important;
}

.checkmark-checkbox-tabela-generica-selecao-linha:after {
    content: "";
    position: absolute;
    display: none;
}

.container-checkbox-tabela-generica-selecao-linha input:checked ~ .checkmark-checkbox-tabela-generica-selecao-linha:after {
    display: block;
}

.container-checkbox-tabela-generica-selecao-linha .checkmark-checkbox-tabela-generica-selecao-linha:after {
    left: 6.5px;
    top: 1px;
    width: 8px;
    height: 16px;
    border: solid #36D896;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* ---------- RADIO SELEÇÃO DE LINHA ---------- */
.container-radio-tabela-generica-selecao-linha {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    margin-top: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container-radio-tabela-generica-selecao-linha input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark-radio-tabela-generica-selecao-linha {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
}

.checkmark-radio-tabela-generica-selecao-linha:after {
    content: "";
    position: absolute;
    display: none;
}

.container-radio-tabela-generica-selecao-linha input:checked ~ .checkmark-radio-tabela-generica-selecao-linha:after {
    display: block;
}

.container-radio-tabela-generica-selecao-linha .checkmark-radio-tabela-generica-selecao-linha:after {
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #36D896;
}

/* ---------- BOTÕES CABEÇALHO ---------- */
.botao-cabecalho-tabela-generica{
    display: flex;
    background-color: transparent;
    outline: 0em !important;
    border: transparent;
    font-size: 1em!important;
    line-height: 1.1875em!important;
    font-weight: 700!important;
    width: 100%!important;
    text-align: initial;
}

.botao-cabecalho-tabela-generica:focus, .botao-cabecalho-tabela-generica:visited, .botao-cabecalho-tabela-generica:active{
    border: transparent;
    outline: 0em !important;
}

.container-icone-ordenacao-coluna-tabela-generica{
    width: 0em!important;
}

.icone-ordenacao-coluna-tabela-generica-DESC{
    vertical-align: middle;
    margin-left: 0.2em;
    transform: rotate(180deg)!important;
}

.icone-ordenacao-coluna-tabela-generica-ASC{
    vertical-align: middle;
    margin-left: 0.2em;
}

.botao-paginacao-reticencias-tabela-generica{
    background-color: transparent!important;
    border: none!important;
    box-shadow: none!important;
    margin-left: 0.8em!important;
    width: 1.9em!important;
}

.botao-paginacao-reticencias-tabela-generica:focus{/* BOTOES NUMERADOS DE PAGINACAO DAS TABELAS */
    outline: none !important;
}

.container-botao-paginacao-tabela-generica {
    display: flex!important;
    font-weight: normal!important;
    font-family: RobotoRegular!important;
    margin-top: 0.6em;
}

.container-contador-itens-tabela-generica {
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
    margin-top: 1em;
}

.botao-remover-linha-tabela-generica{
    height: 1.875em;
    width: 1.875em;
    border-radius: 3.125em;
    background-color: transparent!important;
    border-color: transparent!important;
    margin-left: -0.2em;
}

.icone-botao-remover-linha-tabela-generica{
    margin-top: -0.4375em;
    margin-left: -0.25em;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
}

.esconder-tabela-generica{
    display: none!important;
}

.container-contador-itens-paginacao-tabela-generica{
    display: flex!important;
    justify-content: space-between!important;
}

.link-tabela-generica{
    color:  #36D896!important;
    cursor: pointer!important;
}