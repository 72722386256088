.modal-cadastro-cliente{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 40em!important;
}

.grupo-cadastro-cliente-1{
    width: 100%!important;
    display: flex!important;
}

.coluna-cadastro-cliente-1{
    width: 48.5%!important;
    margin-right: 3%!important;
}

.coluna-cadastro-cliente-2{
    width: 48.5%!important;
}

.container-botoes-acoes{
    flex-wrap: nowrap!important;
}

.btn-swith-cadastro-cliente{
    margin-top: 1.5em;
}