.corpo-dados-solicitacao{
    margin-right: 1em;
    width: 49%;
}

.corpo-textarea-solicitacao{
    width: 100%;
}

.calendario-z-index{
    z-index: 301 !important;
}

.tooltip-campos-solicitacao{
    margin-bottom: 11.5em !important;
}

.corpo-tabela-alteracao-solicitacao {
    max-height: 12em !important;
    overflow: auto !important;
    width: 100% !important;
}

.solicitacao-alteracao{
    height: 16em !important;
    width: 100% !important;
    border-radius: 0.3125em !important;
}

.container-conteudo-alteracao-solicitacao{
    height: 12em !important;
    width: 96% !important;
    padding-right: 1em!important;
    margin-top: 0em !important;
    margin-left: 3em !important;
    /* overflow: auto !important; */
}

.usuario-alteracao-corpo-linha{
    margin-top: 0.5em !important;
}

.tabela-transparente-header-fixo-solicitacao{
    overflow: auto!important;
    max-height: 13.5em!important;
    margin-left: 0.9375em !important;
    margin-bottom: 2em;
}
.tabela-transparente-header-fixo-solicitacao th{
    padding: 0em !important;
    border-bottom: 0.0625em solid black !important;
    margin-top: -12px;
    /* min-width: 14em!important; */
    font-size: 0.875em !important;
    z-index: 10!important;
}
.tabela-transparente-header-fixo-solicitacao td{
    /* padding: 0.4em 1em 0.4em 0em!important; */
    border-bottom: 0.0625em solid black !important;
}

.grupo-botao-paginacao-tabela-5 {
    display: flex!important;
    justify-content: center!important;
    font-weight: normal!important;
    font-family: RobotoRegular!important;
    float: left!important;
    z-index: 1;
    position: absolute;
    margin-top: 14px;
}

.icone-calendario-default{
    margin-left: 20em !important;
    margin-top: 5px !important;
}

.campo-texto-calendario-default{
    height: 2em!important;
    width: 100%!important;
    border-radius: 5em!important;
    padding-left: 25.5em!important;
    font-size: 0.875em!important;
    line-height: 1.6em!important;
}

.container-itens-expandido-solicitacao{
    display: flex !important;
}

.container-detalhemento-solicitacoes{
    border-radius: 0.3125em !important;
    padding: 0px 10px 10px 22px !important;
    width: 100%;
}

.container-detalhemento-solicitacoes-alteracao{
    height: 15.9375em !important;
    width: 49% !important;
    border-radius: 0.3125em !important;
    margin-left: 1% !important;
    padding: 0px 10px 10px 22px !important;
}

.itens-exibidos-tabela-solicitacoes-2{
    height: 15.9375em !important;
    width: 35% !important;
    border-radius: 0.3125em !important;
    margin-left: 0.9375em !important;
    min-width: 27.5em !important;
}

.icone-label-detalhamento-solicitacao{
    height: 0.375em!important;
    width: 0.375em!important;
    margin-right: 0.8em!important;
    margin-bottom: 0.3125em!important;
    border-radius: 6.25em!important;
    background-color: #36D896!important;
}