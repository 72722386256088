/********** CLASSES DO PAINEL VIRTUEYES CLIENTES **********/
/* Sempre verifique se a classe que você procura já       */
/* existe. As classes listadas aqui são utilizadas pelas  */
/* pela tabela clientes virtueyes e suas telas de ação.   */
/******** MATEUS FOLLETTO - 15/05/2020 - VIRTUEYES ********/
/******************** RADIO BUTTONS DA TABELA ********************/
.label-checkbox-cliente-virtueyes {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-family: RobotoRegular;
    font-size: 0em;
    color: #E0E0E0 !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.label-checkbox-cliente-virtueyes {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-family: RobotoRegular;
    font-size: 0em;
    color: #E0E0E0 !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.label-checkbox-cliente-virtueyes input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.label-checkbox-cliente-virtueyes input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-cliente-virtueyes {
    position: absolute;
    bottom: 1px;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #252827;
    border-radius: 50%;
}

.label-checkbox-cliente-virtueyes:hover input ~ .checkbox-cliente-virtueyes {
    background-color: #36D896;
}

.label-checkbox-cliente-virtueyes input:checked ~ .checkbox-cliente-virtueyes {
    background-color: #252827;
}

.checkbox-cliente-virtueyes:after {
    content: "";
    position: absolute;
    display: none;
}

.label-checkbox-cliente-virtueyes input:checked ~ .checkbox-cliente-virtueyes:after {
    display: block;
}

.label-checkbox-cliente-virtueyes-nova-tabela input:checked ~ .checkbox-cliente-virtueyes:after {
    display: block;
}

.label-checkbox-cliente-virtueyes .checkbox-cliente-virtueyes:after {
    top: 2.5px;
    left: 2.5px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #36D896;
}

/*nova-tabela*/

.checkbox-cliente-virtueyes-nova-tabela{
    position: absolute;
    bottom: -3px;
    left: 14px;
    height: 18px;
    width: 18px;
    background-color: #252827;
    border-radius: 50%;
    border: 1px solid #4F6275;
}

.label-checkbox-cliente-virtueyes-nova-tabela {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-family: RobotoRegular;
    font-size: 0em;
    color: #E0E0E0 !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.label-checkbox-cliente-virtueyes-nova-tabela input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.label-checkbox-cliente-virtueyes-nova-tabela:hover input ~ .checkbox-cliente-virtueyes-nova-tabela {
    background-color: #36D896;
}

.label-checkbox-cliente-virtueyes-nova-tabela input:checked ~ .checkbox-cliente-virtueyes-nova-tabela {
    background-color: #252827;
}

.checkbox-cliente-virtueyes-nova-tabela:after {
    content: "";
    position: absolute;
    display: none;
}

.label-checkbox-cliente-virtueyes-nova-tabela input:checked ~ .checkbox-cliente-virtueyes-nova-tabela:after {
    display: block;
}

.label-checkbox-cliente-virtueyes-nova-tabela .checkbox-cliente-virtueyes-nova-tabela:after {
    top: 1.2px;
    left: 1.2px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #36D896;
}

/******************** CONSULTAR SIMCARD ********************/
.modal-acao-consultar-simcard{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 59.625em!important;
}

.grupo-consultar-simcard-1{
    width: 100%!important;
    display: flex!important;
}

.coluna-consultar-simcard-1{
    width: 23%!important;
    margin-right: 1%!important;
}

.coluna-consultar-simcard-2{
    width: 23%!important;
}

.botao-pesquisar-redondo-acao-consultar-simcard{
    height: 1.875em!important;
    width: 1.875em!important;
    margin-top: 1.72625em!important;
    border-radius: 50%!important;
    background-color: #36D896!important;
    border: 0.09375em solid #36D896!important;
    box-shadow: none!important;
}

.icone-pesquisar-redondo-acao-consultar-simcard{
    top: 50%!important;
    left: 50%!important;
    transform: translate(-40%, -40%)!important;
}

.modal-acao-consultar-simcard-espacador{
    margin-bottom: 1.875em!important;
}

/******************** HISTORICO DE ACESSOS ********************/
.modal-acao-historico-acesso{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 59.625em!important;
}

.grupo-botao-paginacao-tabela-historico-acessos{
    position: absolute!important;
    bottom: -2em!important;
    width: 100%!important;
    /* left: -20%!important; */
    flex-direction: row!important;
    display: flex!important;
    justify-content: center!important;
    font-weight: normal!important;
    font-family: RobotoRegular !important;
}

.itens-exibidos-tabela-historico-acessos{
    position: absolute!important;
    bottom: 2em!important;
    right: 5%!important;
    display: flex!important;
    font-family: RobotoRegular !important;
    justify-content: center!important;
}

.modal-acao-historico-acessos-espacador{
    margin-bottom: 1.875em!important;
}

/******************** ADICIONAR LOGIN ********************/
.modal-adicionar-login-virtueyes{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 30.125em!important;
}

.login-menu-virtueyes-espacador{
    margin-bottom: 1em!important;
}

.adicionar-alterar-login-virtueyes-grupo{
    display: flex!important;
    width: 100%!important;
}

.adicionar-alterar-login-virtueyes-coluna-1{
    width: 47.5%!important;
    margin-right: 3%!important;
}

.adicionar-alterar-login-virtueyes-coluna-2{
    width: 47.5%!important;
}