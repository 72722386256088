.container-principal-trial{
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    background-color: #e8e7ec!important;
}

.container-logo-lateral{
    width: 33% !important;
    height: 100% !important;
    background-color: #e8e7ec!important;

}

.container-formulario-trial{
    width: 67% !important;
    height: 100% !important;
    background-color: white !important;
    border-radius: 1.875em 0em 0em 1.875em  !important;
}

.modal-cadastro-trial {
    padding: 0em 2em 2em 2em!important;
    width: 33.5em!important;
    margin: auto !important;
    margin-top: 3% !important;
}

.modal-cadastro-trial input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill{
    -webkit-text-fill-color: #000000 !important;
    -webkit-box-shadow: 0 0 0 30px #FFFFFF inset !important;
}

.botao-confirmar-trial{
    float: left!important;
}

.campo-select-trial{
    height: 3em!important;
    width: 100%!important;
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
    line-height: 1em!important;
    box-shadow: none!important;
    border-radius: 5em!important;
    display: flex!important;
    justify-content: space-between!important;
    background-color: white !important;
}

.imagem-trial-spinner{
    width: 100% !important;
    height: 100% !important;
}

.imagem-trial-veye{
    width: 13.5em !important;
    position: absolute !important;
    height: 3.4475em !important;
    margin-top: 6% !important;
    margin-left: 11.5% !important;
}

.campo-texto-trial{
    height: 3em!important;
    width: 100%!important;
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
    line-height: 1em!important;
    box-shadow: none!important;
    border-radius: 5em!important;
    display: -webkit-inline-box!important;
}

.campo-texto-trial-2{
    height: 3em!important;
    width: 21em!important;
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
    line-height: 1em!important;
    box-shadow: none!important;
    border-radius: 5em!important;
    display: -webkit-inline-box!important;
}

input[type=checkbox].input-checkbox-header-trial{
    z-index: 99!important;
    width: 1.125em!important;
    height: 1.125em!important;
    display: none!important;
}

.label-checkbox-trial{
    width: 1em!important;
    height: 1em!important;
    border: 1px solid #00D994;
    box-sizing: border-box;
    border-radius: 5px !important;
}

input[type=checkbox].input-checkbox-header-trial:checked + label.label-checkbox-trial{
    display: flex!important;
    width: 1em!important;
    height: 1em!important;
    background-image: url("./../../../../src/icones/checked_header.svg")!important;
    background-repeat: no-repeat!important;
    background-size: contain!important;
}

.container-switch-trial{
    margin-left: 0em!important;
    margin-top: 0em !important;
    width: 1.875em!important;
    -webkit-user-select:none!important;
    -moz-user-select:none!important;
    -ms-user-select: none!important;
    top:5.5px;
    z-index: 9999;
    height: 0em;
    width: 0em;
   }

.container-switch-trial input[type=checkbox] {
    display: none!important;
}

.termos-adesao-cor{
    color: #00D994;
    font-family: RobotoBold!important;
    font-size: 1em !important;
}


.termos-adesao-cor:hover{
    color: #00D994;
    font-family: RobotoBold!important;
    font-size: 1em !important;
    text-decoration: underline !important;
    cursor: pointer !important;
}


.campo-texto-acordo-switch{
    font-family: RobotoRegular!important;
    font-size: 1em !important;
    display: flex !important;
    margin-left: 1.5625em !important;
    line-height: 1.1875em !important;
}

.mensagem-erro-senha-diferente-trial {
    color: red !important;
    margin-bottom: 1em !important;
    font-family: Roboto !important;
    font-size: 0.75em !important;
    margin-top: 0em !important;
    margin-right: 36em !important;
    width: 12em !important;
}

.label-modal-trial{
    font-family: RobotoRegular !important;
    font-style: normal !important;
    font-weight: 100 !important; 
    font-size: 1.75em !important;
    line-height: 2.0625em !important;
    margin-bottom: 1.5em !important;
    color: #000000 !important;
}

.botao-confirmar-trial{
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    height: 3em!important;
    min-width:  8.8125em!important;
    padding-left: 1.875em!important;
    padding-right: 1.875em!important;
    box-shadow: none!important;
    border-radius: 3.125em!important;
    margin-left: 0em !important;
    float: left!important;
}

.botao-confirmar-desabilitado-2{
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    height: 3em!important;
    min-width:  8.8125em!important;
    padding-left: 1.875em!important;
    padding-right: 1.875em!important;
    border: 0.09375em solid #787B7A!important;
    background-color: #787B7A!important;
    box-shadow: none!important;
    margin-left: 0em !important;
    border-radius: 3.125em!important;
    float: left !important;
}

.label-campo-cnpj{
    width: 19em !important;
}

.botao-confirmar-preencher{
    width: 11em !important;
    height: 2.7em !important;
    padding-left: 1.5em!important;

}

.label-botao-preencher{
    font-family: RobotoBold !important;
    font-size: 1.0625em !important;
    text-align: center !important;
}

.campo-texto-select-tamanho-trial{
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 100% !important;
    text-align: left !important;
    line-height: 1.5em !important;
}

.campo-select-icone-desativado-2{
    transform: rotate(-90deg)!important;
    transition: 0.5s ease!important;
    margin-top: 5px !important;
}

.grupo-cadastro-trial-1 {
    width: 100% !important;
}

.grupo-cadastro-trial-2{
    display: flex !important;
    width: 30.15em !important;
}

.coluna-cadastro-trial-3 {
    width: 30% !important;
}

.coluna-cadastro-trial-4 {
    width: 67% !important;
    margin-left: 3% !important;
}

.coluna-cadastro-trial-5 {
    width: 48.5% !important;
}

.coluna-cadastro-trial-6 {
    width: 48.5% !important;
    margin-left: 3% !important;
}

.coluna-cadastro-trial-7 {
    width: 43.5% !important;
}

.coluna-cadastro-trial-8 {
    width: 53.5% !important;
    margin-left: 3% !important;
}

.coluna-cadastro-trial-9 {
    margin-top: 2.8125em !important;
}

.container-botao-confirmar {
    width: 100% !important;
    height: 100% !important;
    margin-top: 0.5em !important;
}

.modal-cadastro-trial-1{
    padding: 0em 2em 2em 2em!important;
    width: 34.5em!important;
    margin: auto !important;
    margin-top: 3% !important;
}

.label-modal-trial-2{
    font-family: RobotoRegular !important;
    font-weight: 300 !important;
    font-size: 1.75em !important;
    line-height: 2.0625em !important;
}

.label-modal-trial-3{
    font-family: RobotoRegular;
    font-weight: 300 !important;
    font-size: 1.125em !important;
    line-height: 2.0625em !important;
    margin-top: 2.4375em !important;
}

.label-modal-trial-4{
    font-family: RobotoRegular !important;
    font-size: 1em !important;
    line-height: 1.1875em !important;
    color: #36D896 !important;
    margin-top: 2em !important;
    text-decoration: underline !important;
}

.barra-trial-1 {
    width: 10em !important;
    height: 0em !important;
    background-color: #BDBDBD !important;
    border: 1px solid #BDBDBD !important;
    margin-top: 6px;
}
.barra-trial-2 {
    width: 10em !important;
    height: 0em !important;
    background-color: #BDBDBD !important;
    border: 1px solid #BDBDBD !important;
    margin-top: 6px;
}

.elipse-trial-1 {
    width: 1em !important;
    height: 1em !important;
    border-radius: 50% !important;
    background-color: #BDBDBD !important;
    /* margin-top: 55px !important; */
    position: absolute !important;
}

.elipse-trial-2 {
    width: 1em !important;
    height: 1em !important;
    border-radius: 50% !important;
    background-color: #BDBDBD !important;
    /* margin-top: 55px !important; */
    position: absolute !important;
    margin-left: -5px !important;
}

.elipse-trial-3 {
    width: 1em !important;
    height: 1em !important;
    border-radius: 50% !important;
    background-color: #BDBDBD !important;
    /* margin-top: 55px !important; */
    position: absolute !important;
    margin-left: -5px !important;
}

.elipse-trial-4 {
    width: 1em !important;
    height: 1em !important;
    border-radius: 50% !important;
    background-color: #BDBDBD !important;
    margin-top: -10px !important;
    position: absolute !important;
    margin-left: 152px !important;
}

.elipse-trial-verde{
    background-color:#36D896 !important;
}

.container-principal-barra-trial {
    width:  100% !important;
}

.container-principal-barra-elipse{
    display: flex !important;
}

.modal-cadastro-trial-2 {
    padding: 0em 2em 2em 1.5em!important;
    width: 33.5em!important;
    margin: auto !important;
    margin-top: 7% !important;
    display: flex !important;
}

.label-barra-progresso-trial-default {
    color: #FFFFFF !important;
    font-family: RobotoRegular !important;
    font-size: 0px !important;
    position: absolute !important;
  
}

.label-barra-progresso-trial {
    color: #36D896 !important;
    font-family: RobotoRegular !important;
    font-size: 12px !important;
    line-height: 14px!important;
    margin-left: -14px !important;
    position: absolute !important;
    margin-bottom: 0em !important;
    margin-top: -20px !important;
}

.label-barra-progresso-trial-2 {
    color: #36D896 !important;
    font-family: RobotoRegular !important;
    font-size: 12px !important;
    line-height: 14px!important;
    margin-left: 124px !important;
    position: absolute !important;
    margin-bottom: 0em !important;
    margin-top: -29px !important;
}

.grupo-barra-processo {
    display: flex !important;
    margin-top: 1.5em !important;
}