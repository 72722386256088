.div-visualizar-versao {
    margin-top: 0.1875em!important;
    margin-bottom: 6px;
    background-color: transparent!important;
    border: none!important;
    border-radius: 3.125em!important;
    box-shadow: none!important;
}

/* .div-visualizar-versao:focus{} */

/* BOTAO  */
/* .btn-visualizar-versao {
    margin-top: -0.80em!important;
    margin-left: -0.51em!important;
} */

.btn-editar-versao, .btn-apagar-versao {
    background-color: transparent!important;
    border: none!important;
}

.btn-editar-versao {
    margin-right: 30px;
}

.btn-editar-versao img, .btn-apagar-versao img {
    height: 18px;

}

/* ----------------------------------------------- */
.btn-nova-versao {
    box-shadow: 0px 1px 5px 0px #0000001F;
    box-shadow: 0px 2px 2px 0px #00000024;
    box-shadow: 0px 3px 1px -2px #00000033;

    margin-right: 20px;
    margin-bottom: 40px;
}