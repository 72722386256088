/******************** CONTAINERES GRAFICOS FUNDO ********************/
.container-dashboard-fundo-migracaoPlano {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  grid-template-rows: 1fr 1fr !important;
  gap: 20px;
  width: 99% !important;
}

.container-dashboard-tela-migracao-plano {
  width: 100% !important;
}
