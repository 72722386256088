.grupo-painel-sms-enviar-sms-1 {
  width: 100% !important;
  justify-content: space-between;
  display: flex !important;
}

.coluna-painel-sms-enviar-sms-1 {
  width: 33.3% !important;
  margin-top: -0.4375em !important;
}

.coluna-painel-sms-enviar-sms-2 {
  width: 33.4% !important;
}

.coluna-painel-sms-enviar-sms-3 {
  width: 33.3% !important;
}

.grupo-painel-sms-enviar-sms-2 {
  float: right !important;
  margin-top: 1em !important;
}
