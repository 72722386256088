.container-cabecalho-modal-relatorios{
    background-color: #36D896!important;
    height: 3.75em!important;
    border-radius: 0.3125em 0.3125em 0em 0em!important;
    padding-top: 0.375em!important;
    display: flex!important;
    justify-content: center!important;
}

.label-cabecalho-modal-relatorios{
    font-family: Roboto!important;
    font-size: 2.0625em!important;
    font-weight: 700!important;    
}

.container-fundo-relatorios{
    margin-top: 0.625em!important;
    height: 111vh!important;
    width: 100%!important;
    border-radius: 0.3125em!important;
    padding: 2.5em !important;
}

.container-modal-relatorio{
    width: 100%!important;
    /* max-height: 17.5em!important; */
    height: 25em!important;
    overflow: auto!important;
}

.container-botoes-cancelar-confirmar-relatorios{
    margin-top: 1em!important;
    margin-right: 6em !important;
}

.container-tipo-relatorio-1{
    width: 30% !important;
    margin-top: 2em !important;
}

.container-tipo-relatorio{
    width: 100% !important;
}

.conta-dados-divisoria-relatorios{
    margin-top: 1.5em !important;
    width: 100% !important;
}

.cointaner-principal-campo-texto{
    margin-top: 1.5em !important;
    display: flex !important;
    justify-content: space-between !important;
}

.cointaner-principal-campo-texto-2{
    margin-top: 1.5em !important;
    display: flex !important;
    justify-content: space-between !important;
}

.container-campo-texto-1, .container-campo-texto-2{
    width: 30% !important;
}

.container-campo-texto-3{
    width: 30% !important;
    margin-right: 8em !important;
}


.botao-download-relatorio{
    background-color: transparent !important;
    box-shadow:  none !important;
    border: none !important;
    margin-left: 36% !important;
}

.botao-download-relatorio-default{
    background-color: transparent !important;
    box-shadow:  none !important;
    border: none !important;
    margin-left: 36% !important;
    cursor:  default !important;

}
