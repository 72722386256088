.container-select{
    align-items: center;
    overflow-x: hidden!important;
    max-height: 100px!important;
    margin-top: 20px;
}

.span-select-iten{
    margin-left: -39px;
}

.select-divisora-mensagem-atencao {
    margin-left: -20px!important;
    width: 292px!important;
    background-color: black!important;
    height: 1px!important;
    align-items: center;
}

.label-sms {
  margin-right: 2.5em !important;
}

.sms-titulo{
  padding-top: 5% !important;
}

.sms-item{
  padding-top: 2.5% !important;
  padding-bottom: 1% !important;
}