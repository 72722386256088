.container-legenda-grafico-pizza-topo-sms{
    width: 40%!important;
    height: 100%!important;
}

.container-grafico-pizza-topo-sms{
    height: 100%!important;
    width: 60%!important;
    display: relative!important;
}

.coluna-graficos-topo-1-sms {
    height: 82%!important;
    width: 13%!important;
    margin-top: 45px !important;
}

.grupo-grafico-pizza-grande-estoque{
    position: relative!important;
    display: flex!important;
    height: 83%!important;
}

.container-grafico-pizza-grande-estoque-1{
    position: relative!important;
    height: 47%!important;
    width: 33%!important;
    align-content: center!important;
    margin-left: 14% !important;
}

.container-grafico-pizza-grande-estoque-2{
    position: relative!important;
    height: 47%!important;
    width: 33%!important;
    align-content: center!important;
    margin-left: 5% !important;
}

.label-percentual-graficos-pizza-envios {
    font-family: Roboto!important;
    font-size: 188% !important;
    line-height: 1.4375em!important;
}

.label-percentual-graficos-pizza-estoque {
    font-family: Roboto!important;
    font-size: 200% !important;
    line-height: 1.4375em!important;
}

.label-menor-grafico {
    font-size: 220%!important;
}

.container-legenda-grafico-pizza-grande-estoque {
    position: absolute!important;
    margin-top: 0%!important;
    margin-right: 3%!important;
    text-align: right!important;
    float: right!important;
    width: 97%!important;
    bottom: 0!important;
    right: 0!important;
    /* margin-left: 3% !important; */
}

.container-legenda-estoque {
    margin-bottom: -50px;
}

.linha-legenda-sem-comunicar-estoque-1{
    display: flex!important;
    width: 80% !important;
    margin-top: 3%!important;
    margin-bottom: 6%!important;
    margin-left: 20% !important;
}

.linha-legenda-sem-comunicar-estoque-2 {
    display: flex!important;
    width: 80% !important;
    margin-top: 3%!important;
    margin-bottom: 2%!important;
    margin-left: 20% !important;
}

.coluna-legenda-sem-comunicar-1-estoque{
    padding-top: 0.8%!important;
    width: 10%!important;
}

.container-grafico-pizza-sem-comunicar-sms{
    height: 30%!important;
    width: 100%!important;
    display: flex!important;
    padding-left: 5%!important;
    padding-right: 5%!important;
}


.caixa-grafico-pizza-sem-comunicar-sms{
    height: 100%!important;
    width: 18%!important;
    margin-left: 1%!important;
    margin-right: 1%!important;
}

.coluna-legenda-sem-comunicar-2-estoque{
    width: 6em!important;
    text-align: left !important;
    margin-left: 6% !important;
}

.coluna-legenda-sem-comunicar-2-legenda{
    width: 17em!important;
}

.coluna-legenda-sem-comunicar-3-estoque{
    width: 15%!important;
    text-align: right!important;
    margin-left: 5% !important;
}

.label-janela-dashboard-2-estoque{
    height: 2em !important;
    font-family: Roboto!important;
    font-size: 1.13em!important;
    font-weight: 700!important;
    line-height: 2.3125em!important;
    margin-left: 0.625em!important;
    margin-top: 0.3em !important;
}

.label-janela-dashboard-envio{
    font-family: Roboto!important;
    font-size: 1.25em!important;
    font-weight: 700!important;
    line-height: 2em!important;
}

.container-grafico-pizza-grande-recarga {
    position: relative!important;
    height: 83%!important;
    width: 100%!important;
    align-content: center!important;
    font-family: Roboto !important;
}

.label-percentual-graficos-pizza-grande-ultima-recarga{
    font-size: 2em!important;
    text-align: center!important;
}

.label-percentual-graficos-pizza-status {
    font-family: Roboto!important;
    font-size: 20px !important;
    line-height: 1.4375em!important;
}

.menu-opcoes-corpo-topo-dashboard-sms{
    width: 205px!important;
    max-height: 90vh!important;
    border-radius: 5px!important;
}

.menu-opcoes-triangulo-topo-dashboard-sms{
    width: 0!important;
    height: 0!important;
    border-top: 0.9375em solid transparent!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-left: 10.4em!important;
    margin-top: -1.3em!important;
}

.bota-opcoes-dashboard-sms{
    height: 1.875em!important;
    width: 1.875em!important;
    border: 0em solid transparent!important;
    box-shadow: none!important;
    margin-top: 0.5em!important;
    margin-right: 0.5em!important;
    float: right!important;
    background-color: transparent!important;
}

.bota-opcoes-dashboard-sms-envios{
    height: 1.875em!important;
    width: 1.875em!important;
    border: 0em solid transparent!important;
    box-shadow: none!important;
    margin-right: 0.5em!important;
    float: right!important;
    background-color: transparent!important;
}

.drop-acoes-menu-dashboard-sms{
    margin-left: -2.9em!important;
    width: 15.5em!important;
    height: 2.3em!important;
    color: #FFF!important;
    font-size: 0.875em!important;
    line-height: 0em!important;
    cursor: pointer;
}

.drop-acoes-menu-label-dashboard-sms {
    margin-left: 1.8em!important;
    height: 1.875em!important;
    font-size: 1em!important;
    line-height: 2.2em!important;
    font-weight: 700!important;
}

.drop-acoes-menu-label-dashboard-sms-status{
    margin-left: 1.8em!important;
    height: 1.875em!important;
    font-size: 1em!important;
    line-height: 2.2em!important;
    font-weight: 700!important;
}

.menu-opcoes-dashboard-topo{
    position: absolute!important;
    right: 0!important;
    width: 200px !important;
    height: 100px!important;
}

.menu-opcoes-dashboard-topo-envio{
    position: absolute!important;
    right: -30px!important;
    width: 230px !important;
    height: 100px!important;
}

.menu-opcoes-corpo-dashborad-sms{
    width: 205px!important;
    max-height: 90vh!important;
    border-radius: 5px!important;
}

.menu-opcoes-triangulo-dashboard-sms {
    width: 0!important;
    height: 0!important;
    border-top: 0.9375em solid transparent!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-left: 10.5em!important;
    margin-top: -1.3em!important;
}

.menu-opcoes-icone-ativado {
    margin-left: 38px!important;
    margin-top: -4px!important;
    transition: 0.5s ease!important;
} 

.menu-opcoes-icone-desativado {
    margin-left: 38px!important;
    margin-top: -4px!important;
    transform: rotate(-90deg);
    transition: 0.5s ease!important;
}

.drop-acoes-menu-label-dashoard-sms-exportar{
    margin-left: 1.8em!important;
    height: 1.875em!important;
    font-size: 1em!important;
    line-height: 2em!important;
    font-weight: 700!important;
}

.container-legenda-sem-comunicar-sms{
    margin-left: 3.4375em !important;
    margin-top: 1.875em !important;
}

.menu-opcoes-divisora-topo-dashboard-sms {
    height: 1px!important;
    width: 99%!important;
    margin-left: -10%!important;
}

.container-simcards-sem-comunicar-sms{
    width: 100%!important;
}

.menu-opcoes-sub-label-dashboard-sms {
    margin-left: 35px!important;
    height: 30px!important;
    font-size: 14px!important;
    line-height: 28px!important;
}

.linha-grafico-topo-dashboard-sms{
    width: 100%!important;
    height: 50%!important;
    display: flex!important;
}
.titulo-grafico-dashboard{
    width: 80% !important;
}