/* ---------- MODAL ---------- */
.backoffice-modal-cadastro-cliente{
    padding: 0em 2em 2em 2em;
    border-radius: 0.625em 0.625em 0.625em 0.625em;
    width: 65em;
    /* height: 32em; */
}

.backoffice-modal-cadastro-parceiro{
    padding: 0em 2em 2em 2em;
    border-radius: 0.625em 0.625em 0.625em 0.625em;
    width: 40em;
    /* height: 32em; */
}

/* ---------- ABAS ---------- */
.backoffice-cadastro-cliente-tabs{
    background-color: transparent;
    display: flex;
    text-align: center;
    justify-content: space-evenly;
}
.backoffice-cadastro-cliente-tab-ativado{
    border-bottom: 0.2em solid #36D896;
    padding-left: 0px;
    padding-right: 0px;
    width: 12em;
}
.backoffice-cadastro-cliente-tab-desativado{
    padding-left: 0px;
    padding-right: 0px;
    width: 12em;
}

/* ---------- PRIMEIRA ABA ---------- */
.backoffice-cadastro-parceiro-linha-1{
    width: 100%;
    display: flex;
    gap: 10px;
}

.not-cursor-pointer{
    cursor: default !important;
}

.backoffice-cadastro-parceiro-linha-2{
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;

    margin-top: 20px;
    margin-bottom: 40px;
}

.cadastro-parceiro-cliente{
    width: 100%;
}

.campo-texto-parceiros{/* CLASSE PADRAO PARA CAMPOS DE INPUT */
    height: 40px!important;
    width: 100%!important;
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
    line-height: 1em!important;
    border: 0em!important;
    box-shadow: none!important;
    border-radius: 5em!important;
    display: -webkit-inline-box!important;
}

.campo-logo {
    height: 150px;

    background: #BDBDBD;

    border-radius: 20px;
    margin-bottom: 10px;
    margin-top: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 20px;
}

.green-logo-border{
    border: solid 3px #33CC99;
}

.red-logo-border{
    border: solid 3px #F44336;
}



.backoffice-cadastro-cliente-linha-2{
    width: 100%;
    display: flex;
    margin-bottom: 1em;
}
.backoffice-cadastro-cliente-coluna-0{
    width: 20%;
}
.backoffice-cadastro-cliente-coluna-1{
    width: 18%;
}
.backoffice-cadastro-cliente-coluna-2{
    width: 39.5%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-3{
    width: 39.5%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-4{
    width: 80.5%;
}
.backoffice-cadastro-cliente-coluna-5{
    width: 18%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-6{
    width: 27%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-7{
    width: 70%;
}
.backoffice-cadastro-cliente-coluna-8{
    width: 15%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-9{
    width: 12%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-10{
    width: 40%;
}
.backoffice-cadastro-cliente-coluna-11{
    width: 35%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-12{
    width: 22%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-13{
    width: 80%;
}
.backoffice-cadastro-cliente-coluna-14{
    width: 20%;
}

/* ---------- SEGUNDA ABA ---------- */
.backoffice-modal-cadastro-usuario-container-permissoes{
    display: flex;
}
.backoffice-modal-cadastro-usuario-container-lista-modulos{
    width: 15em;
    height: 22em;
    margin-bottom: 1em;
    border-radius: 0.625em;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 1em;
}
.backoffice-modal-cadastro-usuario-lista-modulos{
    display: flex;
    height: 4em;
    cursor: pointer;
    justify-content: space-between;
    padding-right: 2em;
    padding-left: 1em;
}
.backoffice-modal-cadastro-usuario-lista-modulos-label{
    display: flex;
    align-items: center;
}
.backoffice-modal-cadastro-usuario-lista-modulos-container-switch{
    white-space: nowrap;
    width: 25%!important;
    padding-top: 0.8em!important;
}
.backoffice-modal-cadastro-usuario-lista-permissoes{
    height: 22em;
    width: 45.4em;
    border-radius: 0.625em;
    overflow-y: auto;
    overflow-x: hidden;
}
.backoffice-cadastro-usuario-permissoes-tabs{
    background-color: transparent!important;
    display: flex;
    text-align: center;
    justify-content: space-evenly;
}
.backoffice-cadastro-usuario-permissoes-ativado{
    border-bottom: 0.2em solid #36D896;
    padding-left: 0px!important;
    padding-right: 0px!important;
    width: 12em;
}
.backoffice-cadastro-usuario-permissoes-desativado{
    padding-left: 0px!important;
    padding-right: 0px!important;
    width: 12em;
}
.backoffice-modal-cadastro-usuario-permissoes-campo-divisor{
    height: 0.0625em!important;
    width: 100%!important;
}
.backoffice-modal-cadastro-usuario-permissoes-container-switch{
    display: flex;
    align-items: center;
    height: 2.7em;
    margin-left: 1em;
}
.backoffice-modal-cadastro-usuario-permissoes-container-switches{
    display: flex!important;
    flex-wrap: wrap!important;
    width: 100%;
}
.backoffice-modal-cadastro-usuario-permissoes-linha-switch{
    display: flex!important;
    width: 50%;
}
.backoffice-modal-cadastro-usuario-permissoes-icone{
    align-self: normal;
    width: 1.375em;
    height: 1.375em;
    margin-top: 1.3em;
}
.backoffice-modal-cadastro-usuario-permissoes-icone-invisivel{
    visibility: hidden!important;
    display: none!important;
}
.backoffice-modal-cadastro-usuario-permissoes-ativo{
    display: flex;
    height: 4em;
    cursor: pointer;
}
.backoffice-modal-cadastro-usuario-permissoes-desativo{
    display: flex;
    height: 4em;
    cursor: pointer;
}

.linha-botoes-imagem{
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.botao-cad-imagem{
    cursor: pointer;
    height: 30px;
    flex: 1;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
    border-radius: 24px;

    user-select: none;


    font-family: "Open Sans", RobotoRegular ;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 0.46px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;


}

.botao-imagem-texto{
    font-size: 14px!important;
}

.upload-imagem{
    background-color: #00D994;
    color: #FFFFFF;
    width: 205px;
}

.disabled {
    cursor: not-allowed !important;
}

.remover-imagem{
    background-color: #FFFFFF;
    color: #243B53;
}

.imagem-icone{
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*INPUTS CADASTRO PARCEIRO*/

.cad-parceiros-large {
    display: flex;
    flex: 3;
}
.cad-parceiros-small {
    display: flex;
    flex: 3
}

.fundo-branco{
    background-color: white;
}

.descricao-erro-logo{
    color: #dc3545;
}