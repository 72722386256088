.btn-status-tabela{
    background-color: transparent!important;
    border-style: none!important;
}
.btn-status-tabela-erro{
    background-color: transparent!important;
    border-style: none!important;
}
.btn-status-tabela-erro:hover{
    font-weight: bold;
}
.btn-status-tabela:active{
    border-style: none!important;
}
.btn-status-tabela:focus {
    outline: none!important;
    /* outline: 2px auto -webkit-focus-ring-color; */
}
.modal-adicionar-log-reset{
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
}

.caixa-descricao-solicitacao-reset-log {
    margin-top: 1.5625em;
    margin-left: 0.9375em;
    float: left!important;
    max-height: 9.375em!important;
    overflow: visible;
    width: 100%!important;
    border-left: 0.3125em solid #36D896;
    padding: 0px 1.25em 0px;
    overflow-y: auto;
}

.container-caixas-tela-descricao-solicitacao-log-reset{
    padding: 0em 2em 1em 1em;
    border-radius: 0.5em;
    max-height: 34.875em;
    min-height: 13.875em;
    width: 103.5%;
}