.migracaoPlano-container-detalhamento-linha {
    z-index: 1 !important;
    height: 23.8em !important;
    width: calc(100% - 11.3125em);
    position: relative !important;
}

.migracaoPlano-corpo-detalhamento {
    margin-left: -20px;
    height: 92.2% !important;
    width: calc(100% - 1.875em) !important;
    position: absolute !important;
}

.corpo-navegacao-detalhamento-linha {
    height: max-content!important;
    width: 100% !important;
    border-radius: 0.3125em 0em 0em 0.3125em !important;
    padding-top: 1.25em !important;
    padding-bottom: 1.25em !important;
  }

.detalhamento-migracao-plano-tabela-historico {
    margin-left: 20px;
    width: 94%!important;
}

.titulo-grafico-ultimos-6-ciclos {
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: -20px;
    padding: 16px;

    font-size: 18px;
    font-weight: 700;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.15px;
}

/******************** GRAFICO ULTIMOS CICLOS ********************/
.migracaoPlano-container-grafico-ciclo-ultimo {
    height: 16em !important;
    width: 100% !important;
    margin-top: 1em !important;
    zoom: 125% !important;
}