
/* ============================================ */
/*               NOVO PEDIDO                    */
/* ============================================ */

.campo-select-novo-pedido{
    height: 2em!important;
    width: 10.625em !important;
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
    line-height: 1em!important;
    border: 0em!important;
    box-shadow: none!important;
    border-radius: 5em!important;
    display: flex!important;
    justify-content: space-between!important;
}

.container-campos-novo-pedido{
    display: flex !important;
}
.container-campos-novo-pedido-select{
    width: 10.625em;
}

.botao-cancelar-novo-pedido{
    margin-right: 0.625em !important;
    margin-top: 1.3125em !important;
    box-sizing: border-box !important; 
    height: 2.1875em !important;
    width: 6.5625em !important;
    border-radius: 3.125em !important;
    border: 0.09375em solid #36D896 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
}

.botao-confirmar-novo-pedido{
    margin-top: 1.3125em !important;
    box-sizing: border-box !important;
    height: 2.1875em !important;
    width: 6.5625em !important;
    border-radius: 3.125em !important;
    border: none !important;
    background-color: #36D896 !important;
    box-shadow: none !important;
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
}

.container-botoes{
    text-align: center !important;
}

.container-campo-texto{
    display: flex !important;
}

.texto-reais{
    position: absolute !important;
    margin-left: 2.4em !important;
    font-family: Roboto !important;
    font-size: 0.875em !important;
    margin-top: 0.1875em !important;
}

.label-pacote{
    font-family: Roboto !important;
    font-size: 1em !important;
    font-weight: 700 !important;
    line-height: 1.1875em !important; 
    margin-bottom: 0em !important;
}


.label-valor{
    margin-left: 8.6875em !important;
    font-family: Roboto !important;
    font-size: 1em !important;
    font-weight: 700 !important;
    line-height: 1.1875em !important;
    margin-bottom: 0em !important;
}

.container-label{
    display: flex !important;
    margin-bottom: 0em !important;
}

.container-pacote-novo-pedido-sms{
    margin-left: 1em !important;
}
    
.grupo-novo-pedido-sms{
    width: 100%!important;
    display: flex!important
}

.coluna-novo-pedido-sms-1{
    width: 49%!important;
}

.coluna-novo-pedido-sms-2 {
    width: 26%!important;
    margin-left: 25%!important;
    margin-bottom: 1em!important;
}

.campo-texto-valor-pedido{
    text-align: right !important;
    border-radius: 1em !important;
    padding: 0em 1em .1em 1em !important;
}

.div-valor-pedido{
    display: flex !important;
    justify-content: space-around !important;
}