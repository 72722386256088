.modal-cadastro-planos-faturas{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 50em!important;
}

.grupo-cadastro-planos-faturas-1{
    width: 100%!important;
    display: flex!important;
}

.coluna-cadastro-planos-faturas-1{
    margin-right: 3%;
    width: 50%;
}

.coluna-cadastro-planos-faturas-2-1{
    width: 24%;
    margin-right: 3%;
}

.coluna-cadastro-planos-faturas-2-2{
    width: 20%;
}

.coluna-cadastro-planos-faturas-3{
    margin-right: 3%;
    width: 53%;
}

.coluna-cadastro-planos-faturas-4{
    margin-left: 3%;
    width: 20%;
}

.coluna-cadastro-planos-faturas-5{
    width: 20%;
}

.coluna-cadastro-planos-faturas-6{
    margin-right: 3%;
    width: 20%;
}

.coluna-cadastro-planos-faturas-7{
    margin-right: 3%;
    width: 20%;
}

.coluna-cadastro-planos-faturas-8{
    margin-right: 3%;
    width: 10%;
}

.coluna-cadastro-planos-faturas-9{
    margin-right: 3%;
    width: 20%;
}

.coluna-cadastro-planos-faturas-10{
    padding-top: 1.5em;
    margin-right: 3%;
    width: 5%;
}

.coluna-cadastro-planos-faturas-11{
    padding-top: 1.5em;
    margin-right: 3%;
    width: 5%;
}

.coluna-cadastro-planos-faturas-12{
    padding-top: 1.5em;
    width: 5%;
}

.coluna-cadastro-planos-faturas-13{
    margin-left: 65%;
    width: 35%;
}

.divisoria-cadastro-operadoras-faturas{
    width: 100%!important;
    height: 0.0625em!important;
    margin-top: 0.5em!important;
    margin-bottom: 1.5em!important;
}

.expand-row-tabela-planos-faturas-1{
    max-width: 101%!important;
    margin-left: -0.58em;
    transition: 0.5s ease!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
    height: 17em!important;
}

.expand-row-tabela-planos-faturas-2{
    margin-left: -0.58em;
    max-width: 101%!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    height: 17em!important;
    transition: 0.5s ease!important;
}

.flipswitch-planos-faturas{
    margin-right: 0.625em!important;
    /* margin-top: 0.3125em!important; */
    position: relative!important;
    width: 3.5em!important;
    -webkit-user-select:none!important;
    -moz-user-select:none!important;
    -ms-user-select: none!important;
    float: left!important;
}

.flipswitch-planos-faturas input[type=checkbox]{/* CLASSE PADRAO DE FUNCIONAMENTO DO SWITCH */
    display: none!important;
}

.container-switch-detalhamento-planos-faturas{
    margin-top: 5px;
}

.triangulo-detalhamento-planos-faturas{
    width: 0!important;
    height: 0!important;
    border-left: 0.9em solid transparent!important;
    border-right: 0.9em solid transparent!important;
    margin-left: 2.9375em !important;
    margin-top: 0.3125em !important;
    transform: translate(-50%, 0%)!important;
}