@media only screen and (max-width: 1600px) {
    .cliente-container-informacoes-login-resumo{
        width: 94vw;
    }
}

@media only screen and (min-width: 1601px) {
    .cliente-container-informacoes-login-resumo{
        width: 99.2% !important;
        max-width: 100vw;
    }
}

.cliente-container-tela-conteudo-detalhamento{
    margin-left: 0.9375em!important;
    min-width: calc(100% - 1.875em)!important;
    width: 116em!important;
}


.cliente-container-informacoes-login-resumo {
    border-radius: 0.3125em !important;
    /*height: 23.6875em !important;*/
    max-height: 21em !important;
    padding: 1em 2em 2em 2em !important;
    margin-top: 1em !important;
}

.header-info-login{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px !important;
    margin-right: 24px !important;
}