@font-face{
font-family: Roboto;
src: url(../../fonts/Roboto-Regular.ttf)!important;
}

@font-face{
font-family: Objectivity;
src: url(../../fonts/Objectivity-Regular.otf);
font-weight: normal;
}

@font-face{
    font-family: ObjectivityBold;
    src: url(../../fonts/Objectivity-Bold.otf);
    font-weight: normal;
}

@font-face{
    font-family: ObjectivityLigth;
    src: url(../../fonts/Objectivity-Light.otf);
    font-weight: normal;
}


#WrapperTelaLogin{
    width: 100% !important;
    min-height: 100vh !important;
    flex-direction: column !important;
    display: flex !important;
    align-items: center !important; 
    /* overflow: hidden !important;
    justify-content: center !important;*/
}



.CorpoTelaLogin{
    width: 100% !important;
    background-color: #FFF !important;
}

.CorpoTelaLoginNovaSenha{
    width: 100% !important;
    background-color: #FFF !important;
}

.container-form-row-cabecalho{
    height: 6.875em !important;
}

.img-algar{
    background: white;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 270px;
}

.logoVirtueyes{
    width: 220px !important;
    height: 60px !important;
    margin-right: 22em !important;
    margin-bottom: 1.5em !important;
}

.logoVeye {
    width: 9.4em !important;
    height: 4em !important;
    margin-top: -0.3em !important;
}

.logoParceiro{
    width: 20.4em !important;
    height: 4em !important;
    margin-top: -0.3em !important;
}

.logoParceiroLetreiro{
    width: 330px !important;
    height: 60px !important;
    margin-right: 22em !important;
    margin-bottom: 1em !important;
}

.texto-logo {
    height: 2.4em !important;
    width: 11em !important;
    color: #36D896 !important;
    font-family: Objectivity !important;
    font-size: 0.875em !important;
    line-height: 1.2em !important;
    margin-top: 2em !important;
    margin-left: 4% !important;
    margin-right: 1.25em !important;
}

.container-principal {
    width: 95%!important;
    display: flex!important;
    margin-top: 1.2%!important;
    margin-left: 6.9% !important;
    justify-content: space-between !important;
    margin-bottom: 1.3em !important;
}

.container-logo{
    display: flex !important;
}




.container-logo-nova-senha {
    display: flex !important;
    width:86% !important;
    margin: auto !important;
    margin-bottom: 0.5em !important;
    margin-top: 1.5em !important;
    height: 75px;
}

.container-campo-email-senha{
    display: flex !important;
    margin-top: 0.4em !important;
    margin-right: 1.7em !important;
}

/* .container-senha{
    width: 100% !important;
} */

.container-entrar{
    width: 75% !important;
    margin-left: 8.1% !important;
    margin-top: -0.0625em !important;
}

.campo-email{
    padding: 0.375em 0.375em 0.875em 0em !important;
    height: 1.7em !important;
    width: 14.375em !important;
    margin-right: 2.875em !important;
    background-color: #FFF !important;
    font-size: 1em !important;
    border: none !important;
    border-bottom:1px solid gray !important;
    border-radius: 0em !important;
    box-shadow: none !important;
}

.campo-senha{
    padding: 0.375em 0.375em 0.875em 0em !important;
    height: 1.7em !important;
    width: 14.375em !important;
    z-index: 1 !important;
    background-color: #FFF !important;
    font-size: 1em !important;
    border: none !important;
    border-radius: 0em !important;
    border-bottom: 1px solid gray !important;
    box-shadow: none !important;
}

.verSenha{
    padding: 0em !important;
    height: 1.5em !important;
    z-index: 99 !important;
    margin-top: -2em !important;
    margin-left: 13em !important;
    border: none !important;
    box-shadow: none !important;    
}

.ver-senha-nova-senha{
    padding: 0em !important;
    height: 1.5em !important;
    z-index: 99 !important;
    border: none !important;
    box-shadow: none !important;
    margin-left: 25.4em !important;
    position: absolute !important;

    margin-left: 330px !important;
    margin-top: 50px !important;
}

.ver-nova-senha-1{
    margin-top: 2.55em !important;
}

.ver-nova-senha-2{
    margin-top: 2.1em !important;
}

.container-recuperar-senha{
    margin-top: 0.125em !important;
}

.mensagem-erro-login {
    color: red !important;
    margin-bottom: 0em !important;
    font-family: Roboto !important;
    font-size: 0.75em !important;
    margin-top: 0.3125em !important;
    font-weight: 500 !important;
}

.LinkRecuperarSenha{
    color: #36D896;
    font-family: Roboto !important;
    font-size: 0.875em !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0em !important;
    margin-left: 98px;
    margin-top: 9px;
}

.LinkRecuperarSenha:hover{
    text-decoration: underline !important;
}

.btnEntrar{
    height: 3.125em !important;
    width: 12.5em !important;
    border-radius: 6em !important;
    background-color: #36D896;
    border: none !important;
    box-shadow: none !important;
    color: #FFF !important;
    font-family: RobotoBold !important;
    font-size: 1em !important;
    line-height: 1.1875em !important;
}

.btnEntrar:hover{
    color: #FFF !important;
}

.texto-recuperar-senha{
    text-align: left !important;
    width: 7em !important;
    color: #FFF !important;
    font-family: ObjectivityBold !important;
    font-size: 3.4375em !important;
    font-weight: 700 !important;
    margin-top: -12em !important;
    line-height: 1em !important;
}

.texto-confimacao-texto-1{
    text-align: left !important;
    height: 1em !important;
    width: 10.2em !important;
    color: #FFF !important;
    font-family: ObjectivityBold !important;
    font-size: 3.4375em !important;
    font-weight: 700 !important;
    margin-top: -12.3em !important;
}

.texto-confimacao-texto-erro-email{
    text-align: left !important;
    height: 1em !important;
    width: 12.2em !important;
    color: #FFF !important;
    font-family: ObjectivityBold !important;
    font-size: 3.4375em !important;
    font-weight: 700 !important;
    margin-top: -12.3em !important;
}

.btnEnviar, .btnIrLogin, .btnVoltarHomepage, .btnRedefinir, .btnSaibaMais {
    height: 3.125em !important;
    width: 10em !important;
    border-radius: 6.25em !important;
    background-color: #FFF!important;
    color: #36D896!important;
    font-family: Roboto !important;
    font-size: 1em !important;
    font-weight: 700 !important;
    line-height: 1.1875em !important;

    border: none !important;
    box-shadow: none !important;
    padding: 0em !important;


    /* Elevation/2 (grid) */
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)!important;
}

.btnEnviar, .btnIrLogin, .btnVoltarHomepage {
    margin-right: 30em !important;
    margin-top: 2.7em !important;

    border-radius: 24px!important;
}

.btnRedefinir{
    height: 3.125em !important;
    width: 11.125em !important;

    margin-right: 31em !important;
    margin-top: 52px !important;
}

.btnSaibaMais{
    height: 3.125em !important;
    width: 12.5em !important;

    margin-right: 21.4em !important;
    margin-top: 2.7em !important;
}


.btnEnviar:disabled, .btnIrLogin:disabled, .btnRedefinir:disabled, .btnVoltarHomepage:disabled {
    color: #818181!important;
}

.btnIrLogin{
    height: 3.125em !important;
    width: 12.5em !important;
}

.btnVoltarHomepage{
    height: 3.125em !important;
    width: 15.3125em !important;
}

.campo-email-recuperar-senha{
    padding-bottom: 0px !important;
    padding-left: 12px !important;

    height: 1.7em !important;
    width: 26.4em !important;
    margin-right: 2.875em !important;
    margin-top: 1.7em !important;
    background-color: #36D896;
    color: #FFF;
    font-family: Objectivity !important;
    font-size: 18px !important;

    border: none !important;
    border-radius: 0em !important;
    box-shadow: none !important;
    border-bottom: 0.1em solid #FFF !important;
    border-radius: 20px !important;

    width: 482px !important;
    height: 38px !important;
}



.campo-texto-nova-senha{ 
    height: 38px!important;
    width: 267px!important;
    padding-left: 12px !important;
    flex: none !important;
    
    font-size: 18px !important;
    line-height: 1.0625em !important;

    border: none !important;
    border-radius: 24px!important;
    border-bottom: 0.08em solid #FFF !important;
    box-shadow: none !important;

    width: 367px !important;
    height: 38px !important;
}

.campo-texto-nova-senha::placeholder{
    color: #FFF !important;
}

.nova-senha-1{
    margin-right: 2.875em !important;
    margin-top: 2em !important;
}

.nova-senha-2{
    margin-right: 2.875em !important;
    margin-top: 1.6em !important;
}

.container-principal-imagens{
    display: flex !important;
    width: 100% !important;
    height: 66.5em !important;
}

.container-carousel{
    display: flex !important;
    width: 47.8% !important;
}

.container-fundo-verde{
    z-index: 99 !important;
    position: absolute !important;
    margin-left: 1209px !important;
}

.container-fundo-verde-nova-senha{
    margin-left: 2% !important;
    width: 57% !important;
    z-index: 99 !important;
}

.iconeVideo{
    margin-top: -53.5em !important;
    margin-left: -10em !important;
}

.container-textos {
    width: 67% !important;
    /* margin-left: 6.8em !important; */
    margin: auto !important;
    margin-bottom: 18em !important;
}

.texto-1{
    text-align: left !important;
    width: 10em !important;
    color: #FFF;
    font-family: Objectivity !important;
    font-size: 3.4em !important;
    font-weight: 700 !important;
    line-height: 1.3em !important;
    letter-spacing: 1px !important;
}

.texto-senha-redefinida{
    text-align: left !important;
    width: 10em !important;
    color: #FFF !important;
    font-family: ObjectivityBold !important;
    font-size: 3.4200em !important;
    font-weight: 700 !important;
    line-height: 1.2em !important;
    margin-top: -12.2em !important;
    letter-spacing: 0.01em !important;
}

.texto-nova-senha{
    text-align: left !important;
    width: 8em !important;
    color: #FFF !important;
    font-family: ObjectivityBold !important;
    font-size: 342% !important;
    font-weight: 550 !important;
    line-height: 1.2em !important;
    letter-spacing: 0.01em !important;
    margin-bottom: 0.7em !important;
}

.container-texto-2{
    text-align: left !important;
    width: 31em !important;
    color: #000 !important;
    margin-top: 2.2em !important;
}

.container-texto-2-nova-senha{
    text-align: left !important;
    width: 27em !important;
    color: #000 !important;
}

.container-confirmacao-texto-2{
    text-align: left !important;
    /* width: 33em !important; */
    color: #000 !important;
    margin-top: 2.3em !important;
}

.texto-2{
    font-size: 1.5em !important;
    font-family: ObjectivityBold !important;
}

.texto-3 {
    height: 0.9em !important;
    font-family: Objectivity !important;
    font-size: 20px!important;
    font-weight: 700 !important;
}

.grupo-botoes{
    margin-top: 5.5625em !important;
    float: left !important;
}

.btnBaixar{
    height: 2.7775em !important;
    width: 9.375em !important;
    background-color: #1E2126 !important;
    color: #FFF !important;
    border: none !important;
    box-shadow:  none !important;
    padding: 0em !important;
    border-radius: 0.4em !important;
}

.texto-baixar{
    color: #191919 !important;
    font-family: Objectivity !important;
    font-size: 1em !important;
    font-weight: 700 !important;
    line-height: 0.9375em !important;
    text-align: left !important;
    margin-bottom: 1.25em !important;
}

.btnAndroid{
    margin-right: 2.25em !important;
}

.fundo-verde-2{
    display: flex !important;
    background-color: #36D896;
    width: 310vw !important;
}

.container-textos-nova-senha{
    width: 88% !important;
    margin-left: 10.3em !important;
    margin-bottom: 14.375em !important;
}

.container-texto-2-redefinida{
    width: 26em !important;
}

.imagem-carousel-nova-senha-fixa{
    width: 0em !important;
    height: 0em !important;
}

.texto-logo-nova-senha {
    height: 2.4em !important;
    width: 10em !important;
    color: #36D896 !important;
    font-family: Objectivity !important;
    font-size: 0.875em !important;
    line-height: 1.2em !important;
    margin-top: 2em !important;
    margin-left: 0.5em !important;
    margin-right: 1.25em !important;
}

.logoVeye-nova-senha {
    width: 9.4em !important;
    height: 4em !important;
    margin-top: -0.3em !important;
}

.container-textos-recuperar{
    margin-left: 6.8% !important;
    height: 368px;
}

.campo-senha:-webkit-autofill {
    -webkit-text-fill-color: black !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.campo-email:-webkit-autofill {
    -webkit-text-fill-color: black !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.campo-texto-nova-senha{
    -webkit-text-fill-color:  #243B53  !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}























.container-verifique-email {
    width: 367px !important;
    text-align: left;
}


@media  screen and (max-width: 1675px){ 

    .container-principal-imagens {
        height: 100vh !important;
    }

    .container-textos {
        margin-left: 5em !important;
    }
    .container-fundo-verde{
        margin-left: 1000px !important;
    }
}

@media  screen and (max-width: 1615px){ 
    .container-textos {
        margin-left: 3em !important;
    }
}

/* RESOLUÇÃO PARA TELA DE 1400PX X 900PX */
@media  screen and (max-width: 1440px){ 
    .container-principal{
        margin-left: 5.5% !important;
    }

    .container-campo-senha{
        margin-right: -1.625em !important;
    }

    .container-carousel {
        display: flex !important;
        width: 47.8% !important;
        margin-left: -250px;
    }

    .texto-1 {
        font-size: 3.4em !important;
    }

    .texto-3{
        font-size: 1.3em!important;
    }

    .texto-2 {
        font-size: 1.3em!important;
        font-family: ObjectivityBold !important;
    }

    .container-texto-2{
        width: 27em !important;
    }

    .container-textos{
        margin-left: 11em !important;
        margin-bottom: 20em !important;
    }

    .btnSaibaMais{
        height: 2.679em !important;
        width: 10.84em !important;
        font-family: Roboto !important;
        font-size: 0.875em !important;
        padding: 0em !important;
    }

    .container-entrar{
        margin-left: 7% !important;
        margin-top: 0.15em !important;
    }

    .btnEntrar {
        height: 2.7em !important;
        width: 10.643em !important;
        margin-right: 0em !important;
        font-size: 0.875em !important;
    }

    .container-campo-email-senha{
        margin-right: -7.1em !important;
    }

    .container-form-row-cabecalho{
        height: 6.875em !important
    }

    .logoVeye{
        width: 7.5em !important;
        height: 3em !important;
        margin-top: 0.2em !important;
    }
  
    .texto-logo{
        font-size: 0.75em !important;
        width: 11em !important;
        margin-top: 2.4em !important;
        line-height: 1em !important;
    }
    
    .container-fundo-verde {
        margin-left: 8% !important;
        position: initial !important;
        margin-top: -1px !important;
    }

    .campo-email{
        padding: 0.375em 0.375em 1.2em 0em !important;
        height: 2.2em !important;
        width: 16.429em !important;
        font-size: 0.875em !important;
    }

    .campo-senha{
        padding: 0.375em 0.375em 1.2em 0em !important;
        height: 2.2em !important;
        width: 16.429em !important;
        font-size: 0.875em !important;
    }

    .olhoAtivo{
        height: 0.9375em !important;
        width: 1.1875em !important;
    }

    .olhoDesativo{
        height: 0.9375em !important;
        width: 1.1875em !important;
    }

    /* .container-senha{
        width: 85.4% !important;
    } */

    .verSenha{
        margin-top: -2.2em !important;
    }

    .texto-recuperar-senha {
        font-size: 3em !important;
        margin-top: -15.5em !important;
    }

    .campo-email-recuperar-senha {
        width: 21.31em !important;
    }

    .btnEnviar {
        height: 2.34375em !important;
        width: 9.353125em !important;
        font-size: 0.875em !important;
        margin-top: 3em !important;
        margin-right: 44em !important;
    }

    .texto-confimacao-texto-1 {
        font-size: 3em!important;
        margin-top: -15.5em !important;
    }

    .container-confirmacao-texto-2 {
        text-align: left !important;
        width: 33em !important;
        line-height: 2.1875em !important;
    }

    .btnVoltarHomepage {
        height: 3em !important;
        width: 17.1em !important;
        font-size: 0.75em !important;
        margin-right: 45em !important;
    }

    .texto-nova-senha {
        font-size: 263% !important;
        line-height: 1.5em !important;
    }

    .container-texto-2-nova-senha{
        width: 20em !important;
        line-height: 2.1875em !important;
    }

    .campo-texto-nova-senha {
        width: 17.78em !important;
    }

    .ver-senha-nova-senha {
        margin-left: 18.7em !important
    }

    .btnRedefinir {
        height: 2.715em !important;
        width: 9.93em !important;
        font-size: 0.875em !important;
    }

    .container-textos-nova-senha {
        margin-bottom: 17.375em !important;
    }

    .texto-senha-redefinida {
        font-size: 3em !important;
        margin-top: -16.2em !important;
        line-height: 1.2em !important;
    }

    .container-texto-2-redefinida{
        width: 19em !important;
    }

    .btnIrLogin {
        height: 2.72em !important;
        width: 11.215em !important;
        font-size: 0.875em !important;
        margin-top: 2.9em !important;
    }

    .container-textos-redefinida{
        margin-left: 11em !important;
    }

    .container-logo-nova-senha {
        display: flex !important;
        margin-bottom: 0.5em !important;
        margin-top: 0.4375em !important;
    }

    .texto-logo-nova-senha {
        color: #36D896 !important;
        margin-left: 0.375em !important;
        font-family: Objectivity !important;
        font-size: 0.75em !important;
        width: 10em !important;
        margin-top: 2.6em !important;
        line-height: 1em !important;
        margin-left: 0.5 !important;
    }

    .logoVeye-nova-senha {
        width: 7.5em !important;
        height: 3em !important;
        margin-top: 0.2em !important;
    }

    .mensagem-erro-senha-vazia {
        font-size: 0.75em !important;
        width: 20em !important;
    }

    .container-fundo-verde-nova-senha {
        margin-left: 10% !important;
        width: 57% !important;
        z-index: 99 !important;
    }

    .fundo-verde-2{
        width: 0em !important;
        height: 0em !important;
    }
}


@media  screen and (max-width: 1366px){
    .btnEntrar{
        height: 2.5em !important;
        width: 10.143em !important;
        margin-right: 0em !important;
    }

    .container-campo-senha {
        margin-right: 0em !important;
    }

    .btnSaibaMais {
        height: 2.917em !important;
        width: 11.834em !important;
        font-size: 0.75em !important;
        margin-top: 3.5em !important;
    }

    .logoVeye {
        width: 7em !important;
        height: 3em !important;
        margin-top: 0em !important;
    }

    .texto-logo {
        font-size: 0.625em !important;
        width: 11em !important;
        margin-top: 2.9em !important;
    }

    .container-principal {
        margin-left: 6.323% !important;
    }

    .LinkRecuperarSenha{
        font-size: 0.625em !important;
    }

    .campo-email{
        padding: 0.375em 0.375em 1.2em 0em !important;
        height: 2.2em !important;
        width: 19.167em !important;
        font-size: 0.75em !important;
    }

    .mensagem-erro-login{
        font-size:0.625em !important;
        margin-top: 0.1em !important;
    }

    .campo-senha{
        padding: 0.375em 0.375em 1.2em 0em !important;
        height: 2.2em !important;
        width: 19.167em !important;
        font-size: 0.75em !important;
    }
    .olhoAtivo{
        height: 0.596875em !important;
        width: 0.875em !important;
    }

    .olhoDesativo{
        height: 0.596875em !important;
        width: 0.875em !important;
    }

    .verSenha {
        margin-top: -2em !important;
    }
    
    .LinkRecuperarSenha{
        font-size: 0.625em !important;
    }

    .container-recuperar-senha{
        margin-top: -0.2em !important;
    }

    .container-form-row-cabecalho {
        height: 6.875em !important;
    }
    
    .texto-1 {
        font-size: 2.4375em !important;
    }

    .container-textos {
        margin-left: 12.2em !important;
        margin-bottom: 30em !important;
    }

    .texto-3 {
        font-size: 1em!important;
    }

    .container-texto-2 {
        width: 21em !important;
        margin-top: 1.3em !important;
        line-height: 1.875em !important;
    }

    .texto-2 {
        font-size: 1em !important;
        font-family: ObjectivityBold !important;
    }

    .container-entrar {
        margin-left: 7% !important;
        margin-right: 0.75em;
    }

    .container-fundo-verde{
        margin-left: 8% !important;
        width: 70% !important;
    }

    .texto-recuperar-senha {
        font-size: 2.4375em !important;
        margin-top: -19em !important;
    }

    .campo-email-recuperar-senha {
        width: 19.2em !important;
    }

    .btnEnviar {
        height: 2.917em !important;
        width: 11.834em !important;
        margin-top: 2em !important;
        font-size: 0.75em !important;
        margin-right: 50em !important;
    }

    .container-verifique-email{
        margin-left: 10em !important;
    }

    .container-confirmacao-texto-2 {
        width: 26.5625em !important;
    }

    .texto-nova-senha {
        font-size: 244% !important;
        margin-bottom: 0.3em !important
    }

    .container-texto-2-nova-senha {
        width: 18em !important;
        margin-bottom: 0.5em !important;
        line-height: 2em !important;
    }

    .campo-texto-nova-senha {
        width: 20.715em !important;
        font-size: 0.875em !important;
        padding: 0.375em 0.375em 0.9em 0em !important;
    }

    .ver-senha-nova-senha {
        margin-left: 17em !important;
        height: 0.76875em !important;
        width: 1.125em !important;
    }

    .olhoAtivo {
        height: 1em !important;
        width: 1.125em !important;
    }

    .olhoDesativo {
        height: 1em !important;
        width: 1.125em !important;
    }

    .ver-nova-senha-1 {
        margin-top: 1.3em !important;
    }

    .ver-nova-senha-2 {
        margin-top: 2.9em !important;
    }

    .btnRedefinir {
        height: 3em !important;
        width: 11.587em !important;
        font-size: 0.75em !important;
        margin-right: 38em !important;
    }

    .nova-senha-2{
        margin-top: 3.9em !important;
    }
    
    .texto-senha-redefinida {
        font-size: 2.4375em !important;
        margin-top: -19.5em !important;
        line-height: 1.5em !important;
    }

    .container-texto-2-redefinida{
        width: 15em !important;

    }

    .btnIrLogin {
        height: 3em !important;
        width: 12.75em !important;
        font-size: 0.75em !important;
        margin-top: 2.9em !important;
    }

    .fundo-verde-2{
        width: 0em !important;
        height: 0em !important;
    }

    .container-fundo-verde-nova-senha {
        margin-left: 15% !important;
    }
}

/* IPAD 1024 x 768  */
@media  screen and (max-width: 1024px){
    .container-fundo-verde {
        margin-left: 9% !important;
        width: 70% !important
    }

    .container-principal {
        margin-left: 1.935% !important;
    }

    .container-campo-email-senha {
        margin-left: 3em !important;
    }

    .campo-email{
        margin-right: 4em !important;
    }

    .container-textos {
        margin-left: 9.5em !important;
    }

    .container-logo{ 
        margin-left: 0em !important;
    }

    .container-verifique-email {
        margin-left: 7.5em !important;
        margin-bottom: 30em !important;
    }

    .btnSaibaMais {
        margin-top: 2.6em !important;
    }

    .campo-email-recuperar-senha {
        width: 17.7em !important;
    }

    .btnEnviar {
        height: 2.188em !important;
        width: 8.875em !important;
        font-size: 1em !important;
    }
    
    .texto-confimacao-texto-1 {
        font-size: 2.4375em!important;
    }

    .btnVoltarHomepage {
        height: 3em !important;
        width: 17.085em !important;
        font-size: 0.75em !important;
    }

    .container-textos-nova-senha{
        margin-left: 11.5em !important;
        margin-bottom:  23.4375em !important;
    }

    .container-textos-redefinida {
        margin-left: 8em !important;
    }
    
    .container-fundo-verde-nova-senha {
        margin-left: 8% !important;
        width: 70% !important;
    }

    .container-logo-nova-senha {
        display: flex !important;
        margin-bottom: 0em !important;
        margin-top: 0.75em !important;
        margin-left: 2.5em !important;
    }

    .fundo-verde-2{
        width: 0em !important;
        height: 0em !important;
    }
}

/* IPAD 768 x  1024 */
@media  screen and (max-width: 830px){

    .carousel-item {
        height: 90vh !important;
    }

    .CorpoTelaLogin{
        overflow-y: auto !important;
        overflow-x: hidden !important;
    }

    .imagem-carousel{
        width: 0em !important;
        height: 0em !important;
    }

    .container-fundo-verde {
        /*background-color: #36D896;*/
        width: 100% !important;
        margin-top: 0em !important;
        margin-left: 0em !important;
    }

    .container-itens{
        width: 0em !important;
        height: 6.25em !important;
    }

    .container-form-row-cabecalho {
        height: 23.125em !important;
    }

    .container-principal {
        display: grid !important;
        width: 75vw !important;
        min-width: 26.2em !important;
        margin: auto !important;
        margin-top: 36px !important;
        margin-bottom: 0em !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .mensagem-erro-login{
        font-size: 0.625em !important;
    }

    .container-campo-email-senha{
        display: flow-root !important;
        width: 14.375em !important;
        margin-top: 3.125em !important;
        margin-left: 0em !important;
    }

    .verSenha{
        margin-top: -0.1875em !important;
        margin-left: 13em !important;
        position: absolute;
    }

    .container-logo {
        width: 14.5em !important;
        display: flex !important;
        margin-left: 0em !important;
    }

    .logoVeye {
        width: 6.9em !important;
        height: 3em !important;
    }

    .texto-logo {
        font-size: 0.625em !important;
        width: 10em !important;
        margin-top: 2.9em !important;
    }

    .container-campo-senha{
        margin-top: 2.125em !important;
    }

    .container-entrar{
        width: 100% !important;
        margin-left: 0% !important;
        margin-top: 3.46875em !important;
    }
    
    .btnEntrar{
        margin-top: 0em !important;
        height: 2.219em !important;
        width: 8.875em !important;
        margin-left: 49.8px !important;
    }

    .texto-1 {
        font-size: 3.1em !important;
        margin-top: 0em !important;
        text-align: center !important;
        width: 85% !important;
        margin-left: 7% !important;
    }

    .container-textos {
        width: 75vw !important;
        margin: auto !important;
        min-width: 26.2em !important;
        margin-top: 3em !important;
        align-items: center !important;
        justify-content: space-between !important;
    }

    .texto-3 {
        font-size: 1.3em!important;
    }
    
    .container-texto-2 {
        width: 100% !important;
        text-align: center !important;
    }
    
    .texto-2 {
        font-size: 1.3em !important;
        font-family: ObjectivityBold !important;
    }

    .btnSaibaMais {
        height: 2.917em !important;
        width: 11.834em !important;
        font-size: 0.75em !important;
        margin-top: 2.5 em !important;
        margin-right: 0.125em !important;
    }

    .olhoAtivo{
        height: 0.596875em !important;
        width: 0.875em !important;
    }

    .olhoDesativo{
        height: 0.596875em !important;
        width: 0.875em !important;
    }

    .LinkRecuperarSenha{
        font-size: 0.625em !important;
    }

    .container-textos-recuperar {
        margin-left: 0em !important;
        margin: auto !important;
        margin-top: 3em !important;
    }

    .texto-recuperar-senha {
        width: 100% !important;
        font-size: 3em !important;
        margin-top: 0em !important;
        text-align: center !important;
    }

    .container-texto-2-recuperar {
        width: 80% !important;
        text-align: center !important;
        align-items: center !important;
        margin: auto !important;
        line-height: 1.5em !important;
    }

    .campo-email-recuperar-senha {
        width: 55% !important;
        min-width: 240px !important;
        margin-left: 0em !important;
        align-items: center !important;
        justify-content: space-between !important;
        margin: auto !important;
        margin-top: 2em !important;
    }

    .btnEnviar {
        height: 2.1875em !important;
        width: 9.8125em !important;
        margin-right: 0em !important;
        margin-left: 0em !important;
    }
    
    .texto-confimacao-texto-1 {
        width: 100% !important;
        height: 100% !important;
        font-size: 3em !important;
        margin-top: 0em !important;
        text-align: center !important;
    }

    .container-confirmacao-texto-2 {
        text-align: center !important;
        width: 100% !important;
        line-height: 2.5em !important;
        margin-top: 0em !important;
        margin-left: 0em !important;
    }

    .btnVoltarHomepage {
        height: 3em !important;
        width: 17.1em !important;
        font-size: 0.75em !important;
        margin-right: 0em !important;
        margin-top: 3em !important;
    }

    .container-fundo-verde-nova-senha {
        margin-left: 0 !important;
        width: 100% !important;
        margin-top: 0em !important;
        position: absolute !important;
    }

    .container-form-row-cabecalho-nova-senha{
        height: 5em !important;
    }

    .container-logo-nova-senha {
        margin-top: 0em !important;
        margin-left: 0em !important;
        display: flex !important;
    }

    .texto-logo-nova-senha {
        width: 11em !important;
        font-size: 0.625em !important;
        color: #36D896 !important;
        font-family: Objectivity !important;
        margin-top: 2.55em !important;
        margin-left: 0.5em !important;
        line-height: 1.4em !important;
        margin-right: 0em !important;
    }

    .logoVeye-nova-senha {
        margin-top: 0em !important;
    }

    .imagem-carousel-fundo-verde {
        height: 100% !important;
        width: 100em !important;
        background-color: #36D896;
    }

    .container-textos-nova-senha {
        width: 61vw !important;
        margin-left: 0% !important;
        margin-bottom: 0em !important;
        margin: auto !important;
        margin-top: 3em !important;
    }

    .imagem-carousel-nova-senha-fixa {
        height: 40em !important;
        width: 61em !important;
    }

    .container-nova-senha-fixa-sem-tamanho{
        width: 0em !important;
        height: 0em !important;
    }

    .texto-nova-senha {
        width: 100% !important;
        font-size: 2.4em !important;
        text-align: center !important;
    }

    .container-texto-2-nova-senha {
        width: 76% !important;
        text-align: center !important;
        margin-left: 0em !important;
        line-height: 35px !important;
        margin: auto !important;
    }

    .campo-texto-nova-senha {
        width: 16.572em !important;
        font-size: 0.875em !important;
        padding: 0.375em 0.375em 0.8em 0em !important;
    }

    .olhoAtivo {
        height: 0.77em !important;
        width: 1.13em !important;
    }

    .olhoDesativo {
        height: 0.77em !important;
        width: 1.13em !important;
    }

    .container-nova-senha {
        margin-left: 0em !important;
        justify-content: center !important;
    }

    .ver-senha-nova-senha {
        margin-left: 12.8em !important;
    }

    .btnRedefinir {
        height: 3em !important;
        width: 10.792em !important;
        font-size: 0.75em !important;
        margin: auto !important;
        margin-top: 4em !important;
    }

    .ver-nova-senha-2 {
        margin-top: 2.8em !important;
    }

    .ver-nova-senha-1 {
        margin-top: 1.4em !important;
    }

    .nova-senha-2 {
        margin-top: 3.6em !important;
    }

    .container-nova-senha-fixa {
        height: 29.5em !important;
        width: 48em !important;
        margin-left: 5em !important;
        margin-top: -0.75em !important;
    }
    
    .container-textos-redefinida {
        margin-bottom: 0em !important;
        line-height: 30px !important;
        margin: auto !important;
        margin-top: 10em !important;
        width: 100% !important;
    }

    .container-texto-2-redefinida {
        width: 30% !important;  
        margin: auto !important;
        margin-top: 1.3em !important;
    }

    .texto-senha-redefinida {
        text-align: center !important;
        font-size: 3em !important;
        line-height: 54px !important;
        margin: auto !important;
        width: 53% !important;
    }

    .btnIrLogin {
        height: 3em !important;
        width: 12.75em !important;
        font-size: 0.75em !important;
        align-items: center !important;
        justify-content: space-between;
        margin: auto !important;
        margin-top: 2.9em !important;
    }

    .mensagem-erro-senha-diferente {
        margin-left: 7.25em !important;
    }

    .mensagem-erro-senha-vazia {
        width: 20em !important;
        margin-left: 7.25em !important;
    }

    .container-verifique-email{
        margin-left: 0em !important;
        margin: auto !important;
        margin-top: 3em !important;
    }

    .logoVirtueyes {
        width: 220px !important;
        height: 60px !important;
        margin-right: 0em !important;
        margin-bottom: 1em !important;
        margin-left: 0% !important;
    }

    .fundo-verde-2{
        width: 0em !important;
        height: 0em !important;
    }

    .conteudo-carousel {
        width: 100% !important;
        height: 100% !important;
        padding: 0em !important;
        margin: 0em !important;
        left: 0em !important;
        right: 0em !important;
        bottom: 0em !important;
    }

    .imagem-carousel-nova-senha-fixa {
        height: 0em !important;
        width: 0em !important;
    }

    .nova-senha-1 {
        margin-right: 0em !important;
    }

    .nova-senha-2 {
        margin-right: 0em !important;
    }

    .container-principal-nova-senha {
        display: grid !important;
        width: 92% !important;
        height: 100% !important;
        margin: auto !important;
        align-items: center !important;
        justify-content: space-between!important;
    }
}

@media  screen and (max-width: 755px){
    .texto-1{
        font-size: 2.8em !important;
    }
}

@media  screen and (max-width: 685px){
    .texto-1{
        font-size: 6vw !important;
    }
}

@media  screen and (max-width: 565px){
    .container-principal {
        min-width: 320px !important;
    }

    .container-textos {
        min-width: 320px !important;
    }

    .texto-1{
        font-size: 6.4vw!important;
    }

    .texto-3 {
        font-size: 3.7vw!important;
        height: 2vh !important;
    }

    .logoVirtueyes {
        width: 36.7vw!important;
        margin-bottom: 0em !important;
    }

    .container-texto-2 {
        line-height: 0.5em !important;
    }

    .container-texto-2-recuperar {
        line-height: 1.5em !important;
    }

    .container-confirmacao-texto-2 {
        line-height: 2em !important;
    }

    .container-texto-2-redefinida{
        line-height: 1.5em !important;
    }
}

@media  screen and (max-width: 400px){
    .container-principal {
        min-width: 250px !important;
    }

    .container-textos {
        min-width: 250px !important;
    }

    .container-fundo-verde {
        position: absolute !important;
        height: 100vh !important;
    }

    .texto-3 {
        font-size: 3.6vw!important;
        height: 1vh !important;
    }

    .texto-recuperar-senha{
        font-size: 2.5em !important;
    }

    .btnIrLogin {
        height: 2.5em !important;
        width: 8.75em !important;
    }

    .texto-senha-redefinida {
        font-size: 2.5em !important;
    }

    .container-texto-2-redefinida {
        width: 42% !important;
    }
}

.mensagem-erro-login-vermelho, .mensagem-erro-senha-diferente, .mensagem-erro-senha-vazia {
    color: #D32F2F;
    margin-top: 4px;
    position: absolute;
    width: 367px;
    text-align: start;
}

.mensagem-erro-recuperar-senha {
    display: flex;
    color: #B23842;
    margin-top: 4px;
}

.mensagem-erro-login-vermelho {
    margin-top: 9px;
}

.mensagem-erro-senha-diferente, .mensagem-erro-senha-vazia {
    position: relative;
    margin-top: 20px;
}

.linha-divisoria-MensagemConfirmacaoEmail { /* hr */
    border: 1px solid #FFF;
}

.text-rodape-MensagemConfirmacaoEmail { /* span */
    font-size: 16px!important;
    color: #000 !important;
    text-align: start;
}