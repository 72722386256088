/*********** CLASSES GENERICAS DO SISTEMA ***********/
/* manter classes nomeadas de modo genérico.        */
/* Sempre verifique se a classe que você procura já */
/* existe. As classes listadas aqui são reutiliza-  */
/* das por todo o Sistema. Caso queira alterar      */
/* detalhes particulares de cada elemento em telas  */
/* específicas, crie um arquivo CSS exclusivo para  */
/* a tela em questão e utilize classes que referen- */
/* ciem somente a própria, evitando conflitos.      */
/***** MATEUS FOLLETTO - 15/05/2020 - VIRTUEYES *****/
/******************** BASE DO SISTEMA ********************/
* {
  /* BASE DO SISTEMA - NAO MEXER */
  box-sizing: border-box !important;
  /*font-size: 16px!important;*/
}

html,
body {
  /* BASE DO SISTEMA - NAO MEXER */
  height: 100% !important;
  width: 100% !important;
  /*font-size: 16px!important;*/
  display: block !important;
  background-color: black !important;
  overflow: hidden !important;
}

body {
  zoom: 80% !important;
}

/******************** FONTES E TEXTOS ********************/
@font-face {
  src: url('../fonts/Roboto-Light.ttf');
  font-family: RobotoLigth;
}

@font-face {
  src: url('../fonts/Roboto-Medium.ttf');
  font-family: RobotoMedium;
}

@font-face {
  src: url('../fonts/Roboto-Bold.ttf');
  font-family: RobotoBold;
}

@font-face {
  src: url('../fonts/Roboto-Regular.ttf');
  font-family: RobotoRegular;
}

.label-janela {
  /* TEXTO DOS TITULOS DAS JANELAS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1.375em !important;
  line-height: 1.8125em !important;
  font-weight: 700 !important;
}

.label-janela-alteracao-ciclo {
  /* TEXTO DOS TITULOS DAS JANELAS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1.375em !important;
  line-height: 1.8125em !important;
  font-weight: 700 !important;
  margin-left: 40px;
}

.label-integracao {
  /* TEXTO DOS TITULOS DAS JANELAS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1.375em !important;
  line-height: 1.8125em !important;
  font-weight: 700 !important;
}

.label-integracao-alteracao-ciclo {
  /* TEXTO DOS TITULOS DAS JANELAS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1.375em !important;
  line-height: 1.8125em !important;
  font-weight: 700 !important;
  margin-left: 22.8125em !important;
  margin-left: 541px;
  position: absolute;
  margin-top: -3px;
}

.properties {
  font-family: RobotoRegular !important;
  font-size: 1.375em !important;
  line-height: 1.8125em !important;
  font-weight: 700 !important;
  margin-top: -43px;
  margin-left: 160px;
}

.properties-alteracao-ciclo {
  font-family: RobotoRegular !important;
  font-size: 1.375em !important;
  line-height: 1.8125em !important;
  font-weight: 700 !important;
  margin-top: -43px;
  margin-left: 303px;
}
.label-cmp,
.label-cmp-alteracao-ciclo {
  font-family: RobotoRegular !important;
  font-size: 1.375em !important;
  line-height: 1.8125em !important;
  font-weight: 700 !important;
  margin-top: -40px;
  margin-left: 188px;
}
.label-cmp-alteracao-ciclo {
  margin-left: 364px;
}

.label-campo {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
}

.label-interno-wrapper {
  width: 100% !important;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.label-campo-novo {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  display: flex;
  align-items: center;
}

.label-campo-marca {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  margin-top: 6px;
}

.label-campo-marca-2 {
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  margin-top: 1px;
}

.label-campo-contrato {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  line-height: 1em !important;
  margin-left: 6px;
  margin-top: 3px;
}

.label-campo-contrato-filtro {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  line-height: 1em !important;
  margin-top: 3px;
  position: fixed;
}

.label-campo-contrato-filtro-simcard {
  font-family: RobotoRegular !important;
  line-height: 1em !important;
  margin-top: 3px;
  position: absolute;
}

.label-caracteres-restantes {
  margin-top: 0.2em;
  padding-right: 0.5em;
}

.label-campo-simcard {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  margin-left: -64px;
}

.label-caracteres-restantes {
  margin-top: 0.2em;
  padding-right: 0.5em;
}

.label-campo-extra {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  margin-left: -30px;
}

.label-campo-pacotes-b {
  /* TEXTO DOS TITULOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  margin-left: -27px;
}

.label-campo-sms {
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  margin-left: -33px;
}

.label-campo-sms-b {
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
  margin-left: 81px;
}

.label-normal {
  /* TEXTO DOS TEXTOS E CONTEUDOS DOS CAMPOS DO SISTEMA */
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
}

.label-normal-valor {
  font-family: RobotoRegular !important;
  font-size: 1em !important;
  line-height: 1em !important;
}
.label-alerta {
  /* TEXTO DOS ALERTAS DE CAMPOS ORBIGATORIOS */
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1.5em !important;
}

.label-titulo-mensagem {
  /* TEXTO DOS TITULOS DAS MENSAGENS */
  font-family: RobotoBold !important;
  font-weight: 700 !important;
  font-size: 1.875em !important;
  line-height: 1.5em !important;
  word-break: break-word !important;
}

.label-corpo-mensagem {
  /* TEXTO DO CORPO DAS MENSAGENS */
  font-family: RobotoRegular !important;
  font-size: 1.125em !important;
  line-height: 1.5em !important;
  word-break: break-word !important;
  /* white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important; */
}

.label-tabela-1 {
  /* TEXTO DO CORPO DAS TABELAS */
  font-family: RobotoRegular !important;
  font-size: 16px !important;
  line-height: 1.5em !important;
  padding-left: 0px;
}

.label-tabela-nova-1 {
  /* TEXTO DO CORPO DAS TABELAS */
  font-family: RobotoRegular !important;
  font-size: 0.975em !important;
  line-height: 1.5em !important;
  margin-bottom: 0px;
}

.label-tabela-2 {
  /* TEXTO DO CONTEUDOS ESPECIFICOS QUE PRECISAM DE TRATAMENTO DIFERENCIADO */
  font-family: RobotoRegular !important;
  font-size: 1rem !important;
  line-height: 1.4em !important;
  height: 0.25em !important;
}

.centralizar {
  /* CLASSE PADRAO PARA ALINHAR TEXTOS DE MODO CENTRALIZADO */
  text-align: center !important;
}

.negrito {
  font-weight: 700 !important;
}

.label-elipses {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.nao-selecionavel {
  /* NAO DEIXA O TEXTO SER SELECIONAVEL COM O MOUSE */
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.contador-carateres-restantes {
  float: right !important;
  margin-bottom: 1.4375em !important;
}

/******************** CAMPOS DE INPUT ********************/
.campo-texto {
  /* CLASSE PADRAO PARA CAMPOS DE INPUT */
  height: 2em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: -webkit-inline-box !important;
}

.campo-texto-material {
  /* CLASSE PADRAO PARA CAMPOS DE INPUT */
  height: 3.2em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: -webkit-inline-box !important;
}

.campo-texto-area {
  /* CLASSE PADRAO PARA CAMPOS DE TEXT AREA */
  height: 6em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 0.3125em !important;
  display: -webkit-inline-box !important;
  resize: none;
}

/******************** CAMPOS SELECT ********************/
.campo-select-filtro {
  /* CLASSE PADRAO PARA CAMPOS SELECT */
  height: 2.55em !important;
  width: 220px;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
}

.campo-select-filtro-2 {
  /* CLASSE PADRAO PARA CAMPOS SELECT */
  height: 2.4em !important;
  width: 220px;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
  margin-top: -4px;
  margin-left: 67px;
}

.campo-select-filtro-5 {
  /* CLASSE PADRAO PARA CAMPOS SELECT */
  height: 2.4em !important;
  width: 220px;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
  margin-top: -4px;
  margin-left: 106px;
}

.campo-select-filtro-6 {
  /* CLASSE PADRAO PARA CAMPOS SELECT */
  height: 2.4em !important;
  width: 220px;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
  margin-top: -5px;
  margin-left: 91px;
}

.campo-select {
  /* CLASSE PADRAO PARA CAMPOS SELECT */
  height: 2em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
}

.campo-select-material {
  /* CLASSE PADRAO PARA CAMPOS SELECT ESTILO MATERIAL */
  height: 3.2em !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
}

.campo-select-filtro-grupo {
  /* CLASSE PADRAO PARA CAMPOS SELECT */
  height: 2.4em !important;
  width: 67% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
  margin-left: 106px;
  margin-top: -4px;
}

.campo-select-filtro-modelo {
  /* CLASSE PADRAO PARA CAMPOS SELECT */
  height: 2.4em !important;
  width: 13.9em !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
  margin-left: 67px;
  margin-top: -4px;
}

.campo-select-filtro-marca {
  /* CLASSE PADRAO PARA CAMPOS SELECT */
  height: 2.4em !important;
  width: 67% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  justify-content: space-between !important;
  margin-left: 89px;
  margin-top: -4px;
}

.label-campo-select-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.campo-select-icone-desativado {
  /* CLASSE PARA ROTACIONAR O ICONE DE DROPDOWN QUANDO DESATIVADO */
  transform: rotate(-90deg) !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.campo-select-icone-desativado-filtro {
  /* CLASSE PARA ROTACIONAR O ICONE DE DROPDOWN QUANDO DESATIVADO */
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
  margin-right: -149px;
}

.campo-select-icone-desativado-filtro-b {
  /* CLASSE PARA ROTACIONAR O ICONE DE DROPDOWN QUANDO DESATIVADO */
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
  margin-top: 8px;
}

.campo-select-icone-ativado {
  /* CLASSE PARA POSICIONAR O ICONE DE DROPDOWN CORRETAMENTE QUANDO ATIVADO */
  margin-top: -0.02em !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.campo-select-icone-ativado-filtro {
  /* CLASSE PARA POSICIONAR O ICONE DE DROPDOWN CORRETAMENTE QUANDO ATIVADO */
  transform: rotate(-180deg) !important;
  margin-top: -0.02em !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
  margin-left: 146px;
}

.campo-select-icone-ativado-filtro-b {
  /* CLASSE PARA POSICIONAR O ICONE DE DROPDOWN CORRETAMENTE QUANDO ATIVADO */
  transform: rotate(-180deg) !important;
  margin-top: 8px;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.campo-select-icone-ativado-filtro-b-2 {
  transform: rotate(-180deg) !important;
  margin-top: 3px;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.campo-select-triangulo {
  /* CLASSE PADRAO PARA POSICIONAR O TRIANGULO QUE AJUDA A DAR FORMA AO CORPO DO SELECT */
  width: 0 !important;
  height: 0 !important;
  border-top: 0.9375em solid transparent !important;
  border-left: 0.9375em solid transparent !important;
  border-right: 0.9375em solid transparent !important;
  margin-left: 3em !important;
  margin-top: -0.9em !important;
}

.campo-select-triangulo-2 {
  /* CLASSE PADRAO PARA POSICIONAR O TRIANGULO QUE AJUDA A DAR FORMA AO CORPO DO SELECT */
  width: 0 !important;
  height: 0 !important;
  border-top: 0.9375em solid transparent !important;
  border-left: 0.9375em solid transparent !important;
  border-right: 0.9375em solid transparent !important;
  margin-left: 6em !important;
  margin-top: -0.9em !important;
}

.campo-select-triangulo-3 {
  /* CLASSE PADRAO PARA POSICIONAR O TRIANGULO QUE AJUDA A DAR FORMA AO CORPO DO SELECT */
  width: 0 !important;
  height: 0 !important;
  border-top: 0.9375em solid transparent !important;
  border-left: 0.9375em solid transparent !important;
  border-right: 0.9375em solid transparent !important;
  margin-left: 8em !important;
  margin-top: -0.9em !important;
}

.campo-select-triangulo-4 {
  /* CLASSE PADRAO PARA POSICIONAR O TRIANGULO QUE AJUDA A DAR FORMA AO CORPO DO SELECT */
  width: 0 !important;
  height: 0 !important;
  border-top: 0.9375em solid transparent !important;
  border-left: 0.9375em solid transparent !important;
  border-right: 0.9375em solid transparent !important;
  margin-left: 5em !important;
  margin-top: -0.9em !important;
}

.campo-select-corpo {
  /* CLASSE PADRAO PARA O CORPO DO SELECT */
  width: 100% !important;
  max-height: 10em !important;
  z-index: 500 !important;
  border-radius: 0.3125em !important;
  overflow-x: hidden !important;
}

.campo-select-estado-corpo {
  /* CLASSE PADRAO PARA O CORPO DO SELECT */
  width: 22% !important;
  max-height: 16em !important;
  z-index: 500 !important;
  border-radius: 0.3125em !important;
  overflow-x: hidden !important;
  position: absolute !important;
}

.campo-select-corpo-2 {
  /* CLASSE PADRAO PARA O CORPO DO SELECT */
  width: 100% !important;
  max-height: 8em !important;
  z-index: 500 !important;
  border-radius: 0.3125em !important;
  overflow-x: hidden !important;
  margin-left: 36px;
}

.campo-select-corpo-3 {
  /* CLASSE PADRAO PARA O CORPO DO SELECT */
  width: 100% !important;
  max-height: 7em !important;
  z-index: 500 !important;
  border-radius: 0.3125em !important;
  overflow-x: hidden !important;
  margin-left: 32px;
}

.select-modelo-simcard {
  height: 95px;
}

.select-modelo-simcard {
  height: 95px;
}

.campo-select-corpo-4 {
  /* CLASSE PADRAO PARA O CORPO DO SELECT */
  width: 79% !important;
  max-height: 10em !important;
  z-index: 500 !important;
  border-radius: 0.3125em !important;
  overflow-x: auto;
  margin-right: -32px;
  height: 83px;
  margin-left: 56px;
}

.campo-select-comeco {
  /* CLASSE PADRAO PARA O ITEM NAO CLICAVEL QUE INICIA O SELECT */
  margin-left: -2.5em !important;
  width: 150% !important;
  height: 1.875em !important;
  line-height: 1.75em !important;
}

.campo-select-opcao {
  /* CLASSE PADRAO PARA OS ITEMS DO SELECT */
  margin-left: -2.5em !important;
  width: 150% !important;
  height: 1.875em !important;
  line-height: 1.75em !important;
  cursor: pointer !important;
}

.campo-select--opcao-filtro {
  /* CLASSE PADRAO PARA OS ITEMS DO SELECT */
  margin-left: -2.5em !important;
  width: 150% !important;
  height: 1.875em !important;
  line-height: 1.75em !important;
  cursor: pointer !important;
}

.campo-select-opcao-b {
  /* CLASSE PADRAO PARA OS ITEMS DO SELECT */
  margin-left: -2.5em !important;
  width: 150% !important;
  height: 1.875em !important;
  line-height: 1.75em !important;
  cursor: pointer !important;
}

.campo-select-opcao-2 {
  /* CLASSE PADRAO PARA OS ITEMS DO SELECT */
  margin-left: -2.5em !important;
  width: 113% !important;
  height: 1.875em !important;
  line-height: 1.75em !important;
  cursor: pointer !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.campo-select-opcao-3 {
  width: 150% !important;
}

.campo-select-divisor {
  /* CLASSE PADRAO PARA AS LINHAS DIVISORIAS ENTRE OS ITEMS DO SELECT */
  height: 0.0625em !important;
  width: 100% !important;
  margin-left: -1.2em !important;
}

.campo-select-lista {
  /* CLASSE PADRAO PARA LIMPAR BULLETS DA LISTA DE ITEMS DOS SELECTS */
  list-style-type: none !important;
}

.campo-select-lista-filtro {
  /* CLASSE PADRAO PARA LIMPAR BULLETS DA LISTA DE ITEMS DOS SELECTS */
  list-style-type: none !important;
}

/******************** BOTOES ********************/
.botao-fechar-janela {
  /* CLASSE PADRAO PARA POSICIONAMENTO DO BOTAO DE FECHAR JANELA */
  background-color: transparent !important;
  border-color: transparent !important;
  float: right !important;
  padding: 0 !important;
  margin-top: 0.05em !important;
  box-shadow: none !important;
}

.botao-fechar-janela-alteracao-ciclo {
  /* CLASSE PADRAO PARA POSICIONAMENTO DO BOTAO DE FECHAR JANELA */
  background-color: transparent !important;
  border-color: transparent !important;
  float: right !important;
  padding: 0 !important;
  margin-top: -2.5em !important;
  box-shadow: none !important;
  position: absolute;
  margin-left: 887px;
}

.espaco-entre-linhas {
  height: 33px;
}

.botao-fechar-janela-icone {
  /* CLASSE PADRAO PARA DIMENSIONAR O ICONE DO BOTAO FECHAR */
  height: 1.5em !important;
  width: 2.1875em !important;
  margin-top: 4px;
}

.botao-fechar-janela-icone-alteracao-ciclo {
  /* CLASSE PADRAO PARA DIMENSIONAR O ICONE DO BOTAO FECHAR */
  height: 2.1875em !important;
  width: 2.1875em !important;
  margin-top: -5px;
}

.botao-fechar-janela-icone-barra-filtragem {
  /* FERRAR BARRA DE FILTRAGEM */
  height: 1.5em !important;
  width: 2.1875em !important;
  margin-top: 11px;
}

.botao-confirmar {
  /* CLASSE PADRAO PARA BOTOES DE CONFIRMACAO */
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2.375em !important;
  min-width: 6.875em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 0.625em !important;
  float: right !important;
}

.botao-confirmar-desabilitado {
  /* CLASSE PADRAO PARA BOTOES DE CONFIRMACAO DESABILITADO */
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2.375em !important;
  min-width: 6.875em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  border: 0.09375em solid #787b7a !important;
  background-color: #787b7a !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 0.625em !important;
  float: right !important;
}

.botao-confirmar-desabilitado-detalhamento-linha {
  /* CLASSE PADRAO PARA BOTOES DE CONFIRMACAO DESABILITADO */
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2.375em !important;
  min-width: 6.875em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  border: 0.09375em solid #787b7a !important;
  background-color: #787b7a !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 2.5em !important;
  float: right !important;
}

.botao-cancelar {
  /* CLASSE PADRAO PARA BOTOES DE CANCELAMENTO */
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2.375em !important;
  min-width: 6.875em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  border: 0.09375em solid #36d896 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 0.625em !important;
  float: right !important;
}

.botao-cancelar-desabilitado {
  /* CLASSE PADRAO PARA BOTOES DE CANCELAMENTO */
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2.375em !important;
  min-width: 6.875em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  border: 0.09375em solid #787b7a !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 0.625em !important;
  float: right !important;
}
.botao-expandir-row {
  height: 1.875em !important;
  width: 1.875em !important;
  border-radius: 3.125em !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.botao-expandir-row:active,
.botao-expandir-row:focus {
  box-shadow: none !important;
}

/******************** OVERLAY DAS MODAIS ********************/
.componente-modal-overlay {
  /* CLASSE PADRÃO PARA OVERLAY DE MODAIS */
  position: fixed !important;
  z-index: 1000 !important;
  top: 0% !important;
  left: 0% !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.componente-modal {
  position: absolute !important;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.componente-spinner-overlay {
  /* CLASSE PADRÃO PARA OVERLAY DE MODAIS */
  position: fixed !important;
  z-index: 1000 !important;
  top: 0% !important;
  left: 0% !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.componente-spinner-overlay-filtro-simcard {
  /* CLASSE PADRÃO PARA OVERLAY DE MODAIS */
  position: absolute !important;
  z-index: 1000 !important;
  top: -11% !important;
  left: -3% !important;
  height: 117% !important;
  width: 157% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.componente-spinner-overlay-abrir-filtro-simcard {
  /* CLASSE PADRÃO PARA OVERLAY DE MODAIS */
  position: absolute !important;
  z-index: 1000 !important;
  top: 10px;
  left: -212px;
  height: 99% !important;
  width: 1420px;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.componente-spinner-container {
  /* CLASSE PADRÃO PARA CENTRALIZAR MODAIS HORIZONTALMENTE*/
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

/******************** ICONES ********************/
.icone-label-janela {
  /* DIMENSIONA E POSICIONA CORRETAMENTE O ICONE DE FECHAR JANELA */
  height: 0.375em !important;
  width: 0.375em !important;
  margin-right: 0.625em !important;
  margin-bottom: 0.5em !important;
  border-radius: 6.25em !important;
  background-color: #36d896 !important;
}

.icone-label-janela-alteracao-ciclo {
  /* DIMENSIONA E POSICIONA CORRETAMENTE O ICONE DE FECHAR JANELA */
  height: 0.375em !important;
  width: 0.375em !important;
  margin-right: 0.625em !important;
  margin-bottom: 0.5em !important;
  border-radius: 6.25em !important;
  background-color: #36d896 !important;
  position: absolute;
  margin-top: 11px;
}

.icone-alerta {
  /* DA COR E DIMENSIONA CORRETAMENTE O ICONE DE ALERTA */
  color: #cd2d37 !important;
  width: 1em !important;
  margin-top: -0.1125em !important;
}

.mensagem-icone {
  /* CLASSE PADRAO PARA  DAS MENSAGENS*/
  height: 3.125em !important;
  width: 3.125em !important;
  margin-top: 1.5625em !important;
  margin-bottom: 1.25em !important;
  left: 50% !important;
}

.icone-botao-expandir-row {
  /* POSICIONA CORRETAMENTE O ICONE DE EXPANDIR ROW */
  margin-top: -0.4375em !important;
  margin-left: -0.25em !important;
}

.campo-calendario img {
  /* POSICIONA CORRETAMENTE O ICONE DO CALENDARIO */
  float: right !important;
}

.icone-calendario {
  /* DIMENSIONA CORRETAMENTE O ICONE DO CALENDARIO */
  height: 1em !important;
  width: 1em !important;
}

/******************** MENSAGENS ********************/
.mensagem-overlay {
  /* CLASSE PADRAO PARA OVERLAY DAS MENSAGENS*/
  position: fixed !important;
  z-index: 1000 !important;
  top: 0% !important;
  left: 0% !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.mensagem-container {
  /* CLASSE PADRAO PARA CONTAINER DAS MENSAGENS*/
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.mensagem-corpo {
  /* CLASSE PADRAO PARA CORPO DAS MENSAGENS*/
  white-space: normal !important;
  text-align: center !important;
  width: 26.5625em !important;
  border-radius: 0.3125em !important;
  padding: 0em 1.5625em 1.5625em 1.5625em !important;
}

.mensagem-container-icone {
  /* CLASSE PADRAO PARA  DAS MENSAGENS*/
  width: 100% !important;
}

.mensagem-container-titulo {
  /* CLASSE PADRAO PARA  DAS MENSAGENS*/
  margin-bottom: 1.25em !important;
}

.mensagem-container-botao {
  /* CLASSE PADRAO PARA  DAS MENSAGENS*/
  margin-top: 1.25em !important;
}

.mensagem-botao-confirmar {
  /* CLASSE PADRAO PARA BOTOES DE CONFIRMACAO DAS MENSAGENS*/
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2.375em !important;
  min-width: 6.875em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  /* border: 0.09375em solid #36D896!important; */
  /* background-color: #36D896!important; */
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 0.625em !important;
}

.mensagem-botao-cancelar {
  /* CLASSE PADRAO PARA BOTOES DE CANCELAMENTO DAS MENSAGENS */
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2.375em !important;
  min-width: 6.875em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  /* border: 0.09375em solid #36D896!important; */
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 0.625em !important;
}

.mensagem-botao-cancelar:disabled {
  border: 0.09375em solid #787b7a !important;
}

.mensagem-container-lista {
  /* POSICIONA CORRETAMENTE A LISTA DA MENSAGEM*/
  position: relative !important;
}

.mensagem-campo-select-opcao {
  /* CLASSE PADRAO PARA OS ITEMS DO SELECT DA MENSAGEM*/
  margin-left: -32% !important;
  width: 150% !important;
  height: 1.875em !important;
  line-height: 1.75em !important;
  cursor: pointer !important;
  text-align: center !important;
}
/******************** TABELAS ********************/
table {
  /* REMOVE ESPACAMENTO FORCADO ENTRE ROWS DAS TABELAS */
  border: 0 !important;
  border-collapse: separate !important;
  border-spacing: 0 0 !important;
}

td {
  /* REMOVE A BORDA BRANCA DO CORPO DAS TABELAS */
  border: 0 !important;
}

th {
  /* REMOVE A BORDA BRANCA DO CABECALHO DAS TABELAS */
  border: 0 !important;
}

.react-bootstrap-table thead tr th:first-child {
  /* CLASSE PADRAO PARA ARREDONDAR CANTO SUPERIOR ESQUERDO DAS TABELAS */
  border-top-left-radius: 0.4375em !important;
}

.react-bootstrap-table thead tr th:last-child {
  /* CLASSE PADRAO PARA ARREDONDAR CANTO SUPERIOR DIREITO DAS TABELAS */
  border-top-right-radius: 0.4375em !important;
}

.react-bootstrap-table tr:last-child td:first-child {
  /* CLASSE PADRAO PARA ARREDONDAR CANTO INFERIOR ESQUERDO DAS TABELAS */
  border-bottom-left-radius: 0.4375em !important;
  border-style: solid !important;
}

.react-bootstrap-table tr:last-child td:last-child {
  /* CLASSE PADRAO PARA ARREDONDAR CANTO INFERIOR DIREITO DAS TABELAS */
  border-bottom-right-radius: 0.4375em !important;
  border-style: solid !important;
}

.tabela-coluna-primeira-header {
  /* CLASSE PADRAO PARA A PRIMEIRA COLUNA DAS TABELAS */
  margin-left: 0.625em !important;
}

.tabela-coluna-ultima-header .btn-toolbar {
  /* CLASSE PADRAO PARA A ULTIMA COLUNA DAS TABELAS */
  display: flex !important;
  justify-content: center !important;
}

.table {
  /* CLASSE PADRAO PARA FORMATAR AS DIMENSOES DAS TABELAS E ESPACAMENTO ENTRE ROWS */
  border-collapse: collapse !important;
  width: 99% !important;
  margin-bottom: 0em !important;
}

.react-bootstrap-table tr {
  /* REMOVE O ESPACO EM BRANCO QUE CERCA AS TABELAS */
  white-space: nowrap !important;
}

.react-bootstrap-table td {
  /* CLASS PADRAO PARA FORMATAR O ESPACO INTERNO DAS ROWS DAS TABELAS */
  padding-top: 0.125em !important;
  padding-right: 1.625em !important;
  padding-left: 1.625em !important;
  /* padding-right: 0.625em!important; SE DEIXAR ESSE PADDING MUDA TODA O ALINHAMENTO DOS DETALHAMENTOS DAS LINHAS*/
  vertical-align: middle !important;
  text-align: initial !important;
}

.cores-tooltip .tooltip-inner {
  margin-top: -12px !important;
}

.barra-dados-tooltip {
  margin-top: -1.4em !important;
  white-space: nowrap !important;
}

.tooltip-inner {
  color: #ffffff !important;
  background-color: #5b605d !important;
}

/* MUDA A COR DO TRIANGULO DA TOOLTIP  */
.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #5b605d !important;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #5b605d !important;
}

.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #5b605d !important;
}

.react-bootstrap-table td.expand-cell {
  /* CLASSE PADRAO PARA ALINHAR A COLUNA DE EXPANSAO DE ROWS */
  padding-top: 0.125em !important;
  padding-left: 0em !important;
  padding-right: 0em !important;
}

.react-bootstrap-table th {
  /* CLASSE PADRAO PARA FORMATAR O ESPACO INTERNO DOS CABECALHOS DAS TABELAS */
  vertical-align: inherit !important;
  padding-top: 0.125em !important;
  padding-bottom: 0.125em !important;
  padding-right: 1.6375em !important;
  padding-left: 1.625em !important;
  margin-left: 0.625em !important;
  white-space: nowrap !important;
  outline: none !important;
}

.react-bootstrap-table-pagination {
  /* CLASSE PADRAO PARA FORMATAR O PADDING DOS BOTOES DE PAGINACAO DAS TABELAS */
  padding-top: 0.3125em !important;
}

.react-bootstrap-table-pagination-list {
  /* CLASSE PADRAO PARA ORGANIZAR OS BOTOES DE PAGINACAO DAS TABELAS */
  float: right !important;
}

.barra-progresso-container {
  /* CLASSE PADRAO DO CONTAINER QUE DA FORMA AS BARRAS DE PROGRESSO DAS TABELAS */
  position: relative !important;
  text-align: center !important;
  height: 1.125em !important;
  width: 6.25em !important;
  border-radius: 3.125em 3.125em 3.125em 3.125em !important;
}

.barra-progresso {
  /* CLASSE DE COR PADRAO DAS BARRAS DE PROGRESSO DAS TABELAS - TALVEZ SEJA REMOVIDO */
  background-color: #36d896 !important;
}

.container-tabela-modal {
  /* CLASSE PADRAO PARA LIMITAR O TAMANHO DAS TABELAS QUE APARECEM DENTRO DE MODAIS */
  overflow: auto !important;
  max-height: 10.75em !important;
}

.container-tabela-modal-faturas {
  overflow: auto !important;
  max-height: 38vh !important;
}

.container-tabela-historico-acesso {
  overflow: auto !important;
  max-height: 22.75em !important;
}

.expand-cell-header {
  /* CLASSE PADRAO PARA DESABILITAR A EXPANSAO DE TODAS AS ROWS CLICANDO NO CABECALHO DA COLUNA DE EXPANSAO */
  pointer-events: none !important;
}

/******************** SWITCHES ********************/
.flipswitch {
  /* CLASSE PADRAO DO SWITCH */
  margin-right: 0.625em !important;
  margin-top: 0.3125em !important;
  position: relative !important;
  width: 3.5em !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  float: left !important;
}

.flipswitch input[type='checkbox'] {
  /* CLASSE PADRAO DE FUNCIONAMENTO DO SWITCH */
  display: none !important;
}

.flipswitch-label {
  /* CLASSE PADRAO DA FORMA DO SWITCH */
  display: block !important;
  overflow: hidden !important;
  cursor: pointer !important;
  border-radius: 3.125em !important;
}

.flipswitch-inner {
  /* CLASSE PADRAO DA ANIMACAO DO TRILHO DO SWITCH */
  width: 8em !important;
  margin-left: -0em !important;
  -webkit-transition: margin 0.3s ease-in 0s !important;
  -moz-transition: margin 0.3s ease-in 0s !important;
  -ms-transition: margin 0.3s ease-in 0s !important;
  -o-transition: margin 0.3s ease-in 0s !important;
  transition: margin 0.3s ease-in 0s !important;
}

.flipswitch-inner:before,
.flipswitch-inner:after {
  /* CLASSE PADRAO PARA POSICIONAMENTO DO BOTAO DO SWITCH */
  float: left !important;
  width: 50% !important;
  height: 1.58em !important;
  padding: 0 !important;
  line-height: 1.9375em !important;
  font-size: 1.125em !important;
  color: white !important;
  font-family: Trebuchet, Arial, sans-serif !important;
  font-weight: bold !important;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.flipswitch-cmp {
  /* CLASSE PADRAO DA ANIMACAO DO TRILHO DO SWITCH */
  width: 8em !important;
  margin-left: -0em !important;
  -webkit-transition: margin 0.3s ease-in 0s !important;
  -moz-transition: margin 0.3s ease-in 0s !important;
  -ms-transition: margin 0.3s ease-in 0s !important;
  -o-transition: margin 0.3s ease-in 0s !important;
  transition: margin 0.3s ease-in 0s !important;
}

.flipswitch-cmp-habilitado {
  /* CLASSE PADRAO DA ANIMACAO DO TRILHO DO SWITCH */
  width: 8em !important;
  margin-left: -0em !important;
  -webkit-transition: margin 0.3s ease-in 0s !important;
  -moz-transition: margin 0.3s ease-in 0s !important;
  -ms-transition: margin 0.3s ease-in 0s !important;
  -o-transition: margin 0.3s ease-in 0s !important;
  transition: margin 0.3s ease-in 0s !important;
}

.flipswitch-cmp-habilitado:before,
.flipswitch-cmp-habilitado:after {
  /* CLASSE PADRAO PARA POSICIONAMENTO DO BOTAO DO SWITCH */
  float: left !important;
  width: 34% !important;
  height: 1em !important;
  padding: -4 !important;
  line-height: 1.9375em !important;
  font-size: 1.125em !important;
  color: green !important;
  font-family: Trebuchet, Arial, sans-serif !important;
  font-weight: bold !important;
  box-sizing: border-box !important;
  margin-top: 5px;
  margin-left: 6px;
  border-radius: 2.25rem;
}

.flipswitch-cmp:before,
.flipswitch-cmp:after {
  /* CLASSE PADRAO PARA POSICIONAMENTO DO BOTAO DO SWITCH */
  float: left !important;
  width: 34% !important;
  height: 1em !important;
  padding: -4 !important;
  line-height: 1.9375em !important;
  font-size: 1.125em !important;
  /* color: green!important; */
  font-family: Trebuchet, Arial, sans-serif !important;
  font-weight: bold !important;
  box-sizing: border-box !important;
  margin-top: 6px;
  margin-left: 6px;
  border-radius: 2.25rem;
}

.flipswitch-cmp-habilitado:before,
.flipswitch-cmp-habilitado:after {
  /* CLASSE PADRAO PARA POSICIONAMENTO DO BOTAO DO SWITCH */
  float: left !important;
  width: 34% !important;
  height: 1em !important;
  padding: -4 !important;
  line-height: 1.9375em !important;
  font-size: 1.125em !important;
  color: green !important;
  font-family: Trebuchet, Arial, sans-serif !important;
  font-weight: bold !important;
  box-sizing: border-box !important;
  margin-top: 5px;
  margin-left: 6px;
  border-radius: 2.25rem;
}

.flipswitch-inner:before {
  /* CLASSE PADRAO PARA ESCONDER O CONTEUDO DE FUNCIONAMENTO DO SWITCH QUANDO DESATIVADO */
  content: '' !important;
}

.flipswitch-cmp:before {
  /* CLASSE PADRAO PARA ESCONDER O CONTEUDO DE FUNCIONAMENTO DO SWITCH QUANDO DESATIVADO */
  content: '' !important;
}

.flipswitch-cmp-habilitado:before {
  /* CLASSE PADRAO PARA ESCONDER O CONTEUDO DE FUNCIONAMENTO DO SWITCH QUANDO DESATIVADO */
  content: '' !important;
}

.flipswitch-inner:after {
  /* CLASSE PADRAO PARA ESCONDER O CONTEUDO DE FUNCIONAMENTO DO SWITCH QUANDO ATIVADO */
  content: '' !important;
}

.flipswitch-cmp:after {
  /* CLASSE PADRAO PARA ESCONDER O CONTEUDO DE FUNCIONAMENTO DO SWITCH QUANDO ATIVADO */
  content: '' !important;
}

.flipswitch-cmp-habilitado:after {
  /* CLASSE PADRAO PARA ESCONDER O CONTEUDO DE FUNCIONAMENTO DO SWITCH QUANDO ATIVADO */
  content: '' !important;
}

.flipswitch-switch {
  /* CLASSE PADRAO DA ESFERDA DO SWITCH */
  width: 1.35em !important;
  height: 1.35em !important;
  margin-top: 0.21875em !important;
  border-radius: 3.125em !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 1.9em !important;
  -webkit-transition: all 0.3s ease-in 0s !important;
  -moz-transition: all 0.3s ease-in 0s !important;
  -ms-transition: all 0.3s ease-in 0s !important;
  -o-transition: all 0.3s ease-in 0s !important;
  transition: all 0.3s ease-in 0s !important;
}

.flipswitch-cb:checked + .flipswitch-label .flipswitch-inner {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  margin-left: 0 !important;
}

.flipswitch-cmp:checked + .flipswitch-label .flipswitch-inner {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  margin-left: 0 !important;
}

.flipswitch-cmp-habilitado:checked + .flipswitch-label .flipswitch-inner {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  margin-left: 0 !important;
}

.flipswitch-cb:checked + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  right: 0.25em !important;
}

.flipswitch-cmp:checked + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  right: 0.25em !important;
}

.flipswitch-cmp-habilitado:checked + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  right: 0.25em !important;
}

.flipswitch-cb + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH DESATIVADO*/
  background-color: #cd2d37 !important;
}

.flipswitch-cmp + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH DESATIVADO*/
  background-color: #fafafa !important;
}

.flipswitch-cmp-habilitado + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH DESATIVADO*/
  background-color: #fafafa !important;
}

.flipswitch-cb:checked + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH ATIVADO*/
  /* background-color: #36D896!important; */
}

.flipswitch-cmp:checked + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH ATIVADO*/
  /* background-color: #36D896!important; */
}

.flipswitch-cmp-habilitado:checked + .flipswitch-label .flipswitch-switch {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH ATIVADO*/
  background-color: #36d896 !important;
}

.flipswitch-medium {
  /* CLASSE PADRAO DO SWITCH */
  margin-right: 0.625em !important;
  margin-top: 0.3125em !important;
  position: relative !important;
  width: 3em !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  float: left !important;
}

.flipswitch-medium input[type='checkbox'] {
  /* CLASSE PADRAO DE FUNCIONAMENTO DO SWITCH */
  display: none !important;
}

.flipswitch-medium-label {
  /* CLASSE PADRAO DA FORMA DO SWITCH */
  display: block !important;
  overflow: hidden !important;
  cursor: pointer !important;
  border-radius: 3.125em !important;
}

.flipswitch-medium-inner {
  /* CLASSE PADRAO DA ANIMACAO DO TRILHO DO SWITCH */
  width: 200% !important;
  margin-left: -100% !important;
  -webkit-transition: margin 0.3s ease-in 0s !important;
  -moz-transition: margin 0.3s ease-in 0s !important;
  -ms-transition: margin 0.3s ease-in 0s !important;
  -o-transition: margin 0.3s ease-in 0s !important;
  transition: margin 0.3s ease-in 0s !important;
}

.flipswitch-medium-inner:before,
.flipswitch-medium-inner:after {
  /* CLASSE PADRAO PARA POSICIONAMENTO DO BOTAO DO SWITCH */
  float: left !important;
  width: 50% !important;
  height: 1.5em !important;
  padding: 0 !important;
  line-height: 1.9375em !important;
  font-size: 1.125em !important;
  color: white !important;
  font-family: Trebuchet, Arial, sans-serif !important;
  font-weight: bold !important;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.flipswitch-medium-inner:before {
  /* CLASSE PADRAO PARA ESCONDER O CONTEUDO DE FUNCIONAMENTO DO SWITCH QUANDO DESATIVADO */
  content: '' !important;
  padding-left: 0.8125em !important;
  color: #ffffff !important;
}

.flipswitch-medium-inner:after {
  /* CLASSE PADRAO PARA ESCONDER O CONTEUDO DE FUNCIONAMENTO DO SWITCH QUANDO ATIVADO */
  content: '' !important;
  padding-right: 0.8125em !important;
  color: #888888 !important;
  text-align: right !important;
}

.flipswitch-medium-switch {
  /* CLASSE PADRAO DA ESFERDA DO SWITCH */
  width: 1.2em !important;
  height: 1.2em !important;
  margin: 0.21875em !important;
  background: #ffffff !important;
  border-radius: 3.125em !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 1.3125em !important;
  -webkit-transition: all 0.3s ease-in 0s !important;
  -moz-transition: all 0.3s ease-in 0s !important;
  -ms-transition: all 0.3s ease-in 0s !important;
  -o-transition: all 0.3s ease-in 0s !important;
  transition: all 0.3s ease-in 0s !important;
}

.flipswitch-medium-cb:checked
  + .flipswitch-medium-label
  .flipswitch-medium-inner {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  margin-left: 0 !important;
}

.flipswitch-medium-cb:checked
  + .flipswitch-medium-label
  .flipswitch-medium-switch {
  /* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
  right: 0 !important;
}

.flipswitch-medium-cb + .flipswitch-medium-label .flipswitch-medium-switch {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH DESATIVADO*/
  background-color: #c23434ed !important;
}

.flipswitch-medium-cb:checked
  + .flipswitch-medium-label
  .flipswitch-medium-switch {
  /* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH ATIVADO*/
  /* background-color: #36D896!important; */
}

/******************** BARRAS DE RANGE ********************/
.custom-range {
  /* CLASSE PADRAO DAS BARRAS DE RANGE */
  height: 1.3125em !important;
  margin-top: 0.125em !important;
  border-radius: 3.125em 3.125em 3.125em 3.125em !important;
  width: 100% !important;
  position: relative !important;
  /* background-color: #36D896!important; */
}

/******************** CALENDARIO ********************/
.campo-calendario {
  /* DA FORMA E ORGANIZA O BOTAO DO CALENDARIO ATIVADO*/
  height: 2em !important;
  width: 100% !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  border-radius: 3.125em !important;
  box-shadow: none !important;
  text-align: left !important;
  display: inline-flexbox !important;
}

.campo-calendario:disabled {
  /* DA FORMA E ORGANIZA O BOTAO DO CALENDARIO DESATIVADO */
  height: 2em !important;
  width: 100% !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  border-radius: 3.125em !important;
  box-shadow: none !important;
  text-align: left !important;
  display: inline-flexbox !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--today {
  /* ARREDONDA O DIA SELECIONADO */
  border-radius: 50% !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.react-datepicker__header {
  /* REMOVE LINHA DIVISORIA ENTRE DIAS DA SEMANA E DATAS */
  border: 0em !important;
}

.react-datepicker {
  /* REMOVE BORDA PRETA AO REDOR DA MODAL */
  border: 0em !important;
}

.react-datepicker-time__input {
  /* DA FORMA E POSICIONA CORRETAMENTE O CAMPO DE HORARIO */
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  height: 2em !important;
  border: 0em !important;
  border-radius: 6.25em !important;
  box-shadow: none !important;
  text-align: center !important;
}

.react-datepicker__navigation--next {
  /* POSICIONA CORRETAMENTE A SETA DE MES SEGUINTE */
  border-left-color: transparent !important;
  right: 0em !important;
  margin-top: 0.1875em !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.react-datepicker__navigation--previous {
  /* POSICIONA CORRETAMENTE A SETA DE MES ANTERIOR */
  border-right-color: transparent !important;
  left: 0.75em !important;
  margin-top: 0.1875em !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.react-datepicker__navigation {
  /* DIMENSIONA CORRETAMENTE AS SETAS DE MES ANTERIOR E SEGUINTE */
  width: 1.1875em !important;
  height: 1.3125em !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.react-datepicker-wrapper {
  /* DIMENSIONA CORRETAMENTE O CAMPO DO CALENDARIO */
  width: 100% !important;
}

/* TABELA TRANSPARENTE MODAIS  */

.tabela-transparente-modais th {
  padding: 0em !important;
  font-size: 1rem !important;
}
.tabela-transparente-modais td {
  padding: 0.4em 1em 0.4em 0em !important;
}
.tabela-transparente-modais {
  margin-left: 0.9375em !important;
}


/******************** DESTINO INCERTO ********************/
/* .progress span { PERTENCE A CATEGORIA TABELA, POREM PARECE NAO SURTIR EFEITO ATE DADO MOMENTO.
    font-family: RobotoRegular!important;
    line-height: 1.125em!important;
    position: absolute!important; 
    text-align: center!important;
    width: 100%!important;
} */

/*NOVO PEDIDO*/
.label-wrapper-novo-pedido {
  margin-top: 20px;
  font-size: 24px;
}

.lote-row-novo-pedido {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.label-row-novo-pedido {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: 20px;
  width: 100%;
}

.label-title-novo-pedido {
  margin-left: 16px;
}

.input-row-novo-pedido-wrapper {
  max-height: 25vh !important;
  overflow-y: auto;
}

.input-row-novo-pedido {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */

  gap: 10px;

  margin-top: 20px;
  font-size: 20px;
  width: 100%;
}
.input-row-novo-pedido-newlote {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  gap: 10px;

  margin-top: 20px;
  font-size: 20px;
  width: 100%;
}

.new-lote-row {
  margin-right: 10px !important;
}

.input-row-delete {
  cursor: pointer;
}

.input-row-delete:hover {
  cursor: pointer;
}

.resumo-pedido-linha-disclaimer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  margin-bottom: 12px;
}

.button-novo-lote-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.button-novo-lote {
  border-radius: 24px;
  height: 40px;
  width: 120px;
  margin-bottom: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #ffffff;
  color: #243b53;

  cursor: pointer;

  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14);

  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.button-novo-lote:hover {
  background-color: #f2f2f2;
}

.button-novo-lote-dark {
  border-radius: 24px;
  height: 40px;
  width: 120px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #00d994;
  color: #000000;

  cursor: pointer;

  margin-bottom: 8px;

  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14);

  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
}

button-novo-lote-dark:hover {
  background-color: #24966c;
}

.campo-texto-novo {
  /* CLASSE PADRAO PARA CAMPOS DE INPUT */
  height: 47px !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: -webkit-inline-box !important;
}

.campo-select-novo {
  /* CLASSE PADRAO PARA CAMPOS SELECT */
  height: 47px !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between !important;
}

.campo-select-novo-motivo {
  /* CLASSE PADRAO PARA CAMPOS SELECT */
  height: 47px !important;
  width: 20% !important;
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
  border: 0em !important;
  box-shadow: none !important;
  border-radius: 5em !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between !important;
}

.label-novo-pedido-endereco {
  font-size: 20px !important;
}
.margin-top-divider {
  margin-top: 24px !important;
}

.modal-novo-pedido-header {
  display: flex;
  flex-direction: column;
}

.header-novo-pedido-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.header-title-left-block {
  padding-top: 12px;
}

.modal-novo-pedido-content {
  display: flex;
  flex-direction: column;
  flex: 3;
}
.modal-footer-novo-pedido {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
