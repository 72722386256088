.calendar-container {
  background-color: transparent;
  border-radius: 5px;
}

.calendar-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 6px;
}

.calendar-header > span {
  margin: 0px 35px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  width: 189px;
  height: 132px;
  padding: 6px 16px 6px 16px;
  gap: 8px !important;
}

.calendar-month {
  text-align: center;
  cursor: pointer;
  border-radius: 5px !important;

  width: 49px;
  height: 24px;
  gap: 10px;
  border-radius: 3px 0px 0px 0px;
}

.calendar-month.disabled {
  cursor: not-allowed;
}

.seta-esquerda-opcoes {
  width: 20px;
  height: 20px;
}

.seta-direita-opcoes {
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
}
