.img-eclipse-status-usuario{
    width: 0.75em;
    height: 0.75em;
    margin-right: 0.3em;
}

.modal-novo-cliente-usuario{
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    min-width: 106.625em!important;
}

.btn-alterar-cadastro-usuario-cliente.col {
    margin-top: 0.5em!important;
    max-width: 5.6em!important;
    padding-top: 1.2em!important;
}

.switch-group-cadastro-usuario-cliente{
    display: grid;
    padding-top: 0.5em;
    margin-left: 1.6em;
}

.imput-cadastro-usuario-cliente.col{
    padding-left: 0.5em!important;
    padding-right: 0.5em!important;
}

.imput-status-cadastro-usuario-cliente{
    max-width: 10.9em!important;
}

.divisoria-cadastro-usuario-cliente {
    height: 0.0625em!important;
    width: 91%!important;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    /* margin-left: -0.6em; */
}

.modulos-cadastro-usuario-cliente{
    max-width: 17%!important;    
    border-radius: 0 0 5px 5px;
}

.modulo-permissao-cadastro-usuario-cliente-ativo{
    display: flex;
    height: 4em;
    cursor: pointer;
}
.modulo-permissao-cadastro-usuario-cliente-desativo{
    display: flex;
    height: 4em;
    cursor: pointer;
}

.modulo-permissao-cadastro-usuario-cliente-mock{
    height: 34em !important;
    overflow: auto !important;
    cursor: pointer;
}

.label-permissoes-cadastro-usuario-cliente{
    margin-top: 1em;
    margin-bottom: 1.5em;
    margin-left: 0.7%;
    cursor: pointer;
}

.permissoes-cadastro-usuario-cliente{
    margin-left: 0.4%!important;
}

.col-permissoes-cadastro-usuario-cliente.col{
    margin-left: 1.2%!important;
    margin-right: 2.3%!important;
    border-radius: 0.3125em;
    max-height: 34em;
    overflow: hidden;
}
.elementos-permissoes-cadastro-usuario-cliente{
    background-color: transparent!important;
    display: flex;
    margin-left: 3%;
    text-align: center;
}

.tab-elementos-permissoes-cadastro-usuario-cliente-ativado{
    /* border-bottom: 0.2em solid #36D896; */
    padding-left: 0px!important;
    padding-right: 0px!important;
    margin-left: 5%;
    width: 20%;
}

.tab-elementos-permissoes-cadastro-usuario-cliente-desativado{
    padding-left: 0px!important;
    padding-right: 0px!important;
    margin-left: 5%;
    width: 20%;
}

.campo-divisor{
    height: 0.0625em!important;
    width: 100%!important;
}

.container-tab-elementos-permissoes-cadastro-usuario-cliente{
    padding-top: 5%;
    padding-left: 2%;
    display: flex!important;
    flex-direction: column!important;
    flex-wrap: wrap!important;
    height: 29em;
}

.conteudo-tab-elementos-permissoes-cadastro-usuario-cliente-menu{
    white-space: nowrap;
    width: 25%!important;
    padding-top: 0.75em!important;
}

.conteudo-tab-elementos-permissoes-cadastro-usuario-cliente{
    white-space: nowrap;
    width: 25%!important;
}

.switch-tab-elementos-permissoes-cadastro-usuario-cliente{
    position: fixed;
    display: flex;
    align-items: center;
    margin-left: 1.6em;
}

.imagem-conteudo-tab-elementos-permissoes-cadastro-usuario-cliente{
    width: 1.25em;
    height: 1.25em;
}

.col-confirmar-cancelar-cadastro-usuario-cliente.col{
    right: 2.2%;
    margin-top: 2em!important;
}

@media (max-width: 1670px) and (max-height: 940px){
    .modulo-permissao-cadastro-usuario-cliente-ativo{
        display: flex;
        height: 3.5em;
    }
    .modulo-permissao-cadastro-usuario-cliente-desativo{
        display: flex;
        height: 3.5em;
    }
    .modal-novo-cliente-usuario{
        min-width: 81.625em!important;
    }
    .modulos-cadastro-usuario-cliente {
        max-width: 20%!important;
    }
    .container-tab-elementos-permissoes-cadastro-usuario-cliente{
        height: 25em;
    }
}

.label-modulo-permissao-cadastro-usuario-cliente{
    display: flex;
    align-items: center;
}