.modal-cadastro-mensagem{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 50.625em!important;
}

.modal-envios-central-mensagens{
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 70em!important;
}

.grupo-botoes-nova-mensagem-central-mensagens{
    width: 100%!important;
    display: flex!important;
}

.coluna-botoes-nova-mensagem-central-mensagens{
    width: 50%!important;
}

.grupo-nova-mensagem-central-mensagens{
    width: 100%!important;
    display: flex!important;
}

.coluna-nova-mensagem-central-mensagens-1{
    width: 30.7%!important;
}

.coluna-nova-mensagem-central-mensagens-2{
    width: 35.2%!important;
    display: flex!important;
    margin-left: 34%!important;
}

.coluna-nova-mensagem-central-mensagens-2-0{
    display: flex!important;
    width: 100%!important;
}

.grupo-pesquisa-nova-mensagem-central-mensagens{
    display: flex!important;
}

.coluna-nova-mensagem-central-mensagens-2-1{
    width: 12%!important;
}

.coluna-nova-mensagem-central-mensagens-2-2{
    width: 88%!important;
}

.coluna-nova-mensagem-central-mensagens-3{
    width: 15%!important;
    padding-top: 1.8em;
    margin-left: 2%!important;
}

.coluna-nova-mensagem-central-mensagens-4{
    width: 30.7%!important;
    margin-left: 38.5%!important;
}

.coluna-nova-mensagem-central-mensagens-5{
    width: 20%!important;
    margin-left: 5px;
}

.coluna-nova-mensagem-central-mensagens-6{
    width: 40.9%!important;
    margin-left: 8%!important;
}

.coluna-nova-mensagem-central-mensagens-7{
    width: 4.5%!important;
    padding-top: 1.7em;
    margin-left: 2%!important;
}

.coluna-nova-mensagem-central-mensagens-8{
    margin-left: 8%!important;
    width: 16.5%!important;
    padding-top: 1.7em;
}

.coluna-nova-mensagem-central-mensagens-9{
    width: 69.3%!important;
    display: flex!important;
}

.coluna-nova-mensagem-central-mensagens-10{
    margin-left: 9%!important;
    width: 48.2%!important;
}

.coluna-nova-mensagem-central-mensagens-11{
    margin-left: 12.4%!important;
    width: 27%!important;
}

.coluna-switches-nova-mensagem-central-mensagens-1{
    width: 32%!important;
}

.coluna-switches-nova-mensagem-central-mensagens-2{
    margin-top: -1.8em;
    width: 32%!important;
}

.coluna-switches-nova-mensagem-central-mensagens-3{
    margin-top: -1.8em;
    width: 32%!important;
}

.coluna-switches-nova-mensagem-central-mensagens-4{
    width: 26%!important;
    margin-top: 1.6em!important;
    display: flex!important;
}

.coluna-nova-mensagem-central-mensagens-12{
    width: 50%!important;
}

.coluna-nova-mensagem-central-mensagens-13{
    margin-left: 25%!important;
    width: 25%!important;
}

.container-tabela-nova-mensagem-central-mensagens{
    /* background-color: gray; */
    max-height: 20em!important;
    overflow: hidden!important;
    overflow-y: auto!important;
    overflow-x: auto!important;
}

.label-checkbox-nova-mensagem-central-mensagens{
    line-height: 1.7em!important;
}

.botao-nova-mensagem-central-mensagens-pesquisar{
    float: left!important;
    height: 1.8em!important;
    width: 2.1875em!important;
    /* margin-top: 0.0625em!important; */
    border-color: transparent!important;
    border-radius: 3.125em 0em 0em 3.125em!important;
    outline: none!important;
    box-shadow: none!important;
}

.icone-nova-mensagem-central-mensagens-pesquisar{
    margin-top: -0.75em!important;
    margin-left: -0.1875em!important;
}

.campo-pesquisa-nova-mensagem-central-mensagens{
    height: 2em!important;
    width: 100%!important;
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
    line-height: 1em!important;
    border: 0em!important;
    box-shadow: none!important;
    border-radius: 0em 5em 5em 0em!important;
    display: -webkit-inline-box!important;
}

.botao-tab-nova-mensagen-central-mensagens{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    margin-top: 1em!important;
    margin-bottom: -1em!important;
    height: 3em!important;
    font-weight: 700!important;
    border-radius: 0!important;
    background-color: transparent!important;
    border: none!important;
    box-shadow: none!important;
}

.botao-importar-nova-mensagem-central-mensagens{
    height: 2em!important;
    width: 7.75em!important;
    border-radius: 50px!important;
    background-color: #36D896!important;
    box-shadow: none!important;
    border: none!important;
  }

.label-importar-nova-mensagem-central-mensagens{
    position: absolute!important;
    font-family: Roboto!important;
    font-size: 1em!important;
    font-weight: 700!important;
    margin-top: -0.75em!important;
    margin-left: -3em!important;
}

.botao-importar-icone{
    position: absolute!important;
    margin-top: -0.5em!important;
    margin-left: 1.5em!important;
}

.botao-tab-nova-mensagen-central-mensagens-ativado{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    margin-top: 1em!important;
    margin-bottom: -1em!important;
    height: 3em!important;
    font-weight: 700!important;
    border-radius: 0!important;
    background-color: transparent!important;
    border: none!important;
    box-shadow: none!important;
    border-bottom: 0.1875em #36D896 solid !important;
}

.grupo-cadastro-mensagem-1{
    width: 100%!important;
    display: flex!important;
}

.coluna-cadastro-mensagem-1{
    width: 48.5%!important;
    margin-right: 3%!important;
    margin-top: -1.5em!important;
}

.coluna-cadastro-mensagem-2{
    width: 48.5%!important;
    margin-right: 3%!important;
    margin-top: -1.5em!important;
}

.coluna-cadastro-mensagem-3{
    width: 48.5%!important;
    margin-top: -1.5em!important;
}

.coluna-cadastro-mensagem-4{
    width: 48.5%!important;
    margin-right: 3%!important;
}

.coluna-cadastro-mensagem-5{
    width: 48.5%!important;
}

.label-switch-cadastro-mensagem{
    line-height: 2.875em!important;
}

.formatacao-espaco-cadastro-mensagem{
    margin-bottom: -5.875em!important;
}

.tabela-central-mensagens-container-esfera{
    display: flex!important;
}
.tabela-central-mensagens-esfera-verde{
    margin-top: 0.25em!important;
    margin-right: 1em!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 50%!important;
    background-color: #36D896!important;
}

.tabela-central-mensagens-esfera-amarela{
    margin-top: 0.25em!important;
    margin-right: 1em!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 50%!important;
    background-color: #FDCB62!important;
}

.tabela-central-mensagens-esfera-vermelha{
    margin-top: 0.25em!important;
    margin-right: 1em!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 50%!important;
    background-color: #CD2D37!important;
}

.campo-select-perfis-checkbox-corpo{
    max-height: 10em!important;
    margin-top: -11em!important;
    width: 100%!important;
    border-radius: 0.3125em!important;
    overflow-x: hidden!important;
}

.campo-barra-pesquisa-modal-envios-central-mensagens{
    float: left!important;
    height: 2em!important;
    width: 100%!important;
    border-color: transparent!important;
    border-radius: 0em 2em 2em 0em!important;
    outline: none!important;
    box-shadow: none!important;
}

.coluna-1-envios-central-mensagens{
    margin-bottom: 1em!important;
    margin-left: 1%!important;
    width: 2em!important;
}

.coluna-2-envios-central-mensagens{
    width: 30em!important;
}

.coluna-3-envios-central-mensagens{
    margin-left: 3%!important;
}

.container-filtros-central-mensagens{
    position: relative!important;
    z-index: 99999!important;
}

.botao-filtro-central-mensagens{
    height: 2em!important;
    width: 7.8em!important;
    border-color: transparent!important;
    border-radius: 3.125em!important;
    box-shadow: none!important;
    font-weight: 700!important;
    line-height: 1em!important;
    text-align: left!important;
    position: relative!important;
    z-index: 99999!important;
}

.sun-editor .se-controller-resizing{
    background-color: transparent !important;
}

.sun-editor .se-controller-link, .sun-editor .se-controller-table, .sun-editor .se-controller-table-cell{
    background-color: transparent!important;
    border: transparent!important;
}

.sun-editor .se-list-layer{
    background-color: transparent !important;
    border: 1px solid transparent !important;
}

.sun-editor .se-dialog-tabs{
    border: 0px !important;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-header{
    border: 0px !important;
}

.sun-editor input, .sun-editor select, .sun-editor textarea{
    border: 0px !important;
}

.sun-editor .se-btn-primary{
    border: 0px !important;
}


.grupo-nova-mensagem-central-mensagens-editor input[type="file"]::-webkit-file-upload-button{
    margin: 0em !important;
    margin-left: -4px !important;
    height: 2.25em !important;
    width: 8.5em !important;
    border-radius: 3.125em !important;
    color: white !important;
    border-radius: 1.25em !important;
    border: none !important;
    cursor: pointer !important;
    background-position: 5.625em !important;
    background-repeat: no-repeat !important;
    font-family: Roboto !important;
    box-shadow: none !important;
    text-decoration: none !important;
    background-color: #36D896 !important;
    padding: 0 !important;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-content .se-btn-primary{
    background-color: #36D896 !important;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-control{
    box-shadow: none !important;
}

.sun-editor .se-list-layer.se-list-align .se-list-inner{
    width: 190px !important;
}

.sun-editor .se-list-inner .se-list-basic li>button{
    border-radius: 0px !important;
}

.contador-usuarios-geral{
    width: 14em;
    margin-top: 2em;

}