.div-grupo-filtro-min-max {
    width: 230px;
}

.div-grupo-filtro-min-max .filtros-iccid-valor-min-2-b,
.div-grupo-filtro-min-max .filtros-iccid-valor-max {
    width: 220px!important;
}

.div-grupo-filtro-min-max .filtros-dias,
.div-grupo-filtro-min-max .filtros-dias-b {
    width: 165px!important;
}
