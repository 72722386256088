.detalhamento-endereco{
    height: 8.8125em !important;
    width: 100% !important;
    border-radius: 0.3125em !important;
    min-width: 8.8125em !important;
}

.container-informacoes {
    border-left: 0.3125em solid #36D896!important;
    height: 3.5em!important;
    width: 90% !important;
    margin-left: 5% !important;
    margin-top: 1.2% !important;
    display: flex !important;
    justify-content: space-between !important;

}

.campo-endereco-empresas, .campo-bairro-empresas, .campo-cidade-empresas, .campo-cep-empresas,
.campo-estado-empresas, .campo-email-empresas{
    height: 1.5625em !important;
    margin-top: 0.9375em !important;
    font-size: 0.875em !important;
}

.campo-endereco-empresas{
    margin-left: 1.25em !important;
}

.informacoes-empresas{
    font-size: 0.875em !important;
}

.corpo-dados-usuario-razao{
    width: 39% !important;
}

.corpo-dados-usuario-endereco-empresa{
    width: 29.75em;
    margin-right: 1em;
}

.modal-acao-faturas-cadastro-conta{
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 54.375em!important;
    overflow: auto !important;
}

.corpo-dados-conta {
    margin-right: 0.5em !important;
    margin-left: 0.5em !important;
    width: 44% !important;
}

.corpo-dados-conta-numero-conta{
    width: 44% !important;
    margin-left: 1.3em !important;
    margin-right: 0.5em !important;
}

.corpo-dados-conta-campos-disabled{
    width: 44% !important;
    margin-left: 1.3em !important;
}
.usuario-dados-corpo-conta{
    justify-content: center;
}

.botao-confirmar-margin{
    margin-right: 1.25em !important;
}

.conta-dados-divisoria-corpo-conta{
    margin-top: 1.5em !important;
    margin-left: 1.0625em !important;
    width: 93% !important;
}

.container-botoes-cancelar-confirmar{
    margin-top: 3em!important;
}

.css-1wa3eu0-placeholder{
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.label-textos-foto{    
    margin-left: 1.625em;
    margin-top: 1.5em;
    color: black !important;
    width: 198px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    font-size: 22px !important;
}

.imagem-fatura-fundo{
    width: 15.8125em;
    height: 20em;
    border-style: solid;
    border-radius: 0.3125em;
    margin-top: 1.5625em;
    margin-bottom: 1.5625em;
    border-width: 0.1875em;
}

.container-imagem-fatura-1{
    margin-left: 0.5em;
}
.container-imagem-fatura-2{
    margin-left: 0.5em; 
}
.container-imagem-fatura-3{
    margin-left: 0.5em;
    margin-top: -0.625em;
}
.container-imagem-fatura-4{
    margin-left: 0.5em;
    margin-top: -0.625em;
}
.container-imagem-fatura-5{
    margin-left: 0.5em;
    margin-top: 0.5em;
}
.container-imagem-fatura-6{
    margin-left: 0.5em;
    margin-top: -0.625em;
}
.container-imagem-fatura-7{
    margin-left: 0.5em;
    margin-top: -0.625em;
}
.container-imagem-fatura-8{
    display: flex!important;
    margin-top: 0.25em;
    margin-left: 0.5em;
}
.container-imagem-fatura-9{
    margin-top: -1.9375em;
    margin-left: 125px;
}

.label-imagem-fatura-1{
    font-weight: 800;
    font-size: 1.5625em!important;
    width: 96%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.label-imagem-fatura-2{
    font-weight: 600;
    font-size: 1em!important;
    width: 96%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.label-imagem-fatura-3{
    font-size: 1.625em!important;
    font-weight: 700;
}
.label-imagem-fatura-4{
    font-size: 1.0625em!important;
    font-weight: 700;
}

.codigo-barras-imagem-fatura-1{
    width: 0.1875em;
    height: 3.125em;
    margin-left: 0.125em!important;
}
.codigo-barras-imagem-fatura-2{
    width: 0.3125em;
    height: 3.125em;
    margin-left: 0.125em!important;
}
.codigo-barras-imagem-fatura-3{
    width: 0.125em;
    height: 3.125em;
    margin-left: 0.125em!important;
}
.codigo-barras-imagem-fatura-4{
    width: 0.1875em;
    height: 3.125em;
    margin-left: 0.125em!important;
}
.codigo-barras-imagem-fatura-5{
    width: 0.5em;
    height: 3.125em;
    margin-left: 0.125em!important;
}

.label-alerta-sem-margem{
    margin: 0 !important;
}

.faturas-nova-conta-grupo-1{
    display: flex!important;
}

.drop-dados-conta-divisora-1{
    width: 100%!important;
    height: 0.0625em !important;
}

.expand-row-fat-contas-1{
    max-width: 141%!important;
    margin-left: -0.58em;
    transition: 0.5s ease!important;
    transition: 0.5s ease!important;
    height: 25.5em!important;
}

.expand-row-fat-contas-2{
    max-width: 141%!important;
    margin-left: -0.58em;
    transition: 0.5s ease!important;
    height: 25.5em!important;
}