
.svg-spinnner-veye {
  width: 300px;
}
.elementos-spinner {
  animation: break-spinner 4s ease-in-out infinite reverse;
  /* //animation-delay: 1s; */
}
  
.circulo-spinner {
  animation: bola-spinner 4s ease-in-out infinite reverse;
  opacity: 0;
}
  
.elemento-spinner-2 {
  /* // transform: translate(-90px, 120px); */
  transform: translate(-5%, 8%);
}
  
.elemento-spinner-3 {
  /* // transform: translate(-170px, 235px); */
  transform: translate(-10%, 16%);
}
  
.elemento-spinner-4 {
  /* / transform: translate(-230px, 340px); */
  transform: translate(-14%, 23%);
}
  
.circulo-spinner {
  /* // transform: translate(-230px, 340px);
  // transform: translate(-39%, 55%); */
  transform: translate(-57%, 85%); 
}

@keyframes break-spinner {
  50% {
    transform: translate(0, 0);
  }
}

@keyframes bola-spinner {
  20% {
    opacity: 1;  
  }
  
  50% {
    transform: translate(0, 0);
  }
  
  80% {
    opacity: 1;
  }
}