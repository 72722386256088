@font-face{
  src: url('../../../fonts/Roboto-Regular.ttf');
  font-family: Roboto;
}

.titulo {
  margin-left: 2em !important;
  padding-top: 0.625em !important;
  font-family: Roboto !important;
  font-size: 1.375em !important;
  font-weight: 700 !important;
  line-height: 1.8125em !important;
}

.CardPrincipal{
  margin-left: 6.2em !important;
  width: 41.125em !important;
  border-radius: 0.625em 0.625em 0.625em 0.625em!important;
  border:  none !important;
}

.elipse{
  margin-bottom: 0.3125em !important;
  margin-right: 0.3125em !important;
  height: 0.375em !important;
  width: 0.375em !important;
  background-color: #36D896 !important;
  display: inline-block !important;
  border-radius: 50% !important;
  margin-bottom: 0.25em !important;
}

.botaoFechar{
  background-image: url('../../../icones/icone-fechar-vermelho.svg') !important;
  margin-top: -2.1875em !important ;
  margin-left: 35.9375em !important ;
  background-position: center !important;
  background-repeat: no-repeat !important;
  cursor: pointer !important;
  height: 2.21em !important;
  width: 2.21em !important;
}

.divBotoes{
  display: inline;
  margin-top: 2em;
  margin-bottom: 1.875em;
  padding-right: 2.8125em;
}

.btCancelar {
  margin-left: 24.0625em !important;
  height: 2.1875em !important;
  width: 6.5625em !important;
  border-radius: 3.125em !important;
  border: 0.09375em solid #36D896 !important;
  background-color: transparent !important;
  flex: none!important;
  box-shadow: none!important; 
  font-family: Roboto !important;
  color: #36D896 !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  cursor: pointer !important;
}

.btConfirmar{
  margin-left: 0.625em !important;
  margin-top: 1.875em !important;
  margin-bottom: 1.875em !important;
  height: 2.1875em !important;
  width: 6.5625em !important;
  border-radius: 3.125em !important;
  border: none !important;
  background-color: #36D896 !important;
  flex: none!important;
  box-shadow: none!important; 
  font-family: Roboto !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  cursor: pointer !important;
}

.textoAviso{
  margin-top: -1.0625em !important;
  margin-left: 4.7em !important;
  height: 2em !important;
  width: 34.1875em !important;
  font-family: Roboto !important;
  font-size: 0.875em !important;
  font-weight: 700 !important;
  line-height: 1.3em !important;
}

.simboloAviso{
  margin-top: 1.25em !important;
  margin-left: 2.8125em !important;
  width: 1.0625em;
  height: 1.0625em;
  background-image: url("../../../icones/avisoI.png") !important;
  background-repeat: no-repeat !important;
}

.UploadMessage{
  margin-right: 2em !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
}

.botaoImportar{
  margin-left: 1.5625em;
  height: 2em !important;
  width: 7.75em !important;
  border-radius: 3.125em !important;
  /* background-color: #36D896 !important; */
  border: none !important;
  cursor: pointer;
  background-position: 5.625em !important;
  background-repeat: no-repeat !important;
  font-family: Roboto;
  box-shadow:  none !important;
}

.imagemImportar{
  margin-top: -2.8125em !important;
  margin-left: 5em !important;
}

/* CLASSE DO DROPZONE  */
.eOyPTn{
  height: 25em !important;
  width: 41.125em !important;
  border-radius: 0 0 0.3125em 0.3125em !important;
  background-color: #000 !important;
  color: #fff !important;
  max-width: 41.25em !important;
  padding: 0em !important;
  margin:  0em !important;
}

.div-Template{
  margin-top: 1.4375em;
  display: flex;
}

.bt-dropdown-template{
  margin-left: 2.8125em !important;
  height: 2em !important;
  width: 7.75em !important;
  border-radius: 3.125em !important;
  color: white !important;
  border-radius: 1.25em !important;
  border: none !important;
  cursor: pointer !important;
  background-image: url("../../../icones/exportar.svg") !important;
  background-position: 5.625em !important;
  background-repeat: no-repeat !important;
  font-family: Roboto !important;
  box-shadow:  none !important;
  text-decoration: none !important;
}

.menu-dropdown-template{
  margin-left: 2.8125em !important;
  margin-top: 0.8125em !important;
  height: 4em !important;
  width: 7.6875em !important;
  border-radius: 0.3125em !important;
  min-width:  0em !important
}

.triangulo-dropdown-template{
  position: absolute !important;
  /* z-index: 1!important; */ /* ESTAVA FICANDO POR CIMA DE OUTROS ELEMENTOS */
  width: 0!important;
  height: 0!important;
  border-left: 0.9375em solid transparent !important;
  border-right: 0.9375em solid transparent !important;
  margin-top: -0.625em!important;
  margin-left: 2em!important;
}

.template-border{
  width: 5.8125em !important;
  border: 0.03125em solid black !important;
  margin: 0.125em 0em 0em 0.9375em  !important;
}

.botaoDownload{
  width: 7.5em !important;
  background-color: transparent !important;
  border: none !important;
  margin-left: 0.4375em !important;
  text-decoration: none !important;
  font-family: Roboto !important;
  font-size: 0.875em !important;
  font-weight: 700 !important;
  line-height: 1em !important;
  cursor: pointer !important;
  box-shadow: none !important;
  padding: 0.5em 0em 0.3125em 0.75em !important;
  display: flex !important;
}

.exportarTemplate{
  margin-left: 2.8em !important;
  margin-top: -2px !important;
}

.textoBotao{
  width: 5.625em !important;
  margin-left: -2.1875em !important;
  color: white !important;
  text-decoration: none !important;
}

.container-importar-descricao-lista-arquivos{
  overflow: hidden !important;
  padding: 0em 11em 0em 3em;
}

.lista {
  margin-top: 0.3125em !important;
  display: flex !important;
  align-items: center !important;
  color: #fff !important;
  display: flex !important;
  align-items: center !important;
  height: 1.9375em !important;
  width: 35.125em !important;
  border-radius: 3.125em !important;
  background-color: #343736 !important;
}

.listaNome{
  width: 35.25em !important;
  margin-top: 0.875em !important;
  margin-left: 1.0625em !important;
  color: #FFF !important;
  font-family: Roboto !important;
  font-size: 0.875em !important;
  line-height: 1em !important;
}

.excluir {
  display: flex !important;
  flex-direction: column !important;
  border: 0 !important;
  background: transparent !important;
  color: #e57878 !important;
  cursor: pointer !important;
  display: inline !important;
  width: 0.75em !important;
  height:1em !important;
}

.lixeira{
  background-image: url('../../../icones/lixeira_vermelha.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 0.75em !important;
  height: 1em !important;
}

.tituloLista{
  margin-top: 1.375em  !important;
  margin-left: 2.8125em !important;
  font-family: Roboto !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
}


/* RESOLUÇÃO DOS CONFLITOS COM O CSS DO PROJETO  */

.dropdown-toggle::after{
  border: none !important;
}

.btn-sair-importar-descricao{
  float: right;
}