.container-login{
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    overflow: hidden;
    display: flex;
}

.corpo-login-veye-esquerda{
    background-color: #ffffff;
    height: 100%;
    width: 70%;
}

.imagem-fundo-login-veye{
    width: 100%;
    height: 100%;
    border-style: none;
}

.corpo-login-veye-direita{
    height: 100%;
    width: 30%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: #000000 0em 0em 1em 0
}

.container-corpo-login-veye-direita-header{
    display: flex;
    flex-direction: column;
    height: 20%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.logo-empresa-login-veye{
    height: 3em;
}

.container-corpo-login-veye-direita-body{
    display: flex;
    flex-direction: column;
    height: 60%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.container-imput-email-login-veye{
    display: flex;
    width: 80%;
}

.input-email-login-veye{
    height: 2.5em;
    width: 100%;
    margin-bottom: 1em;
    margin-top: 1em;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.5em!important;
    font-weight: 400;
    border-bottom: 0.1em solid #6c757d;
}

.label-erro-senha-erro{
    font-family: RobotoRegular;
    font-size: 0.8em;
    user-select: none;
    margin-top: 0.4em;
}

.container-imput-senha-login-veye{
    display: flex;
    width: 80%;
}

.input-senha-login-veye{
    height: 2.5em;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.5em!important;
    font-weight: 400;
    border-bottom: 0.1em solid #6c757d;
}

.container-icone-ocultar-login-veye{
    height: 3.75em;
    width: 2.5em;
    margin-top: 1.5em;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 0.15em #6c757d solid;
    cursor: pointer;
}

.icone-ocultar-login-veye {
    width: 2em;
    height: 2em;
    margin-top: 0.2em;
}

.icone-ocultar-login-veye path{
    fill: #6c757d;
}

.botao-confirmar-login-veye{
    width: 8.7em;
    height: 3.3em;
    margin-top: 3em;
    margin-bottom: 1.5em;
    border-radius: 5em;
    color: #ffffff;
    font-family: RobotoBold;
    font-size: 1.6em!important;
    transition: 0.5s;
    outline: none;
    user-select: none;
}

.botao-confirmar-login-veye:disabled,
.botao-confirmar-login-veye:disabled:hover,
.botao-confirmar-login-veye:disabled:active{
    background-color: #c6c6c6;
    border: #c6c6c6 0.1em solid;
    color: #6c757d;
    transition: 0.5s;
}

.botao-confirmar-login-veye:hover{
    transition: 0.5s;
    outline: none;
}

.botao-confirmar-login-veye:active{
    transition: 0.1s;
    outline: none;
}

.label-recuperacao-senha-login-veye{
    font-family: RobotoRegular;
    font-size: 1.8em!important;
    line-height: 0.9em;
    cursor: pointer;
    user-select: none;
}

.label-recuperacao-senha-login-veye:hover{
    font-family: RobotoRegular;
    font-size: 1.8em!important;
    line-height: 0.8em;
    cursor: pointer;
    user-select: none;
}

.label-recuperacao-senha-erro{
    font-family: RobotoRegular;
    font-size: 1.8em!important;
    line-height: 0.9em;
    height: 0em;
    cursor: pointer;
    user-select: none;
}

.titulo-conteudo-corpo-login-veye{
    width: 70%;
    text-align: center;
    font-family: Objectivity;
    font-size: 1.8em!important;
    font-weight: 700;
    line-height: 1.3em;
    letter-spacing: 0.125em;
    user-select: none;
}

.titulo-conteudo-corpo-login-veye-erro{
    width: 70%;
    text-align: center;
    font-family: Objectivity;
    font-size: 1.8em!important;
    font-weight: 700;
    line-height: 1.3em;
    letter-spacing: 0.125em;
    user-select: none;
}

.corpo-conteudo-corpo-login-veye{
    height: fit-content;
    text-align: center;
    width: 70%;
    font-family: Objectivity;
    font-size: 1.8em!important;
    font-weight: 700;
    color: #000000;
    user-select: none;
    margin-top: 1em;
}

.container-corpo-login-veye-direita-footer{
    display: flex;
    flex-direction: column;
    height: 20%;
    width: 100%;
    align-items: center;
    justify-content: center;
}




.container-login{
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    overflow: hidden;
    display: flex;
}

.corpo-login-algar-direita{
    background-color: #ffffff;
    height: 100%;
    width: 75%;
}

.detalhes-imagem-fundo-login-algar{
    position: absolute;
    height: 100%;
    margin-left: 2em;
}

.imagem-fundo-login-algar{
    width: 100%;
    height: 100%;
    border-style: none;
}

.corpo-login-algar-esquerda{
    height: 100%;
    width: 25%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: #000000 1em 0em 0em 0
}

.container-corpo-login-algar-esquerda-header{
    display: flex;
    flex-direction: column;
    height: 20%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.logo-empresa-login-algar{
    height: 6em;
}

.container-corpo-login-algar-esquerda-body{
    display: flex;
    flex-direction: column;
    height: 60%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.label-titulo-campo-login-algar {
    position: absolute;
    margin-top: -0.85em;
    margin-left: 1em;
    padding-left: 0.1em;
    padding-right: 0.1em;
    background: white;
    color: #5cb85c;
    font-size: 1em!important;
    font-family: RobotoRegular;
}

.container-imput-email-login-algar{
    display: flex;
    width: 80%;
    border-radius: 0.5em;
    border: solid 0.1em #5cb85c;
}

.input-email-login-algar{
    height: 2.3em;
    width: 100%;
    margin: 0.1em;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1em!important;
    font-weight: 400;
}

.label-erro-senha-erro{
    font-family: RobotoRegular;
    font-size: 1em!important;
    user-select: none;
    margin-top: 0.4em;
}

.container-imput-senha-login-algar{
    display: flex;
    width: 80%;
    border-radius: 0.5em;
    border: solid 0.1em #5cb85c;
    margin-top: 5em;
}

.container-imput-nova-senha-login-algar{
    display: flex;
    width: 80%;
    border-radius: 0.5em;
    border: solid 0.1em #5cb85c;
}

.container-imput-confirmar-senha-login-algar{
    display: flex;
    width: 80%;
    border-radius: 0.5em;
    border: solid 0.1em #5cb85c;
    margin-top: 4em;
}

.input-senha-login-algar{
    height: 2em;
    width: 100%;
    margin: 0.1em;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1em!important;
    font-weight: 400;
}

.container-icone-ocultar-login-algar{
    width: 3.5em;
    height: 0.5em;
    margin-top: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    cursor: pointer;
}

.icone-ocultar-login-algar {
    width: 1.5em;
    height: 1.5em;
    margin-top: 0.1em;
}

.icone-ocultar-login-algar path{
    fill: #6c757d;
}

.botao-confirmar-login-algar{
    width: 80%;
    height: 2.3em;
    margin-top: 2em;
    margin-bottom: 1.5em;
    border-radius: 0.5em;
    color: #ffffff;
    font-family: RobotoBold;
    font-size: 1em!important;
    transition: 0.5s;
    outline: none;
    -webkit-user-select: none;
    user-select: none;
}

.botao-confirmar-login-algar:disabled,
.botao-confirmar-login-algar:disabled:hover,
.botao-confirmar-login-algar:disabled:active{
    background-color: #787b7a;
    border: #787b7a 0.1em solid;
    color: #c6c6c6;
    transition: 0.5s;
}

.botao-confirmar-login-algar:hover{
    transition: 0.5s;
    outline: none;
}

.botao-confirmar-login-algar:active{
    transition: 0.1s;
    outline: none;
}

.label-recuperacao-senha-login-algar{
    font-family: RobotoRegular;
    margin-top: 2em;
    font-size: 1em!important;
    line-height: 0.9em;
    cursor: pointer;
    user-select: none;
}

.label-recuperacao-senha-login-algar:hover{
    font-family: RobotoRegular;
    font-size: 1em!important;
    line-height: 0.8em;
    cursor: pointer;
    user-select: none;
}

.label-recuperacao-senha-erro{
    font-family: RobotoRegular;
    font-size: 1em!important;
    line-height: 0.9em;
    height: 0em;
    cursor: pointer;
    user-select: none;
}

.titulo-conteudo-corpo-login-algar{
    width: 70%;
    text-align: center;
    font-family: RobotoRegular;
    font-size: 1em!important;
    font-weight: 700;
    line-height: 1.3em;
    letter-spacing: 0.125em;
    user-select: none;
}

.titulo-conteudo-corpo-login-algar-erro{
    width: 70%;
    text-align: center;
    font-family: RobotoRegular;
    font-size: 1em!important;
    font-weight: 700;
    line-height: 1.3em;
    letter-spacing: 0.125em;
    user-select: none;
}

.corpo-conteudo-corpo-login-algar{
    height: fit-content;
    text-align: left;
    width: 80%;
    font-family: RobotoRegular;
    font-size: 1em!important;
    font-weight: 100;
    color: #000000;
    user-select: none;
    margin-top: 1em;
    color: #5cb85c;
    margin-bottom: 3em;
}

.container-corpo-login-algar-esquerda-footer{
    display: flex;
    flex-direction: column;
    height: 20%;
    width: 100%;
    align-items: center;
    justify-content: center;
}