.filtro-conteudo-container-migracao-plano {
  max-height: 720px;
  height: 23rem;
}

.coluna-filtro-operadora-migracao {
  width: 12% !important;
}

.coluna-alterar-ciclo-vida-1-status_migracao_plano {
  width: 17% !important;
}

.container-checkbox-status-migracao {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;

  > label {
    margin: 0 !important;
  }
}

.div-filtro-campo-data .react-datepicker-popper {
  zoom: 120% !important;
}

/* Customize the label (the container) */
.label-checkbox-filtros-status_migracao_plano {
  margin-left: -0.5em !important;
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 2em !important;
  cursor: pointer;
  height: 1em;
  width: 100% !important;
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: auto !important;
}

.label-checkbox-filtros-status_migracao_plano {
  margin-right: 30px !important;
}

.label-checkbox-filtros-status_migracao_plano input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-checkbox-filtros-status_migracao_plano
  input:checked
  ~ .checkbox-filtros-status_migracao_plano:after {
  display: block;
}

.label-checkbox-filtros-status_migracao_plano
  .checkbox-filtros-status_migracao_plano:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #ffff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-filtros-status_migracao_plano {
  position: absolute;
  bottom: 0.0625em;
  left: 0;
  height: 1.1em;
  width: 1.1em;
  border: solid;
  border-width: 1px;
}

.checkbox-filtros-status_migracao_plano:after {
  content: "";
  position: absolute;
  display: none;
}

.div-filtro-campo-data {
  width: 25% !important;
  margin-right: 0% !important;
}

/* Cliente Final  -------------------------*/
.migracao-cliente-container-cliente-final {
  margin: 0px !important;
}

.div-filtro-cliente-final-codigo > .filtros-cliente-final,
.div-filtro-cliente-final-nome > .filtros-cliente-final {
  margin-top: -40px !important;
  margin-left: 34px !important;
  width: 220px !important;
}

.migracao-cliente-container-cliente-final > div > .filtros-texto-linha-margin {
  margin-left: 0px !important;
}

.div-filtro-cliente-final-nome {
  margin-top: 10px;
}

.div-filtro-cliente-final-codigo > .label-radio-filtros-2,
.div-filtro-cliente-final-nome > .label-radio-filtros-2 {
  width: 20px !important;
  margin-left: 0.7em !important;
}

/* Filtro Cliente Nome ------------------------ */
.select-autocomplete-nome-cliente {
  margin-top: -41px;
  width: 220px !important;
  margin-left: 36px;
}

.select-autocomplete-nome-cliente button {
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
}

.select-autocomplete-nome-cliente .campo-select-generico {
  height: 35px !important;
}

.grupo-filtros-min-max {
  display: flex !important;
  gap: 130px;
}

.container-status-migracao {
  display: flex;
}
