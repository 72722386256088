.container-bread-crumbs {
  margin-bottom: 40px;
  height: 63px;
  display: flex;
}

.container-bread-crumbs .div-seta {
  width: 55px;
}

#titulo-pagina {
  margin-top: 7px;
  font-family: "roboto";
  font-size: 34px !important;
  color: #33cc99;
  line-height: 25px;
}

#div-bread-crumbs > div {
  height: 20px;
}

#breadcrumbs-div-voltar {
  cursor: pointer;
  margin-right: 16px;
}

#breadcrumbs-seta-voltar {
  width: 30px;
  height: 30px;
  margin: 8px;
}

.span-bread-crumbs{
    font-family: 'roboto';
}