.modal-acao-alterar-ciclo-vida{
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 60em!important;
}

.modal-atribuir-linhas{
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 35em!important;
}

.modal-filtro-simcard {
    padding: .5em 2em 2em 2em !important;
    border-radius: 0.625em 0.625em 0.625em 0.625em !important;
    width: 90em !important;
    /*max-height: 740px;*/
}

.modal-acao-alterar-servico {
    padding: .5em 2em 2em 2em !important;
    border-radius: 0.625em 0.625em 0.625em 0.625em !important;
    width: 40em !important;
}

.grupo-alterar-ciclo-vida-1-filtro {
    /*width: 100% !important;*/
    display: flex !important;
}


.grupo-alterar-ciclo-vida-1 {
    /*width: 100% !important;*/
    display: flex !important;
}

.coluna-alterar-ciclo-vida-1-filtro {
    width: 39% !important;
    margin-right: 0% !important;
}

.coluna-alterar-ciclo-vida-1-filtro-2 {
}

.coluna-alterar-ciclo-vida-1-filtro-trafego {
    width: 18% !important;
    margin-right: 0% !important;
}

.coluna-alterar-ciclo-vida-1-filtro-iccid {
    width: 18% !important;
    margin-right: 0% !important;
    margin-left: -226px;
}

.coluna-alterar-ciclo-vida-1-dias {
    width: 39% !important;
    margin-right: 0% !important;
    margin-left: -25px;
}

.coluna-alterar-ciclo-vida-1-dias-2 {
    margin-left: 31px;
}

.coluna-alterar-ciclo-vida-1-dias-3 {
}

.coluna-alterar-ciclo-vida-1-ativacao {
    width: 230px;
    margin-right: 0% !important;
    margin-left: -30px;
}

.coluna-alterar-ciclo-vida-1-tec {
    width: 30% !important;
    margin-right: 0% !important;
    margin-left: -16px;
}

.coluna-alterar-ciclo-vida-1 {
    width: 33% !important;
    margin-right: 2% !important;
}

.coluna-alterar-ciclo-vida-conexao {
    width: 36% !important;
    margin-right: 0% !important;
}

.coluna-alterar-ciclo-vida-1-pacote {
    width: 12% !important;
    margin-right: 0% !important;
    margin-left: 24px;
}

.coluna-alterar-ciclo-vida-1-pacote-portal {
    width: 27% !important;
    margin-right: 0% !important;
    margin-left: 24px;
}

.coluna-alterar-ciclo-vida-1-tempo {
    width: 26% !important;
    margin-right: -20px !important;
    margin-left: 80px;
}

.coluna-alterar-ciclo-vida-1-simcard {
    width: 37% !important;
    padding-left: 194px;
}

.coluna-alterar-ciclo-vida-1-sms {
    margin-right: -8% !important;
    margin-left: -156px;
}

.fundo-filtro {
    align-items: flex-start;
    /*width: 1530px;*/
    height: 175px;

    /* background: #ECF0EE; */
    border-radius: 8px;
    padding: 16px 34px 34px;

}

.fundo-filtro-2 {
    align-items: flex-start;
    padding: 20px;

    /*width: 1530px;*/
    height: 170px;

    background: #ECF0EE;
    border-radius: 8px;
    margin-top: 12px;

}

.fundo-filtro-3 {
    align-items: flex-start;
    padding: 15px;

    /*width: 1530px;*/
    height: 151px;

    background: #ECF0EE;
    border-radius: 8px;
    margin-top: 12px;

}

.fundo-filtro-4 {
    align-items: flex-start;
    padding: 15px;

    /*width: 1530px;*/
    height: 156px;

    background: #ECF0EE;
    border-radius: 8px;
    margin-top: 13px;

}

.coluna-alterar-ciclo-vida-1-status-b {
    width: 37% !important;
    margin-right: 0% !important;
    margin-left: -60px;
}

.coluna-alterar-ciclo-vida-1-status {
    width: 37% !important;
    margin-right: 0% !important;
    margin-left: 116px;
}

.coluna-alterar-ciclo-vida-1-operadora {
    width: 12% !important;
    margin-right: -5% !important;
}

.coluna-alterar-ciclo-vida-1-status-contrato {
    display: flex;
    flex-direction: column;
    min-width: 230px!important;
    /* width: 36% !important; */
    margin-right: 0% !important;
    margin-top: -18px;
    margin-left: -26px;
}

.coluna-alterar-ciclo-vida-1-status-modelo {
    width: 22% !important;
    margin-right: 0% !important;
    margin-top: -18px;
    margin-left: -26px;
}

.coluna-alterar-ciclo-vida-1-status-grupo {
    width: 22% !important;
    margin-right: 0% !important;
    margin-top: -18px;
    margin-left: -68px;
}

.coluna-alterar-ciclo-vida-1-cliente-final {
    width: 22% !important;
    margin-right: 0% !important;
    margin-top: -18px;
    margin-left: -50px;
}

.coluna-alterar-ciclo-vida-1-status-marca {
    width: 22% !important;
    margin-right: 0% !important;
    margin-top: -18px;
    margin-left: -74px;
}

.coluna-alterar-ciclo-vida-1-conexao {
    width: 33% !important;
    margin-right: -5% !important;
}

.coluna-alterar-ciclo-vida-2 {
    width: 33% !important;
}

.operadoras-filtro {
    margin-top: 38px;
}

.break {
    height: 14px;
}

.break-1 {
    height: 4px;
}

.break-2 {
    height: 10px;
}

.break-3 {
    height: 10px;
}

.break-4 {
    height: 13px;
}

.operadoras-filtro-2 {
    margin-top: 20px;
}

.status-contrato-filtro {
    margin-top: 23px;
    margin-left: 56px;
}

.status-contrato-filtro-2 {
    margin-top: 23px;

}

.conexoes-filtro{
    margin-left: 64px;
}

.contador-linhas-acao-altera-ciclo-vida {
    float: right !important;
    margin-top: 0.4375em !important;
    margin-bottom: 1em !important;
}

.divisoria-alterar-servico {
    height: 0.0625em !important;
    width: 100% !important;
    margin-top: 0.5em;
    margin-bottom: 1.5em;
}

.grupo-configurador-alterar-servico {
    width: 100% !important;
    display: flex !important;
    min-height: 1em !important;
    margin-bottom: 1em !important;
}

.coluna-configurador-alterar-servico-1 {
    width: 7.125em !important;
    height: 10.9em !important;
    min-height: 1em !important;
    margin-right: 1em !important;
}

.coluna-configurador-alterar-servico-2 {
    padding-top: 0em !important;
    padding-bottom: 1em !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
    width: 28.5em !important;
    height: 10.9em !important;
    min-height: 1em !important;
    display: flex !important;
    flex-wrap: wrap !important;
    border-radius: 5px !important;
}

.grupo-botoes-alterar-servico {
    width: 100% !important;
    display: flex !important;
    margin-top: -1em;
    margin-bottom: 2em;
}

.coluna-botoes-alterar-servico {
    width: 50% !important;
}

.container-switches-alterar-servico {
    height: 18.9em !important;
    min-height: 1em !important;
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    margin-left: 8px !important;
}

.grupo-switches-alterar-servico {
    display: flex !important;
    margin-bottom: 0.37em !important;
}

.divisoria-alterar-servico-botoes {
    height: 0.0625em !important;
    width: 100em !important;
    margin-top: -1em;
}

.divisoria-alterar-servico-switches {
    height: 0.0625em !important;
    width: 26.5em !important;
    margin-top: 6.8em;
    position: absolute;
}

.triangulo-personalizar-alterar-servico {
    width: 0 !important;
    height: 0 !important;
    margin-left: 6.7em !important;
    margin-top: 0.1em !important;
    transform: rotate(90deg) !important;
    position: absolute !important;
    z-index: 1 !important;
    border-top: 2.2575em solid transparent !important;
    border-left: 0.7em solid transparent !important;
    border-right: 0.7em solid transparent !important;
}

.campo-operadoras{
    margin-left: -10px
}

.campo-trafego{
    margin-left: 10px
}

.campo-ultimo-evento{
    margin-left: 25px;
}

.campo-plano-tipo{
    margin-left: -35px;
}
.campo-envio-sms{
    margin-left: 80px;
}
.campo-tipo-simcard{
    margin-left: -66px;
}
.campo-pacote-extra{
    margin-left: -31px;
}
.campo-pacotes{
    margin-left: -27px;
}

.campo-dias-utilizados{
    margin-left: 13px;
}

.campo-aquisicao{
    margin-left: 32px;
}

.campo-ativacao{
    margin-left: 60px;
}

.campo-consumo{
    margin-left: 115px;
}

.campo-consumo-2{
    margin-left: 15px!important;
}

.campo-iccid-intervalo{
    margin-left: 37px;
}

.campo-modelo{
    margin-left: 70px;
}

.campo-marca{
    margin-left: 96px !important;
}

.campo-filtro-grupo{
    margin-left: 110px !important;
}
.campo-cliente-final{
    margin-left: 102px !important;
}

.campo-filtro-pacotes{
    margin-left: -30px !important;
}

.filtro-conteudo-container-n1-n2{
    height: 100vh;
    max-height: 720px;
    overflow-y: auto;
}

.filtro-conteudo-container-portal{
    height: 100vh;
    max-height: 540px;
    overflow-y: auto;
}

.label-pacotes-portal{
    margin-left: -25px !important;
}

.coluna-alterar-ciclo-vida-1-dias-portal{
    width: 41% !important;
    margin-right: 0% !important;
    margin-left: -25px;
}

/* .coluna-alterar-ciclo-vida-1-aquisicao-portal {
    width: 29% !important;
    margin-left: -73px;
} */

.coluna-alterar-ciclo-vida-1-sms-portal{
    /* margin-right: 16% !important; */
    margin-left: -13px;
    width: 33% !important;
}

/* .coluna-alterar-ciclo-vida-1-simcard-portal{
    width: 37% !important;
    padding-left: 109px;
} */

.coluna-alterar-ciclo-vida-1-consumo-portal {
    width: 218px;
    margin-left: 43px;
    margin-right: 24px;
}

.coluna-alterar-ciclo-vida-1-tempo-portal{
    /* width: 29% !important; */
    /* margin-right: -17px !important; */
    /* margin-left: 217px; */
    width: 289px;
}


/*MODAL ATRIBUIR LINHAS*/
.atribuir-linha-wrapper{
    display: flex;
    width: 100%;
    flex-direction: column;

}

.atribuir-selected-row{
    display: flex;
    justify-content: space-between;
    gap: 10px;

    height: 55px;
}

.atribuir-template-row{
    display: flex;
    justify-content: space-between;
    gap: 10px;

    margin-right: 25px;
    height: 55px;
}

.atribuir-radio{
    display: flex;
    align-items: center;
    gap: 3px;
}

.atribuir-radio-label{
    margin-left: -5px;
}

.atribuir-radio-label-noturno{
    margin-left: -5px;
    color: white;
}

.atribuir-divider{

    width: 100%;
    height: 1px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin: 25px 0;
}

.atribuir-divider-noturno{

    width: 100%;
    height: 1px;
    border: 1px solid rgba(238, 238, 238, 0.47);
    margin: 25px 0;
}

.template-row-action-btns{
    margin-top: 25px;
    display: flex;
    gap: 10px;
}

.template-action-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex: 1;

    cursor: pointer;

    height: 35px;

    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px !important;

    border-radius: 24px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
}

.template-row-file-field{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;

    font-weight: 500;

    margin-top: 11px;
    padding: 5px 16px;

    background-color: #FFFFFF;
    border-radius: 24px;
}

.disclaimer-qtd-linhas{
    display: flex;
    gap: 8px;
    margin-top: 10px;
}

.input-arquivo-atribuir{
    display: none;
}

.action-buttons-wrapper{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 25px;
}


.delete-file-btn{
    width: 17px;
    height: 21px;
    cursor: pointer;;
}

.tmp-btn{
    background-color: #fff;
    color: #243B53;
}

.upload-btn{
    background-color: #3C9;
    color: #fff;
}

.btn-disable{
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
}

.btn-disable-noturno{
    background-color: rgba(93, 93, 93, 0.12);
    color: rgba(187, 187, 187, 0.26);
}

.sucess-icon{
    color: #28a745;
}

.error-icon{
    color: #dc3545
}

.cancel-action-btn{
    background-color: transparent;
    color: #33CC99;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 24px;
    border: 1px solid #33CC99;

    width: 20%;
    height: 32px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.handler-action-btn{
    background-color: #33CC99;
    cursor: pointer;
    color: #fff;
    border-radius: 24px;
    text-transform: uppercase;

    width: 20%;
    height: 32px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
}

.handler-action-btn:hover{
    background-color: #239a75;


    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
}



/*MATERIAL UI AJUSTES*/
.MuiOutlinedInput-root{
    border-color: #33CC99 !important;
    height: 55px;
    border-radius: 24px !important;
    background-color: #FFFFFF;
}

/* for change border color*/
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #33CC99 !important;
}

/*for change label color in focus state*/
.MuiFormLabel-root.Mui-focused{
    color: #33CC99 !important;
}




