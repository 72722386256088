*********** CLASSES DO DETALHAMENTO DE CLIENTE ***********
/* Sempre verifique se a classe que você procura já         */
/* existe. As classes listadas aqui são utilizadas pelas    */
/* pelas telas do detalhamento de cliente e seus elementos. */
/********* MATEUS FOLLETTO - 15/05/2020 - VIRTUEYES *********/
.expand-row-tabela-clientes-virtueyes-1{/* ALTERNA DE ACORDO COM UM ESTADO DE REDUX PARA TORNAR A EXPAND ROW RESPONSIVA */
    max-width: 107.3vw!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
}

.expand-row-tabela-clientes-virtueyes-2{/* ALTERNA DE ACORDO COM UM ESTADO DE REDUX PARA TORNAR A EXPAND ROW RESPONSIVA */
    max-width: 117.2vw!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
}

.container-detalhamento-cliente{/* CONTAINER DO DETALHAMENTO DE CLIENTE */
    margin-left: -0.9em;
    width: 101.4%!important;
    display: flex!important;
    height: 24.8em!important;
}

.container-detalhamento-cliente-novo{/* CONTAINER DO DETALHAMENTO DE CLIENTE */
    margin-left: -0.9em;
    width: 101.4%!important;
    display: flex!important;
}

.container-navegacao-detalhamento-cliente-novo{/* CONTAINER DA NAVEGACAO DO DETALHAMENTO DE CLIENTE */
    display: inline-block!important;
    width: 11em!important;
    overflow: hidden!important;
    margin-left: 0.3125em!important;
}

/******************** NAVEGACAO DO DETALHAMENTO ********************/
.container-navegacao-detalhamento-cliente{/* CONTAINER DA NAVEGACAO DO DETALHAMENTO DE CLIENTE */
    display: inline-block!important;
    height: 24.8em!important;
    width: 11em!important;
    overflow: hidden!important;
    margin-left: 0.3125em!important;
}

.triangulo-navegacao-detalhamento-cliente{
    width: 0!important;
    height: 0!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-left: 50%!important;
    transform: translate(-50%, 0%)!important;
}

.corpo-navegacao-detalhamento-cliente-novo{
    width: 100% !important;
    border-radius: 0.3125em 0.3125em 0.3125em 0.3125em!important;
    padding-top: 1.25em!important;
    padding-bottom: 1.25em!important;
}

.corpo-navegacao-detalhamento-cliente{
    height: 23.75em!important;
    width: 100% !important;
    border-radius: 0.3125em 0.3125em 0.3125em 0.3125em!important;
    padding-top: 1.25em!important;
    padding-bottom: 1.25em!important;
}

/******************** ITEMS DA NAVEGACAO ********************/
.lista-navegacao-detalhamento-cliente{
    list-style-type: none!important;
    margin-left: -2.5em!important;
    cursor: pointer!important;
}

.container-item-navegacao-detalhamento-cliente-ativado{
    height: 3.6875em!important;
    display: flex!important;
    font-weight: 700!important;
    line-height: 1.875em!important;
    margin-top: 2.48em!important;
}

.container-item-navegacao-detalhamento-cliente-desativado{
    height: 3.6875em!important;
    display: flex!important;
    font-weight: 700!important;
    margin-top: 2.48em!important;
}

.container-item-navegacao-detalhamento-virtueyes-cliente-desativado{
    height: 3.6875em!important;
    display: flex!important;
    font-weight: 700!important;
    margin-top:  1.5em!important;
}

.container-item-navegacao-detalhamento-virtueyes-cliente-ativado{
    height: 3.6875em!important;
    display: flex!important;
    font-weight: 700!important;
    line-height: 1.875em!important;
    margin-top: 1.48em!important;
}


.coluna-item-navegacao-detalhamento-cliente-1{
    width: 30%!important;
}

.coluna-item-navegacao-detalhamento-cliente-2{
    width: 70%!important;
}

.icone-item-navegacao-detalhamento-cliente{
    margin-top: 50%!important;
    margin-left: 50%!important;
    transform: translate(-50%, -30%)!important;
}

.label-item-navegacao-detalhamento-cliente{
    line-height: 3.4375em!important;
}


/******************** TELAS DO DETALHAMENTO ********************/
.conteudo-detalhamento-cliente{
    width: calc(100% - 11.3125em)!important;
}

.container-tela-sem-filtro-detalhamento-cliente{
    z-index: 1!important;
    height: 24.8em!important;
    width: 100%!important;
    position: relative!important;
}

.container-tela-conteudo-detalhamento-cliente{
    margin-left: 0.9375em!important;
    min-width: calc(100% - 1.875em)!important;
    width: 116em!important;
}

.crud-cliente-container-tela-conteudo-detalhamento{
    margin-left: 0.9375em!important;
    min-width: calc(100% - 1.875em)!important;
    width: 83vw!important;
}

.icone-label-filtro-detalhamento-cliente{
    height: 0.375em!important;
    width: 0.375em!important;
    margin-right: 0.3125em!important;
    margin-bottom: 0.3125em!important;
    border-radius: 6.25em!important;
    background-color: #36D896!important;
}

.container-tela-corpo-detalhamento-cliente-1{
    height: 23em!important;
    width: 100.8%!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
}
.container-tela-corpo-detalhamento-cliente-1-novo{
    width: 100.8%!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
}
.container-tela-corpo-detalhamento-cliente-2{
    height: 23em!important;
    width: 100.8%!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
}

/*DADOS DO CLIENTE - NOVO - TELA CLIENTES N3*/
.container-dados-cliente-wrapper{
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}
.dados-cliente{
    display: flex;
    flex-direction: column;
    background-color: #F4F6F5;

    border-radius: 10px;

    margin-top: 16px;
    padding: 16px;
}
.dados-cliente-noturno{
    display: flex;
    flex-direction: column;
    background-color: #2E2E2E;

    border-radius: 10px;

    margin-top: 16px;
    padding: 16px;
}

.cadastro-label{
    color: #243B53;
    font-weight: 400;
    font-size: 29px !important;
}

.cadastro-label-noturno{
    color: #D9E2EC;
    font-weight: 400;
    font-size: 29px !important;
}

.cadastro-content{
    display: flex;
    flex-direction: column;
    gap: 8px;

    border-bottom: solid 1px rgba(0, 0, 0, 0.12);

    padding-bottom: 15px;

    color: #4F6275;
    font-weight: 400;
    font-size: 20px !important;
}

.border-none{
    border: none !important;
}

.cadastro-content-text{
    font-size: 20px !important;
    font-weight: 400;
}

.cadastro-content-noturno{
    display: flex;
    flex-direction: column;
    gap: 8px;

    padding-bottom: 10px;
    border-bottom: solid 1px rgba(253, 252, 252, 0.65);

    color: #F8FAFB;
    font-weight: 400;
    font-size: 20px !important;
}

.caixa-mapa-background{
    background-color: #F4F6F5;
    border-radius: 10px;
    padding: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.caixa-mapa-background-noturno{
    background-color: #2E2E2E;
    border-radius: 10px;
    padding: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.caixa-mapa{
    display: flex;
    width: 67vw;
    height: 450px;
}

/******************** DADOS DO CLIENTE ********************/
.container-dados-cliente{
    width: 100%!important;
    height: 21.8em!important;
    display: flex!important;
}

.container-dados-cliente-novo{
    width: 100%!important;
    display: flex!important;
    gap: 20px;
}

.container-dados-cliente-telas{
    margin-top: 1em!important;
    height: 21.75em!important;
    margin-bottom: 1em!important;
    width: 100%!important;
    display: flex!important;
}

.container-dados-cliente-cadastro{
    border-radius: 0.3125em!important;
    width: 100%!important;
    height: 23.6875em!important;
    margin-right: 1%!important;
    padding: 1.5em 2em 2em 2em!important;
}

.container-dados-cliente-contato{
    border-radius: 0.3125em!important;
    width: 48.5%!important;
    height: 23.6875em!important;
    padding: 1em 2em 2em 2em!important;
}

.divisoria-detalhamento-cliente{
    height: 0.0625em!important;
}

.label-dados-cliente {
    width: 100% !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    margin-left: 5px!important;
}

.label-dados-cliente-2 {
    margin-left: 5px !important;
}

.label-dados-cliente-3 {
    margin-left: 5px !important;
}

.caixa-detalhamento-cliente-1{
    border-left: 0.3125em solid #36D896!important;
    padding-left: 2.25em!important;
    padding-right: 1.25em!important;
    height: 7.5em!important;
    width: 50% !important;
}

.caixa-detalhamento-cliente-2{
    border-left: 0.3125em solid #36D896!important;
    padding-left: 2.25em!important;
    padding-right: 1.25em!important;
    height: 5em!important;
    width: 50% !important;
    margin-left: 4px !important; 
}

.grupo-detalhamento-cliente-1 {
    display: flex!important;
    justify-content: space-between!important;
    height: 2.4em!important;
    padding-top: 0.6em!important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.grupo-detalhamento-cliente-2{
    display: flex!important;
    justify-content: space-between!important;
    height: 2em!important;
    padding-top: 2.4em!important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.coluna-detalhamento-cliente-1{
    display: flex!important;
}

.coluna-detalhamento-cliente-2{
    display: flex!important;
    width: 75% !important;
}

/******************** VISAO GERAL ********************/
.container-visao-geral{
    width: 100%!important;
    height: 21.8em!important;
    display: flex!important;
}

.container-visao-geral-telas{
    height: 21.75em!important;
    margin-bottom: 1em!important;
    width: 100%!important;
    display: flex!important;
}

.container-visao-geral-resumo{
    margin-top: 1em!important;
    border-radius: 0.3125em!important;
    width: 49.25%!important;
    height: 108.85%!important;
    margin-right: 1.5%!important;
    padding: 1em 2em 2em 2em!important;
}

.container-visao-geral-pedido{
    margin-top: 1em!important;
    border-radius: 0.3125em!important;
    width: 48.5%!important;
    height: 108.85%!important;
    padding: 1em 2em 2em 2em!important;
}

.container-tabela-visao-geral{
    border-left: 0.3125em solid #36D896!important;
    overflow: auto!important;
    padding-left: 2.25em!important;
    max-height: 17.5em!important;
    height: 17.5em!important;
    overflow-x: hidden!important;
}

/******************** INFORMACOES DE LOGIN ********************/
.container-informacoes-login{
    width: 100%!important;
    height: 21.8em!important;
    display: flex!important;
}

.container-informacoes-login-telas{
    height: 21.75em!important;
    margin-bottom: 1em!important;
    width: 100%!important;
    display: flex!important;
}

.container-informacoes-login-resumo{
    border-radius: 0.3125em!important;
    width: 94vw !important;
    height: 23.6875em!important;
    padding: 1em 2em 2em 2em!important;
    margin-top: 1em!important;
}



.container-tabela-informacoes-login{
    border-left: 0.3125em solid #36D896!important;
    overflow: auto!important;
    padding-left: 2.25em!important;;
    max-height: 17.5em!important;
    height: 17.5em!important;
    overflow: auto!important;
}

.container-tabela-informacoes-login-usuarios{
    overflow: auto!important;
    margin-left: -1.7em;
    padding-left: 0.5em!important;
    max-height: 16.5em!important;
    height: 16.5em!important;
    overflow: auto!important;
}

.informacoes-login-usuario-img{
    width: 2em!important;
    height: 2em!important;
    border-radius: 50%!important;
    margin-right: 0.625em!important;
}

.status-detalhemento-cliente{
    line-height: 0em!important;
    margin-bottom: 1.5em!important;
    margin-top: 1.5em!important;
}

.label-tabela-detalhamento-cliente-1{/* TEXTO DO CORPO DAS TABELAS */
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
    line-height: 1.5em!important;
    margin-top: -0.8em;
    margin-bottom: -1em;
}

.label-tabela-detalhamento-cliente-1-novo{/* TEXTO DO CORPO DAS TABELAS */
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
    line-height: 5.5em!important;
    margin-top: -0.8em;
    margin-bottom: -1em;
}

.label-janela-contato{
    margin-top: 20px !important;
}

.col-contato{
    margin-left: 5px !important;
}

.caixa-detalhamento-cliente-3 {
    border-left: 0.3125em solid #36D896!important;
    width: 49% !important;
    height: 20em !important;
    float: right !important;
    margin-top: -19.2em !important;
    margin-right: 0em !important;
}

.container-dados-mapa{
    margin-left: 30px !important;
    width: 96% !important;
    height: 20em !important;
}
