.container-tela-inicial{
    overflow: hidden !important;
    height: 100% !important;
    width: 99.2% !important;
    border-radius: 5px !important;
}

.texto-bem-vindo{
    font-family: ObjectivityBold;
    font-size: 2.5em !important;
    line-height: 2.5em;
    position: relative;
    top: 2% !important;
    left: 2.4% !important;
}

.container-imagem-tela-inicial{
    height: 100% !important;
    width: 100% !important;
}

.imagem-tela-inicial {
    width: 120% !important;
    height: 141% !important;
    margin-top: 6% !important;
    margin-left: -23% !important;
}

.imagem-tela-inicial-algar {
    width: 60% !important;
    height: 86% !important;
    margin-top: -1% !important;
    margin-left: 20% !important;
}