/********** CLASSES DA NAVEGACAO DO SISTEMA **********/
/* Sempre verifique se a classe que você procura já  */
/* existe. Este arquivo ainda precisa ter suas       */
/* renomeadas de modo genérico e organizadas de modo */
/* a facilitar a manutenção e compreensão.           */
/***** MATEUS FOLLETTO - 26/05/2020 - VIRTUEYES ******/
/******************** NAVEGACAO DO SISTEMA ********************/

.container-navegacao-sistema-1,
.container-navegacao-sistema-algar {
    width: 15.9375em!important;
    overflow-x: hidden!important;
    overflow-y: auto!important;
}

.container-navegacao-sistema-1{/* NAVEGACAO DO SISTEMA EXPANDIDA */
    height: 100%!important;
}
.container-navegacao-sistema-algar{/* NAVEGACAO DO SISTEMA EXPANDIDA */
    height: 95%!important;
}

.container-navegacao-sistema-2{/* NAVEGACAO DO SISTEMA ENCOLHIDA */
    width: 3.9375em!important;
    height: 100%!important;
    overflow-x: hidden!important;
    overflow-y: hidden!important;
}

.container-logo-veye{/* CONTAINER DO LOGO DA VEYE */
    height: 5.9375em!important;
    width: 15.9375em!important;
    cursor: pointer!important;
}

.logo-veye-virtueyes-1 {
    width: 70px !important;
    margin-left: 56px !important;
    margin-top: 35px !important;
}

.logo-veye-virtueyes-2 {
    width: 70px !important;
    margin-left: 63px !important;
    margin-top: 37px !important;
}

.logo-veye-eye{/* EYE DO LOGO DA VEYE */
    margin: 1.875em!important;
    margin-left: 6.5em!important;
    width: 5.6875em!important;
    height: 2em !important;
    margin-top: 10px !important;
}

.logo-veye-v-1{/* V DO LOGO DA VEYE EXPANDIDO */
    margin-top: -7.5em!important;
    margin-left: 3.85625em!important;
    width: 2.3125em!important;
}

.logo-veye-v-2{/* V DO LOGO DA VEYE ENCOLHIDO */
    margin-top: -7.5em!important;
    margin-left: 1.04375em!important;
    width: 2.3125em!important;
}

.logo-algar {
    margin-left: -9em!important;
    margin-top: 2.4em;
}

.logo-algar-default{
    margin-left: 0.6em!important;
    margin-top: 1em;
    transition: 0.5s!important;
    transition: 0.5s!important;
}

.logo-algar-1{/* V DO LOGO DA VEYE EXPANDIDO */
    margin-top: 2.5em!important;
    margin-left: 2.6em!important;
    width: 10em!important;
    transition: 0.5s!important;
}
.logo-algar-2{/* V DO LOGO DA VEYE MINIMIZADO */
    margin-top: 2.6em!important;
    margin-left: -7em!important;
    width: 0em!important;
    /* position: absolute !important; */
}

.container-logo-algar{
    width: 1em !important;
    display: flex;
}

.powered-veye{
    width: 11em !important;
    margin-left: 31px !important;
    /* margin-bottom: 35px !important; */
    margin-top: 19px !important;
}

.powered-veye-default{
    width: 0em !important;
    margin-left: 35px !important;
    margin-bottom: 35px !important; 
    margin-top: 10px !important;
    transition: 0.5s !important;
}


.container-usuario-logado{/* CONTAINER DE USUARIO LOGADO */
    /* margin-top: 29%!important; */
    /* margin-bottom: 8%!important; */
    text-align: center!important;
}

.container-usuario-logado-2{/* CONTAINER DE USUARIO LOGADO */
    /* margin-top: 29%!important; */
    /* margin-bottom: 8%!important; */
    text-align: center!important;
}

.icone-usuario-logado-1{/* IMAGEM DO USUARIO LOGADO EXPANDIDA */
    width: 8em!important;
    height: 8em!important;
    border-radius: 100%!important;
    /* border: 0.375em solid #36D896!important; */
}

.icone-usuario-logado-2{/* IMAGEM DO USUARIO LOGADO ENCOLHIDA */
    /* margin-top: 1.5625em!important;
    margin-bottom: -1.5625em!important; */
    width: 3em!important;
    height: 3em!important;
    border-radius: 100%!important;
    /* border: 0.1875em solid #36D896!important; */
}

.container-usuario-logado-dados{/* CONTAINER DOS DADOS DO USUARIO */
    width: 15.9375em!important;
}

.container-usuario-logado-dados-2{/* CONTAINER DOS DADOS DO USUARIO recolhido */
    width: 3.8em!important;
}

.usuario-logado-dados-nome-1{/* NOME DO USUARIO LOGADO EXPANDIDO */
    color: #FFFFFF!important;
    font-size: 1em!important;
    font-family: RobotoBold!important;
    margin-top: 2.5em!important;
}

.usuario-logado-dados-nome-2{/* NOME DO USUARIO LOGADO ENCOLHIDO */
    visibility: hidden!important;
    display: none !important;
}

.usuario-logado-dados-email-1{/* EMAIL DO USUARIO LOGADO EXPANDIDO */
    font-size: 0.75em!important;
    font-family: RobotoRegular!important;
}

.usuario-logado-dados-email-2{/* EMAIL DO USUARIO LOGADO ENCOLHIDO */
    visibility: hidden!important;
    display: none !important;
}

.container-dropdown-perfil-cabecalho-sistema{
    margin-top: -1.5625em;
    margin-left: 5.4375em;
}
.container-dropdown-perfil-cabecalho-sistema-2{
    margin-top: 0;
}


/******************** ITENS DA NAVEGACAO ********************/
.icone-menu{
    align-self: normal;
    margin-left: 2.5em;
    width: 1.375em;
    height: 1.375em;
    margin-top: 1.3em;
}

.icone-menu-filtro{
    margin-left: 0.8em;
    width: 1.375em;
    margin-top: -0.2em;
}

.icone-menu-filtro-arquivo{
    align-self: normal;
    margin-left: 0.5em;
    width: 1.2em;
    height: 1.375em;
    margin-top: -0.4em;
    margin-right: -0.999em;
}

.icone-menu-invisivel{
    visibility: hidden!important;
    display: none!important;
}

.icone-menu-filho{
    margin-left: 2.9375em;
    margin-right: 0.3125em;
    width: 0.625em!important;
}

.icone-menu-tela-filho-1{
    margin-left: 3.8125em !important;
    margin-right: 0.3125em !important;
    width: 0.625em!important;
}

.icone-menu-tela-filho-2{
    margin-left: 2.9375em !important;
    margin-right: 0.3125em !important;
    width: 0.625em!important;
}

.menu-links{
    margin-left: -2.8125em;
}

.menu-expandido-item:hover{
    font-family: RobotoMedium;
    cursor: pointer!important;
    text-decoration: none!important
}
  
.menu-expandido-item-filho:hover{
    font-family: RobotoMedium;
    list-style: none!important;
}
  
.menu-expandido-item-filho{
    font-family: RobotoMedium;
    font-size: 1.0625em;
    line-height: 270%!important;
    align-items: center;
    width: 17.1875em!important;
}

.badge-novidade-menu-lateral{
    /* top: -1em !important; */
    border-radius: 6.25em!important;
    line-height: 0.625em!important;
    margin-right: 50px!important;
}

.menu-expandido-item-tela-filho:hover{
    font-family: RobotoMedium;
    list-style: none!important;
}

.menu-expandido-item-tela-filho{
    font-family: RobotoMedium;
    font-size: 1.0625em;
    line-height: 270%!important;
    align-items: center;
    width: 17.1875em!important;
    list-style: none!important;
}
  
.menu-expandido-item {
    font-family: RobotoMedium;
    font-size: 1.0625em;
    line-height: 420%!important;
    color: #FFF;
    align-items: baseline;
    background-color: transparent;
    transition: 0.5s, ease;
    width: 17.1875em!important;
}

/* CSS COMUM À VÁRIAS CLASSES ------------------------------- */
.container-navegacao-sistema-1,
.container-navegacao-sistema-algar,
.container-navegacao-sistema-2,
.logo-veye-v-1,
.logo-veye-v-2,
.logo-algar,
.logo-algar-2,
.icone-usuario-logado-1,
.icone-usuario-logado-2 {
    -webkit-transition: 0.5s!important;
    -moz-transition: 0.5s!important;
    -o-transition: 0.5s!important;
    transition: 0.5s!important;
}

.menu-expandido-item:hover, 
.menu-expandido-item-filho:hover, 
.menu-expandido-item-filho, 
.menu-expandido-item-tela-filho:hover,
.menu-expandido-item-tela-filho,
.menu-expandido-item {
    -webkit-transition: none!important;
    -moz-transition: none!important;
    -o-transition: none!important;
    transition: none!important;
}
/* ---------------------------------------------------------- */
  
.menu-expandido-item-filho:hover{
    text-decoration: none!important;
    cursor: pointer;
}

.menu-expandido-item-filho:focus{
    color:#36D896!important;
}

.menu-expandido-item-tela-filho span{
    list-style: none!important;
}

.menu-expandido-item-tela-filho:hover{
    list-style: none!important;
    text-decoration: none!important;
    cursor: pointer;
}

.menu-expandido-item-tela-filho:focus{
    color:#36D896!important;
    list-style: none!important;
}

/* TOOL TIPS */
.tooltip-menu-navegacao{
    position: absolute!important;
    margin-left: -60px!important;
    margin-top: 0px!important;
}

.tooltip-menu-navegacao-2{ /* barra de menu recolhida */
    position: absolute!important;
    margin-left: -210px!important;
    margin-top: 0px!important;    
}

/******************** INFORMACOES DO USUARIO ********************/
.botao-expandir-infos-usuario{
    margin-top: -13.125em!important;
    margin-right: -5.625em!important;
    height: 1.625em!important;
    width: 1.625em!important;
    border-radius: 6.25em!important;
    /* background-color: #36D896!important; */
    box-shadow:  none!important;
    border: 0em!important;
}

.botao-expandir-infos-usuario-2{ /* barra recolhida */
    margin: -25px 0 0 35px !important;
    height: 1.625em!important;
    width: 1.625em!important;
    border-radius: 6.25em!important;
    background-color: #36D896!important; 
    box-shadow:  none!important;
    border: 0em!important;
}

.botao-expandir-infos-usuario:focus, .botao-expandir-infos-usuario:active, .botao-expandir-infos-usuario:hover{
    margin-top: -13.125em!important;
    margin-right: -5.625em!important;
    height: 1.625em!important;
    width: 1.625em!important;
    border-radius: 6.25em!important;
    /* background-color: #36D896!important; */
    box-shadow:  none!important;
    border: 0em!important;
}

.botao-expandir-infos-usuario-2:focus, .botao-expandir-infos-usuario-2:active, .botao-expandir-infos-usuario-2:hover{
    margin: -25px 0 0 35px !important;
    height: 1.625em!important;
    width: 1.625em!important;
    border-radius: 6.25em!important;
    /* background-color: #36D896!important; */
    box-shadow:  none!important;
    border: 0em!important;
}

.icone-botao-expandir-infos-usuario-desativado{
    height: 0.851875em!important;
    width: 0.851875em!important;
    margin-top: -0.9375em!important;
    margin-left: -0.5em!important;
    transform: rotate(270deg)!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important
}

.icone-botao-expandir-infos-usuario{
    height: 0.851875em!important;
    width: 0.851875em!important;
    margin-top: -1.25em!important;
    margin-left: -0.375em!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important
}

.icone-botao-expandir-infos-usuario-2{
    height: 0.851875em!important;
    width: 0.851875em!important;
    margin-top: -0.9em !important;
    margin-left: -0.35em !important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important
}

.container-infos-usuario{
    z-index: 1!important;
    position: relative!important;
    min-width: 10.9375em!important;
    max-width: 10.9375em!important;
    max-height: 6.875em!important;
    margin-top: -6.875em!important;
    margin-left: 2.5em!important;
}

.container-infos-usuario-2{ /* barra menu recolhida */
    z-index: 9999 !important;
    position: absolute !important;
    min-width: 10.9375em!important;
    max-width: 10.9375em!important;
    max-height: 6.875em!important;
    margin-top: -25px !important;
    margin-left: 40px !important;
}

.triangulo-infos-usuario{
    z-index: 1!important;
    width: 0!important;
    height: 0!important;
    border-left: 0.75em solid transparent!important;
    border-right: 0.75em solid transparent!important;
    border-top: 0.75em solid transparent!important;
    /* border-bottom: 0.75em solid #36D896!important; */
    margin-left: 69%!important;
}

.triangulo-infos-usuario-2{ /* barra menu recolhida */
    z-index: 9999 !important;
    width: 0!important;
    height: 0!important;
    border-left: 0.75em solid transparent!important;
    border-right: 0.75em solid transparent!important;
    border-top: 0.75em solid transparent!important;
    /* border-bottom: 0.75em solid #36D896!important; */
    margin-left: 1%!important;
}

.corpo-infos-usuario{
    /*min-height: 21.3125em!important;*/
    /*min-height: 17em!important;*/
    max-height: 21.3125em!important;
    background-color: #36D896!important;
    border-radius: 0.3125em!important;
}

.corpo-infos-usuario-algar{
    /* min-height: 21.3125em!important;  */
    min-height: 14em!important;
    max-height: 21.3125em!important;
    /* background-color: #36D896!important; */
    border-radius: 0.3125em!important;
}

.item-menu-info-usuario{
    padding-top: 0.625em!important;
    padding-bottom: 0.625em!important;
    text-align: left!important;
    /* background-color: #36D896!important; */
    cursor: pointer!important;
}

.item-menu-info-usuario-primeiro{
    padding-top: 0.625em!important;
    padding-bottom: 0.625em!important;
    text-align: left!important;
    background-color: transparent!important;
    border-radius: 0.3125em 0.3125em 0em 0em!important;
    cursor: pointer!important;
}
.item-menu-info-usuario-primeiro-desabilitado{
    padding-top: 0.625em!important;
    padding-bottom: 0.625em!important;
    text-align: left!important;
    background-color: #646C69!important;
    border-radius: 0.3125em 0.3125em 0em 0em!important;
    cursor: pointer!important;
}

.item-menu-info-usuario-ultimo{
    padding-top: 0.625em!important;
    padding-bottom: 0.625em!important;
    text-align: left!important;
    /* background-color: #36D896!important; */
    border-radius: 0em 0em 0.3125em 0.3125em!important;
    cursor: pointer!important;
}


.item-menu-info-usuario-desabilitado{
    padding-top: 0.625em!important;
    padding-bottom: 0.625em!important;
    text-align: left!important;
    background-color: #646C69!important;
    cursor: pointer!important;
}

.item-menu-info-usuario-primeiro:hover{
    background-color: #21875E!important;
    cursor: pointer!important;
}

.item-menu-info-usuario-ultimo:hover{
    background-color: #21875E!important;
    cursor: pointer;
}

.item-menu-info-usuario:hover{
    background-color: #21875E!important;
}

.fonte-info-usuario{
    margin-left: 1.125em!important;
    font-weight: 700!important;
    letter-spacing: 0.02875em!important;
    font-size: 1em!important;
    line-height: 1em!important;
    color: black!important;
    font-family: RobotoRegular!important;
}

.container-info-usuario-status{
    display: flex!important;
    justify-content: space-evenly!important;
}

.fonte-info-usuario-status{
    margin-top: 0.625em!important;
    font-size: 0.875em!important;
    line-height: 1em!important;
    color: black!important;
    font-family: RobotoRegular!important;
}

.linha-menu-info-usuario{
    background-color: black!important;
    height: 0.0725em!important;
    margin-left: 1.125em!important;
    width: 78%!important;
}

.campo-select-icone-desativado-tela{
    max-height:0!important;
    overflow:hidden!important;
    margin-left: -2.5em!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
}


.dropdown-desativado-tela{
    max-height:0!important;
    overflow:hidden!important;
    margin-left: -2.5em!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
}
  
.dropdown-ativado-tela{
    max-height: 50em!important;
    margin-left: -2.5em!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important;
}

.container-menus-navegacao{
    height: calc(100% - 22em);
    overflow-x: hidden;
    overflow-y: auto;
    transition: 0.5s;
}

.container-menus-navegacao-1{
    height: 75%;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 0.5s;
}

.nav{
    height: 100%;
}

.container-logo-veye-2{
    display: flex;
    align-items: center;
}


.logo-navegacao-experience-1-1, .logo-navegacao-experience-1-2{
    margin-left: 0.5em;
    transition: 0.5s;
    height: 2em;
    width: 2em;
}

.logo-navegacao-experience-2-1,
.logo-navegacao-experience-2-2{
    transition: 0.5s;
    height: 2.2em;
    margin-left: -0.7em;
    margin-right: -0.7em;
}

.logo-navegacao-experience-2-1{
    width: 12em;
}

.logo-navegacao-experience-2-2{
    width: 0em;
}

.logo-navegacao-experience-3-1{
    transition: 0.5s;
    height: 2em;
    width: 2em;
}

.logo-navegacao-experience-3-2{
    transition: 0.5s;
    height: 2em;
    width: 2em;
}.botaoImportarDesabilitado{
    margin-left: 1.5625em !important;
    height: 2em !important;
    width: 7.75em !important;
    border-radius: 3.125em !important;
    border: none !important;
    cursor: pointer !important;
    background-position: 5.625em !important;
    background-repeat: no-repeat !important;
    font-family: Roboto !important;
    box-shadow: none !important;
    background-color: #787B7A!important;
    line-height: 1.3em !important;
}
.div-importar-pedido-faturas-2 {
    display: flex;
    margin-top: 1em;
    margin-left: 1em;
}

.logo-virtueyes-simbolo-1{
    margin-left: 2.7em;
    margin-top: 1.5em;
    transition: 0.5s;
    width: 12%;
}

.logo-virtueyes-simbolo-2{
    margin-left: 0.6em;
    margin-top: 1.5em;
    transition: 0.5s;
    width: 45px;
}

.logo-virtueyes-palavra-1{
    margin-top: 1.5em;
    margin-left: 0.5em;
    transition: 0.5s;
    width: 50%;
}

.logo-virtueyes-palavra-2{
    margin-top: 1.5em;
    margin-left: 1em;
    width: 0em;
    transition: 0.5s;
}

.logo-parceiro-1{
    margin-top: 1.5em;
    margin-left: 0.8em;
    transition: 0.5s;
    width: 90%;
    max-height: 100%;
}

/*.escuro{*/
/*    filter: invert(1);*/
/*}*/

.powered-virtueyes-container{
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 70%
}

.powered-virtueyes-texto{
    font-weight: 500;
}

.powered-virtueyes-imagem{
    width: 80%;
}