/** ARQUIVO CONTENDO AS CLASSES DE ESTILIZAÇÃO DA BARRA DE DADOS GENÉRICA
 * 
 * CRIADO POR: MATEUS FOLLETTO
 * EM: 2021-04-09
 * 
 * ULTIMA ALTERAÇÃO: 2021-04-09
 * POR: MATEUS FOLLETTO
 * MOTIVO: Criação do arquivo
 */

.barra-de-dados-generica{
    display: flex!important;
    justify-content: space-evenly!important;
    height: 2.9375em!important;
    width: 100%!important;
    padding: 0em 1em 0em 1em!important;
    margin: 0em 0em 1em 0em!important;
    border-radius: 0.3125em!important;
    position: relative!important;
}

.barra-de-dados-generica-container-dado{
    min-width: 5em!important;
    line-height: 3em!important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

.barra-de-dados-generica-titulo{
    font-family: RobotoRegular!important;
    font-size: 0.975em!important;
    font-weight: bold!important;
}

.barra-de-dados-generica-valor{
    font-family: RobotoRegular!important;
    font-size: 0.975em!important;
}