.container-mensagem{
    display: flex!important;
    float: left!important;
}

.data-hora-mensagem{
    width: 100%!important;
    margin-top: 1.0625em!important;
    text-align: right!important;
}

.icone-elipse-mensagem{
    margin-left:5%!important;
    margin-bottom:2%!important;
}

.fonte-data-hora-mensagem{
    font-family: Roboto!important;
    font-size: 0.875em!important;
}

.texto-mensagem{
    margin-top: 3%!important;
    width:100%!important;
    /* height:14em!important; */
    float:left!important;
    word-wrap: break-word;
    overflow-y: auto!important;
}

.container-mensagem{
    display: flex!important;
    margin-top: 1em!important;
    border-left: 0.4em solid #36D896!important;
    width: 100%!important;
    padding-left: 1em!important;
    padding-bottom: 1.5em!important;
}

.fonte-texto-mensagem{
    font-family: Roboto!important;
    font-size: 0.875em!important;
}
.titulo-mensagem{
    /* margin-top: 1%!important; */
    height: 4em!important;
    width: 100%!important;
    overflow-y: auto!important;
    overflow: hidden!important;
}

.fonte-titulo-mensagem{
    font-family: Roboto!important;
    font-size: 1.375em !important;
    text-align: left !important;
    font-weight: bold;
    display: table-cell!important;
    vertical-align: middle!important;
}

.footer1-mensagem{
    width:100%!important;
    display: flex!important;
    justify-content: center!important;
    margin-top: 4%!important;
    padding-right:5%!important;
}

.footer2-mensagem{
    width:100%!important;
    display: flex!important;
    justify-content: center!important;
    padding-right:5%!important;
}

.fonte-footer1-mensagem{
    height: 1em!important;
    color: #BDBDBD!important;
    font-family: Roboto!important;
    font-size: 0.78em!important;
    line-height: 1em!important;
}

.fonte-footer2-mensagem{
    height: 1em!important;
    color: #BDBDBD!important;
    font-family: Roboto!important;
    font-size: 0.78em!important;
    line-height: 1em!important;
    text-align: center !important;
    margin-top:0.5% !important;
}

.modal-mensagem{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    /* width: 43.625em!important; */
    overflow-x: auto !important;
    max-height: 60em !important;
    overflow-y: auto !important;
}

.icone-logo-redondo-mensagem {
    /* margin-left: 2%!important;
    margin-bottom: 2%!important; */
    margin-right: 5%!important;
    width: 4em!important;
    height: 4em!important;
    height: 100%!important;
    max-height: 4em!important
}

.container-logo-titulo-mensagem {
    display: flex;
}

.divisor-mensagem{
    background-color: #4c4a4a!important;
    margin-top: 2%!important;
    height: 0.0625em!important;
    width: 100%!important;
    margin-top:2em!important;
}


.elipse-amarelo-mensagem-tabela{
    transform: translate(-50%, -10%)!important;
    margin-top: 2%!important;
    margin-left: 15%!important;
    width: 0.85em!important;
    height: 0.85em!important;
    border-radius: 6.25em!important;
    background-color: #FDCB62!important;
    color: #FDCB62!important;
}

.elipse-verde-mensagem-tabela{
    transform: translate(-50%, -10%)!important;
    margin-top: 2%!important;
    margin-left: 15%!important;
    width: 0.85em!important;
    height: 0.85em!important;
    border-radius: 6.25em!important;
    background-color: #36D896!important;
    color: #36D896!important;
}

.elipse-vermelho-mensagem-tabela{
    transform: translate(-50%, -10%)!important;
    margin-top: 2%!important;
    margin-left: 15%!important;
    width: 0.85em!important;
    height: 0.85em!important;
    border-radius: 6.25em!important;
    background-color: #CD2D37!important;
    color:#CD2D37!important;
}

.filtro-mensagem-importancia-esfera-verde{/* USADA PARA POSICIONAR A ESFERA CORRETAMENTE DENTRO DO FILTRO */
    margin-left: 3.40em!important;
    width: 1em!important;
    height: 1em!important;
    border-radius: 50%!important;
    background-color: #36D896!important;
}

.filtro-mensagem-importancia-esfera-amarela{/* USADA PARA POSICIONAR A ESFERA CORRETAMENTE DENTRO DO FILTRO */
    margin-left: 4.2em!important;
    width: 1em!important;
    height: 1em!important;
    border-radius: 50%!important;
    background-color: #FDCB62!important;
}

.filtro-mensagem-importancia-esfera-vermelha{/* USADA PARA POSICIONAR A ESFERA CORRETAMENTE DENTRO DO FILTRO */
    margin-left: 3.7em!important;
    width: 1em!important;
    height: 1em!important;
    border-radius: 50%!important;
    background-color: #CD2D37!important;
}

.filtro-central-mensagens-importancia-esfera-verde{/* USADA PARA POSICIONAR A ESFERA CORRETAMENTE DENTRO DO FILTRO */
    margin-left: 3.40em!important;
    width: 1em!important;
    height: 1em!important;
    border-radius: 50%!important;
    background-color: #36D896!important;
}

.filtro-central-mensagens-importancia-esfera-amarela{/* USADA PARA POSICIONAR A ESFERA CORRETAMENTE DENTRO DO FILTRO */
    margin-left: 1em!important;
    width: 1em!important;
    height: 1em!important;
    border-radius: 50%!important;
    background-color: #FDCB62!important;
}

.filtro-central-mensagens-importancia-esfera-vermelha{/* USADA PARA POSICIONAR A ESFERA CORRETAMENTE DENTRO DO FILTRO */
    margin-left: 1.5em!important;
    width: 1em!important;
    height: 1em!important;
    border-radius: 50%!important;
    background-color: #CD2D37!important;
}



.grupo-mensagem-1{
    display: flex!important;
    width: 100%!important;
}

.coluna-mensagem-1{
    width: 75%!important;
}

.coluna-mensagem-2{
    width: 25%!important;
}
