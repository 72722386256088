/******************** GERAL ********************/


/******************** CONTAINERES GRAFICOS TOPO ********************/
.container-dashboard-topo{
    height: 43.35%!important;
    width: 100%!important;
    border-radius: 0.3125em!important;
    margin-bottom: 1%!important;
    padding-top: 0.5em!important;
    padding-left: 1.5625em!important;
    padding-right: 0.625em!important;
}

.container-dashboard-topo-2{
    height: 42%!important;
    width: 100%!important;
    border-radius: 0.3125em!important;
    margin-bottom: 1%!important;
    padding-top: 0.5em!important;
    padding-left: 1.5625em!important;
    padding-right: 0.625em!important;
}

.container-graficos-topo{
    width: 100%!important;
    height: 86%!important;
    position: relative!important;
    display: flex!important;
}

/******************** GRAFICOS TOPO ********************/
.coluna-graficos-topo-1{
    height: 100%!important;
    width: 13%!important;
}

.coluna-graficos-topo-2{
    height: 100%!important;
    width: 85%!important;
    zoom: 125%!important;
}

.linha-grafico-topo{
    width: 100%!important;
    height: 25%!important;
    display: flex!important;
}

.container-grafico-pizza-topo{
    height: 100%!important;
    width: 40%!important;
    display: relative!important;
}

.label-percentual-graficos-pizza-topo{
    height: 1.1875em!important;
    width: 1.875em!important;
    font-family: Roboto!important;
    font-size: 1em!important;
}

.container-legenda-grafico-pizza-topo{
    width: 70%!important;
    height: 100%!important;
}

.container-titulo-legenda-grafico-pizza-topo{
    width: 100%!important;
    position: relative;
    top: 50%!important;
    transform: translateY(-100%)!important;
}

.texto-titulo-legenda-grafico-pizza-topo{
    width: 100%!important;
    font-family: Roboto!important;
    font-size: 1em!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

.container-corpo-legenda-grafico-pizza-topo{
    width: 100%!important;
    position: relative;
    top: 50%!important;
    transform: translateY(-100%)!important;
}

.texto-corpo-legenda-grafico-pizza-topo{
    width: 100%!important;
    font-family: Arial!important;
    font-size: 1.125em!important;
    line-height: 1.3125em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

/******************** CONTAINERES GRAFICOS FUNDO ********************/
.container-dashboard-fundo-4{
    height: 45%!important;
    width: 100%!important;
    display: flex!important;
    margin-bottom: 0.5%!important;
}

.container-dashboard-faturas-fundo-1{
    height: 100%!important;
    width: 50%!important;
    border-radius: 0.3125em!important;
    margin-right: 1%!important;
    padding-left: 0.625em!important;
    padding-right: 0.625em!important;
}

.container-dashboard-faturas-fundo-2{
    height: 100%!important;
    width: 100%!important;
    border-radius: 0.3125em!important;
    padding-left: 0.625em!important;
    padding-right: 0.625em!important;
}

.container-dashboard-faturas-fundo-3{
    height: 100%!important;
    width: 50%!important;
    border-radius: 0.3125em!important;
    padding-right: 0.625em!important;
    padding-left: 0.625em!important;
}

/******************** GRAFICOS GRANDES FUNDO ********************/
.grupo-grafico-pizza-grande{
    position: relative!important;
    display: flex!important;
    height: 83%!important;
}

.container-grafico-pizza-grande{
    position: relative!important;
    height: 100%!important;
    width: 100%!important;
    align-content: center!important;
    z-index: 10!important;
}

.container-legenda-grafico-pizza-grande{
    position: absolute!important;
    margin-top: 0%!important;
    margin-right: 3%!important;
    text-align: right!important;
    float: right!important;
    width: 97%!important;
    bottom: 0!important;
    right: 0!important;
}

.container-titulo-legenda-grafico-pizza-grande{

}

.texto-titulo-legenda-grafico-pizza-grande{
    font-family: Roboto!important;
    font-size: 1.5em!important;
    line-height: 1.1875em!important;
    text-align: right!important;
}

.container-corpo-legenda-grafico-pizza-grande{
    
}

.texto-corpo-legenda-grafico-pizza-grande{
    font-family: Roboto!important;
    font-size: 2.75em!important;
    font-weight: 700!important;
    text-align: right!important;
}

/******************** GRAFICOS SEM COMUNICAR ********************/
.container-simcards-sem-comunicar{
    width: 100%!important;
    height: 100%!important;
    display: flex!important;
}

.container-grafico-pizza-sem-comunicar{
    height: 100%!important;
    width: 50%!important;
}

.caixa-grafico-pizza-sem-comunicar{
    height: 100%!important;
    width: 100%!important;
}

.linha-legenda-sem-comunicar{
    display: flex!important;
    width: 100%!important;
    margin-top: 3%!important;
    margin-bottom: 3%!important;
}

.coluna-legenda-sem-comunicar-1{
    padding-top: 0.8%!important;
    width: 10%!important;
}

.coluna-legenda-sem-comunicar-2{
    width: 14em!important;
}

.coluna-legenda-sem-comunicar-2-faturas{
    width: 7em!important;
}

.coluna-legenda-sem-comunicar-3{
    width: 23%!important;
    text-align: left!important;
}

.texto-legenda-sem-comunicar{
    font-family: Roboto!important;
    font-size: 1em!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

.texto-legenda-sem-comunicar-numero-faturas{
    font-family: Roboto!important;
    font-size: 1.3em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

/******************** PLANOS CONTRATADOS ********************/
.container-simcards-planos-contratados{
    width: 100%!important;
    height: 100%!important;
}

.container-grafico-pizza-planos-contratados{
    height: 35%!important;
    width: 100%!important;
    display: flex!important;
    padding-left: 5%!important;
    padding-right: 5%!important;
}

.caixa-grafico-pizza-planos-contratados{
    height: 100%!important;
    width: 23%!important;
    margin-left: 1%!important;
    margin-right: 1%!important;
}

.container-legenda-planos-contratados{
    width: 100%!important;
    padding-left: 8%!important;
}

.linha-legenda-planos-contratados{
    display: flex!important;
    width: 100%!important;
    margin-top: 3%!important;
    margin-bottom: 3%!important;
}

.coluna-legenda-planos-contratados-1{
    padding-top: 0.8%!important;
    width: 10%!important;
}

.coluna-legenda-planos-contratados-2{
    width: 12em!important;
}

.coluna-legenda-planos-contratados-3{
    width: 15%!important;
    text-align: right!important;
}

.texto-legenda-planos-contratados{
    font-family: Roboto!important;
    font-size: 1em!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

.texto-legenda-planos-contratados-numero{
    font-family: Roboto!important;
    font-size: 1.375em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

/******************** STATUS CONTRATO E TOTAL SIMCARDS (OPERADORAS) ********************/
.container-grafico-pizza-status-contratos{
    height: 35%!important;
    width: 100%!important;
    display: flex!important;
    justify-content: center!important;
}

.caixa-grafico-pizza-status-contratos{
    height: 100%!important;
    width: 18%!important;
    margin-left: 3%!important;
    margin-right: 3%!important;
}

/******************** STATUS DE TRAFEGO ********************/
.container-simcards-status-trafego{
    width: 100%!important;
    height: 100%!important;
}

.container-grafico-pizza-status-trafego{
    height: 50%!important;
    width: 100%!important;
    display: flex!important;
    justify-content: center!important;
}

.caixa-grafico-pizza-status-trafego{
    height: 100%!important;
    width: 28%!important;
    margin-left: 3%!important;
    margin-right: 3%!important;
}


/******************** BOTOES PAGINACAO ********************/

/******************** BOTOES OPCOES ********************/
.bota-opcoes{
    height: 1.875em!important;
    width: 1.875em!important;
    border: 0em solid transparent!important;
    box-shadow: none!important;
    margin-left: 0.625em!important;
    float: right!important;
    background-color: transparent!important;
}

.data-ciclo{
    float: right!important;
    line-height: 40px!important;
    font-weight: 700!important;
}

/******************** OUTROS ********************/
.label-janela-dashboard{
    font-family: Roboto!important;
    font-size: 1.25em!important;
    font-weight: 700!important;
    line-height: 2.3125em!important;
}

.label-janela-dashboard-2{
    font-family: Roboto!important;
    font-size: 1.13em!important;
    font-weight: 700!important;
    line-height: 2.3125em!important;
    margin-left: 0.625em!important;
}

.label-percentual-graficos-pizza-composto{
    font-size: 2.4em!important;
    text-align: center!important;
}

.label-percentual-graficos-pizza-grande{
    font-size: 4.6875em!important;
    text-align: center!important;
}

.label-percentual-graficos-pizza-grupo-cinco{
    font-family: Roboto!important;
    font-size: 1.25em!important;
    line-height: 1.4375em!important;
}

.label-percentual-graficos-pizza-grupo-quatro{
    font-family: Roboto!important;
    font-size: 1.5625em!important;
    line-height: 1.4375em!important;
}

.container-tooltip-grafico-pizza{
    background-color: #1F8EFA!important;
    border-radius: 0.625em!important;
    position: relative!important;
    float: right!important;
}

.label-tooltip-grafico-pizza{
    margin-top: 0.625em!important;
    margin-left: 0.625em!important;
    margin-right: 0.625em!important;
    height: 1.3125em!important;
    font-family: RobotoRegular!important;
    font-size: 1.125em!important;
    color: #ffffff!important;
}

.esfera-legenda-dashboard{
    width: 1.125em!important;
    height: 1.125em!important;
    border-radius: 50%!important;
}

.carrossel-dashboard{
    width: 100%!important;
    height: 100%!important;
}

.carrossel-dashboard>.carousel-inner{
    position: relative!important;
    width: 100%!important;
    height: 100%!important;
}

.item-carrossel-dashboard{
    width: 100%!important;
    height: 100%!important;
}

.container-conexoes-status-contratos{
    width: 100%!important;
    height: 100%!important;
}

.carrossel-dashboard>.carousel-control-prev{
    margin-left: -7%!important;
}

.carrossel-dashboard>.carousel-control-next{
    margin-right: -7%!important;
}

.carrossel-dashboard>.carousel-control-prev:hover{
    margin-left: -7%!important;
}

.carrossel-dashboard>.carousel-control-next:hover{
    margin-right: -7%!important;
}

.carrossel-dashboard>.carousel-control-prev:focus{
    margin-left: -7%!important;
}

.carrossel-dashboard>.carousel-control-next:focus{
    margin-right: -7%!important;
}

.carrossel-dashboard>.carousel-control-prev:active{
    margin-left: -7%!important;
}

.carrossel-dashboard>.carousel-control-next:active{
    margin-right: -7%!important;
}

.carrossel-dashboard .carousel-control-prev-icon{
    background-image: url("../../../icones/iconesVersaoVerde/icone-maior.svg")!important;
    transform: rotate(180deg)!important;
}

.carrossel-dashboard .carousel-control-next-icon{
    background-image: url("../../../icones/iconesVersaoVerde/icone-maior.svg")!important;
}

.menu-opcoes-dashboard-topo{
    position: absolute!important;
    right: 0!important;
    width: 140px;
    height: 100px!important;
}

.menu-opcoes-dashboard-fundo{
    position: absolute!important;
    right: 0!important;
    width: 205px;
    height: 100px!important;
}

.menu-opcoes-triangulo-topo{
    width: 0!important;
    height: 0!important;
    border-top: 0.9375em solid transparent!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-left: 5.24em!important;
    margin-top: -1.9em!important;
}

.menu-opcoes-triangulo{
    width: 0!important;
    height: 0!important;
    border-top: 0.9375em solid transparent!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-left: 11.24em!important;
    margin-top: -1.9em!important;
}

.menu-opcoes-corpo-topo{
    width: 120px!important;
    max-height: 90vh!important;
    border-radius: 5px!important;
}

.menu-opcoes-corpo{
    width: 215px!important;
    max-height: 90vh!important;
    border-radius: 5px!important;
}

.menu-opcoes-icone-desativado{
    margin-left: 10px!important;
    margin-top: -6px!important;
    transform: rotate(-90deg);
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important
}

.menu-opcoes-icone-ativado{
    margin-left: 10px!important;
    margin-top: -6px!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important
}

.menu-opcoes-sub-label{
    margin-left: 40px!important;
    height: 30px!important;
    font-size: 14px!important;
    line-height: 28px!important;
}

.menu-opcoes-container-timeline{
    margin-left: 50px !important;
}

.menu-opcoes-container{
    position: absolute!important;
    z-index: 5000;
    border-radius: 5px!important;
    background-color: transparent!important;
    border: 0 !important;
}

.menu-opcoes-divisora-topo{
    height: 1px!important;
    width: 100%!important;
    margin-left: -25%!important;
}

.menu-opcoes-divisora{
    height: 1px!important;
    width: 100%!important;
    margin-left: -10%!important;
}

.elipse-azul-grafico-area-dashboard{
    margin-top: 50%!important;
    margin-left: 50%!important;
    transform: translate(-50%, -10%)!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 6.25em!important;
    background-color: #1F8EFA!important;
    color: #1F8EFA!important;
}

.container-tooltip-grafico-area-dashboard{
    margin-left: -3.125em!important;
    border-radius: 0.3125em!important;
    height: 1.25em!important;
    width: 5em!important;
    background-color: #1F8EFA!important;
    margin-top: 0.6875em!important;
}

.label-tooltip-grafico-area-dashboard{
    color: #FFFFFF!important;
    font-family: RobotoRegular!important;
    font-size: 0.75em!important;
    line-height: 1.7875em!important;
    text-align: center!important;
    font-weight: 700!important;
    margin-bottom: -0.2em!important;
}
.triangulo-tooltip-grafico-area-dashboard{
    position: absolute !important;
    z-index: 1!important;
    width: 0!important;
    height: 0!important;
    margin-left: 1.5625em!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    border-bottom: 0.9375em solid transparent!important;
    border-top: 0.9375em solid #1F8EFA!important;
}

.barra-tooltip-grafico-area-dashboard{
    box-sizing: border-box!important;
    margin-left: 2.375em!important;
    height: 32vh!important;
    width: 0.214375em!important;
    border: 0.125em solid #1F8EFA!important;
    background-color: #1F8EFA!important;
}

.barra-tooltip-grafico-area-dashboard-2{
    box-sizing: border-box!important;
    margin-left: 2.375em!important;
    height: 32vh!important;
    width: 0.214375em!important;
    border: 0.125em solid #1F8EFA!important;
    background-color: #1F8EFA!important;
}

.elipse-tooltip-grafico-area-dashboard{
    margin-left: 2.0625em!important;
    border-radius: 6.25em!important;
    height: 0.875em!important;
    width: 0.875em!important;
    background-color: #1F8EFA!important;
}

.titulo-grafico-dashboard{
    width: 80% !important;
}

.container-dashboard-fundo-5{
    height: 50%!important;
    width: 100%!important;
    display: flex!important;
    margin-bottom: 1em !important;
    margin-top: 12% !important;
}

.container-dashboard-faturas-fundo-faturas{
    height: 50%!important;
    width: 100%!important;
    display: flex!important;
    margin-bottom: 1em !important;
}
