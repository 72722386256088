/******************** BARRA DE FILTROS ********************/
.barra-filtragem-cliente {
    height: 2.9375em !important;
    width: 99% !important;
    margin-bottom: 0.3em !important;
    border-radius: 0.3125em !important;
    overflow: hidden;
}

.tags-barra-filtragem {
    max-width: 70% !important;
    float: left;
}

.mais-filtros {
    visibility: visible;
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    width: 7% !important;
    /* border: 1px solid #ECF0EE!important; */
    float: left;
    color: #33CC99;
    margin-top: 13px;
}

.menos-filtros {
    visibility: none;
    display: none;
}

.barra-filtragem-cliente-fechar {
    height: 2.9375em !important;
    width: 13.44% !important;
    margin-bottom: 0.625em !important;
    border-top-right-radius: 0.3125em !important;
    border-bottom-right-radius: 0.3125em !important;
    /* overflow: auto !important; */
    float: right;
}

.qtd-filtro {
    float: left;
    color: #33CC99;
    margin: 16px 0 0 0;
}

.filtrado-por {
    margin-top: 12px;
    float: left;
    padding: 0 10px !important;
}

.limpar-filtro {
    margin-top: 12px;
    float: left;
}


.barra-filtragem-caixa {
    float: left !important;
    margin-left: 0.625em !important;
    margin-right: 0.625em !important;
    min-width: 5% !important;
    color: white !important;
    font-size: 0.875em !important;
    font-family: RobotoRegular !important;
    font-weight: 700 !important;
    line-height: 3.3375em !important;
    text-align: center !important;
}

.filtragem-caixa {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 6px;
    height: 28px;
    border-radius: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 12px;
}

.divisora-filtragem {
    float: left !important;
    height: 1.25em !important;
    margin-top: 0.8125em !important;
}

.barra-filtragem-caixa-fechar {
    padding-right: 0.3125em !important;
    float: right !important;
}

.barra-filtragem-caixa-limpar-item {
    opacity: 0;
    float: left !important;
}

.barra-filtragem-caixa-limpar-item:hover {
    padding-top: 0.45em !important;
    padding-right: 0.3em !important;
    opacity: 100;
}

.botao-limpar-item-icone {
    width: 1.2em;
    height: 1.615625em;
    margin-left: 10px;
}