/***************** CLASSES DAS TELAS DE AÇÃO *****************/
/* Classes responsáveis por posicionar corretamente diversos */
/* elementos nas telas de ação, além de posicionar as mesmas */
/* de modo correto na tela. Todas as classes estão nomeadas  */
/* de modo genérico para fácil entendimento de qual arquivo  */
/* pertencem e o que representam em seu uso.                 */
/********* MATEUS FOLLETTO - 15/05/2020 - VIRTUEYES **********/
/******************** UNIVERSAL ENTRE AS ACOES ********************/
.contador-linhas-acoes-dispositivos{/* PARA POSICIONAR O CONTADOR DE LINHAS */
    float: right!important;
    margin-top: 0.4375em!important;
}

/******************** ADICIONAR PACOTE EXTRA ********************/
.modal-acao-adicionar-pacote-extra{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 51.875em!important;
}

.grupo-adicionar-pacote-extra-1{/* PARA ORGaNIZAR O SLIDER, CAMPO DE MB E CAMPO DE VALOR */
    margin-top: 1.25em!important;
    display: flex!important;
}

.coluna-adicionar-pacote-extra-1{/* PARA ORGANIZAR A COLUNA DO SLIDER */
    width: 40%!important;
}

.coluna-adicionar-pacote-extra-2{/* PARA ORGANIZAR A COLUNA DO CAMPO DE MB */
    width: 20%!important;
    transform: translate(28%, 0%)!important;
}

.coluna-adicionar-pacote-extra-3{/* PARA ORGANIZAR A COLUNA DO CAMPO DE VALOR */
    width: 30%!important;
    transform: translate(33.5%, 0%)!important;
}

.campo-texto-mb-adicionar-pacote-extra{/* PARA POSICIONAR O CAMPO DE MB */
    margin-top: 1.5em!important;
    margin-left: 20%!important;
    width: 60%!important;
    display: flex!important;
    align-content: center!important;
    text-align: center!important;
}

.componente-modal-menu-filtros{
    margin-left: 20em !important;
}

.mb-text{
    position: relative;
    float: right;
    bottom: 1.55em;
    right: 3.2em;
}

.span-validacao-mb{
    font-size: .8em !important;
    color: red;
    width: 6em;
    margin-left: 2.6em;
    transition: ease;
}

.label-mb-adicionar-pacote-extra{/* PARA POSICIONAR O CONTEUDO DO CAMPO DE MB */
    margin-left: 50%!important;
    transform: translate(-50%, 25%)!important;
    width: 6em;
    border: none;
    border-radius: 10em;
    margin-top: 1.05em;
    text-align: center;
    padding: .2em;
    margin-bottom: .3em;
    padding-right: 2em;
    outline: none !important;
}

.label-mb-adicionar-pacote-extra:focus{
    box-shadow: none !important;
    border: none !important;
}

.campo-texto-valor-adicionar-pacote-extra{/* PARA POSICIONAR O CAMPO DE VALOR */
    display: flex!important;
    width: 100%!important;
    margin-top: 1.8em!important;
}

.label-valor-adicionar-pacote-extra-1{/* PARA POSICIONAR A LABEL DO CAMPO DE VALOR  */
    justify-content: space-between!important;
    transform: translate(30%, 25%)!important;
}

.label-valor-adicionar-pacote-extra-2{/* PARA POSICIONAR O VALOR */
    justify-content: space-between!important;
    transform: translate(19%, 25%)!important;
    text-align: right!important;
    width: 50%!important;
}

.container-switch-adicionar-pacote-extra{/* PARA ORGANIZAR O SIWTCH */
    margin-top: 1.875em!important;
    margin-bottom: 1.875em!important;
}

.label-termos-adicionar-pacote-extra{/* PARA ORGANIZAR O TERMO DE ACEITACAO */
    line-height: 1.375em!important;
}

/******************** RESET ********************/
.modal-acao-reset{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 55.625em!important;
}

/******************** INFORMAR FALHA EM OPERADORA ********************/
.modal-acao-informar-falha-em-operadora{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 55.625em!important;
}

.container-linha-expandida-informar-falha-em-operadora{/* PARA FORMATAR O CONTAINER DA ROW EXPANDIDA */
    padding: 0.9375em 0.9375em 0.9375em 0.9375em !important;
}

.tabela-transparente-modais-falha-operadora td {
    padding: 0em 0em 0em 0em!important;
}

.espaco-linha-expandida-informar-falha-em-operadora{/* PARA ESPACAR OS CAMPOS DENTRO DA ROW EXPANDIDA */
    margin-bottom: 0.75em!important;
}

.coluna-labels-informar-falha-em-operadora-1{/* PARA ORGANIZAR A PRIMEIRA COLUNA DA ROW EXPANDIDA */
    max-width: 28%!important;
}

.coluna-campos-informar-falha-em-operadora-1{/* PARA ORGANIZAR A PRIMEIRA COLUNA DA ROW EXPANDIDA */
    max-width: 28%!important;
}

.coluna-labels-informar-falha-em-operadora-2{/* PARA CRIAR ESPAÇO ENTRE AS COLUNAS DA ROW EXPANDIDA */
    max-width: 4%!important;
}

.coluna-campos-informar-falha-em-operadora-2{/* PARA CRIAR ESPAÇO ENTRE AS COLUNAS DA ROW EXPANDIDA */
    max-width: 4%!important;
}

.coluna-labels-informar-falha-em-operadora-3{/* PARA ORGANIZAR A SEGUNDA COLUNA DA ROW EXPANDIDA */
    max-width: 68%!important;
}

.coluna-campos-informar-falha-em-operadora-3{/* PARA ORGANIZAR A SEGUNDA COLUNA DA ROW EXPANDIDA */
    max-width: 68%!important;
}

.espaco-label-informar-falha-em-operadora{/* PARA CRIAR ESPAÇO ENTRE ELEMENTOS E OS BOTOES DA MODAL */
    margin-top: 1.25em!important;
    margin-bottom: 1.25em!important;
}

/******************** SUPORTE TECNICO ********************/
.modal-acao-suporte-tecnico{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 55.625em!important;
}

/******************** CANCELAMENTO / REPOSICAO ********************/
.modal-acao-cancelamento-resposicao{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 2em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 796px !important;
    max-height: 100vh;
    overflow-y: auto;
}

.modal-acao-cancelamento-resposicao-novo{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: 0em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;

    width: 1210px !important;
    min-height: 94vh;
    max-height: 100vh;

    display: flex;
    flex-direction: column;

    overflow-y: auto;
}

/******************** CANCELAMENTO / REPOSICAO ********************/
.modal-acao-cancelamento{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 58.25em!important;
    max-height: 100vh;
    overflow-y: auto;
}

.grupo-cancelamento-reposicao-1{/* PARA ORGaNIZAR O SLIDER, CAMPO DE MB E CAMPO DE VALOR */
    display: flex!important;
}

.coluna-cancelamento-reposicao-1{/* PARA ORGANIZAR A COLUNA DO SWITCH */
    margin-top: -0.625em!important;
    width: 70%!important;
}
.coluna-cancelamento-reposicao-2{/* PARA ORGANIZAR A COLUNA DO CAMPO DE VALOR */
    width: 30%!important;
}
.coluna-cancelamento-reposicao-3{/* PARA ORGANIZAR A COLUNA DO CAMPO DE VALOR A DIREITA */
    margin-left: 1em;
    width: 50%!important;
}

.campo-texto-valor-cancelamento-reposicao{/* PARA POSICIONAR O CAMPO DE VALOR */
    display: flex!important;
    width: 100%!important;
    margin-top: 2em!important;
}

.label-valor-cancelamento-reposicao-1{/* PARA POSICIONAR A LABEL DO CAMPO DE VALOR  */
    justify-content: space-between!important;
    transform: translate(30%, 25%)!important;
}

.label-valor-cancelamento-reposicao-2{/* PARA POSICIONAR O VALOR */
    justify-content: space-between!important;
    transform: translate(30%, 25%)!important;
    text-align: right!important;
    width: 50%!important;
}
.label-valor-cancelamento-reposicao-3{/* PARA POSICIONAR O VALOR */
    justify-content: space-between!important;
    transform: translate(30%, 25%)!important;
    text-align: right!important;
    width: 40%!important;
}
.modal-itens-abaixo-tabela{
    margin-top: 1%;
}

.container-switch-cancelamento-reposicao{/* PARA ORGANIZAR O SIWTCH */
    margin-top: 1.875em!important;
    margin-bottom: 1.875em!important;
}

.label-termos-cancelamento-reposicao-1{/* PARA ORGANIZAR O TERMO DE ACEITACAO DO SWITCH*/
    line-height: 2.95em!important;
}

.label-termos-cancelamento-reposicao-2{/* PARA ORGANIZAR O TERMO DE ACEITACAO ABAIXO DO MOTIVO */
    margin-top: -1.5625em!important;
    margin-bottom: 1em!important;
}

.label-normal-caso{
    margin-top: 5px !important;
}
/******************** ATIVAR LINHA ********************/
.modal-acao-ativar-linha{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 53.9375em!important;
}

.modal-nova-solicitacao{
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 37.9375em!important;
}

.espaco-ativar-linha{/* PARA CRIAR ESPACO ENTRE TABELA E BOTOES */
    margin-top: 2em!important;
}

/******************** REATIVAR LINHA ********************/
.modal-acao-reativar-linha{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 53.9375em!important;
}

.espaco-reativar-linha{/* PARA CRIAR ESPACO ENTRE TABELA E BOTOES */
    margin-top: 2em!important;
}

/******************** DESBLOQUEAR USO ********************/
.modal-acao-desbloquear-uso{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 53.9375em!important;
}

/******************** REMOVER BLOQUEIO TEMPORARIO ********************/
.modal-acao-remover-bloqueio-temporario{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 55.625em!important;
}

.grupo-remover-bloquear-temporariamente-1{
    width: 100%!important;
    display: flex!important;
}

.coluna-remover-bloquear-temporariamente-1{
    width: 23.5%!important;
    margin-right: 3%!important;
}



/******************** SUSPENDER LINHAS ********************/
.modal-acao-suspender-linha{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 53.9375em!important;
}

/******************** BLOQUEAR USO ********************/
.modal-acao-bloquear-uso{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 53.9375em!important;
}

/******************** BLOQUEAR TEMPORARIAMENTE ********************/
.modal-acao-bloquear-temporariamente{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 55.625em!important;
}

.grupo-bloquear-temporariamente-1{
    width: 100%!important;
    display: flex!important;
}

.coluna-bloquear-temporariamente-1{
    width: 23.5%!important;
    margin-right: 3%!important;
}

.coluna-bloquear-temporariamente-2 {
    width: 23.5%!important;
}

.coluna-select-iot-telecom-1 {
    display: flex;
    gap: 66px;
}

#produtos {
    width: 305px;
}

/******************** ALTERAR PLANO / VALOR ********************/
.modal-acao-alterar-plano-valor{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 58.25em!important;
}

.container-switch-alterar-plano-valor{/* PARA POSICIONAR O SWITCH CORRETAMENTE */
    margin-top: 1.42em!important;
    margin-bottom: 1.875em!important;
    width: 87%;
}

.container-switch-alterar-plano-valor-2{
    margin-top: 1.42em!important;
    margin-bottom: 1.875em!important;
    width: 80%;
    margin-left: 2.2%;
}

.label-termos-alterar-plano-valor{/* PARA POSICIONAR O TEXTO DE TERMOS CORRETAMENTE */
    line-height: 2.4em!important;
    margin-bottom: -1.875em!important;
}

.espaco-motivo-alterar-plano-valor{/* PARA ESPACAR O CAMPO MOTIVO CORRETAMENTE */
    margin-top: 1.4em!important;
}

.grupo-alterar-plano-valor-1{/* PARA POSICIONAR AS COLUNAS CORRETAMENTE */
    display: flex!important;
    width: 100%!important;
}

.coluna-alterar-plano-valor-1{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 23%!important;
    margin-right: 4.5%!important;
}

.coluna-alterar-plano-valor-2{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 23%!important;
    margin-right: 4.5%!important;
}

.coluna-alterar-plano-valor-3{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 23%!important;
    margin-right: 4.5%!important;
}

.coluna-alterar-plano-valor-4{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 23%!important;
}

/******************** TROCA DE TITULARIDADE ********************/
.modal-acao-troca-de-titularidade{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 58.25em!important;
}

.container-switch-troca-de-titularidade{/* PARA POSICIONAR O SWITCH CORRETAMENTE */
    margin-top: 1.42em!important;
    margin-bottom: 1.875em!important;
}

.label-termos-troca-de-titularidade{/* PARA POSICIONAR O TEXTO DE TERMOS CORRETAMENTE */
    line-height: 2.4em!important;
    margin-bottom: -1.875em!important;
}

.espaco-motivo-troca-de-titularidade{/* PARA ESPACAR O CAMPO MOTIVO CORRETAMENTE */
    margin-top: 1.4em!important;
}

.grupo-troca-de-titularidade-1{/* PARA POSICIONAR AS COLUNAS CORRETAMENTE */
    display: flex!important;
    width: 100%!important;
}

.coluna-troca-de-titularidade-1{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 23%!important;
    margin-right: 4.5%!important;
}

.coluna-troca-de-titularidade-2{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 23%!important;
}

.coluna-troca-de-titularidade-3{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 23%!important;
    margin-right: 4.5%!important;
}

.coluna-troca-de-titularidade-4{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 23%!important;
}

.grupo-troca-de-titularidade-2{/* PARA POSICIONAR AS COLUNAS CORRETAMENTE */
    display: flex!important;
    width: 100%!important;
}

.coluna-troca-de-titularidade-5{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 20%!important;
    margin-right: 3%!important;
}

.coluna-troca-de-titularidade-6{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 20%!important;
    margin-right: 3%!important;
}

.coluna-troca-de-titularidade-7{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 54%!important;
}

.grupo-troca-de-titularidade-3{/* PARA POSICIONAR AS COLUNAS CORRETAMENTE */
    display: flex!important;
    width: 100%!important;
    margin-bottom: 1.22em!important;
}

.coluna-troca-de-titularidade-8{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 20%!important;
    margin-right: 3%!important;
}

.coluna-troca-de-titularidade-9{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 77%!important;
}

.troca-de-titularidade-divisor{/* PARA POSICIONAR A LINHA DIVISORA CORRETAMENTE */
    height: 0.0625em!important;
    margin-top: 1.5625em!important;
    margin-bottom: 1.5625em!important;
}

/******************** CANCELAMENTO DE CONTA ********************/
.modal-acao-cancelamento-de-conta{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 58.25em!important;
}

.grupo-cancelamento-de-conta-1{/* PARA ORGaNIZAR O SLIDER, CAMPO DE MB E CAMPO DE VALOR */
    display: flex!important;
}

.coluna-cancelamento-de-conta-1{/* PARA ORGANIZAR A COLUNA DO SWITCH */
    margin-top: -0.625em!important;
    width: 70%!important;
}
.coluna-cancelamento-de-conta-2{/* PARA ORGANIZAR A COLUNA DO CAMPO DE VALOR */
    width: 30%!important;
}

.campo-texto-valor-cancelamento-de-conta{/* PARA POSICIONAR O CAMPO DE VALOR */
    display: flex!important;
    width: 100%!important;
    margin-top: 2em!important;
    margin-bottom: 2em !important;
}

.label-valor-cancelamento-de-conta-1{/* PARA POSICIONAR A LABEL DO CAMPO DE VALOR  */
    justify-content: space-between!important;
    transform: translate(30%, 25%)!important;
}

.label-valor-cancelamento-de-conta-2{/* PARA POSICIONAR O VALOR */
    justify-content: space-between!important;
    transform: translate(30%, 25%)!important;
    text-align: right!important;
    width: 50%!important;
}

.label-termos-cancelamento-de-conta-2{/* PARA ORGANIZAR O TERMO DE ACEITACAO ABAIXO DO MOTIVO */
    margin-top: -1.5625em!important;
    margin-bottom: 1em!important;
}

/******************** ATIVAR DEMONSTRACAO ********************/
.modal-acao-ativar-demonstracao{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 58.25em!important;
}

.container-switch-ativar-demonstracao{/* PARA POSICIONAR O SWITCH CORRETAMENTE */
    margin-top: 1.42em!important;
    margin-bottom: 1.875em!important;
}

.label-termos-ativar-demonstracao{/* PARA POSICIONAR O TEXTO DE TERMOS CORRETAMENTE */
    line-height: 2.4em!important;
    margin-bottom: -1.875em!important;
}

.espaco-motivo-ativar-demonstracao{/* PARA ESPACAR O CAMPO MOTIVO CORRETAMENTE */
    margin-top: 1.4em!important;
}

.grupo-ativar-demonstracao-1{/* PARA ORGaNIZAR O SLIDER, CAMPO DE MB E CAMPO DE VALOR */
    display: flex!important;
    width: 100%!important;
}

.coluna-ativar-demonstracao-1{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 23%!important;
    margin-right: 4.5%!important;
}

.coluna-ativar-demonstracao-2{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 23%!important;
}

.coluna-ativar-demonstracao-3{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 23%!important;
    margin-right: 4.5%!important;
}

.coluna-ativar-demonstracao-4{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 23%!important;
}

/******************** DESATIVAR DEMONSTRACAO ********************/
.modal-acao-desativar-demonstracao{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 53.9375em!important;
}

/******************** LIBERAR SMS ********************/
.modal-acao-liberar-sms{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 55.625em!important;
}

.modal-acao-liberar-sms hr {
    border-color: #D1D1D1;
}

.grupo-liberar-sms-1{/* PARA ORGaNIZAR O SLIDER, CAMPO DE MB E CAMPO DE VALOR */
    display: flex!important;
    width: 100%!important;
    margin-top: 1.22em!important;
}

.coluna-liberar-sms-1{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 48.5%!important;
    margin-right: 3%!important;
}

.coluna-liberar-sms-2{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 48.5%!important;
}

.grupo-liberar-sms-2{/* PARA ORGaNIZAR O SLIDER, CAMPO DE MB E CAMPO DE VALOR */
    display: flex!important;
    width: 100%!important;
    margin-bottom: 1.22em!important;
}

.coluna-liberar-sms-3{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 50%!important;
    margin-right: 3%!important;
}

.coluna-liberar-sms-4{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 30%!important;
    margin-right: 3%!important;
}

.coluna-liberar-sms-5{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 17%!important;
}

.campo-texto-sms-liberar-sms{/* PARA POSICIONAR O CAMPO DE SMS CORRETAMENTE */
    margin-top: 1.7em!important;
    width: 100%!important;
    display: flex!important;
    align-content: center!important;
    text-align: center!important;
}

.label-sms-liberar-sms{/* PARA POSICIONAR O TEXTO COM A QUANTIDADE DE SMS CORRETAMENTE */
    margin-left: 50%!important;
    transform: translate(-50%, 25%)!important;
}

.label-termos-liberar-sms-2{/* PARA POSICIONAR O TEXTO DE TERMOS CORRETAMENTE */
    margin-bottom: 1em!important;
}

.botao-disabled {
    background-color: #00000042!important;
}

/******************** BLOQUEAR SMS ********************/
.modal-acao-bloquear-sms{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 55.625em!important;
}

.espaco-bloquear-sms{/* PARA CRIAR ESPACO ENTRE TABELA E BOTOES */
    margin-top: 2em!important;
}

/******************** ENVIAR SMS ********************/
.modal-acao-enviar-sms{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 55.625em!important;
}

.grupo-enviar-sms-1{/* PARA ORGaNIZAR O SLIDER, CAMPO DE MB E CAMPO DE VALOR */
    display: flex!important;
    width: 100%!important;
}

.coluna-enviar-sms-1{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 30%!important;
    margin-right: 5%!important
}

.coluna-enviar-sms-2{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 30%!important;
    margin-right: 5%!important
}

.coluna-enviar-sms-3{/* PARA ESPACAR A COLUNA CORRETAMENTE */
    width: 30%!important;
}

.container-switch-enviar-sms{/* PARA POSICIONAR O SWITCH CORRETAMENTE */
    margin-top: 1.575em!important;
    margin-bottom: 1.875em!important;
}

.label-termos-enviar-sms{/* PARA POSICIONAR O TEXTO DE TERMOS CORRETAMENTE */
    line-height: 2.4em!important;
    margin-bottom: -1.875em!important;
}

/******************** MOSTRAR NO MAPA ********************/
.componente-modal-mapa{/* POSICIONAR E DIMENSIONA A MODAL DE MAPAS CORRETAMENTE */
    z-index: 1000!important;
    position: absolute!important;
    width: 80%!important;
    height: 80%!important;
    margin-top: 4.84375em!important;
    left: 50%!important;
    transform: translate(-50%, -0%)!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    overflow: hidden!important;
}

.container-modal-mapa{
    height: 100%!important;
    min-width: 100vw!important;
    /* width: 100%!important; */
    border-radius: 15px!important;
}

.container-interno-modal-mapa{
    width: 100%!important;
    height: 100%!important;
    border-radius: 15px!important;
}

.corpo-mapa{
    width: 100%!important;
    height: calc(100vh - 2.8125em)!important;
}

.grupo-mostrar-no-mapa-1{/* PARA ORGANIZAR COLUNAS CORRETAMENTE */
    display: flex!important;
    width: 100%;
    height: 2.8125em!important;
}

.coluna-mostrar-no-mapa-1{ /* PARA POSICIONAR E DIMENSIONAR A COLUNA CORRETAMENTE */
    position: absolute!important;
    z-index: 500!important;
    left: 1%!important;
    margin-top: 0.15em!important;
}

.coluna-mostrar-no-mapa-2{/* PARA POSICIONAR E DIMENSIONAR A COLUNA CORRETAMENTE */
    position: absolute!important;
    z-index: 500!important;
    left: 65%!important;
    margin-top: 0.15em!important;
}

.coluna-mostrar-no-mapa-3{/* PARA POSICIONAR E DIMENSIONAR A COLUNA CORRETAMENTE */
    width: 11em!important;
    position: absolute!important;
    z-index: 500!important;
    left: 70%!important;
    margin-top: 0.5em!important;
}

.coluna-mostrar-no-mapa-4{/* PARA POSICIONAR E DIMENSIONAR A COLUNA CORRETAMENTE */
    position: absolute!important;
    z-index: 550!important;
    left: 96.5%!important;
    margin-top: 0.2em!important;
}

.botao-trocar-mapa-mostrar-no-mapa{/* PARA DIMENSIONAR E ESTILIZAR O BOTAO CORRETAMENTE */
    margin-top: 0.375em!important;
    height: 2em!important;
    width: 2em!important;
    border-radius: 3.125em!important;
    background-color: transparent!important;
    border: 0.0625em #36D896 solid!important;
}

.botao-trocar-mapa-mostrar-no-mapa:active{/* DESATIVA OS EFEITOS GRAFICOS DO BOTAO */
    box-shadow: none !important;
    border-color: transparent!important;
    border-style: outset !important;
    outline: none!important;
    background-color: transparent!important;
    border: 0.0625em #36D896 solid!important;
}

.botao-trocar-mapa-mostrar-no-mapa:focus{/* DESATIVA OS EFEITOS GRAFICOS DO BOTAO */
    box-shadow: none !important;
    border-color: transparent!important;
    border-style: outset!important;
    outline: none!important;
    background-color: transparent!important;
    border: 0.0625em #36D896 solid!important;
}

.icone-botao-trocar-mapa-mostrar-no-mapa{/* DIMENSIONA E POSICIONA CORRETAMENTE O ICONE DO BOTAO */
    position: absolute!important;
    width: 70%!important;
    height: 70%!important;
    top: 57%!important;
    left: 51%!important;
    transform: translate(-50%, -50%)!important;
}

.container-mapa-generico{/* CONTAINER PARA POSICIONAR E DIMENSIONAR CORRETAMENTE OS MAPAS */
    z-index: 1000 !important;
    width: 100% !important;
    height: 100% !important;
}

.container-mapa-openstreet{/* CONTAINER PARA POSICIONAR E DIMENSIONAR CORRETAMENTE OS MAPAS */
    width: 100% !important;
    height: 100% !important;
}

.container-mapa-google{/* CONTAINER PARA POSICIONAR E DIMENSIONAR CORRETAMENTE OS MAPAS */
    width: 100% !important;
    height: 94.2% !important;
}

/******************** PERSONALIZAR COLUNAS ********************/
.modal-exibir-colunas{/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 2em 2em 3em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 49.8125em!important;
}

/******************** GRUPOS ********************/
.modal-configurar-grupo{
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 28.5em!important;
    max-height: 33em !important;
    overflow-y: auto !important;
}

.modal-alterar-grupo{
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 40.5em!important;
}

.icone-ordenacao-DESC{
    vertical-align: middle;
    margin: 0.2em;
    margin-top: 0.4em;
    transform: rotate(180deg)!important;
}

.icone-ordenacao-ASC{
    vertical-align: middle;
    margin: 0.2em;
    margin-bottom: 0.4em;
}

.btn-header-nome{
    background-color: transparent;
    outline: 0em !important;
    border: transparent;
    font-size: 1em!important;
    line-height: 1.1875em!important;
    font-weight: 700!important;
    width: 100%!important;
    text-align: initial;
    padding-left: 0px;
}

.tabela-nova{
    margin-bottom: 1em !important;
}


.row-tabela-nova{
    && td.expand-cell{
        padding-left: 18px !important;
        width: 30px;
    }
    && td.selection-cell{
        width: 50px !important;
        padding: 0px !important;
        text-align: center !important;
    }
}

.btn-header-nome-tabela-nova{
    background-color: transparent;
    outline: 0em !important;
    border: transparent;
    font-size: 1em!important;
    line-height: 1.1875em!important;
    font-weight: 700!important;
    width: 100%!important;
    text-align: initial;
}



.btn-header-nome:focus, .btn-header-nome:visited, .btn-header-nome:active{
    border: transparent;
    outline: 0em !important;
}

.img-eclipse-status{
    width: 0.66em;
    margin-right: 1.2em;
}

.valor-simcard {
    text-align: right !important;
    width: 70% !important;
    font-size: 1em!important;
    margin-left: 5px!important;
}

.valor-rs-simcard {
    width: 30% !important;
    height: 100% !important;
}

.container-valor-simcard {
    display: inline-flex !important;
    /* width: 100% !important;  */
    height: 1.25em !important;
}

.container-tabela-modal-itens-nao-permitidos {
    overflow: auto!important;
    max-height: 13.75em!important;
    overflow-x: hidden!important;
}

.itens-nao-permitidos-btn-voltar{
    margin-top: 1em;
}

.modal-mensagem-atencao-acoes-simcards{
    text-align: center!important;
    padding: 0em 1.875em 1.25em 1.875em;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
}

.container-switch-modo-selecao-dispositivos{
    margin-left: 0.9em!important;
    margin-top: 0.0em !important;
    width: 1.875em!important;
    -webkit-user-select:none!important;
    -moz-user-select:none!important; 
    -ms-user-select: none!important;
    user-select: none!important;
    position: relative;
    top:5.5px;
    z-index: 9999;
    height: 0px;
    width: 0px;
    
   }


.container-switch-modo-selecao-dispositivos input[type=checkbox] {
    display: none!important;
}

#exportar-linhas-xls{
    visibility: hidden!important;
    margin-top: -200px!important;
    position: absolute!important;
}

.label-atencao-pacote-extra{
    margin-top: 1em;
}

.modal-mensagem-atencao-linha-nao-encontrada{
    text-align: center!important;
    padding: 0em 51px 1.25em 1.875em;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
}

.componente-modal-linha-nao-encontrada{
    padding: 49px 230px 29px 222px;
}

.mensagem-modal{
    font-family: RobotoRegular!important;
    font-size: 17px!important;
    line-height: 1.8125em!important;
    font-weight: 700!important;
    height: 94px;
    width: 428px;
    margin-left: 9px;
}
.modal-input-dimensoes{
    width: 168px !important;
}

.modal-itens-abaixo-tabela-margin{
    margin-left: 6px !important;
    margin-top: 10px !important;
}

.botao-add-quantidade-linhas{
    display: flex !important;
    justify-content: flex-end !important;
    margin: 5px 0px 18px 0px !important;
}

.container-tabela-modal-cancelamento-reposicao-texto{
    font-size: 12px !important;
}

.contador-linhas-acoes-dispositivos-margin{
    margin-top: 12px !important;
}

.coluna-cancelamento-reposicao-3{
    margin-left: 181px !important;
}