/******************** GERAL ********************/
.container-dashboard-tela{
    height: 100%!important;
    width: 100%!important;
    overflow: hidden!important;
}

/******************** CONTAINERES GRAFICOS TOPO ********************/
.container-dashboard-topo{
    height: 50%!important;
    width: 100%!important;
    border-radius: 0.3125em!important;
    margin-bottom: 1%!important;
    padding-top: 0.5em!important;
    padding-left: 1.5625em!important;
    padding-right: 0.625em!important;
}

.container-dashboard-topo-2{
    height: 45%!important;
    width: 100%!important;
    border-radius: 0.3125em!important;
    margin-bottom: 1%!important;
    padding-top: 0.5em!important;
    padding-left: 1.5625em!important;
    padding-right: 0.625em!important;
}

.container-graficos-topo{
    width: 100%!important;
    height: 86%!important;
    display: flex!important;
}

/******************** GRAFICOS TOPO ********************/
.coluna-graficos-topo-1{
    height: 100%!important;
    width: 13%!important;
}

.coluna-graficos-topo-2{
    height: 100%!important;
    width: 85%!important;
    zoom: 125%!important;
    margin-left:30px;
}

.linha-grafico-topo{
    width: 100%!important;
    height: 25%!important;
    display: flex!important;
}

.container-grafico-pizza-topo{
    height: 100%!important;
    width: 40%!important;
    display: relative!important;
}

.label-percentual-graficos-pizza-topo{
    height: 1.1875em!important;
    width: 1.875em!important;
    font-family: Roboto!important;
    font-size: 1em!important;
}

.container-legenda-grafico-pizza-topo{
    width: 70%!important;
    height: 100%!important;
}

.container-titulo-legenda-grafico-pizza-topo{
    width: 100%!important;
    position: relative;
    top: 50%!important;
    transform: translateY(-100%)!important;
}

.texto-titulo-legenda-grafico-pizza-topo{
    width: 100%!important;
    font-family: Roboto!important;
    font-size: 1em!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

.container-corpo-legenda-grafico-pizza-topo{
    width: 100%!important;
    position: relative;
    top: 50%!important;
    transform: translateY(-100%)!important;
}

.texto-corpo-legenda-grafico-pizza-topo{
    width: 100%!important;
    font-family: Arial!important;
    font-size: 1.125em!important;
    line-height: 1.3125em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

/******************** CONTAINERES GRAFICOS FUNDO ********************/
.container-dashboard-fundo{
    height: 50%!important;
    width: 100%!important;
    display: flex!important;
    margin-bottom: 1em !important;
}

@media only screen and (max-width: 1600px){
    .container-dashboard-fundo{
        max-height: 47vh!important;
    }
}

.container-dashboard-fundo-4{
    height: 45%!important;
    width: 100%!important;
    display: flex!important;
    margin-bottom: 0.5%!important;
}

.container-dashboard-fundo-1{
    height: 100%!important;
    width: 32.66%!important;
    border-radius: 0.3125em!important;
    margin-right: 1%!important;
    padding-left: 0.625em!important;
    padding-right: 0.625em!important;
}

.container-dashboard-fundo-2{
    height: 100%!important;
    width: 32.66%!important;
    border-radius: 0.3125em!important;
    margin-right: 1%!important;
    padding-left: 0.625em!important;
    padding-right: 0.625em!important;
}

.chart-wrapper{
    height: 40vh !important;
    width: 65vw !important;
}

.container-dashboard-fundo-3{
    height: 100%!important;
    width: 32.66%!important;
    border-radius: 0.3125em!important;
    padding-right: 0.625em!important;
    padding-left: 0.625em!important;
}

.dashboard-ajuste-portal-cliente {
    margin-right: 1vw;
    width: 69vw!important;
}

@media only screen and (min-width: 1800px){
    .dashboard-ajuste-portal-cliente {
        width: 72vw!important;
    }
}

/******************** NOVOS CSS PARA NOVA DASHBOARD 28/10/2022 ********************/
/* criado para card da dashboard ocupando 2 espaços */
.container-dashboard-fundo-box-duplo{
    height: 100%!important;
    width: 66.32%!important;
    border-radius: 0.3125em!important;
    margin-right: 0%!important;
    padding-left: 0.625em!important;
    padding-right: 0.625em!important;
}

.container-legenda-status{
    width: 50%!important;
    align-self: center!important;    
    overflow: hidden!important;
    overflow-y: auto!important;
    align-self: center!important;
    max-height: 73%!important;
}

.linha-legenda-status{
    display: flex!important;
    width: 100%!important;
    margin-top: 3%!important;
    margin-bottom: 3%!important;
}

.coluna-legenda-status-1{
    padding-top: 1.8%!important;
    width: 14%!important;
}

.coluna-legenda-status-2{
    width: 5em!important;
}

.coluna-legenda-status-3{
    text-align: end !important;
    width: 40%;
}

/******************** GRAFICOS GRANDES FUNDO ********************/
.grupo-dash-grafico-pizza-grande{
    position: relative!important;
    display: flex!important;
    height: 80%!important;
}

.container-dash-grafico-pizza-grande{
    position: relative!important;
    height: 100%!important;
    width: 60%!important;
    align-content: center!important;
    z-index: 10!important;
}

.container-grafico-pizza-grande{
    position: relative!important;
    height: 100%!important;
    width: 80%!important;
    align-content: center!important;
    z-index: 10!important;
}

.container-legenda-grafico-pizza-grande{
    position: absolute!important;
    margin-top: 0%!important;
    margin-right: 3%!important;
    text-align: right!important;
    float: right!important;
    width: 97%!important;
    bottom: 0!important;
    right: 0!important;
   
}

.container-legenda-grafico-pizza-grande2{
    position: absolute!important;
    margin-top: 0%!important;
    margin-right: 3%!important;
    text-align: right!important;
    float: right!important;
    width: 97%!important;
    bottom: 0!important;
    right: 0!important;
    z-index:999!important;
}

.container-titulo-legenda-grafico-pizza-grande{

}

.texto-titulo-legenda-grafico-pizza-grande-dash{
    font-family: Roboto!important;
    font-size: 1.5em!important;
    line-height: 1.1875em!important;
    text-align: right!important;
}

.container-corpo-legenda-grafico-pizza-grande{
    
}

.texto-corpo-legenda-grafico-pizza-grande{
    font-family: Roboto!important;
    font-size: 2.15em!important;
    font-weight: 700!important;
    text-align: right!important;
}

/******************** GRAFICOS SEM COMUNICAR ********************/
.container-dash-simcards-sem-comunicar{
    width: 100%!important;
    height: 100%!important;
    display: flex!important;
}

.container-dash-grafico-pizza-sem-comunicar{
    height: 100%!important;
    width: 50%!important;
}

.caixa-grafico-pizza-sem-comunicar{
    height: 100%!important;
    width: 100%!important;
}

.container-legenda-sem-comunicar{
    width: 50%!important;
    align-self: center!important;    
    overflow: hidden!important;
    overflow-y: auto!important;
    width: 50%!important;
    align-self: center!important;
    max-height: 73%!important;
}

.linha-dash-legenda-sem-comunicar{
    display: flex!important;
    width: 100%!important;
    margin-top: 3%!important;
    margin-bottom: 3%!important;
}

.coluna-legenda-sem-comunicar-1{
    padding-top: 1.8%!important;
    width: 10%!important;
}

.coluna-legenda-sc-sem-comunicar-2{
    width: 10em!important;
}

.coluna-legenda-sem-comunicar-2-faturas{
    width: 7em!important;
}

.coluna-legenda-sc-sem-comunicar-3{
    width: 30%!important;
    text-align: end !important;
}

.texto-legenda-sem-comunicar{
    font-family: Roboto!important;
    font-size: 1em!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

.texto-legenda-box-status{
    font-family: Roboto!important;
    font-size: 1.3em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

.texto-legenda-sem-comunicar-numero{
    font-family: Roboto!important;
    font-size: 1.3em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    position:absolute;
    margin-left:-40px;
}

.texto-legenda-sem-comunicar-numero-unico{
    font-family: Roboto!important;
    font-size: 1.3em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    position:absolute;
    margin-left:9px;
}
.texto-legenda-sem-comunicar-numero-unico-2{
    font-family: Roboto!important;
    font-size: 1.3em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

/******************** GRAFICOS CONSUMO NOS CICLOS ********************/
.container-simcards-consumo{
    width: 100%!important;
    height: 100%!important;
}

.container-simcards-consumo-portal{
    width: 100%!important;
    height: 100%!important;
}


.container-grafico-pizza-consumo{
    margin: 0 auto;
    width: 100%!important;
    height: 50%!important;
}

.container-grafico-pizza-consumo-portal{
    width: 100%!important;
    height: 50%!important;
}

.caixa-grafico-pizza-consumo{
    margin: 0 auto !important;
    width: 80%!important;
    height: 100%!important;
}

.caixa-grafico-pizza-consumo-portal{
    width: 80%!important;
    height: 100%!important;
}

.chart-container{
    height: 400px !important;
}

.apexcharts-xaxis-label{
   fill: black;
}

.apexcharts-xaxis-label-noturno{
    fill: white;
}

/* INICIO CARD EM BARRAS */
.container-legenda-consumo {
    width: 100%!important;
    align-self: center!important;
    overflow: hidden!important;
    overflow-y: auto!important;
    margin-top: 1em!important;
    max-height: 100%!important;
    padding: 0 3%;
}

.linha-legenda-consumo {
    display: flex!important;
    width: 93%!important;
    margin-top: 2%!important;
}

.coluna-legenda-consumo-1{
    padding-top: 0.8%!important;
    width: 6%!important;
    margin: 0 0 0 30px;
}

.coluna-legenda-consumo-2{
    width: 44%!important;
}

.coluna-legenda-consumo-2-faturas{
    width: 7em!important;
}

.coluna-legenda-consumo-3{
    width: 50%!important;
    text-align: end !important;
}

.texto-legenda-consumo{
    font-family: Roboto!important;
    font-size: 1.1em!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

.texto-legenda-box-consumo{
    font-family: Roboto!important;
    font-size: 1.3em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    position:absolute;
    margin-left:-50px;
}

.texto-legenda-consumo-numero{
    font-family: Roboto!important;
    font-size: 1.3em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    position:absolute;
    margin-left:-40px;
}

.texto-legenda-consumo-numero-unico{
    font-family: Roboto!important;
    font-size: 1.3em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    position:absolute;
    margin-left:9px;
}
.texto-legenda-consumo-numero-unico-2{
    font-family: Roboto!important;
    font-size: 1.3em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

/******************** OPERADORAS ********************/
.container-operadoras{
    width: 100%!important;
    height: 100%!important;
    display: flex!important;
}

.container-grafico-pizza-operadoras {
    height: 100%!important;
    width: 60%!important;
}
 
.container-grafico-pizza-tecnologias {
    height: 100%!important;
    width: 50%!important;
}

.caixa-grafico-pizza-operadoras{
    height: 100%!important;
    width: 100%!important;
}

.container-legenda-operadoras{
    width: 50%!important;
    align-self: center!important;    
    overflow: hidden!important;
    overflow-y: auto!important;
    width: 50%!important;
    align-self: center!important;
    max-height: 100%!important;
    padding-right: 5px;
}

.linha-legenda-operadoras{
    display: flex!important;
    justify-content: space-between;
    width: 100%!important;
    margin-top: 3%!important;
    margin-bottom: 3%!important;
    max-width: 220px;
}

.coluna-legenda-operadoras-1{
    padding-top: 1.8%!important;
    display: flex;
    align-items: center;
}

.coluna-legenda-operadoras-2{
    margin-left: 10px;
    margin-right: 15px;
}

.coluna-legenda-operadoras-2-faturas{
    width: 7em!important;
}

.coluna-legenda-operadoras-3{
    /* margin-right: 5px; */
}

.texto-legenda-operadoras{
    font-family: Roboto!important;
    font-size: 1em!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

.texto-legenda-operadoras-numero{
    font-family: Roboto!important;
    font-size: 1.3em!important;
    font-weight: 700!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

.texto-legenda-operadoras-numero-unico{
    font-family: Roboto!important;
    font-size: 1.3em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    position:absolute;
    margin-left:9px;
}
.texto-legenda-operadoras-numero-unico-2{
    font-family: Roboto!important;
    font-size: 1.3em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    position:absolute;
    margin-left:-22px;
}

/******************** STATUS CONTRATO ********************/
.container-status-contrato{
    width: 100%!important;
    height: 100%!important;
    display: flex!important;
}

.container-grafico-pizza-status-contrato{
    height: 100%!important;
    width: 50%!important;
}

.caixa-grafico-pizza-status-contrato{
    height: 100%!important;
    width: 100%!important;
}

.container-legenda-status-contrato{
    width: 50%!important;
    align-self: center!important;    
    overflow: hidden!important;
    overflow-y: auto!important;
    width: 50%!important;
    align-self: center!important;
    max-height: 73%!important;
}

.linha-legenda-status-contrato{
    display: flex!important;
    width: 100%!important;
    margin-top: 3%!important;
    margin-bottom: 3%!important;
}

.coluna-legenda-status-contrato-1{
    padding-top: 1.8%!important;
    width: 11%!important;
}

.coluna-legenda-status-contrato-2{
    width: 7em!important;
}

.coluna-legenda-status-contrato-2-faturas{
    width: 7em!important;
}

.coluna-legenda-status-contrato-3{
    width: 40%!important;
    text-align: end !important;
}

.texto-legenda-status-contrato{
    font-family: Roboto!important;
    font-size: 1em!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

.texto-legenda-status-contrato-numero{
    font-family: Roboto!important;
    font-size: 1.3em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
}

.texto-legenda-status-contrato-numero-unico{
    font-family: Roboto!important;
    font-size: 1.3em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    position:absolute;
    margin-left:9px;
}
.texto-legenda-status-contrato-numero-unico-2{
    font-family: Roboto!important;
    font-size: 1.3em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    position:absolute;
    margin-left:-22px;
}

/******************** STATUS DE TRAFEGO ********************/
.container-simcards-status-trafego{
    width: 100%!important;
    height: 100%!important;
}

.container-grafico-pizza-status-trafego{
    height: 50%!important;
    width: 100%!important;
    display: flex!important;
    justify-content: center!important;
}

.caixa-grafico-pizza-status-trafego{
    height: 100%!important;
    width: 28%!important;
    margin-left: 3%!important;
    margin-right: 3%!important;
}

.container-legenda-status-trafego {
    width: 100%!important;
    margin: 0 0 0 25%;
}
.coluna-legenda-status-trafego-1 {
    padding-top: 0.8%!important;
    width: 6%!important;
}
.coluna-legenda-status-trafego-2 {
    width: 9em!important;
}

.coluna-legenda-status-trafego-3 {
    width: 18%!important;
    text-align: end!important;
}


/******************** BOTOES PAGINACAO ********************/

.fundo-cor-dash-selecionado{
    background-color: rgb(54, 216, 150) !important;
}

.botao-alternar-dashboard{
    width: 10.3em;
    height: .9em;
    border-radius: 1em !important;
    margin-left: 1.5em;
    border: 0 !important;
}

.botao-alternar-dashboard:focus, .botao-alternar-dashboard:hover{
    outline:none !important;
}

.container-botoes-alternar-dashboard{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.wrapper-botoes-alternar-dashboard{
    justify-content: center !important;
    width: 36em;
    position: absolute;
    bottom: 0.5%;
}

/******************** BOTOES OPCOES ********************/
.bota-opcoes{
    height: 1.875em!important;
    width: 1.875em!important;
    border: 0em solid transparent!important;
    box-shadow: none!important;
    margin-left: 0.625em!important;
    float: right!important;
    background-color: transparent!important;
}

.data-ciclo{
    float: right!important;
    line-height: 40px!important;
    font-weight: 700!important;
}

/******************** OUTROS ********************/
.label-janela-dashboard{
    font-family: Roboto!important;
    font-size: 1.25em!important;
    font-weight: 700!important;
    line-height: 2.3125em!important;
}

.label-janela-dashboard-2{
    font-family: Roboto!important;
    font-size: 1.13em!important;
    font-weight: 700!important;
    line-height: 2.3125em!important;
    margin-left: 0.625em!important;
}

.label-percentual-graficos-pizza-composto-dash{
    font-size: 2.4em!important;
    text-align: center!important;
}

.label-percentual-graficos-pizza-grande{
    font-size: 4.6875em!important;
    text-align: center!important;
}

.label-percentual-graficos-pizza-grupo-cinco{
    font-family: Roboto!important;
    font-size: 1.25em!important;
    line-height: 1.4375em!important;
}

.label-percentual-graficos-pizza-grupo-quatro{
    font-family: Roboto!important;
    font-size: 1.5625em!important;
    line-height: 1.4375em!important;
}

.container-tooltip-grafico-pizza{
    background-color: #1F8EFA!important;
    border-radius: 0.625em!important;
    position: relative!important;
    float: right!important;
}

.label-tooltip-grafico-pizza{
    margin-top: 0.625em!important;
    margin-left: 0.625em!important;
    margin-right: 0.625em!important;
    height: 1.3125em!important;
    font-family: RobotoRegular!important;
    font-size: 1.125em!important;
    color: #ffffff!important;
}

/*Gráfico em  BARRA */
.container-tooltip-grafico-barra{
    background-color: #ffffff!important;
    border-radius: 0.625em!important;
    text-align: center;
}

.label-tooltip-grafico-barra{
    text-align: center;
    margin-top: 0.625em!important;
    font-family: RobotoRegular!important;
    font-size: 1.125em!important;
    color: #000000!important;
}

.esfera-legenda-dashboard{
    width: 1.125em!important;
    height: 1.125em!important;
    border-radius: 50%!important;
}

.carrossel-dashboard{
    width: 100%!important;
    height: 100%!important;
}

.carrossel-dashboard>.carousel-inner{
    position: relative!important;
    width: 100%!important;
    height: 100%!important;
}

.item-carrossel-dashboard{
    width: 100%!important;
    height: 100%!important;
}

.container-conexoes-status-contratos{
    width: 100%!important;
    height: 100%!important;
}

.carrossel-dashboard>.carousel-control-prev{
    margin-left: -7%!important;
}

.carrossel-dashboard>.carousel-control-next{
    margin-right: -7%!important;
}

.carrossel-dashboard>.carousel-control-prev:hover{
    margin-left: -7%!important;
}

.carrossel-dashboard>.carousel-control-next:hover{
    margin-right: -7%!important;
}

.carrossel-dashboard>.carousel-control-prev:focus{
    margin-left: -7%!important;
}

.carrossel-dashboard>.carousel-control-next:focus{
    margin-right: -7%!important;
}

.carrossel-dashboard>.carousel-control-prev:active{
    margin-left: -7%!important;
}

.carrossel-dashboard>.carousel-control-next:active{
    margin-right: -7%!important;
}

.carrossel-dashboard .carousel-control-prev-icon{
    background-image: url("../../icones/iconesVersaoVerde/icone-maior.svg")!important;
    transform: rotate(180deg)!important;
}

.carrossel-dashboard .carousel-control-next-icon{
    background-image: url("../../icones/iconesVersaoVerde/icone-maior.svg")!important;
}

.menu-opcoes-dashboard-topo{
    position: absolute!important;
    right: 0!important;
    width: 140px;
    height: 100px!important;
}

.menu-opcoes-dashboard-fundo{
    position: absolute!important;
    right: 0!important;
    width: 205px;
    height: 100px!important;
}

.menu-opcoes-triangulo-topo{
    width: 0!important;
    height: 0!important;
    border-top: 0.9375em solid transparent!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-left: 5.24em!important;
    margin-top: -1.9em!important;
}

.menu-opcoes-triangulo{
    width: 0!important;
    height: 0!important;
    border-top: 0.9375em solid transparent!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-left: 11.24em!important;
    margin-top: -1.9em!important;
}

.menu-opcoes-corpo-topo{
    width: 120px!important;
    max-height: 90vh!important;
    border-radius: 5px!important;
}

.menu-opcoes-corpo{
    width: 215px!important;
    max-height: 90vh!important;
    border-radius: 5px!important;
}

.menu-opcoes-icone-desativado{
    margin-left: 10px!important;
    margin-top: -6px!important;
    transform: rotate(-90deg);
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important
}

.menu-opcoes-icone-ativado{
    margin-left: 10px!important;
    margin-top: -6px!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important
}

.menu-opcoes-sub-label{
    margin-left: 40px!important;
    height: 30px!important;
    font-size: 14px!important;
    line-height: 28px!important;
}

.menu-opcoes-container-timeline{
    margin-left: 50px !important;
}

.menu-opcoes-container{
    position: absolute!important;
    z-index: 5000;
    border-radius: 5px!important;
    background-color: transparent!important;
    border: 0 !important;
}

.menu-opcoes-divisora-topo{
    height: 1px!important;
    width: 100%!important;
    margin-left: -25%!important;
}

.menu-opcoes-divisora{
    height: 1px!important;
    width: 100%!important;
    margin-left: -10%!important;
}

.elipse-azul-grafico-area-dashboard{
    margin-top: 50%!important;
    margin-left: 50%!important;
    transform: translate(-50%, -10%)!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 6.25em!important;
    background-color: #1F8EFA!important;
    color: #1F8EFA!important;
}

.container-tooltip-grafico-area-dashboard{
    margin-left: -3.125em!important;
    border-radius: 0.3125em!important;
    height: 1.25em!important;
    width: 5em!important;
    background-color: #1F8EFA!important;
    margin-top: 0.6875em!important;
}

.label-tooltip-grafico-area-dashboard{
    color: #FFFFFF!important;
    font-family: RobotoRegular!important;
    font-size: 0.75em!important;
    line-height: 1.7875em!important;
    text-align: center!important;
    font-weight: 700!important;
    margin-bottom: -0.2em!important;
}
.triangulo-tooltip-grafico-area-dashboard{
    position: absolute !important;
    z-index: 1!important;
    width: 0!important;
    height: 0!important;
    margin-left: 1.5625em!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    border-bottom: 0.9375em solid transparent!important;
    border-top: 0.9375em solid #1F8EFA!important;
}

.barra-tooltip-grafico-area-dashboard{
    box-sizing: border-box!important;
    margin-left: 2.375em!important;
    height: 32vh!important;
    width: 0.214375em!important;
    border: 0.125em solid #1F8EFA!important;
    background-color: #1F8EFA!important;
}

.barra-tooltip-grafico-area-dashboard-2{
    box-sizing: border-box!important;
    margin-left: 2.375em!important;
    height: 32vh!important;
    width: 0.214375em!important;
    border: 0.125em solid #1F8EFA!important;
    background-color: #1F8EFA!important;
}

.elipse-tooltip-grafico-area-dashboard{
    margin-left: 2.0625em!important;
    border-radius: 6.25em!important;
    height: 0.875em!important;
    width: 0.875em!important;
    background-color: #1F8EFA!important;
}

.titulo-grafico-dashboard{
    width: 80% !important;
}

@media only screen and (min-width: 1200px) and ( max-width: 1451px){

    /* Opções que abrem nos pontinhos verdes*/
    .menu-opcoes-dashboard-fundo {
        margin: -205px 0 0 0;
    }

    .grupo-dash-grafico-pizza-grande {
        display:flex!important;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center !important;
        align-items: center;
        align-content: center;
    }

    .container-dash-grafico-pizza-grande {
        height: 60%!important;
        width: 100%!important;
        align-content: center!important;
        z-index: 10!important;
        flex-direction: row;
        justify-content: center !important;
    }   

    .texto-titulo-legenda-grafico-pizza-grande-dash{
        font-family: Roboto!important;
        font-size: 1.5em!important;
        line-height: 1.1875em!important;
        text-align: right!important;
    }

    .label-percentual-graficos-pizza-composto-dash {
        font-size: 1.5em!important;
        text-align: center!important;
    }
    
    .linha-legenda-status{
        display: flex!important;
        width: 100%!important;
        margin-top: 3%!important;
        margin-bottom: 3%!important;
    }    

    /* card Status */
    .texto-legenda-box-status {
        font-size: 1.2em!important;
        font-weight: 400!important;
    }
    .container-legenda-status{
        width: 50%!important;
        flex-direction: row;
        align-self: center!important;    
        overflow: hidden!important;
        overflow-y: auto!important;
        align-self: center!important;
        justify-content: center;
        max-height: 50%!important;
    }

    /* card Sem Comunicar */
    .container-dash-simcards-sem-comunicar {
        display: flex!important;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center !important;
        align-items: center;
        align-content: center;
    }
    .container-dash-grafico-pizza-sem-comunicar {
        height: 60%!important;
        width: 100%!important;
        align-content: center!important;
        z-index: 10!important;
        flex-direction: row;
        justify-content: center !important;
    }
    .container-legenda-sem-comunicar {
        width: 80%!important;
        flex-direction: row;
        align-self: center!important;    
        overflow: hidden!important;
        overflow-y: auto!important;
        align-self: center!important;
        justify-content: center;
        max-height: 62%!important;
    }
    .linha-dash-legenda-sem-comunicar {
        margin-top: 0%!important;
        margin-bottom: 0%!important;
    }
    .texto-legenda-sem-comunicar {
        font-size: 1.2em!important;
        font-weight: 400!important;
    }
    .texto-legenda-sem-comunicar-numero-unico-2 {
        font-size: 1.2em!important;
        font-weight: 400!important;
    }
    .esfera-legenda-dashboard {
        width: 0.9em!important;
        height: 0.9em!important;
        border-radius: 50%!important;
    }
    .coluna-legenda-sc-sem-comunicar-2 {
        width: 60%!important;
    }

    /* Consumo nos ciclos */
    .caixa-grafico-pizza-consumo {
        margin: -10% 0 0 0;
        width: 99% !important;
    }
    .coluna-legenda-consumo-1 {
        width: 7%!important;
    }
    .coluna-legenda-consumo-2 {
        width: 40%!important;
    }
    .coluna-legenda-consumo-3 {
        width: 40%!important;
    }
    .linha-legenda-consumo {
        margin-top: 0%!important;
    }
    .texto-legenda-consumo {
        font-size: 1em!important;
    }
    .texto-legenda-consumo-numero-unico-2 {
        font-size: 1.2em!important;
        font-weight: 400!important;
    }
    .container-legenda-consumo {
        padding: 0px 0%;
        margin-top: 2em!important;
    }

    /* card operadoras */
    .caixa-grafico-pizza-operadoras {
        width: 99%!important;
    }
    .container-legenda-operadoras {        
        width: 70%!important;
    }
    .texto-legenda-operadoras-numero {
        font-size: 1.2em!important;
        font-weight: 400!important;
    }

    /* Cards Tecnologias */


    /* Cards Status do Contrato */
    .container-status-contrato {
        display: flex!important;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center !important;
        align-items: center;
        align-content: center;
    }
    .container-grafico-pizza-status-contrato {
        height: 60%!important;
        width: 100%!important;
        align-content: center!important;
        z-index: 10!important;
        flex-direction: row;
        justify-content: center !important;
    }
    .container-legenda-status-contrato {
        width: 80%!important;
        flex-direction: row;
        align-self: center!important;    
        overflow: hidden!important;
        overflow-y: auto!important;
        align-self: center!important;
        justify-content: center;
        max-height: 62%!important;
    }
    .texto-legenda-status-contrato-numero {
        font-size: 1.2em!important;
        font-weight: 400!important;
    }

}

@media only screen and (min-width: 1451px) and ( max-width: 1500px){

    .container-dash-grafico-pizza-grande {
        width: 60%!important;
    }

    .container-grafico-pizza-consumo {
        width: 90%!important;
        height: 40%!important;
    }

    .container-grafico-pizza-operadoras, .container-grafico-pizza-tecnologias {
        min-width: 50%!important;
        max-width: 80%!important;
    }

    .texto-titulo-legenda-grafico-pizza-grande-dash{
        font-family: Roboto!important;
        font-size: 1.5em!important;
        line-height: 1.1875em!important;
        text-align: right!important;
    }

    .label-percentual-graficos-pizza-composto-dash {
        font-size: 1.8em!important;
        text-align: center!important;
    }

}

@media only screen and (min-width: 1501px) and ( max-width: 1600px){

    .texto-titulo-legenda-grafico-pizza-grande-dash{
        font-family: Roboto!important;
        font-size: 1.8em!important;
        line-height: 1.1875em!important;
        text-align: right!important;
    }

    .label-percentual-graficos-pizza-composto-dash {
        font-size: 2.2em!important;
        text-align: center!important;
    }

}
