#div-checkbox-autorizar {
  margin-top: 15px;
}

#div-checkbox-autorizar > div {
  display: flex;
  gap: 16px;
}

#div-checkbox-autorizar label {
  /* checkbox  */
  width: 21px !important;
  height: 20px !important;
}

.label-checkbox-sms-checked {
  /* obs: o css do unchecked está no global.js */
  background-color: #33cc99 !important;
}
