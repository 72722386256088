/* ---------- MODAL ---------- */
.backoffice-modal-cadastro-cliente{
    padding: 0em 2em 2em 2em;
    border-radius: 0.625em 0.625em 0.625em 0.625em;
    width: 65em;
    /* height: 32em; */
}

/* ---------- ABAS ---------- */
.backoffice-cadastro-cliente-tabs{
    background-color: transparent;
    display: flex;
    text-align: center;
    justify-content: space-evenly;
}
.backoffice-cadastro-cliente-tab-ativado{
    border-bottom: 0.2em solid #36D896;
    padding-left: 0px;
    padding-right: 0px;
    width: 12em;
}
.backoffice-cadastro-cliente-tab-desativado{
    padding-left: 0px;
    padding-right: 0px;
    width: 12em;
}

/* ---------- PRIMEIRA ABA ---------- */
.backoffice-cadastro-cliente-linha-1{
    width: 100%;
    display: flex;
}
.backoffice-cadastro-cliente-linha-2{
    width: 100%;
    display: flex;
    margin-bottom: 1em;
}
.backoffice-cadastro-cliente-coluna-0{
    width: 20%;
}
.backoffice-cadastro-cliente-coluna-1{
    width: 18%;
}
.backoffice-cadastro-cliente-coluna-2{
    width: 39.5%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-3{
    width: 39.5%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-4{
    width: 80.5%;
}
.backoffice-cadastro-cliente-coluna-5{
    width: 18%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-6{
    width: 27%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-7{
    width: 70%;
}
.backoffice-cadastro-cliente-coluna-8{
    width: 15%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-9{
    width: 12%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-10{
    width: 40%;
}
.backoffice-cadastro-cliente-coluna-11{
    width: 35%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-12{
    width: 22%;
    margin-left: 1.5%;
}
.backoffice-cadastro-cliente-coluna-13{
    width: 100%;
}

/* ---------- SEGUNDA ABA ---------- */
.backoffice-modal-cadastro-usuario-container-permissoes{
    display: flex;
}
.backoffice-modal-cadastro-usuario-container-lista-modulos{
    width: 15em;
    height: 22em;
    margin-bottom: 1em;
    border-radius: 0.625em;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 1em;
}
.backoffice-modal-cadastro-usuario-lista-modulos{
    display: flex;
    height: 4em;
    cursor: pointer;
    justify-content: space-between;
    padding-right: 2em;
    padding-left: 1em;
}
.backoffice-modal-cadastro-usuario-lista-modulos-label{
    display: flex;
    align-items: center;
}
.backoffice-modal-cadastro-usuario-lista-modulos-container-switch{
    white-space: nowrap;
    width: 25%!important;
    padding-top: 0.8em!important;
}
.backoffice-modal-cadastro-usuario-lista-permissoes{
    height: 22em;
    width: 45.4em;
    border-radius: 0.625em;
    overflow-y: auto;
    overflow-x: hidden;
}
.backoffice-cadastro-usuario-permissoes-tabs{
    background-color: transparent!important;
    display: flex;
    text-align: center;
    justify-content: space-evenly;
}
.backoffice-cadastro-usuario-permissoes-ativado{
    border-bottom: 0.2em solid #36D896;
    padding-left: 0px!important;
    padding-right: 0px!important;
    width: 12em;
}
.backoffice-cadastro-usuario-permissoes-desativado{
    padding-left: 0px!important;
    padding-right: 0px!important;
    width: 12em;
}
.backoffice-modal-cadastro-usuario-permissoes-campo-divisor{
    height: 0.0625em!important;
    width: 100%!important;
}
.backoffice-modal-cadastro-usuario-permissoes-container-switch{
    display: flex;
    align-items: center;
    height: 2.7em;
    margin-left: 1em;
}
.backoffice-modal-cadastro-usuario-permissoes-container-switches{
    display: flex!important;
    flex-wrap: wrap!important;
    width: 100%;
}
.backoffice-modal-cadastro-usuario-permissoes-linha-switch{
    display: flex!important;
    width: 50%;
}
.backoffice-modal-cadastro-usuario-permissoes-icone{
    align-self: normal;
    width: 1.375em;
    height: 1.375em;
    margin-top: 1.3em;
}
.backoffice-modal-cadastro-usuario-permissoes-icone-invisivel{
    visibility: hidden!important;
    display: none!important;
}
.backoffice-modal-cadastro-usuario-permissoes-ativo{
    display: flex;
    height: 4em;
    cursor: pointer;
}
.backoffice-modal-cadastro-usuario-permissoes-desativo{
    display: flex;
    height: 4em;
    cursor: pointer;
}