.nova-solicitacao-select-operadora{
    max-width: 11em!important;
    margin-bottom: 1.125em!important;
}
.nova-solicitacao-burcar-linha-operadora{
    justify-content: center!important;
    align-items: center!important;
    margin-top: 1.8em!important;
}

.botao-confirmar-burcar-linha{
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    min-width: 6.875em!important;
    padding-left: 1.875em!important;
    padding-right: 1.875em!important;
    border: 0.09375em solid #36D896!important;
    background-color: #36D896!important;
    box-shadow: none!important;
    border-radius: 3.125em!important;
    margin-left: 0.625em!important;
    float: right!important;
}

.modal-nova-solicitacao .react-bs-table-no-data{
    color: white !important;

}

.span-validacao-buscar-simcards-solicitacoes{
    font-size: .8em !important;
    color: red;
    width: 6em;
    margin-left: 1em;
    transition: ease;
}