.botao-adicionar-endereco {
  border-radius: 50px !important;
  background: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
  text-transform: uppercase !important;
  font-size: 15px !important;
  font-weight: 900 !important;
  color: #243b53;
  line-height: 0 !important;
  height: 42px !important;
  width: 225px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transition: 2s !important; /* Disable hover transition */
}

.botao-adicionar-endereco:hover {
  background: rgb(236, 236, 236);
}

.img-adicionar-endereco {
  width: 20px;
  height: 20px;
}
