.container-lista-conversas{
    max-height: 63vh!important;
    overflow-x: hidden!important;
    overflow-y: auto!important;
}

.botao-ver-mais-conversas-sms{
    width: 100%!important;
    display: flex!important;
    justify-content: center!important;
    margin-top: 0.75em;
    margin-bottom: -0.75em;
}

.botao-ver-mais-mensagens{
    font-family: RobotoBold!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 1.1875em!important;
    height: 2.375em!important;
    min-width: 6.875em!important;
    padding-left: 1.875em!important;
    padding-right: 1.875em!important;
    border: 0.09375em solid #36D896!important;
    background-color: transparent!important;
    box-shadow: none!important;
    border-radius: 3.125em!important;
    margin-left: 0.625em!important;
}

.container-mensagem-enviada{
    float: left!important;
}

.container-mensagem-recebida{
    float: right!important;
}

.container-mensagem-recebida-externo{
    display: flex!important;
    float: right!important;
}

.triangulo-mensagem-enviada{
    float: left!important;
    z-index: 1!important;
    width: 0em!important;
    height: 0em!important;
    border-top: 0.6375em solid transparent!important;
    border-bottom: 0.6375em solid transparent!important;
    border-left: 0.6375em solid transparent!important;
    border-right: 0.6375em #36D896 solid!important;
    margin-top: 1.8em!important;
}

.triangulo-mensagem-recebida{
    float: right!important;
    z-index: 1!important;
    width: 0em!important;
    height: 0em!important;
    border-top: 0.6375em solid transparent!important;
    border-bottom: 0.6375em solid transparent!important;
    border-right: 0.6375em solid transparent!important;
    margin-top: 1.8em!important;
}

.balao-mensagem-enviada{
    padding: 0.5em!important;
    width: 20.5625em!important;
    border-radius: 0.3125em!important;
    background-color: #36D896!important;
}

.balao-mensagem-recebida{
    padding: 0.5em!important;
    width: 20.5625em!important;
    border-radius: 0.3125em!important;
}

.fonte-mensagem-titulo{
    height: 1em!important;
    width: 9.25em!important;
    font-family: Roboto!important;
    font-size: 0.875em!important;
    font-weight: 700!important;
    line-height: 1em!important;
}

.fonte-mensagem-hora{
    height: 1em!important;
    color: #BDBDBD!important;
    font-family: Roboto!important;
    font-size: 0.875em!important;
    line-height: 1em!important;
    float: right!important;
}

.fonte-mensagem-enviada{
    height: 1em!important;
    color: #000!important;
    font-family: Roboto!important;
    font-size: 0.875em!important;
    line-height: 1em!important;
    display: inline-block!important;
    white-space: normal!important;
    word-break: break-all!important;
    max-width: 22.5625em!important;
    line-break: auto!important;
}

.fonte-mensagem-recebida{
    height: 1em!important;
    font-family: Roboto!important;
    font-size: 0.875em!important;
    line-height: 1em!important;
    display: inline-block!important;
    white-space: normal!important;
    word-break: break-all!important;
    max-width: 22.5625em!important;
    line-break: auto!important;
}

.hora-mensagem-enviada{
    margin-top: 0.45em !important;
}