
/* ============================================ */
/*               ALTERAR GRUPOS                 */
/* ============================================ */

#tela-alterar-grupo{
    height: 28.1875em;
    width: 41.875em;
    max-height: 90vh;
    position: relative;
    bottom: 15vh;
    overflow: auto;
    padding-left: 2.5em;
    padding-right: 2.5em;
    padding-top: 0.9375em;
    right: 7em;
}

/* SELECT ALTERAR GRUPOS */
.select-pav-triangulo-grupo {
    position: absolute!important;
    z-index: 1!important;
    width: 0!important;
    height: 0!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-top: -1em!important;
    margin-left: 44%!important;
}

.select-pav-triangulo-grupo-editar{
    position: absolute!important;
    z-index: 1!important;
    width: 0!important;
    height: 0!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-top: -2em!important;
    margin-left: 39%!important;
}

.select-pav-corpo{
    max-height: 9.8125em!important;
    width: 15.625em!important;
    border-radius: 0.3125em!important;
    overflow-x: hidden!important;
    margin-top: 0.9375em!important;
}

.select-pav-corpo-grupos{
    border-radius: 0.3125em!important;
    overflow-x: hidden!important;
    margin-top: 1.5625em!important;
}

.select-pav-divisora-grupos{
    margin-left: -1.25em!important;
    width: 33.4375em!important;
    background-color: black!important;
    height: 0.0625em!important;
}

.label-campo-visualizar{
    font-family: RobotoRegular!important;
    font-size: 1em!important;
    line-height: 1em!important;
    margin-top: 1em !important;
}

.label-campo-nome {
    margin-top: 13px;
}

.fonte-cabecalho-grupo{
    color: #fff;
}

.container-cabecalho-tela-grupo{
  height: 1.875em!important;
}

.grupo-campo-personalizado{
  margin-bottom: 0em!important;
}

.analise-corpo-grupo{
    width: 25em!important;
    border-radius: 0em 0em 0.3125em 0.3125em!important;
}

.campo-select-modal-icone-grupo{
    float: right!important;
    margin-right: 1.25em!important;
    transform: rotate(270deg);
    margin-top:  0.5em!important;
}

.menu-dropdown-pav-grupos{
    margin-top: 0.625em!important;
    border-color: transparent!important;
}



/* ============================================ */
/*            CONFIGURAR GRUPOS                 */
/* ============================================ */

 /* MULTI SLIDER DE SITUAÇÃO */
 .barra-slider svg line {
    stroke-width: 22!important;
}

g:nth-last-child(3) circle:nth-last-child(3) {
    fill:#36D896;
}

g:nth-last-child(3) circle:nth-last-child(2) {
    opacity: 1;
    fill:#343736;
}

g:nth-last-child(3) circle:nth-last-child(2):active {
    opacity: 1;
    fill:#36D896;
}

g:nth-last-child(3) circle:nth-last-child(1) {
    fill:#343736;
}

g:nth-last-child(2) circle:nth-last-child(3) {
    fill:#F3CA3E;
}

g:nth-last-child(2) circle:nth-last-child(2) {
    opacity: 1;
    fill:#343736;
}

g:nth-last-child(2) circle:nth-last-child(2):active {
    opacity: 1;
    fill:#F3CA3E;
}

g:nth-last-child(2) circle:nth-last-child(1) {
    fill:#343736;
}

g:nth-last-child(1) circle:nth-last-child(3) {
    fill:#FC5F0E;
}

g:nth-last-child(1) circle:nth-last-child(2) {
    opacity: 1;
    fill:#343736;
}

g:nth-last-child(1) circle:nth-last-child(2):active {
    opacity: 1;
    fill:#FC5F0E;
}

g:nth-last-child(1) circle:nth-last-child(1) {
    fill:#343736;
}


/* BOTAO  */
.bt-editar-grupo{
    width: 1.25em!important;
    height: 0.8125em!important;
    margin-top: -0.80em!important;
    margin-left: -0.51em!important;
}

.bt-visualizar-grupo{
    width: 1.25em!important;
    height: 0.8125em!important;
    margin-top: -0.9em!important;
    margin-left: -0.55em!important;  
}

.bt-apagar-grupo{
    height: 0.9375em!important;
    width: 0.6875em!important;
    margin-top: -0.75em!important;
    margin-left: -0.25em!important;
}

.bt-configuracao-grupo{
    height: 1.0625em!important;
    width: 1em!important;
    margin-top: -0.82em!important;
    margin-left: -0.38em!important;
}

.bt-adicionar-grupo{
    margin-top: -1.25em!important;
    width: 2em!important;
    height: 2em!important;
}

.bt-barra-modal-grupo{
    margin-bottom: 1.875em!important;
}


.bt-fechar-janela-icone-grupos{
    height: 1.3125em!important;
    width: 1.3125em!important;
    margin-top: 0.3125em!important;
    margin-right: 0.1875em!important;
}

button.campo-texto.campo-texto-grupos.btn.btn-primary{
    margin-bottom: 0.625em;
}

.btn-toolbar-grupos{
    margin-top: -3.4em!important;
    margin-left: 20.5em!important;
    margin-bottom: 1.25em!important;
}


/* CAMPOS DE TEXTO  */
.campo-obrigatorio-grupo{
    color: #CD2D37!important;
    font-size: 0.8125em!important;
    margin-left: 1.25em!important;
    margin-top: 0.3125em!important;
    margin-bottom: -0.3125em!important;
}

.texto-grupo{
    font-family: RobotoRegular!important;
    font-size: 1em!important;
    font-weight: 700!important;
    line-height: 2.5em!important;
    margin-bottom: 1em!important;
}

.campo-texto-peso{
    margin-left: 0.9375em;
    margin-right: 19.2em;
    font-family: RobotoRegular;
    font-size:  0.875em!important;
}

.texto-tolerancia{
    font-family: RobotoRegular;
    font-size:  0.875em!important; 
    line-height: 1em;
}

.campo-texto-tolerancia{
    margin-left: 2.8125em!important;
    height: 1.9375em!important;
    width: 4.0625em!important;
    font-family: RobotoRegular!important;
    font-size:  0.875em!important;
    line-height: 0.9375em!important;
    border-radius: 3.125em!important;
    border: 0em!important;
    box-shadow: none!important;
    display: -webkit-inline-box!important;
}

.campo-texto-minutos-offline{
    display: inline!important;
    margin-left: 2.6em!important;
    margin-top: 0.4em;
    border-radius: 3.125em!important;
    border: 0em!important;
    box-shadow: none!important;
    text-align: center !important;
    height: 1.9375em!important;
    width: 4.572em!important;
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
}

.campo-texto-dias-offline{    
    display: inline!important;
    margin-top: 0.4em;
    border-radius: 3.125em!important;
    border: 0em!important;
    box-shadow: none!important;
    text-align: center !important;
    margin-left: 2.8em!important;
    height: 1.9375em!important;
    width: 4.572em!important;
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
}

.fonte-texto-grupo{
    margin-top: 0.9375em!important;
    margin-left: 1.5em!important;
    font-size:  1em!important;
    font-family: RobotoRegular;
}

.fonte-texto-grupo-offline{
    width: 9em!important;
    display: inline!important;
    margin-left: 0.9375em!important;
    font-family: RobotoRegular!important;
    font-size:  0.875em!important;
    font-weight: 400!important
}

.fonte-texto-grupo-media-offline{
    width: 9em!important;
    display: inline!important;
    margin-left: 0.9375em!important;
    font-family: RobotoRegular!important;
    font-size:  0.875em!important;
    font-weight: 400!important;
    margin-top: 0.625em !important;
}


/******************** SWITCHES ********************/

.switchDiv-online{
    display: flex !important;
}

.flipswitch-online{/* CLASSE PADRAO DO SWITCH */
    position: relative!important;
    width: 4em!important;
    -webkit-user-select:none!important;
    -moz-user-select:none!important;
    -ms-user-select: none!important;
    margin-left: 8.5em !important;
    margin-top: 0.4375em !important;
}

.flipswitch-online input[type=checkbox]{/* CLASSE PADRAO DE FUNCIONAMENTO DO SWITCH */
    display: none!important;
}

.flipswitch-label-online{/* CLASSE PADRAO DA FORMA DO SWITCH */
    display: block!important;
    overflow: hidden!important;
    cursor: pointer!important;
    border-radius: 3.125em!important;
}

.flipswitch-inner-online{/* CLASSE PADRAO DA ANIMACAO DO TRILHO DO SWITCH */
    width: 8em!important;
    margin-left: -0em!important;
    -webkit-transition: margin 0.3s ease-in 0s!important;
    -moz-transition: margin 0.3s ease-in 0s!important;
    -ms-transition: margin 0.3s ease-in 0s!important;
    -o-transition: margin 0.3s ease-in 0s!important;
    transition: margin 0.3s ease-in 0s!important;
}

.flipswitch-inner-online:before, .flipswitch-inner-online:after{/* CLASSE PADRAO PARA POSICIONAMENTO DO BOTAO DO SWITCH */
    float: left!important;
    width: 50%!important;
    height: 1.75em !important;
    padding: 0!important;
    line-height: 1.9375em!important;
    font-size: 1.125em!important;
    color: white!important;
    font-family: Trebuchet, Arial, sans-serif!important;
    font-weight: bold!important;
    -moz-box-sizing: border-box!important;
    -webkit-box-sizing: border-box!important;
    box-sizing: border-box!important;
}

.flipswitch-inner-online:before{/* CLASSE PADRAO PARA ESCONDER O CONTEUDO DE FUNCIONAMENTO DO SWITCH QUANDO DESATIVADO */
    content: ""!important;
}

.flipswitch-inner-online:after{/* CLASSE PADRAO PARA ESCONDER O CONTEUDO DE FUNCIONAMENTO DO SWITCH QUANDO ATIVADO */
    content: ""!important;
}

.flipswitch-switch-online{/* CLASSE PADRAO DA ESFERDA DO SWITCH */
    width: 1.35em!important;
    height: 1.35em!important;
    margin-top: 0.21875em!important;
    border-radius: 3.125em!important;
    position: absolute!important;
    top: 0.1em!important;
    bottom: 0!important;
    right: 2.3em!important;
    -webkit-transition: all 0.3s ease-in 0s!important;
    -moz-transition: all 0.3s ease-in 0s!important;
    -ms-transition: all 0.3s ease-in 0s!important;
    -o-transition: all 0.3s ease-in 0s!important;
    transition: all 0.3s ease-in 0s!important;
}

.flipswitch-cb-online:checked + .flipswitch-label-online .flipswitch-inner-online{/* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
    margin-left: 0!important;
}

.flipswitch-cb-online:checked + .flipswitch-label-online .flipswitch-switch-online{/* CLASSE PADRAO PARA POSICIONAMENTO CORRETO DA ESFERA DO SWITCH */
    right: 0.25em!important;
}

.flipswitch-cb-online + .flipswitch-label-online .flipswitch-switch-online{/* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH DESATIVADO*/
    background-color: #CD2D37!important;
}

.flipswitch-cb-online:checked + .flipswitch-label-online .flipswitch-switch-online{/* CLASSE PADRAO PARA COR DA ESFERA DO SWITCH ATIVADO*/
    background-color: #36D896!important;
}

.numerosRadio0{
    font-size: 1em!important;
    font-family: RobotoRegular!important;
    z-index: 100 !important;
    position: relative; 
}
.numerosRadio1{
    font-size: 1em!important;
    font-family: RobotoRegular!important;
    z-index: 100 !important;
    position: relative; 
}
.numerosRadio2{
    font-size: 1em!important;
    font-family: RobotoRegular!important;
    z-index: 100 !important;
    position: relative; 
}
.numerosRadio3{
    font-size: 1em!important;
    font-family: RobotoRegular!important;
    z-index: 100 !important;
    position: relative;
}
.numerosRadio4{
    font-size: 1em!important;
    font-family: RobotoRegular!important;
    z-index: 100 !important;
    position: relative; 
}
.numerosRadio5{
    font-size: 1em!important;
    font-family: RobotoRegular!important;
    z-index: 100 !important;
    position: relative; 
}
.numerosRadio6{
    font-size: 1em!important;
    font-family: RobotoRegular!important;
    z-index: 100 !important;
    position: relative; 
}
.numerosRadio7{
    font-size: 1em!important;
    font-family: RobotoRegular!important;
    z-index: 100 !important;
    position: relative; 
}
.numerosRadio8{
    margin-left: 0px !important;
    font-size: 1em!important;
    font-family: RobotoRegular!important;
    z-index: 100 !important;
    position: relative; 
}
.numerosRadio9{
    font-size: 1em!important;
    font-family: RobotoRegular!important;
    z-index: 100 !important;
    position: relative; 
}
.numerosRadio10{
    margin-left: -1px !important;
    font-size: 1em!important;
    font-family: RobotoRegular!important;
    z-index: 100 !important;
    position: relative; 
}

.numerosRadioBold0{
    font-size: 1em!important;
    font-family: RobotoBold!important;
}
.numerosRadioBold1{
    font-size: 1em!important;
    font-family: RobotoBold!important;
}
.numerosRadioBold2{
    font-size: 1em!important;
    font-family: RobotoBold!important;
}
.numerosRadioBold3{
    font-size: 1em!important;
    font-family: RobotoBold!important;
}
.numerosRadioBold4{
    font-size: 1em!important;
    font-family: RobotoBold!important;
}
.numerosRadioBold5{
    font-size: 1em!important;
    font-family: RobotoBold!important;
}
.numerosRadioBold6{
    font-size: 1em!important;
    font-family: RobotoBold!important;
}
.numerosRadioBold7{
    font-size: 1em!important;
    font-family: RobotoBold!important;
}
.numerosRadioBold8{
    margin-left: 1px !important;
    font-size: 1em!important;
    font-family: RobotoBold!important;
}
.numerosRadioBold9{
    font-size: 1em!important;
    font-family: RobotoBold!important;
}
.numerosRadioBold10{
    margin-left: -3px !important;
    font-size: 1em!important;
    font-family: RobotoBold!important;
}


/* CONTAINER  */
.container-grafico-peso {
    display: block;
    position: relative;
    margin-left: 1.1em;
    margin-top: 0.0725em;
    cursor: pointer;
    font-size: 0.875em!important;
    font-family: RobotoRegular!important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: white;
}
.container-grafico-peso-situacao {
    margin-left: 1.375em!important;
}
  
.container-grafico-peso input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
.container-grafico-peso input:checked ~ .checkmark0-grafico-peso {
    margin-top: -0.1em!important;
    height: 1.4em!important;
    width: 1.4em!important;
    border: solid 0.1em #fff;
    margin-left: 4px;
    background-color: #1F8EFA !important;
}

.container-visualizar-grupos{
    padding-bottom: 0.625em!important;
}

.editar-corpo-grupos{
    padding: 5% !important;
    width: 100% !important;
    border-radius: 0.3125em !important;
    margin-bottom:0.625em!important;
    overflow-y: auto !important;
    max-height: 17em !important;
}

.btn-fechar-editar-grupo{
    margin-top: -17px!important;
    margin-right: -8px!important;
}

/* SELECT  */
.select-pav-divisora-configuracao-grupos{
    margin-left: -2.5em!important;
    width: 22.8125em!important;
    background-color: black!important;
    height: 0.0625em!important;
}

.select-pav-divisora-configuracao-grupos2{
    width: 25em!important;
    background-color: black!important;
    height: 0.0625em!important;
}

.select-pav-divisora-analise-grupos{
    margin-left: 0.9375em!important;
    width: 23.125em!important;
    background-color: black!important;
    height: 0.0625em!important;
}

.select-pav-corpo-configurar-grupos{
    margin-left: 1.25em;
    border-radius: 0.3125em!important;
    overflow-x: hidden!important;
    margin-top: 0.9375em!important;
    width: 22.75em!important;
}

.select-pav-corpo-analise-grupos{
    margin-left: 1.25em!important;
    margin-top: 1.875em!important;
    border-radius: 0.3125em!important;
    overflow-x: hidden!important;
    width: 22.75em!important;
}

.select-pav-menu-grupos{
    margin-left: -2.5em!important;
    height: 1.875em!important;
    font-size: 0.875em!important;
    line-height: 1.875em!important;
    cursor: pointer;
}
.select-pav-menu-grupos:hover{
    margin-left: -2.5em!important;
    height: 1.875em!important;
    font-size:  0.875em!important;
    line-height: 1.875em!important;
}
.select-pav-menu-grupos:active{
    margin-left: -2.5em!important;
    font-size:  0.875em!important;
    line-height: 1.75em!important;
}



 /* SWITCH DO CONFIGURACAO DE GRUPOS */
.flipswitch-grupo {
    margin-left: 0.625em!important;
    margin-right: 0.625em!important;
    margin-top: 0.3125em!important;
    position: relative!important;
    width: 3.5em!important;
    -webkit-user-select:none!important;
    -moz-user-select:none!important;
    -ms-user-select: none!important;
    float: left!important;
}
.flipswitch-grupo input[type=checkbox] {
    display: none;
}
  .flipswitch-label-grupo {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 3.125em;
}
.flipswitch-inner-grupo {
    width: 200%;
    margin-left: -100%;
    -webkit-transition: margin 0.3s ease-in 0s;
    -moz-transition: margin 0.3s ease-in 0s;
    -ms-transition: margin 0.3s ease-in 0s;
    -o-transition: margin 0.3s ease-in 0s;
    transition: margin 0.3s ease-in 0s;
} 
.flipswitch-inner-grupo:before, .flipswitch-inner-grupo:after {
    float: left;
    width: 50%;
    height: 1.4em;
    padding: 0;
    line-height: 1.9375em!important;
    font-size: 1.125em;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.flipswitch-inner-grupo:before {
    content: "";
    padding-left: 0.8125em;
    color: #FFFFFF;
}
.flipswitch-inner-grupo:after {
    content: "";
    padding-right: 0.8125em;
    color: #888888;
    text-align: right;
}
.flipswitch-switch-grupo {
    margin-right: 0.1875em!important;
    width: 1.1875em;
    height: 1.1875em;
    background: #FFFFFF;
    border-radius: 3.125em;
    position: absolute;
    top: 0.1875em!important;
    bottom: 0!important;
    right: 1.5625em;
    -webkit-transition: all 0.3s ease-in 0s;
    -moz-transition: all 0.3s ease-in 0s;
    -ms-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s;
}
.flipswitch-cb-grupo:checked + .flipswitch-label-grupo .flipswitch-inner-grupo {
    margin-left: 0;
}

.flipswitch-cb-grupo:checked + .flipswitch-label-grupo .flipswitch-switch-grupo {
    right: 0;
}

.flipswitch-cb-grupo + .flipswitch-label-grupo .flipswitch-switch-grupo {
    background-color: #C23434ed;
}

.flipswitch-cb-grupo:checked + .flipswitch-label-grupo .flipswitch-switch-grupo {
    background-color: #36D896;
}
 

  /* // RADIOS GRUPO  */
.barra-analise{
    margin-left: 0.9375em;
    margin-bottom: 0.9375em;
    height: 1.375em!important; 
    width: 17.5em!important;
    border-radius: 3.125em!important;
    background: linear-gradient(90deg, #36D896 0%, #F3CA3E 31%, #FC5F0E 67%, #CD2D37 88%)!important;
    display: flex!important;
}

.barra-situacao{
    margin-top: -0.725em!important;
    width: 18em!important;
    margin-left: 0.625em!important;

}

.numeros-situacao{
    margin-right: 1.5625em !important;
}

.numeros-situacao0{
    margin-left: 0.9375em !important;
    margin-right: 1.390em !important;
}

.barra-numeros{
    margin-top: -1.200em!important;
    zoom: 78% !important;
}

.accordion-analisar-situacao{
    height: 1.875em!important;
    padding: 0em 0em 0em 0.9375em!important;
    font-family: RobotoRegular!important;
    font-size:  0.875em!important;
    font-weight: 700!important;
    line-height: 1.875em!important;
    border: none!important;
}

.checkmark-grafico-peso {
    position: absolute;
    top: 0em;
    left: -0.6875em;
    height: 1.25em;
    width: 1.25em;
    border-radius: 50%;
}

.checkmark-grafico-peso:after {
    font-size: 1em!important;
    margin-top: -0.4375em!important;
    margin-left: 0em!important;
    position: absolute;
    display: none;
}
.checkmark-grafico-peso:after {
    position: absolute;
    display: none;
}

.container-grafico-peso input:checked ~ .checkmark0-grafico-peso:after {
    display: block;
}
  
.container-grafico-peso .checkmark0-grafico-peso:after {
    top: 0.5625em;
    left: 0.5625em;
    width:  0.5em;
    height:  0.5em;
    border-radius: 50%;
    background: transparent!important;
}


.icone-label-janela-grupo{
    height: 0.375em!important;
    width: 0.375em!important;
    margin-right: 0.625em!important;
    border-radius: 6.25em!important;
    background-color: #36D896!important;
    margin-left: 1.25em!important;
}

.icone-label-janela-grupo-atributo{
    height: 0.375em!important;
    width: 0.375em!important;
    margin-right: 0.625em!important;
    border-radius: 6.25em!important;
    background-color: #36D896!important;
    margin-left: 1.25em!important;
}

.label-janela-grupo{
    margin-top: 0em;
    margin-bottom: 0em;
}

.container-cabecalho-tela-grupo-titulos{
    line-height: 1.875em!important;
    zoom: 125% !important;
}

.campo-cadastrar-grupo{
    margin-bottom: 1.3125em!important;
    display: -webkit-inline-box;
    width: 25em!important;
    border: 0em!important;
    border-radius: 6.25em!important;
    box-shadow: none!important;
  }

.campo-texto-grupos-editar{
    width: 22.8125em!important;
    margin-left: 1.125em!important;
}

.campo-grupo{
    width: 18.4375em!important;
    margin-left: 1em!important;
    font-size:  1em!important;
    font-family: RobotoRegular!important;
    line-height: 2.3em!important;
}

.campo-texto-grupos{
    width: 22.8125em!important;
    margin-left: 0.9375em;
}
.campo-select-grupos:hover{
    background-color: transparent!important;
}
.campo-select-grupos:active{
    background-color: transparent!important;
}

.campo-texto-analise{
    margin-top: 1.25em!important;
    margin-left: 1em!important;
    line-height: 1.875em!important;
    font-family: RobotoRegular!important;
    font-size:  0.875em!important;
    font-weight: 700!important;
}

.visualizar-grupo{
    margin-top: 0.1875em!important;
    height: 1.6875em!important;
    width: 1.6875em!important;
    background-color: transparent!important;
    border: none!important;
    border-radius: 3.125em!important;
    box-shadow: none!important;
}

  .visualizar-grupo:focus{
    margin-top: 0.1875em!important;
    height: 1.6875em!important;
    width: 1.6875em!important;
    border: none!important;
    border-radius: 3.125em!important;
    box-shadow: none!important;
  }

  .cadastrar-grupo{
    margin-top: 0.625em!important;
    margin-bottom: 1.25em!important;
    margin-left: 22.1875em!important;
    height: 1.25em!important;
    width: 0.9375em!important;
    background-color: transparent!important;
    border: none!important;
    box-shadow: none!important;
  }

  .modal-editar-grupo{
      margin-left: -12.5em!important;
      margin-top: 3.125em!important;
      width: 6.25em!important;
      height: 12.5em!important;
      background-color: red!important;
  }

.configuracao-corpo-grupo{
    width: 25em!important;
    border-radius: 0.3125em!important;
    margin-bottom:0.625em!important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    max-height: 16em !important;
}

.campo-porcentagem-tolerancia{
    margin-left: -20px !important;
}

.coluna-cadastro-grupos-1{
    width: 100%!important;
}

.container-select-novo-grupo{
    margin-top: 1em !important;
}

.container-tabela-modal-grupos{
    overflow: auto!important;
    overflow-x: hidden!important;
    overflow-y: auto !important;
    max-height: 45vh!important;
}

.container-tabela-generica-modalAlterarGrupoDispositivos{
    max-height: 18em;
    overflow: auto;
}


@media (max-width: 1670px) and (max-height: 940px){
    .configuracao-corpo-grupo{
        max-height: 16em;
        overflow-y: auto;
    }
}