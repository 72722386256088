.grupo-3-campos {
    height: 200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media screen and (min-height: 780px) {
    .grupo-3-campos, .expand-container {
        height: 364px!important;
    }
}

.expand-container {
    height: 280px;
}

.grupo-nova-versao-1{
    display: flex!important;
    gap: 75px;
}
.div-campo-versao{
    padding-top: 5px;
    /* padding-left: 29px; */
}

.div-campo-versao > .form-group {
    display: flex;
    flex-direction: column;
}

.div-campo-versao label {
    margin-bottom: 0.4em;
}

.div-campo-versao input {
    width: 100px!important;
}

.div-campo-imagem-principal {
    width: 190px;
}

.div-campo-imagem-principal #div-importar-img {
    cursor: pointer;
}

.div-campo-imagem-principal button {
    cursor: pointer;
    background: transparent;
    border: none;
}

.div-campo-imagem-principal img { /* ícone */
    cursor: pointer;
    position: absolute!important;
    margin-top: 2px;
    margin-left: 156px;
    z-index: 90;

}

#preview-imagem-principal { /* imagem */
    max-width: 17.5em!important;
    max-height: 8.5em!important;
    border: 0.2em solid #36D896!important;
    margin-left: 0px;
    margin-top: -10px;
    cursor: default;
}

@media screen and (min-height: 780px) {
    #preview-imagem-principal {
        max-width: 552px!important;
        max-height: 261.62px!important;
        transition: 0.3s;
    }
}

@media screen and (max-height: 780px) {
    #preview-imagem-principal:hover{
        margin-top: -16px;
        max-width: 552px!important;
        max-height: 261.62px!important;
        transition: 0.3s;
    }
}

.label-data-versao{
    margin-left: 1.5em !important;
    margin-bottom: 0.4em !important;
}

#div-versao-img-observacao {
    margin-bottom: 10px;
}

#div-mensagem-editor {
    margin-top: 40px;
}

@media screen and (max-height: 780px) {
    #div-mensagem-editor {
        margin-top: 10px;
    }

    .sun-editor-editable {
        height: 40vh!important;
    }
}
