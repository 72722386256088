/********** CLASSES DO CABECALHO DO SISTEMA **********/
/* Sempre verifique se a classe que você procura já  */
/* existe. Este arquivo ainda precisa ter suas       */
/* renomeadas de modo genérico e organizadas de modo */
/* a facilitar a manutenção e compreensão.           */
/***** MATEUS FOLLETTO - 26/05/2020 - VIRTUEYES ******/
/******************** CABECALHO DO SISTEMA ********************/
.container-cabecalho-sistema{
    width: 100%!important;
    margin-top: 0.9em!important;
    padding-left: 1.9em!important;
    padding-right: 1.25em!important;
    justify-content: space-between!important;
    display: flex!important;
    /* position: sticky; */ /*COMENTADO POR ESTAR TIRANDO O COMPONENTE QUE ESCURECE A TELA DO LUGAR*/
    margin-bottom: 0.0em;
    z-index:500!important;
}

.grupo-cabecalho-sistema-1{
    width: 3.825em!important;
}

.grupo-cabecalho-sistema-2{
    width: 65.625em!important;
    display: flex!important;
}

.grupo-cabecalho-sistema-3{
    display: flex!important;
    width: 9em!important;
    justify-content: space-between!important;
    margin-right: 1.5625em !important;
}

.botao-alternar-navegacao-sistema{
    height: 2.25em!important;
    width: 2.625em!important;
    border-color: transparent!important;
    background-color: transparent!important;
    font-size: 0.875em!important;
    font-weight: 700!important;
    line-height: 1em!important;
    box-shadow: none!important;

}

.icone-botao-alternar-navegacao-sistema{
    margin-top: 50%!important;
    margin-left: 50%!important;
    transform: translate(-50%, -30%)!important;
}

.container-barra-de-pesquisa-cabecalho-sistema{
    width: 100%!important;
    display: flex!important;
    max-width: 40em;
}

.coluna-barra-de-pesquisa-1{
    width: 1.875em!important;
}

.coluna-barra-de-pesquisa-2{
    width: 100%!important;
}

.coluna-barra-de-pesquisa-3{
    width: 1.875em!important;
}

.coluna-barra-de-pesquisa-4{
    width: 1.875em!important;
}

.container-menus-filtros-acoes-cabecalho-sistema{
    display: flex!important;
    width: 26em!important;
    /* justify-content: flex-start;  */
}

.container-botao-filtros-cabecalho-sistema{
    margin-left: 0.625em!important;
    height: 2em!important;
    width: 7.8em!important;
}

.botao-filtros-acoes-cabecalho-sistema{
    height: 2em!important;
    width: 7.8em!important;
    border-color: transparent!important;
    border-radius: 3.125em!important;
    box-shadow: none!important;
    font-weight: 700!important;
    line-height: 1em!important;
    text-align: left!important;
}

.margem-botao-relatorios {
    margin-right: -28px;
}

.botao-filtros-acoes-cabecalho-sistema-home{
    margin: 0 137px 0 24px !important;
    height: 2em!important;
    width: 140px !important;
    border-color: transparent!important;
    border-radius: 3.125em!important;
    box-shadow: none!important;
    font-weight: 700!important;
    line-height: 1em!important;
    text-align: center!important;
} 

.botao-filtros-acoes-cabecalho-sistema-desativado{
    cursor: default!important;
    height: 2em!important;
    width: 7.8em!important;
    border-color: transparent!important;
    border-radius: 3.125em!important;
    box-shadow: none!important;
    font-weight: 700!important;
    line-height: 1em!important;
    text-align: left!important;
    background: transparent;
    border: none !important;
    font-size:0;
}

.botao-menu-informacoes{
    text-align: justify !important;
    width: 100% !important;
    padding-top: 0.2em !important;
    padding-left: 0.8em !important;
    border: none!important;
    box-shadow: none!important;
    height: 30px !important;
}

.botao-submenu-informacoes{
    border: none !important;
    box-shadow: none !important;
    padding: 0em !important;
    cursor: pointer !important;
    width: 100% !important;
    text-align: justify !important;
    border-radius: 0em !important;
    margin-top: 5px !important;
}

.label-filtros-acoes-cabecalho-sistema{
    font-size: 0.875em!important;
    cursor: pointer!important;
}

.label-filtros-acoes-cabecalho-sistema-desativado{
    font-size: 0.875em!important;
    cursor: default!important;
    color: rgb(109,109,109)!important;
}

.icone-filtros-cabecalho-sistema{
    position: absolute!important;
    z-index: 1!important;
    margin-left: 2.5em!important;
    cursor: pointer!important;
}

.icone-filtros-cabecalho-sistema-desativado{
    position: absolute!important;
    z-index: 1!important;
    margin-left: 2.5em!important;
    cursor: default!important;
}

.icone-acoes-cabecalho-sistema-desativado{
    position: absolute!important;
    z-index: 1!important;
    margin-top: 0.1625em!important;
    margin-left: 3.125em!important;
    cursor: pointer!important;
    transform: rotate(270deg)!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease !important;
}

.icone-acoes-cabecalho-sistema{
    position: absolute!important;
    z-index: 1!important;
    margin-top: 0.0625em!important;
    margin-left: 3.125em!important;
    cursor: pointer!important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease !important;
}

.icone-menu-informacoes{
    position: absolute!important;
    z-index: 1!important;
    margin-top: 0.3em!important;
    margin-left: 1.5em!important;
    cursor: pointer!important;
    transform: rotate(270deg)!important;
}

.botao-barra-pesquisa-pesquisar{
    float: left!important;
    height: 1.875em!important;
    width: 2.1875em!important;
    border-color: transparent!important;
    border-radius: 3.125em 0em 0em 3.125em!important;
    outline: none!important;
    box-shadow: none!important;
}

.icone-botao-barra-pesquisa-pesquisar{
    margin-top: -0.75em!important;
    margin-left: -0.1875em!important;
}

.campo-barra-pesquisa-cabecalho-sistema{
    float: left!important;
    height: 1.9em!important;
    width: 100%!important;
    border-color: transparent!important;
    border-radius: 0em 0em 0em 0em!important;
    outline: none!important;
    box-shadow: none!important;
}

.campo-barra-pesquisa-cabecalho-sistema-desativado{
    float: left!important;
    height: 1.9em!important;
    width: 100%!important;
    border-color: transparent!important;
    border-radius: 0em 0em 0em 0em!important;
    outline: none!important;
    box-shadow: none!important;
}

.botao-barra-pesquisa-limpar{
    float: left!important;
    height: 1.875em!important;
    width: 1.875em!important;
    border-color: transparent!important;
    border-radius: 0!important;
    box-shadow: none!important;
}

.icone-botao-barra-pesquisa-limpar{
    margin-top: -0.6875em!important;
    margin-left: -0.34375em!important;
}

.botao-barra-pesquisa-dropdown{
    float: left!important;
    height: 1.875em!important;
    width: 2em!important;
    border-color: transparent!important;
    border-radius: 0em 3.125em 3.125em 0em!important;
    box-shadow: none!important;
}

.icone-botao-barra-pesquisa-dropdown{
    margin-top: -0.8125em!important;
    margin-left: -0.375em!important;
}

.botao-barra-pesquisa-pesquisar-desativado{
    cursor: default!important;
    float: left!important;
    height: 1.875em!important;
    width: 2.1875em!important;
    border-color: transparent!important;
    border-radius: 3.125em 0em 0em 3.125em!important;
    outline: none!important;
    box-shadow: none!important;
}

.botao-barra-pesquisa-limpar-desativado{
    cursor: default!important;
    float: left!important;
    height: 1.875em!important;
    width: 1.875em!important;
    border-color: transparent!important;
    border-radius: 0!important;
    box-shadow: none!important;
}

.botao-barra-pesquisa-dropdown-desativado{
    cursor: default!important;
    float: left!important;
    height: 1.875em!important;
    width: 2em!important;
    border-color: transparent!important;
    border-radius: 0em 3.125em 3.125em 0em!important;
    box-shadow: none!important;
}

/******************** DROPDOWN ALERTAS ********************/
.dropdown-item{
    white-space: normal!important;
}

.dropdown-item:hover{
    background-color: transparent!important;
}

.dropdown-item:focus{
    background-color: transparent!important;
}

.menu-dropdown-alerta-cabecalho-sistema{
    position: absolute!important;
    z-index: 9999;
    border: none!important;
    margin-top: 0.625em!important;
    width: 20.125em!important;
    border-radius: 0.15625em!important;
    padding: 1em!important;
    right: 9.5em!important;
}

.triangulo-menu-dropdown-alerta-cabecalho-sistema{
    position: absolute!important;
    z-index: 1!important;
    width: 0em!important;
    height: 0em!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-top: -1.5em!important;
    margin-left: 16.8em!important;
}

.dropdown-alerta-cabecalho-sistema{
    height: 2em!important;
    width: 2em!important;
    border-color: transparent!important;
    border-radius: 6.25em!important;
    background-color: transparent!important;
    font-size: 0.875em!important;
    font-weight: 700!important;
    line-height: 1em!important;
}

.icone-dropdown-alerta-cabecalho-sistema{
    margin-top: -0.6875em!important;
    margin-left: -0.4em!important;
}

.badge-dropdown-alerta-cabecalho-sistema{
    top: -1em !important;
    border-radius: 6.25em!important;
    line-height: 0.625em!important;
    /* background-color: #CD2D37 !important; */
}

.botao-dropdown-alerta-cabecalho-sistema{
    height: 2em!important;
    width: 2em!important;
    border-color: transparent!important;
    border-radius: 6.25em!important;
    background-color: transparent!important;
    box-shadow: none!important;
}

.botao-dropdown-alerta-cabecalho-sistema-2{
    z-index: 9999!important;
    height: 2em!important;
    width: 2em!important;
    border-color: transparent!important;
    border-radius: 6.25em!important;
    background-color: transparent!important;
    box-shadow: none!important;
}

.container-dropdown-alerta-cabecalho-sistema{
    display: flex!important;
}

.container-dropdown-alerta-cabecalho-sistema-2{
    margin-top: 0.1875em!important;
    margin-left: 0.4375em!important;
}

.container-elipse-alerta-cabecalho-sistema{
    border-radius: 3.125em!important;
    height: 0.3125em!important;
    width: 0.3125em!important;
    margin-top: 0.125em!important;
}

.icone-elipse-dropdown-alerta-cabecalho-sistema{
    margin-top: 1.875em!important;
    height: 0.3125em!important;
    width: 0.3125em!important;
}

.minuto-dropdown-alerta-cabecalho-sistema{
    white-space: nowrap!important;
    height: 0.875em!important;
    width: 2.3125em!important;
    font-family: RobotoRegular!important;
    font-size: 0.75em!important;
    line-height: 0.875em!important;
    margin-left: 14em!important;
    font-weight: 100!important;
}

.descricao-dropdown-alerta-cabecalho-sistema{
    padding: 0em!important;
    font-family: RobotoRegular;
    font-size: 0.875em!important;
    letter-spacing: 0.025em!important;
}

/******************** DROPDOWN MENSAGENS ********************/
.dropdown-mensagem-cabecalho-sistema{
    height: 2em!important;
    width: 2em!important;
    border-color: transparent!important;
    border-radius: 6.25em!important;
    background-color: transparent!important;
    font-size: 0.875em!important;
    font-weight: 700!important;
    line-height: 1em!important;
}

.botao-dropdown-mensagem-cabecalho-sistema{
    height: 2em!important;
    width: 2em!important;
    border-color: transparent!important;
    border-radius: 6.25em!important;
    background-color: transparent!important;
    box-shadow: none!important;
}

.botao-dropdown-mensagem-cabecalho-sistema-2{
    z-index: 9999!important;
    height: 2em!important;
    width: 2em!important;
    border-color: transparent!important;
    border-radius: 6.25em!important;
    background-color: transparent!important;
    box-shadow: none!important;
}

.icone-dropdown-mensagem-cabecalho-sistema{
    margin-top: -0.6875em!important;
    margin-left: -0.53125em!important;
}

.badge-dropdown-mensagem-cabecalho-sistema{
    top: -1em !important;
    border-radius: 6.25em!important;
    line-height: 0.625em!important;
}

.triangulo-menu-dropdown-mensagem-cabecalho-sistema{
    position: absolute!important;
    z-index: 1!important;
    width: 0!important;
    height: 0!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-top: -1.5em!important;
    margin-left: 16.8em!important;
}

.menu-dropdown-mensagem-cabecalho-sistema{
    position: absolute!important;
    z-index: 9999!important;
    border: none!important;
    margin-top: 0.625em!important;
    width: 20.125em!important;
    border-radius: 0.15625em!important;
    right: 6.3em!important;
    padding: 1em!important;
}

.container-email-dropdown-mensagem-cabecalho-sistema{
    display: flex!important;
    margin-top: 0.625em!important;
    margin-bottom: 0.625em!important;
}

.container-email-dropdown-mensagem-cabecalho-sistema-2{
    margin-top: 0.1875em!important;
    margin-left: 0.4375em!important;
}

.container-envelope-mensagem-cabecalho-sistema{
    height: 2.1875em!important;
    width: 2.1875em!important;
    margin-top: 0.125em!important;
}

.container-elipse-mensagem-cabecalho-sistema{
    height: 2.1875em!important;
    width: 2.1875em!important;
    margin-top: 0.4em!important;
}

.icone-email-dropdown-mensagem-cabecalho-sistema{
    margin-top: 0.25em!important;
    margin-left: 0.5625em!important;
    height: 0.9375em!important;
    width: 0.9375em!important;
}

.icone-envelope-fechado-dropdown-mensagem-cabecalho-sistema{
    margin-top: -5em!important;
    margin-left: 0.40625em!important;
    height: 1.25em!important;
    width: 1.25em!important;
}

.titulo-email-dropdown-mensagem-cabecalho-sistema{
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
    font-weight: 700!important;
    padding: 0em!important;
    line-height: 1em!important;
    display: flex!important;
    white-space: nowrap!important;
}

.minuto-email-dropdown-mensagem-cabecalho-sistema{
    white-space: nowrap!important;
    height: 0.875em!important;
    width: 2.3125em!important;
    font-family: RobotoRegular!important;
    font-size: 0.75em!important;
    line-height: 0.875em!important;
    position: absolute;
    left:84%;
    font-weight: 100!important;
}

.descricao-email-dropdown-mensagem-cabecalho-sistema{
    padding: 0em!important;
    height: 0.875em!important;
    width: 16.5em!important;
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
    letter-spacing: 0.025em;
}

.menu-dropdown-divisor-cabecalho-sistema-2{
    height: 0.0625em!important;
    margin-left: 5%!important;
    width: 90%!important;
}

.elipse-verde-mensagem{
    margin-top: 50%!important;
    margin-left: 50%!important;
    transform: translate(-50%, -10%)!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 6.25em!important;
    background-color: #36D896!important;
    color: #36D896!important;
}

.elipse-vermelho-mensagem{
    margin-top: 50%!important;
    margin-left: 50%!important;
    transform: translate(-50%, -10%)!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 6.25em!important;
    background-color: #EE423D!important;
    color: #EE423D!important;
}

.elipse-amarelo-mensagem{
    margin-top: 50%!important;
    margin-left: 50%!important;
    transform: translate(-50%, -10%)!important;
    width: 0.75em!important;
    height: 0.75em!important;
    border-radius: 6.25em!important;
    background-color: #FDCB62!important;
    color: #FDCB62!important;
}

/******************** DROPDOWN CONFIGURACOES ********************/
.botao-dropdown-configuracao-cabecalho-sistema{
    height: 2em!important;
    width: 2em!important;
    border-color: transparent!important;
    border-radius: 6.25em!important;
    background-color: transparent!important;
    box-shadow: none!important;
}

.botao-dropdown-configuracao-cabecalho-sistema-2{
    z-index: 9999!important;
    height: 2em!important;
    width: 2em!important;
    border-color: transparent!important;
    border-radius: 6.25em!important;
    background-color: transparent!important;
    box-shadow: none!important;
}

.botao-dropdown-informacoes{ 
    height: 2em!important;
    width: 2em!important;
    border-color: transparent!important;
    border-radius: 6.25em!important;
    background-color: transparent!important;
    box-shadow: none!important;
}

.botao-dropdown-informacoes-2{ 
    z-index: 9999!important;
    height: 2em!important;
    width: 2em!important;
    border-color: transparent!important;
    border-radius: 6.25em!important;
    background-color: transparent!important;
    box-shadow: none!important;
}

.menu-dropdown-configuracoes-cabecalho-sistema{
    position: absolute!important;
    z-index: 9999!important;
    border: none!important;
    margin-top: 0.625em!important;
    width: 9.125em!important;
    border-radius: 0.15625em!important;
    right: 2.5em!important;
    padding: 0em!important;
}

.triangulo-menu-dropdown-configuracoes-cabecalho-sistema{
    position: absolute!important;
    z-index: 1!important;
    width: 0!important;
    height: 0!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-top: -0.5em!important;
    margin-left: 6.8em!important;
}

.label-ver-todos-dropdown-cabecalho-sistema{
    /* color: #36D896!important; */
    font-family: RobotoRegular!important;
    font-size: 0.875em!important;
    font-weight: 700!important; 
    line-height: 1em!important;
    margin-top: 0.5625em!important;
    text-align: center!important;
}

.icone-dropdown-informacoes-cabecalho-sistema{
    margin-top: -0.6875em!important;
    margin-left: -0.5em!important;
}
.icone-dropdown-configuracao-cabecalho-sistema{
    margin-top: -0.6875em!important;
    margin-left: -0.5em!important;
}

.triangulo-menu-dropdown-configuracao-cabecalho-sistema{
    position: absolute!important;
    z-index: 1!important;
    width: 0!important;
    height: 0!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-top: -0.8em!important;
    margin-left: 7.125em!important;
}

.menu-dropdown-configuracao-cabecalho-sistema{
    padding: 0.2rem 0 !important;
    border: none!important;
    margin-top: 0.625em!important;
    width: 9em !important;
    min-width: 8em !important;
    border-radius: 0.15625em!important;
}

.label-menu-informacoes{
    font-family: Roboto !important;
    font-size: 0.875em !important;
    font-weight: 700 !important;
}

.label-submenu-informacoes{
    font-family: Roboto !important;
    font-size: 0.875em !important;
    margin-bottom: 0em !important;
    margin-top: 0em !important;
    margin-left: 1.8em !important;
    cursor: pointer !important;
}

.menu-informacoes-icone-desativado{
    float: right!important;
    margin-top: 0.4375em !important;
    transform: rotate(-90deg) !important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important
}

.menu-informacoes-icone-ativo{
    float: right!important;
    margin-top: 0.4375em !important;
    transform: rotate(-0deg) !important;
    -webkit-transition: 0.5s ease!important;
    -moz-transition: 0.5s ease!important;
    -o-transition: 0.5s ease!important;
    transition: 0.5s ease!important
}

.menu-informacoes-divisora{
    margin-left: 0.65em !important;
    width: 8.4375em !important;
    height: 0.0625em !important;
}

.triangulo-menu-dropdown-informacoes-cabecalho-sistema{
    position: absolute!important;
    z-index: 1!important;
    width: 0!important;
    height: 0!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-top: -0.5em!important;
    margin-left: 8.5em!important;
}

.menu-dropdown-informacoes-cabecalho-sistema{
    position: absolute!important;
    z-index: 9999!important;
    border: none!important;
    margin-top: 0.625em!important;
    width: 11.125em!important;
    border-radius: 0.15625em!important;
    right: 2em!important;
    padding: 0em!important;
}

.item-menu-dropdown-cabecalho-sistema{
    height: 1.85em!important;
    width: 100%!important;
    cursor: pointer!important;
}

.item-menu-dropdown-cabecalho-sistema-modo-noturno{
    display: flex!important;
    height: 1.85em!important;
    width: 100%!important;
    cursor: pointer!important;
}

.label-item-menu-dropdown-cabecalho-sistema{
    margin-left: 0.7em!important;
    font-family: Roboto!important;
    font-size: 0.875em!important;
    font-weight: 700!important;
    line-height: 1.875em!important;
    cursor: pointer!important;
    text-decoration: none !important;
}

.label-item-menu-dropdown-cabecalho-sistema-dasboard-sms{
    margin-left: 1.25em!important;
    font-weight: 700!important;
    line-height: 1.875em!important;
    cursor: pointer!important;
    text-decoration: none !important;
}

.menu-dropdown-divisor-cabecalho-sistema{
    height: 0.0625em!important;
    width: 88%!important;
    margin-left: 0.4625em!important;
}

.label-item-switch-menu-dropdown-cabecalho-sistema{
    display: flex!important;
    height: 0.875em!important;
    width: 1.8125em!important;
    margin-left: 1.5em!important;
    margin-top: 0.3em !important;
    margin-bottom: 0.3em !important;
    font-family: RobotoRegular!important;
    font-weight: 100!important;
    font-size: 0.75em!important;
    line-height: 0.875em!important;
}

.container-item-switch-menu-dropdown-cabecalho-sistema{
    display: flex!important;
    margin-bottom: 0.45em!important;
}

.container-switch-menu-dropdown-cabecalho-sistema{
    margin-left: 0.3125em!important;
    margin-right: 0.3125em!important;
    margin-top: 0.335em !important;
    position: relative!important;
    width: 1.875em!important;
    -webkit-user-select:none!important;
    -moz-user-select:none!important;
    -ms-user-select: none!important;
    float: left!important;
}

.container-switch-menu-dropdown-cabecalho-sistema input[type=checkbox] {
    display: none!important;
}

/******************** MODAL TUTORIAIS ********************/
.modal-acao-tutoriais, .modal-acao-novidades {/* POSICIONA A MODAL CORRETAMENTE NO CENTRO DA TELA E NA BORDA DO CABECALHO */
    padding: .5em 4em 2em 4em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 85em!important;
    /* height: 65em!important; */
}

.modal-acao-novidades {
    width: 37.5em!important;
    height: 100vh!important;
}

/**********************ACOES**********************/
.drop-separador-linha-container{
    position: absolute!important;
    z-index: 10000!important;
    width: 16.25em !important;
    border-radius: 0.3125em !important;
    background-color: transparent!important;
    border: 0 !important;
    left: 49.55%;
    top: 5.6%;
}

.separador-automatico-container{
    font-size: 12px;
    font-weight: 400;
    padding: 16px;
}

.icone-separador{
    color: #12B886;
    font-weight: bold;
}

.triangulo-separador-linha-modal {
    width: 0!important;
    height: 0!important;
    border-top: 0.3375em solid transparent!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-left: 13.7em!important;
    margin-top: -0.9em!important;
    z-index: -1;
    position: fixed;
}



.triangulo-separador-linha-simcard-modal {
    width: 0!important;
    height: 0!important;
    border-top: 0.3375em solid transparent!important;
    border-left: 0.9375em solid transparent!important;
    border-right: 0.9375em solid transparent!important;
    margin-left: 9.2em!important;
    margin-top: -0.9em!important;
    z-index: -1;
    position: fixed;
}

.label-radio-separador-linha {
    margin-left: 1em !important;
    margin-top: 0.75em !important;
    margin-bottom: 0.65em !important;
    display: inline-block;
    position: relative;
    padding-left: 2em!important;
    cursor: pointer;
    height: 1em;
    width: 14.625em;
    font-family: RobotoRegular;
    font-size: 0.875em!important;
    line-height: 1em;
    text-align: initial !important;
    -webkit-user-select: none;
    user-select: none;
}

.label-radio-separador-linha-switch {
    margin-left: -1.8em !important;
    margin-top: 0.75em !important;
    margin-bottom: 0.65em !important;
    display: inline-block;
    position: relative;
    padding-left: 2em!important;
    /* cursor: pointer; */
    height: 1em;
    width: 14.625em;
    font-family: RobotoRegular;
    font-size: 0.875em!important;
    line-height: 1em;
    text-align: initial !important;
    -webkit-user-select: none;
    user-select: none;
}

.container-switch-separador-linha{
    margin-left: 0.3125em!important;
    margin-right: 0.3125em!important;
    margin-top: 0.5em !important;
    position: relative!important;
    width: 1.875em!important;
    -webkit-user-select:none!important;
    -moz-user-select:none!important;
    -ms-user-select: none!important;
    float: left!important;
}

.container-switch-separador-linha input[type=checkbox] {
    display: none!important;
}

.label-radio-separador-linha input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.label-radio-separador-linha:hover input ~ .checkbox-filtros {
    background-color: #36D896;
}

/* When the radio button is checked, add a blue background */
.label-radio-separador-linha input:checked ~ .checkbox-filtros {
    background-color: transparent!important;
}

/* Show the indicator (dot/circle) when checked */
.label-radio-separador-linha input:checked ~ .checkbox-filtros:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.label-radio-separador-linha .checkbox-filtros:after {
    top: 0.15625em;
    left: 0.15625em;
    width: 0.5625em;
    height: 0.5625em;
    border-radius: 50%;
    background: #36D896;
}

.tooltip-notificacoes{
    position: absolute!important;
    margin-left: 13px!important;
    margin-bottom: 230px!important;
}

.tooltip-mensagens{
    position: absolute!important;
    margin-left: 11px!important;
    margin-bottom: 230px!important;
}

.tooltip-configuracoes{
    position: absolute!important;
    margin-left: 11px!important;
    margin-bottom: 230px!important;
}

.tooltip-faturas-ciclo-vida{
    position: absolute!important;
    /* margin-left: 11px!important; */
    margin-bottom: 170px!important;
}

.tooltip-informacoes{ 
    position: absolute!important;
    margin-left: 11px!important;
    margin-bottom: 230px!important;
}

.tooltip-informacoes-tutoriais{ 
    position: absolute!important;
    z-index: 999999!important;
}

#div-novidades > .badge {
    margin-left: 99px;
    position: absolute;
    top: 114px!important;
}