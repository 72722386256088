.drop-dados-conta-divisora {
    width: 101%!important;
    height: 0.0625em !important;
}

.btn-editar-habilitar-dados{
    margin-bottom: 1em;
    margin-top: 1em;
}

.corpo-dados-usuario-email{
    margin-right: 1em;
}

.corpo-dados-usuario-endereco{
    width: 32.75em;
    margin-right: 1em;
}

.corpo-dados-usuario-numero{
    width: 6em;
}

.usuario-dados-corpo-linha{
    margin-top: 1em !important;
}

.usuario-dados-corpo-linha-alterar-senha{
    margin-top: 1em !important;
    display: inline !important;
}

.conta-dados-divisoria-corpo{
    margin-top: 1.5em;
}
/***********PERFIL*************/
.label-janela-perfil{
    font-family: RobotoRegular!important;
    font-size: 1.875em!important;
    line-height: 1.8125em!important;
    font-weight: 700!important;
}

.icone-usuario-perfil {
    width: 8.5em!important;
    height: 8.5em!important;
    border-radius: 100%!important;
    border: 0.375em solid #36D896!important;
}

.botao-importar-foto{
    margin-left: -2em!important;
    height: 2.325em!important;
    width: 2.325em!important;
    border-radius: 6.25em!important;
    background-color: #36D896!important;
    box-shadow: none!important;
    border: 0em!important;
}

.icone-botao-importar-foto{
    height: 1.775em!important;
    width: 1.775em!important;
    margin-top: -0.25em!important;
    margin-left: -0.45em!important;
}
.label-dados-perfil-usuario{
    display: grid;
    align-content: center;
    margin-left: 1em;
}
.label-dados-perfil-usuario-empresa{
    align-content: center;
}
.label-dados-perfil-usuario-ultimo-acesso{
    margin-top: 1em;
}
.label-dados-perfil-usuario-login{
    margin-right: 0.5em;
}

.label-dados-perfil-usuario-cpf{
    margin-left: 5.5em !important;
}

/**********Alterar senha***********/
.btn-olho-visualizar-senha {
    padding: 0em !important;
    height: 2em !important;
    z-index: 99 !important;
    margin-left: -1.9em !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent!important;
    padding-bottom: 0.6em!important;
}
.modal-alterar-senha{
    padding: .5em 2em 2em 2em!important;
    border-radius: 0.625em 0.625em 0.625em 0.625em!important;
    width: 41.875em!important;
}

.label-overflow{
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    width: 646%;
}

.label-termos-dados-conta{
    float: left!important;
    margin-top: 0.71em!important;
    line-height: 1.375em!important;
}

.container-empresa-cpf{
    display: flex !important;
}