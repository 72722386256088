
.container-cabecalho-tabela-sistema-cadastro-permissao{
    background-color: #36D896!important;
    height: 3.75em!important;
    border-radius: 0.3125em 0.3125em 0em 0em!important;
    padding-top: 0.375em!important;
    display: flex!important;
    justify-content: center!important;
}

.container-cabecalho-tabela-modulo-cadastro-permissao{
    background-color: #FDCB62!important;
    height: 3.75em!important;
    border-radius: 0.3125em 0.3125em 0em 0em!important;
    padding-top: 0.375em!important;
    display: flex!important;
    justify-content: center!important;
}

.container-cabecalho-tabela-tela-cadastro-permissao{
    background-color: #CD2D37!important;
    height: 3.75em!important;
    border-radius: 0.3125em 0.3125em 0em 0em!important;
    padding-top: 0.375em!important;
    display: flex!important;
    justify-content: center!important;
}

.container-cabecalho-tabela-elemento-cadastro-permissao{
    background-color: #1F8EFA!important;
    height: 3.75em!important;
    border-radius: 0.3125em 0.3125em 0em 0em!important;
    padding-top: 0.375em!important;
    display: flex!important;
    justify-content: center!important;
}


.grupo-cadastro-permissao-1{
    display: flex !important;
    height: 5.5em;
}

.coluna-cadastro-permissao-2 {
    width: 40% !important;
    margin-left: 7% !important;
    margin-top: 0.7em;
}

.coluna-cadastro-permissao-3 {
    width: 40% !important;
    margin-left: 5% !important;
    margin-top: 0.7em;
}

.col-permissoes-cadastro{
    max-height: 34em;
    overflow: hidden;
}

.form-permissoes-cadastro{
    margin: 0px !important;
}

.container-tab-elementos-permissoes-cadastro{
    padding-top: 4%;
    padding-left: 2%;
    display: flex!important;
    flex-direction: column!important;
    flex-wrap: wrap!important;
    height: 47em;
}

.modulos-cadastro-usuario-cliente-default{
    width: 17%!important;
    height: 50em !important;
}

.permissoes-cadastro-col-default{
    width:83%!important;
}

.container-botoes-cadastro-permissao {
    width: 18em !important;
    height: 2.5em !important;
    bottom: 2% !important;
    position: absolute !important;
    left: 82.5% !important;
}

.elementos-permissoes-cadastro-usuario-permissoes{
    background-color: transparent!important;
    display: flex !important;
    text-align: center !important;
    width: 90% !important;
    margin: auto !important;
}

.tab-elementos-permissoes-cadastro-permissao-ativado{
    padding-left: 0px!important;
    padding-right: 0px!important;
    width: 46% !important;
    border-bottom: 0.2em solid #36D896 !important;
}

.tab-elementos-permissoes-cadastro-permissao-desativado {
    padding-left: 0px!important;
    padding-right: 0px!important;
    width: 46% !important;
}

.container-topo-cadastro-permissao{
    width: 99.5% !important;
}

.container-principal-permissoes{
    width: 99.5% !important;
}

/******************** RADIO BUTTONS ********************/
.container-radio-button-cadastro-permissao-modulo {
    margin-left: 1em;
    margin-top: 1.3em;
}

.container-radio-button-cadastro-permissao-tela{
    margin-left: 0.4em;
    margin-top: 0.35em;
}


.container-radio-button-cadastro-permissao input {/* ESCONDE O RADIO BUTTON NATIVO */
    opacity: 0!important;
    cursor: pointer!important;
}

.radio-button-cadastro-permissao{/* CRIA O RADIO BUTTON */
    position: relative!important;
    margin-left: 0em!important;
    margin-top: 0.3125em!important;
    float: left!important;
    height: 0.9375em!important;
    width: 0.9375em!important;
    background-color: #252827!important;
    border-radius: 50%!important;
    cursor: pointer!important;
}

.container-radio-button-cadastro-permissao .radio-button-cadastro-permissao:after {/* CRIA E DIMENSIONA A ESFERA DO RADIO BUTTON */
    content: ""!important;
    position: absolute!important;
    display: none!important;
    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%, -50%)!important;
    width: 0.5em!important;
    height: 0.5em!important;
    border-radius: 50%!important;
    background: #36D896!important;
}

.container-radio-button-cadastro-permissao input:checked ~ .radio-button-cadastro-permissao:after{/* MOSTRA A ESFERAD DO RADIO BUTTON SOMENTE NO RADIO ATIVADO */
    display: block!important;
}