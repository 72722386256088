.teste-conectividade-box-botoes{
    height: 112px;
    align-items: center;
    border-top: 1px solid #B1B1B1;
    margin: 20px 0px;
    /* padding-top: 24px; */
}

.teste-conectividade-box-botoes .form-group p {
    margin-bottom: 25px;
}

.botao-voltar-desabilitado:disabled{
    border: 0.09375em solid #B1B1B1!important;
}

.botao-voltar-desabilitado-texto{
    color: #B1B1B1;
}

.botao-confirmar-branco {
    display: flex!important;
    align-items: center;
}

.img-exportar-relatorio-teste{
    margin-left: 0.75em;
}