.container-infoHeader-analise-excedentes {
  display: flex;
  justify-content: center;
  gap: 80px;
}

.infoHeader-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoHeader-box > span {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
}

.infoHeader-box > h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 17.5px;
  text-align: left;
  margin-bottom: 0px;
}

.infoHeader-box > p {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.5px;
  text-align: center;
  margin: 0;
}
